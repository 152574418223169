import Column from '../../Grid/column/Column.js';
import ColumnStore from '../../Grid/data/ColumnStore.js';
import DomSync from '../../Core/helper/DomSync.js';
import DH from '../../Core/helper/DateHelper.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
/**
 * @module Calendar/column/AgendaColumn
 */
/**
 * A column that displays events for a day.
 *
 * This is not intended to be used directly, it is used by the {@link Calendar.widget.AgendaView} which
 * is a specialized {@link Grid.view.Grid} subclass.
 *
 * @extends Grid/column/Column
 *
 * @classtype agendacolumn
 * @column
 */
export default class AgendaColumn extends Column {
    //region Config
    static $name = 'AgendaColumn';
    static get type() {
        return 'agendacolumn';
    }
    static get defaults() {
        return {
            id         : 'agenda', 
            text       : 'Agenda',
            filterable : false,
            sortable   : false,
            editor     : false,
            searchable : false,
            // With its defaultRenderer, and no eventRenderer, AgendaColumn measures
            // once then is able to calculate row heights from the cell's event count.
            // This field is automatically set to true if a column renderer or eventRenderer
            // is found.
            autoHeight      : false,
            alwaysClearCell : false
        };
    }
    //endregion
    construct() {
        super.construct(...arguments);
        this.grid.ion({
            beforeResponsiveStateChange : 'onBeforeGridResponsiveStateChange',
            thisObj                     : this
        });
    }
    //region Render
    /**
     * Renderer that shows a list of events for a single date.
     *
     * Note that all {@link Calendar.widget.AgendaView} columns are automatically set to
     * {@link Grid.column.Column#config-autoHeight} because an unpredictable number of events may appear in
     * any cell.
     *
     * @param {Object} renderData Object containing renderer parameters
     * @param {HTMLElement} renderData.cellElement Cell element, for adding CSS classes, styling etc. Can be `null` in case of export
     * @param {*} renderData.value Value to be displayed in the cell
     * @param {DayCell} renderData.record A {@link Calendar.widget.mixin.DayCellCollecter#typedef-DayCell} context object
     *   which contains contextual information about the date being rendered and an `events` property encapsulating
     *   all the events which coincide with the date.
     * @param {Grid.column.Column} renderData.column This column
     * @param {Grid.view.Grid} renderData.grid This grid
     * @param {Grid.row.Row} renderData.row Row object. Can be null in case of export. Use the
     *   {@link Grid.row.Row#function-assignCls row's API} to manipulate CSS class names.
     * @param {Boolean} renderData.isExport True if record is being exported to allow special handling during export
     * @param {Boolean} renderData.isMeasuring True if the column is being measured for a `resizeToFitContent` call.
     *   In which case an advanced renderer might need to take different actions.
     * @returns {DomConfig|String|null}
     */
    defaultRenderer({ cellElement : targetElement, record, grid, size }) {
        const
            me = this,
            {
                events,
                date
            } = record,
            children = [];
        for (let i = 0; i < events.length; i++) {
            const
                eventRecord = events[i],
                eventEndDate = eventRecord.endDate || DH.add(eventRecord.startDate, eventRecord.duration, eventRecord.durationUnit),
                isOverflow = eventRecord.startDate < date,
                overflows = eventEndDate > record.tomorrow,
                eventDomConfig = grid.createEventDomConfig({
                    date     : record.date,
                    isAllDay : eventRecord.allDay || overflows || isOverflow,
                    eventRecord
                });
            // Arrow shows that it continues
            eventDomConfig.className['b-continues-future'] = overflows;
            eventDomConfig.className['b-continues-past'] = isOverflow;
            children.push({
                className : {
                    'b-cal-agenda-event-row' : 1
                },
                dataset : {
                    rowId : eventRecord.id
                },
                children : [
                    grid.eventListEventTimeRenderer(eventRecord, date),
                    eventDomConfig
                ],
                // Match existing data-rowId elements first and ensure DOM order matches
                // children order.
                syncOptions : {
                    syncIdField      : 'rowId',
                    releaseThreshold : 0,
                    strict           : true
                }
            });
        }
        grid._cellRenderer?.(...arguments);
        // cellRenderer may update the cellData record, so test after.
        targetElement.classList[record.isNonWorking ? 'add' : 'remove'](grid.nonWorkingDayCls);
        DomSync.sync({
            domConfig : {
                onlyChildren : true,
                children     : [
                    grid.agendaEventDateRenderer(date),
                    {
                        className : {
                            'b-cal-event-bar-container' : 1
                        },
                        children
                    }
                ],
                // Match existing data-row-id elements first and ensure DOM order matches
                // children order.
                syncOptions : {
                    syncIdField      : 'date',
                    releaseThreshold : 0,
                    strict           : true
                }
            },
            targetElement
        });
        targetElement.dataset.date = record.id;
        if (!me.autoHeight) {
            const
                dateBlock = targetElement.querySelector('.b-cal-agenda-date'),
                cellTbPadding = me._cellTbPadding != null ? me._cellTbPadding : (
                    me._cellTbPadding = DomHelper.getEdgeSize(targetElement, 'padding', 'tb').height
                ),
                containerPadding = me._containerPadding != null ? me._containerPadding : (
                    me._containerPadding = DomHelper.getEdgeSize(targetElement.querySelector('.b-cal-event-bar-container'), 'margin', 'tb').height
                ),
                dateBlockHeight = me._dateBlockHeight != null ? me._dateBlockHeight : (
                    me._dateBlockHeight = Rectangle.outer(dateBlock).height
                );
            size.height = Math.max(record.events.length * (grid.eventHeightInPixels + grid.eventRowSpacing) - grid.eventRowSpacing + cellTbPadding + containerPadding + (grid.responsiveState === 'small' ? dateBlockHeight : 0), 86);
        }
    }
    onBeforeGridResponsiveStateChange() {
        // Invalidate cached measurements
        this._cellTbPadding = this._containerPadding = this._dateBlockHeight = this._dateBlockHeight = null;
    }
    getCellClass(cellContext) {
        const result = super.getCellClass(cellContext);
        result['b-sticky-cell'] = result[`b-day-of-week-${cellContext.record.day}`] = 1;
        return result;
    }
    //endregion
    //region Other
    headerRenderer({ headerElement : targetElement }) {
        targetElement.innerHTML = '';
        DomSync.sync({
            domConfig : {
                className : {
                    'b-cal-agenda-header' : 1,
                    'b-grid-header'       : 1,
                    'b-last-leaf'         : 1
                },
                children : [{
                    className : {
                        'b-cal-agenda-header-date' : 1
                    },
                    text : 'Date'
                }, {
                    className : {
                        'b-cal-agenda-header-time' : 1
                    },
                    text : 'Time'
                }, {
                    className : {
                        'b-cal-agenda-header-event' : 1
                    },
                    text : 'Event'
                }]
            },
            targetElement
        });
    }
    //endregion
}
ColumnStore.registerColumnType(AgendaColumn);
AgendaColumn._$name = 'AgendaColumn';