import CalendarMixin from './mixin/CalendarMixin.js';
import DayCellCollecter from './mixin/DayCellCollecter.js';
import DayTime from '../../Core/util/DayTime.js';
import Panel from '../../Core/widget/Panel.js';
import DH from '../../Core/helper/DateHelper.js';
import EventHelper from '../../Core/helper/EventHelper.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
import DomSync from '../../Core/helper/DomSync.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import Scroller from '../../Core/helper/util/Scroller.js';
import ResizeMonitor from '../../Core/helper/ResizeMonitor.js';
import CalendarRow from './CalendarRow.js';
import DayLayout from '../layout/day/DayLayout.js';
import DaySelectable from './mixin/DaySelectable.js';
import '../layout/day/FluidDayLayout.js';
import GlobalEvents from '../../Core/GlobalEvents.js';
import DateRangeOwner from './mixin/DateRangeOwner.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import ArrayHelper from '../../Core/helper/ArrayHelper.js';
/**
 * @module Calendar/widget/DayView
 */
const
    { MILLIS_PER_HOUR } = DayTime,
    // We need the full longest date because intervening ticks may now be shown.
    widestDate          = new Date(2020, 10, 22, 22, 59, 59),
    blockedAllDayEvents = {
        paint               : 1,
        refresh             : 1,
        resize              : 1,
        eventspercellchange : 1
    },
    stopEvent = e => {
        e.stopImmediatePropagation();
        e.cancelBubble = true;
    },
    preventDefault = e => e.ctrlKey && e.preventDefault(),
    hourHeightLevelZero = {
        level : 0,
        step  : '1 hour'
    },
    zeroRect = new Rectangle(0, 0, 0, 0),
    fiveMinuteSteps = [
        '30 minutes',
        '15 minutes',
        '10 minutes',
        '5 minutes'
    ],
    sixMinuteSteps = [
        '30 minutes',
        '12 minutes',
        '6 minutes'
    ],
    extractEventRecord = e => e.eventRecord || e,
    emptyArray = Object.freeze([]);
/**
 * An object which contains properties which encapsulate hour height breakpoints which dictate
 * when intervening time indicators are introduced into the DayView's time axis.
 *
 * @typedef {Object} HourHeightBreakPoints
 * @property {Number} thirty The height at which half hour time is displayed.
 * @property {Number} fifteen The height at which all fifteen minute times are displayed.
 * @property {Number} ten The height at which all ten minute times are displayed.
 * @property {Number} five The height at which all five minute times are displayed.
 */
/**
 * An object which describes the properties of action buttons injected into event blocks.
 *
 * @typedef {Object<String,String|Function>} ActionButtonsConfig
 * @property {String} cls A class name to add to the button element. It may be of the form
 * `b-icon-xxxx` which will use a font-awesome icon by that name.
 * @property {String} tooltip The tooltip to show when the button is hovered.
 * @property {String|Function} handler A function, or the name of a function in the ownership hierarchy
 * to call when the button is clicked.
 */
/**
 * This is normally used as a {@link Calendar.view.Calendar#config-modes mode} of a Calendar (as seen in the live
 * demo below), but may be used standalone as a regular Widget.
 *
 * {@inlineexample Calendar/widget/CalendarDayView.js}
 *
 * As a standalone widget, it will lack the capabilities of the {@link Calendar.view.Calendar Calendar}
 * class, such as keyboard-based event to event navigation and drag/drop features. As seen in this demo:
 *
 * {@inlineexample Calendar/widget/DayView.js}
 *
 * A Panel which displays one or more columns of days with events for each day arranged in ascending
 * time order in each column.
 *
 * This view is *not* limited to showing weeks. The {@link #config-startDate} and {@link #config-endDate}
 * may be configured to any date value. When the {@link #config-startDate} is changed dynamically,
 * the duration remains the same.
 *
 * This view can be configured to scroll to the specific time on first render, which defaults to 7 AM. This behavior
 * is controlled by the {@link #config-visibleStartTime} config.
 *
 * A {@link Calendar.widget.WeekView WeekView} is a subclass of this view which is tied to showing
 * weeks as defined by the {@link Calendar.widget.mixin.CalendarMixin#config-weekStartDay}.
 *
 * ## Multi day events.
 *
 * All day events, and multi day events are displayed in a {@link #config-allDayEvents row at the top}.
 *
 * Intraday events are arranged in ascending time order down day columns from the {@link #config-dayStartTime} to
 * the {@link #config-dayEndTime}.
 *
 * The {@link #config-showAllDayHeader} config option can be used to *not* show multi day events at
 * the top, but have them wrap across multiple day columns.
 *
 * The following configs which apply to the all day row are passed into the configuration of the
 * {@link #config-allDayEvents} widget:
 * - {@link Calendar.widget.CalendarRow#config-overflowPopup}
 * - {@link Calendar.widget.CalendarRow#config-overflowButtonRenderer}
 * - {@link Calendar.widget.CalendarRow#config-overflowClickAction}
 * - {@link Calendar.widget.CalendarRow#config-dayHeaderRenderer}
 *
 * Event rendering can be customized using the {@link #config-eventRenderer} method.
 *
 * @extends Core/widget/Panel
 * @mixes Core/widget/mixin/Responsive
 * @mixes Calendar/widget/mixin/CalendarMixin
 * @mixes Calendar/widget/mixin/DayCellCollecter
 * @mixes Calendar/widget/mixin/DateRangeOwner
 *
 * @classtype dayview
 * @classtypealias day
 * @typingswidget
 */
export default class DayView extends Panel.mixin(
    CalendarMixin, DayCellCollecter, DaySelectable, DateRangeOwner) {
    static $name = 'DayView';
    static type = 'dayview';
    static get delayable() {
        return {
            updateElementLayout : {
                type              : 'raf',
                cancelOutstanding : true
            },
            refreshDayBackground : {
                type              : 'raf',
                cancelOutstanding : true
            },
            adjustCoreHours : {
                type              : 'raf',
                cancelOutstanding : true
            }
        };
    }
    static get configurable() {
        // region Hidden configs
        /**
         * @hideconfigs shortEventDuration
         * @hide
         */
        /**
         * @hideconfigs eventHeight
         */
        // endRegion
        return {
            // have to add `timeFormat` property to `localizableProperties` even if the iterator in `Localizable` mixin
            // will be iterating over configs with `localeKey` in addition to `localizableProperties`
            // completely unclear why - the property value is localized, but rendering seem to use the old value
            localizableProperties : ['timeFormat'],
            layout : 'vbox',
            textContent : false,
            scrollable : {
                overflowY : true
            },
            title : 'L{Day}',
            eventHeight : 'auto',
            /**
             * The height __in pixels__ of one hour cell in a day column.
             *
             * Hour cells are bounded by lines, and there is a fainter half hour line in the middle.
             * @prp {Number}
             * @default
             */
            hourHeight : 42,
            range : {
                magnitude : 1,
                unit      : 'day'
            },
            /**
             * By default, reconfiguring the {@link #config-startDate} maintains the current duration
             * of the view and moves the {@link #config-endDate}.
             *
             * But for flexibility, reconfiguring the {@link #config-endDate} changes the duration.
             *
             * Setting `fixedDuration` to `true` locks this down to changing either end just moves
             * the view.
             * @config {Boolean}
             */
            fixedDuration : null,
            /**
             * Either the start hour of the day, or a *24 hour * `HH:MM` string denoting the initially visible start
             * time of the day. Configure this as `null` to not have the view scroll to an initial position.
             * @default
             * @config {String|Number} [visibleStartTime]
             */
            visibleStartTime : 7,
            /**
             * Either the start hour of the day, or a *24 hour* `HH:MM` string denoting the start time for days. This
             * is midnight by default.
             *
             * Setting this value to 12, for example, indicates that the 24 hour "day" runs from noon on one day, to
             * noon on the following day. This causes events in this span of time to layout in the same day column. In
             * this example, a two hour event that spanned midnight would be rendered in the same way a normal, 2 hour
             * event spanning noon would be rendered without this option.
             *
             * When this config is non-zero, the headings that display the day name and number are adjusted to indicate
             * the range of days for the column. For example, the "Wed" column for the 12th of the month will now show
             * "Wed-Thu" for the day name and "12-13" for the day number.
             *
             * @config {String|Number}
             * @default 0
             */
            dayStartShift : null,
            /**
             * The **configured** start time of the day, expressed in ms (8am would be represented as 8 * 60 * 60 * 1000).
             * You can set this value to either an hour value (0-23), a *24 hour* `HH:MM` string denoting the start of
             * the first rendered daily time block or to a ms timestamp representing time from midnight.
             *
             * Note that this is only granular to the hour level. The value will be rounded __down__
             * to the starting hour of the specified time.
             * @member {Number} dayStartTime
             */
            /**
             * Either the start hour of the day, or a *24 hour* `HH:MM` string denoting the start of the first rendered
             * daily time block. You can also set this value to a ms timestamp representing time from midnight.
             *
             * This config, along with {@link #config-dayEndTime} determines which hours are displayed in a day column.
             *
             * Note that this is only granular to the hour level. The value will be rounded __down__
             * to the starting hour of the configured time.
             * @config {String|Number}
             * @default 0
             */
            dayStartTime : null,
            /**
             * The **configured** end time of the day, expressed in ms (6pm would be represented as 18 * 60 * 60 * 1000).
             * You can set this value to either an hour value (1-24), a *24 hour* `HH:MM` string denoting the end of
             * the last rendered daily time block or to a ms timestamp representing time from midnight.
             *
             * Note that this is only granular to the hour level. The value will be rounded __up__
             * to the ending hour of the specified time.
             * @member {String|Number} dayEndTime
             */
            /**
             * Either the end hour of the day, or a *24 hour* `HH:MM` string denoting the end date of the last rendered time block.
             * You can also set this value to a ms timestamp representing time from midnight.
             *
             * This config, along with {@link #config-dayStartTime} determines which hours are displayed in a day column.
             *
             * Note that this is only granular to the hour level. The value will be rounded __up__
             * to the ending hour of the configured time.
             * @config {String|Number}
             * @default 24
             */
            dayEndTime : null,
            dayTime : 0,   // ensure the change/update cycle runs using dayStart/EndTime and dayStartShift
            /**
             * A millisecond value to which to snap pointer times when clicking or dragging within a day column.
             *
             * May be specified in string form eg: `'15 minutes'`
             *
             * By default, the pointer position is __rounded__ to the nearest `increment`, but this can be
             * configured using the {@link #config-timeSnapType} option.
             * @prp {Number}
             * @accepts {String|Number}
             * @default
             */
            increment : '15 min',
            /**
             * The type of rounding to apply when calculating a date from a pointer position in a day
             * column.
             *
             * This defaults to `'round`', but may also be `'floor'` or `'ceil'`. A pointer position will
             * be snapped to the appropriate {@link #config-increment}.
             * @prp {'round'|'ceil'|'floor'}
             * @default
             */
            timeSnapType : 'round',
            intradayCls : null,
            /**
             * Configure as `false` to hide the start time normally shown at the top of the events.
             *
             * @config {Boolean}
             * @default
             */
            showTime   : true,
            showBullet : false,
            iconTarget : 'header',
            /**
             * Configuration to manage event layout class.
             * See {@link Calendar.layout.day.FluidDayLayout} class docs to see all possible configurations.
             *
             * Defaults to `{ type : 'fluid' }`.
             *
             * @config {FluidDayLayoutConfig}
             */
            eventLayout : {
                type : 'fluid'
            },
            /**
             * The minimum width of a day column.
             *
             * If this is set, and the day columns overflow the horizontal space available, the columns
             * will be scrollable horizontally in the normal way.
             *
             * There is a {@link #property-horizontalScroller} property which handles scrolling in this
             * dimension.
             * @prp {Number|String}
             */
            minDayWidth : null,
            // In days, event colour means background color
            eventColourStyleProperty : 'backgroundColor',
            monitorResize : Boolean(DomHelper.scrollBarWidth), // Need to adjust the width of the header
            eventFilter : undefined,
            timeFormat : {
                value   : 'LST',
                $config : {
                    localeKey : 'L{timeFormat}'
                }
            },
            /**
             * If {@link #config-showAllDayHeader} is not set to `false`, then this will be an instance
             * of {@link Calendar.widget.CalendarRow} which encapsulates the all day events at the top
             * of this view.
             * @member {Calendar.widget.CalendarRow} allDayEvents
             * @readonly
             */
            /**
             * A {@link Calendar.widget.CalendarRow} widget containing the horizontal series of calendar cells with the
             * day headers and any all-day, or day-spanning events which fall inside this view's time range.
             *
             * Note that this component calculates its height depending on its
             * {@link Calendar.widget.CalendarRow#config-eventHeight},
             * {@link Calendar.widget.CalendarRow#config-defaultEventRowCount} and
             * {@link Calendar.widget.CalendarRow#config-autoHeight} settings, therefore any configured `height`
             * and `flex` values will be ignored.
             *
             * ```javascript
             *     modes : {
             *         day : {
             *             // Do not show the whole week in the header for the one day view
             *             allDayEvents : {
             *                 fullWeek : false
             *             }
             *         }
             *     }
             * ```
             * @config {Calendar.widget.CalendarRow|CalendarRowConfig}
             */
            allDayEvents : {
                $config : ['lazy', 'nullify'],
                value : {
                    type : 'calendarrow'
                }
            },
            /**
             * A config object used to create the {@link Calendar.widget.OverflowPopup} that the
             * {@link #property-allDayEvents} may show when events for one day overflow the available space.
             *
             * For example
             *
             *```javascript
             *     modes : {
             *         week : {
             *             overflowPopup : {
             *                 closable   : false,
             *                 dateFormat : 'dddd, MMM M',
             *                 eventRenderer({ eventRecord, renderData }) {
             *                     if (calendarUtils.isImportantEvent(eventRecord)) {
             *                         // Add CSS class to important events
             *                         renderData.cls['b-important'] = 1;
             *                     }
             *                 }
             *             }
             *         }
             *     }
             *```
             * @config {OverflowPopupConfig}
             */
            overflowPopup : null,
            /**
             * Shows an all day header above the main schedule for All Day events.
             * Set to `false` to not show all day, or multi-day events in an all day header, but
             * to have them showing wrapping through the day cells.
             * @config {Boolean}
             * @default true
             */
            showAllDayHeader : {
                value   : null,
                $config : null,
                default : true
            },
            /**
             * Number of pixels to reduce the height of events by, to leave a gap between them.
             * @config {Number}
             * @default
             */
            eventSpacing : 1,
            responsive : {
                small : {
                    descriptionFormat : 'MMMM YYYY'
                },
                '*' : {
                    descriptionFormat : null
                }
            },
            /**
             * The maximum height the all day event row is allowed to grow within this view
             * when it is expanded to show all its "all day" events.
             *
             * This defaults to `50%`, but can also be set to any CSS size value. A numeric
             * value will be taken as pixels.
             * @config {Number|String}
             * @default 50%
             */
            maxAllDayHeight : null,
            /**
             * When set to `true`, the hours in the day will be sized so that they fit in the available
             * height.
             *
             * In the Object form, the value may contain `minHeight` as the minimum hour height to which
             * the hour cells may shrink:
             *
             * ```javascript
             * fitHours : {
             *     minHeight : 31
             * }
             * ```
             *
             * Note that if the all day events row at the top changes size, the space available for the
             * hours will change too, and the hour cell height will change.
             * @prp {Boolean|Object}
             * @default false
             */
            fitHours : null,
            /**
             * A function, or name of a function which is passed the {@link DomConfig} object which
             * will be used to sync with a day column.
             *
             * ```javascript
             * dayCellRenderer : function(domConfig, cellData) {
             *     if (this.isSpecialDate(cellData.date)) {
             *         domConfig.className['b-fa'] =
             *         domConfig.className['b-fa-birthday-cake'] = 1;
             *     }
             *     return domConfig;
             * }
             *```
             *
             * The result is used to sync the DOM of the day column.
             *
             * @config {Function|String} dayCellRenderer
             * @param {DomConfig} domConfig A {@link DomConfig} config object which is used to sync the day column element.
             * @param {Object} domConfig.className An object who's truthy property names will be applied as class names.
             * @param {Object} domConfig.style A CSS style definition object.
             * @param {Object} domConfig.dataset The DOM data properties to set.
             * @param {DomConfig[]} children The {@link DomConfig} definitions for the events in the day.
             * @param {DayCell} cellData An object that contains data about the cell.
             * @returns {String}
             */
            dayCellRenderer : null,
            /**
             * An object containing two properties, `start` and `end` representing the start and
             * end of core working hours.
             *
             * This causes the non core hours to be covered by a themeable translucent grey mask
             * in the time axis.
             *
             * This may be configured to also mask the non core hours in the day part of the view
             * by setting the `overlayDay` property.
             *
             * ```javascript
             * {
             *     coreHours : {
             *         start      : 9,
             *         end        : 17,
             *         overlayDay : true
             *     }
             * }
             * ```
             *
             * If this is configured as an array of less than seven elements, it is taken to be
             * describing __multiple__ core hours blocks within one day, so it could be
             *
             * ```javascript
             * {
             *     coreHours : [{
             *         start      : 8,
             *         end        : 12,
             *     }, {
             *         start      : 14,
             *         end        : 18
             *     }
             * }
             * ```
             *
             * This may also be a __seven element__ array so as to have different core hours for
             * each JavaScript day of the week (Meaning `0` for Sunday to `6` for Saturday).
             * This causes only the hours in the day columns to be masked:
             *
             * ```javascript
             * {
             *     coreHours : [{
             *         start : 10,
             *         end   : 15
             *     },{
             *         start : 9,
             *         end   : 17
             *     },{
             *         start : 9,
             *         end   : 17
             *     },{
             *         start : 9,
             *         end   : 17
             *     },{
             *         start : 9,
             *         end   : 17
             *     },{
             *         start : 9,
             *         end   : 17
             *     },{
             *         start : 10,
             *         end   : 15
             *     }]
             * }
             * ```
             *
             * This may also be a function to return calculated core hours for every date.
             * This causes only the hours in the day columns to be masked:
             *
             * ```javascript
             * {
             *     coreHours : function(date) {
             *         // Shorter core hours at weekends
             *         if (date.getDay() === 0 || date.getDay() === 6) {
             *             return {
             *                 start : '10:00',
             *                 end   : '15:00'
             *             };
             *         }
             *         // Pre-lunch and post-lunch working blocks in the week
             *         return [{
             *             start : '08:00',
             *             end   : '12:00'
             *         }, {
             *             start : '14:00',
             *             end   : '18:00'
             *         };
             *     }
             * }
             * ```
             *
             * This may also be a __seven element__ array which contains s seperate core hours
             * specification for each day of the week as returned from the `Date` class.
             *
             * This may also be a function, which, when passed a `Date`, returns a core hours
             * specification for that date.
             *
             * In the simplest case an object containing two properties, `start` and `end`:
             *
             * - `coreHours.start` - The start hour or start time string `HH:MM` of the core working hours. `Date` or `String` value allowed.
             * - `coreHours.end` - The end hour or start time string `HH:MM` of the core working hours. `Date` or `String` value allowed
             * - `coreHours.overlayDay` - Set to `true` to have the greyed zone cover the day part of the view.
             *
             * __Only valid when this config is specified as a simple Object__.
             *
             * @prp {Object|Function|String} coreHours
             *
             * @param {Date} date Date for hours calculation
             * @returns {Array<Object>}
             */
            coreHours : null,
            /**
             * You can zoom in and out on the time axis using CTRL-key + mouse wheel
             * on mouse-based devices or pinch-zoom on touch devices.
             * See also the {@link #config-hourHeightBreakpoints} config option.
             *
             * You cannot zoom so far out that the day height falls below the available height.
             *
             * __Note that zooming necessarily sets {@link #config-fitHours} to `false`.__
             *
             * Configure this as `false` to disable this behaviour.
             * @config {Boolean}
             * @default
             */
            zoomOnMouseWheel : true,
            /**
             * An array which encapsulates a set of {@link #config-hourHeight} breakpoints which
             * dictate when subticks - intervening time indicators - are introduced into the DayView's
             * time axis.
             *
             * Entries are in ascending granularity order, so the values must ascend.
             *
             * Subtick visibility is updated dynamically during {@link #config-zoomOnMouseWheel zooming}.
             *
             * When an {@link #property-hourHeight} change causes a change of sub tick granularity, a
             * {@link #event-tickChange} event is fired.
             *
             * @prp {Number[]}
             * @default
             */
            hourHeightBreakpoints : [70, 140, 300, 500],
            /**
             * By default, the most granular time tick level in the DayView's time axis is five minutes.
             *
             * Set this property to `true` to have the hour split into six minute ticks.
             * @prp {Boolean}
             * @default false
             */
            sixMinuteTicks : null,
            /**
             * The minimum height to which event blocks in a day cell may shrink. If an event has very
             * short duration, whatever the {@link #config-hourHeight}, and regardless of
             * {@link #function-zoomTo timeline zooming}, the event block will never drop below this height.
             * @prp {Number|String}
             * @default
             */
            minEventHeight : '1em',
            /**
             * The event block height at which the event display rendition switches to "compact"
             * mode with the name, then start time both on the top line with a slightly smaller font.
             * @prp {Number}
             * @default
             * @private
             */
            shortEventHeight : 32,
            /**
             * A function, or name of a function which produces a {@link DomConfig} block to create the current time indicator
             * which is displayed in a day column which represents the current date. It is the developer's responsibility
             * to add CSS classes and the appropriate CSS rules to produce the desire appearance.
             *
             * ```javascript
             * currentTimeIndicatorRenderer : function() {
             *     return {
             *         className : 'my-current-time',
             *         text      : 'NOW'
             *     };
             * }
             *```
             *
             * The result is used to sync the DOM of the day column.
             *
             * @config {Function|String} currentTimeIndicatorRenderer
             * @returns {DomConfig} An object which describes the DOM structure of the today indicator element.
             */
            currentTimeIndicatorRenderer : () => {
                return {
                    // Don't use a div so that :last-of-type can be used for multiple divs
                    // inside a day column (Such as injected by the DayResourceView)
                    tag           : 'span',
                    className     : 'b-current-time-indicator',
                    retainElement : true
                };
            },
            /**
             * An array of button specifications which add clickable icon buttons to the rendered
             * event blocks which contain the following properties.
             * @config {ActionButtonsConfig[]}
             */
            actionButtons : null,
            /**
             * Configure this as `true` to hide day columns which contain no events.
             *
             * <div class="note">Use with care. This may result in no day columns being rendered
             * for completely empty time ranges.</div>
             * @prp {Boolean}
             * @default false
             */
            hideEmptyDays : null,
            autoRefresh : [
                'hideEmptyDays'
            ],
            /**
             * By default, half hour, fifteen minute tick lines are rendered in a lower contrast
             * colour than hour lines.
             *
             * Configure this as `true` to instead render the minor tick lines as dashed lines
             * at the same contrast level as the hour lines.
             * @prp {Boolean}
             * @default false
             */
            dashedSubticks : null
        };
    }
    /**
     * Returns the resource associated with this day view when used inside a {@link Calendar.widget.ResourceView}
     * @readonly
     * @member {Scheduler.model.ResourceModel} resource
     */
    construct(config) {
        const me = this;
        // If we are configured to not show vertical scrolling, we must know this so that
        // scrollbar equalization can adjust to this.
        me.configuredHiddenVerticalScroll = config.scrollable?.overflowY === 'hidden-scroll';
        // Needs scope
        me.cellMapEventFilter = me.cellMapEventFilter.bind(me);
        super.construct(...arguments);
        if (!me.startDate) {
            me.startDate = me.month.date;
        }
        GlobalEvents.ion({
            theme   : 'onThemeChange',
            thisObj : me
        });
    }
    get hasNonWorkingDays() {
        return true;
    }
    updateZoomOnMouseWheel(zoomOnMouseWheel) {
        if (zoomOnMouseWheel) {
            // Only respond every 50ms to keep zooming response sane to a user.
            this.zoomListener = EventHelper.on({
                element   : this.dayContentElement,
                wheel     : 'onDayContentMousewheel',
                thisObj   : this,
                throttled : {
                    buffer : 100,
                    alt    : preventDefault
                }
            });
        }
        else {
            this.zoomListener?.();
        }
    }
    get childItems() {
        const
            { allDayEvents } = this,
            result           = super.childItems;
        if (allDayEvents) {
            result.push(allDayEvents);
        }
        return result;
    }
    get dayEndTime() {
        return this.dayTime?.timeEnd ?? this._dayEndTime;
    }
    get dayStartTime() {
        return this.dayTime?.timeStart ?? this._dayStartTime;
    }
    get dayStartShift() {
        return this.dayTime?.startShift ?? this._dayStartShift;
    }
    changeDayEndTime(dayEndTime) {
        // Update the UI on AF if needed
        this.adjustCoreHours();
        // Due to how the time axis is rendered with twelve flexed ticks in each hour tick, the view
        // can only support day start and end times in whole hour granularity.
        // Any end time is rounded *up*.
        return Math.ceil((this.configuredDayEndTime = DayTime.parse(dayEndTime)) / MILLIS_PER_HOUR) * MILLIS_PER_HOUR;
    }
    updateDashedSubticks(dashedSubticks) {
        this.dayContainerElement.classList.toggle('b-dashed-subticks', Boolean(dashedSubticks));
    }
    updateDayEndTime(dayEndTime) {
        // When more than one of these 3 configs changes at the same time, the following will trigger the yet to be
        // realized configs, which will in turn call their updaters until the last of the batch runs its updater. In
        // that updater call, the dayTime config will be set to the new DayTime instance that has all of the correct
        // config values. The changeDayTime() method will detect a not-equals() DayTime and return it, which will run
        // updateDayTime(). The updates for the non-final configs will also call changeDayTime() but their DayTime
        // instances will all have the same properties and will not result in a call of updateDayTime().
        //
        // We also clear _dayTime so that the getters won't use the old value in our getter calls.
        this._dayTime = null;
        this.dayTime = new DayTime({
            startShift : this.dayStartShift,
            timeStart  : this.dayStartTime,
            timeEnd    : dayEndTime
        });
    }
    changeDayStartTime(dayStartTime) {
        // Update the UI on AF if needed
        this.adjustCoreHours();
        // Due to how the time axis is rendered with twelve flexed ticks in each hour tick, the view
        // can only support day start and end times in whole hour granularity.
        // Any start time is rounded *down*.
        return Math.floor((this.configuredDayStartTime = DayTime.parse(dayStartTime)) / MILLIS_PER_HOUR) * MILLIS_PER_HOUR;
    }
    adjustCoreHours() {
        // If the app has not configured any coreHours, a start/end hour was rounded, then set the coreHours
        // to the configured day so that the out of range parts are grayed out.
        if (this.initialConfig.coreHours == null) {
            const { _dayStartTime, _dayEndTime, configuredDayStartTime, configuredDayEndTime } = this;
            if (_dayStartTime < configuredDayStartTime || _dayEndTime > configuredDayEndTime) {
                this.coreHours = {
                    start      : configuredDayStartTime || 0,
                    end        : configuredDayEndTime   || 24,
                    overlayDay : true
                };
            }
            else {
                this.coreHours = null;
            }
        }
    }
    updateDate(date, was) {
        const { allDayEvents } = this;
        if (allDayEvents) {
            allDayEvents.date = date;
        }
        super.updateDate(date, was);
    }
    updateDayStartTime(dayStartTime) {
        this._dayTime = null;
        this.dayTime = new DayTime({
            startShift : this.dayStartShift,
            timeStart  : dayStartTime,
            timeEnd    : this.dayEndTime
        });
    }
    updateDayStartShift(dayStartShift) {
        this._dayTime = null;
        this.dayTime = new DayTime({
            startShift : dayStartShift,
            timeStart  : this.dayStartTime,
            timeEnd    : this.dayEndTime
        });
    }
    // dayTime
    changeDayTime(dayTime) {
        if (!dayTime) {
            dayTime = new DayTime(this);
        }
        if (!this._dayTime?.equals(dayTime)) {
            return dayTime;
        }
    }
    updateDayTime(dayTime, was) {
        const
            me             = this,
            { eventStore } = me;
        if (eventStore) {
            eventStore.registerDayIndex(dayTime);
            was && eventStore.unregisterDayIndex(was);
        }
        if (!me.isConfiguring) {
            const { startDate } = me;
            me.syncHours();
            me.setConfig({
                date : startDate,
                startDate
            });
            me.allDayEvents?.setConfig({
                date : startDate,
                dayTime,
                startDate
            });
        }
    }
    dateKey(date) {
        return this.dayTime.dateKey(date);
    }
    dayOfDate(date) {
        return date && this.dayTime.dayOfDate(date);
    }
    /**
     * Scrolls vertically to bring an event or a time into view.
     * @param {Scheduler.model.EventModel|Date|Number} target The event to scroll to or
     * a `Date` to read the hour value from, or an hour number.
     * @param {Object} [options] How to scroll.
     * @param {'start'|'end'|'center'|'nearest'} [options.block] How far to scroll the target.
     * @param {Number} [options.edgeOffset] edgeOffset A margin around the target to bring into view.
     * @param {Object|Boolean|Number} [options.animate] Set to `true` to animate the scroll by 300ms,
     * or the number of milliseconds to animate over, or an animation config object.
     * @param {Number} [options.animate.duration] The number of milliseconds to animate over.
     * @param {String} [options.animate.easing] The name of an easing function.
     * @param {Boolean} [options.highlight] Set to `true` to highlight the target when it is in view, _if the target is an `EventModel`_.
     * @param {Boolean} [options.focus] Set to `true` to focus the target when it is in view.
     * @param {Boolean} [options.x] Pass as `false` to disable scrolling in the `X` axis.
     * @param {Boolean} [options.y] Pass as `false` to disable scrolling in the `Y` axis.
     * @returns {Promise} A promise which is resolved when the target has been scrolled into view.
     */
    async scrollTo(target, options = { animate : true }) {
        const
            me             = this,
            {
                allDayEvents,
                minDayWidth
            }              = me,
            scrollPromises = [];
        if (me.scrollPromise) {
            await me.scrollPromise;
        }
        // If the all day event row is in transition, wait until it's done
        await allDayEvents?.heightAnimation;
        // Component might get destroyed by the time promises above get resolved
        if (me.isDestroyed) {
            return;
        }
        // Convert hour number to a time in our start date
        if (typeof target === 'number') {
            target = DH.add(me.startDate, target, 'hour');
        }
        const targetIsDate = Boolean(target.getHours);
        // CalendarMixin knows how to do this.
        // If it's a Date It must not scroll vertically; we do that below.
        await super.scrollTo(target, Object.assign({}, options, { y : !targetIsDate }));
        // Scrolling to a Date is more granular in this view
        if (targetIsDate) {
            // If we are showing more than one day, ensure the date column is in view
            if (me.duration > 1 && minDayWidth) {
                scrollPromises.push(me.horizontalScroller.scrollIntoView(me.getDayElement(target), options));
            }
            target = target.getHours() + (target.getMinutes() / 60);
            scrollPromises.push(me.scrollable.scrollTo(null, me.getPositionFromTime(target), options));
            // There may be a horizontal as well as vertical component if we are in a multi day
            // view with minDayWidth set causing overflow.
            return Promise.all(scrollPromises);
        }
    }
    getEventElement(event, date) {
        return super.getEventElement(event, date) || this.allDayEvents?.getEventElement(event, date);
    }
    getEventElements(event) {
        return super.getEventElements(event) || this.allDayEvents?.getEventElements(event);
    }
    /**
     * Returns the pixel coordinate on the time axis for the passed time of day.
     * @param {Date|Number|String} time The hour number, 'HH:MM' time or a `Date` instance.
     * @internal
     * @returns {Number}
     */
    getPositionFromTime(time) {
        // If it's not possible, return 0. DayTime returns the positive delta.
        if (DayTime.parse(time) < DayTime.parse(this.dayStartTime) - this.dayStartShift) {
            return 0;
        }
        return this.dayTime.delta(time, 'h') * this.hourHeight;
    }
    updateShowAllDayHeader(showAllDayHeader) {
        const
            me = this,
            { _allDayEvents } = me;
        me._cellMap?.clear();
        if (_allDayEvents) {
            _allDayEvents._cellMap?.clear();
            _allDayEvents.element.classList.toggle('b-hide-allday-header', !showAllDayHeader);
            _allDayEvents.refresh();
        }
        me.refresh();
    }
    changeAllDayEvents(alldayEvents, oldAlldayEvents) {
        // Shortcut nullifying the allDayEvents property
        if (!alldayEvents) {
            return oldAlldayEvents?.destroy();
        }
        this.getConfig('date');  // ensure we have startDate/endDate setup if we were given a date
        // CalendarRow controls its own height based on event count and expanded/collapsed state.
        // But the max height it may scroll in within a DayView is controlled by us, so treat maxHeight
        // configured on the allDayEvents as a request for maxAllDayHeight
        if (alldayEvents.maxHeight) {
            this.maxAllDayHeight = alldayEvents.maxHeight;
            alldayEvents.maxHeight = null;
        }
        const
            me = this,
            {
                autoCreate,
                dayHeaderRenderer,
                overflowButtonRenderer,
                overflowClickAction,
                overflowPopup,
                avatarRendering,
                range
            }      = me,
            result = CalendarRow.reconfigure(oldAlldayEvents, alldayEvents, {
                owner : me,
                defaults : {
                    calendar : me.calendar,
                    parent   : me,
                    rtl      : me.rtl,
                    cls : {
                        'b-dayview-schedule-container' : 1,
                        'b-dayview-initializing'       : 1
                    },
                    // If we were configured with any of the following, they were obviously intended for use
                    // by the all day row
                    [overflowPopup !== undefined ? 'overflowPopup' : '_']     : overflowPopup,
                    [overflowButtonRenderer ? 'overflowButtonRenderer' : '_'] : overflowButtonRenderer,
                    [overflowClickAction ? 'overflowClickAction' : '_']       : overflowClickAction,
                    [dayHeaderRenderer ? 'dayHeaderRenderer' : '_']           : dayHeaderRenderer,
                    [avatarRendering ? 'avatarRendering' : '_']               : avatarRendering,
                    // This view always autoCreates allDay events starting at midnight
                    autoCreate : autoCreate !== false ? {
                        newName   : autoCreate.newName,
                        gesture   : autoCreate.gesture,
                        startHour : 0,
                        duration  : '1 day'
                    } : false,
                    // Only signal a dayNumberClick on a click on the day number element.
                    // Allow clicks in the surrounding header to be used to create new events.
                    dayNameSelector : '.b-day-name-date',
                    // Must use the same configured event filter as us in addition to only showing
                    // the all day and interDay events
                    eventFilter : e => me.showAllDayHeader && me.isAllDayEvent(e) && (!me.eventFilter || me.eventFilter(e)),
                    dayStartTime         : me.dayStartTime,
                    dayEndTime           : me.dayEndTime,
                    dayStartShift        : me.dayStartShift,
                    eventRenderer        : me.eventRenderer,
                    eventStore           : me.eventStore,
                    month                : me.month,
                    fullWeek             : me.duration === 1 && !me.dayTime.startShift,
                    filterEventResources : me.filterEventResources,
                    startDate                 : me.startDate,
                    [range ? 'range' : '_']   : range,
                    [range ? '_' : 'endDate'] : me.endDate,
                    nonWorkingDays    : me.nonWorkingDays,
                    nonWorkingDayCls  : me.nonWorkingDayCls,
                    weekendCls        : me.weekendCls,
                    weekStartDay      : me.weekStartDay,
                    defaultCalendar   : me.defaultCalendar,
                    emptyCellRenderer : me.emptyCellRenderer,
                    hideEmptyDays     : me.hideEmptyDays,
                    internalListeners : {
                        heightChange      : 'updateElementLayout',
                        refresh           : 'updateElementLayout',
                        catchall          : 'relayAllDayEvents',
                        showoverflowpopup : 'onAllDayOverflowPopupShown',
                        thisObj           : me
                    },
                    // If all day row does not render events, the day part must fire
                    // the creation signal so that responders such as EventEdit can do right things.
                    editAutoCreatedEvent(eventRecord) {
                        (me.showAllDayHeader ? this : me).trigger('eventAutoCreated', {
                            eventRecord
                        });
                    },
                    // If we are not showing all day events, always return an empty Map
                    createCellMap() {
                        return me.showAllDayHeader ? this.constructor.prototype.createCellMap.call(this, ...arguments) : (me.emptyMap || (me.emptyMap = new Map()));
                    }
                },
                setup(config) {
                    // We need to postprocess the inheriting of hideNonWorkingDays after
                    // we know how external configurations have played into the full config.
                    // If we are showing only one day, then hiding non working days is invalid
                    config.hideNonWorkingDays = me.duration === 1 && !config.fullWeek ? false : me._hideNonWorkingDays;
                }
            });
        // It's null on destroy
        if (result) {
            // The all day row must control its own height.
            // These configs must be ignored.
            result.height = result.flex = null;
            // The all day row is not in control of its date range.
            // If we ever have to scroll to another date, this entire view does that
            // before handing control on to the all day row widget.
            me.originalAllDayEventsScrollTo = result.scrollTo;
            result.scrollTo = me.allDayEventsScrollTo.bind(me);
        }
        return result;
    }
    // We have to be able to yield the underbar property so that Calendar can ask if there is an
    // overflowPopup for a child view without triggering its creation.
    onAllDayOverflowPopupShown({ overflowPopup }) {
        this._overflowPopup = overflowPopup;
    }
    // This is what gets called when the all day row is asked to scrollTo
    // We have control of what it does. It does not have control of its date range.
    allDayEventsScrollTo(target) {
        const
            me             = this,
            newDate        = target.isEvent ? target.startDate : me.changeEndDate(target),
            dateIntersects = target.isEvent ? DH.intersectSpans(me.startDate, me.endDate, target.startDate, target.endDate) : DH.betweenLesser(newDate, me.startDate, me.endDate);
        // If we do not encompass the date, move to the date.
        if (!dateIntersects) {
            me.date = newDate;
        }
        // Now CalendarRow can have at it.
        return me.originalAllDayEventsScrollTo.call(me.allDayEvents, ...arguments);
    }
    updateAutoCreate(autoCreate) {
        // Use the property name so as not to ingest the lazy config at configure time
        const { _allDayEvents } = this;
        super.updateAutoCreate?.(autoCreate);
        if (_allDayEvents) {
            _allDayEvents.autoCreate = autoCreate ? {
                newName   : autoCreate.newName,
                gesture   : autoCreate.gesture,
                startHour : 0,
                duration  : '1 day'
            } : false;
        }
    }
    updateWeekStartDay(weekStartDay, oldWeekStartDay) {
        super.updateWeekStartDay?.(weekStartDay);
        if (!this.isConfiguring && this.allDayEvents) {
            this.allDayEvents.weekStartDay = weekStartDay;
        }
    }
    updateEventStore(eventStore, was) {
        super.updateEventStore(eventStore, was);
        const { dayTime } = this;
        if (dayTime) {
            eventStore?.registerDayIndex(dayTime);
            was?.unregisterDayIndex(dayTime);
        }
    }
    /**
     * The {@link Calendar.widget.OverflowPopup} instance that the {@link #property-allDayEvents}
     * may show when events for one day overflow the available space.
     * @member {Calendar.widget.OverflowPopup} overflowPopup
     * @readonly
     */
    get overflowPopup() {
        // If we don't have _allDayEvents yet, return the underlying config.
        return this._allDayEvents?.overflowPopup || this._overflowPopup;
    }
    get stepUnit() {
        return this.duration > 1 ? `${this.duration} ${this.L('L{daysUnit}')}` : this.L('L{dayUnit}');
    }
    get eventContentElement() {
        return this.dayContainerElement;
    }
    getDateFromDomEvent(domEvent, precise = false) {
        let date = super.getDateFromDomEvent(domEvent);
        const
            me = this,
            el = DomHelper.getEventElement(domEvent);
        // Override to add a time component to the DOM event's date if we receive and event (not an element) and
        // the event was in the day container.
        if (date && el !== domEvent && me.dayContainerElement.contains(el)) {
            // If it's a KeyboardEvent, the y must be the event el's y
            const y = domEvent.clientY || el.getBoundingClientRect().y - 1;
            date = DH.add(date,
                me.dayStartHour - me.dayStartShift / 3600000 +
                   (y - me.scrollable.element.getBoundingClientRect().y + me.scrollable.y) / me.hourHeight,
                'hour');
            if (!precise) {
                date = DH[me.timeSnapType](date, me.increment);
            }
        }
        return date;
    }
    getDateFromPosition(clientX, clientY, local = false, keyParser) {
        const
            me       = this,
            {
                eventContentElement,
                allDayEvents
            }        = me,
            rect     = local ? zeroRect : Rectangle.fromScreen(eventContentElement).roundPx().translate(-eventContentElement.scrollLeft),
            width    = eventContentElement.scrollWidth,
            height   = me.hourHeight * me.getDayLength('hour'),
            localX   = local ? clientX : clientX - rect.x,
            dx       = me.rtl ? me.eventContentElement.offsetWidth - localX : localX,
            dy       = clientY - rect.y,
            // We have to compare element position because day columns may have different widths
            overCell = me.getDayElementFromX(clientX),
            // Completely empty views must return their date.
            // DayView can hide empty days and DayResourceView can hide empty resources which can result
            // in no day cells being rendered. This method must yield a valid Date.
            date     = me.getDateFromElement(overCell, keyParser) || me.date;
        if (allDayEvents && (dx < 0 || width < dx || dy < 0 || height < dy)) {
            // CalendarDrag will pass this view's DayTime so pass it into CalendarRow
            return allDayEvents.getDateFromPosition(clientX, clientY, keyParser);
        }
        // DateHelper.add() operates in UTC and therefore does not respect DST, so we cannot do this:
        //   DH.add(DH.clearTime(date), me.dayStartMs + Math.floor(dy / height * me.getDayLength()), 'ms')
        // Instead, we leverage setMilliseconds()'s ability to handle denormalized values:
        DH.clearTime(date, /*clone = */false);
        date.setMilliseconds(date.getMilliseconds() + me.dayStartMs + Math.floor(dy / height * me.getDayLength()));
        return DH[me.timeSnapType](date, me.increment);
    }
    // We must implement the CalendarMixin interface.
    // All views must expose a doRefresh method.
    doRefresh() {
        const me = this;
        if (me.isVisible) {
            // Opt out of animations while we refresh to avoid reused elements resizing
            DomHelper.addTemporaryClass(me.element, 'b-no-transitions', 200, me);
            const children = [];
            // Calculate this for getDayDomConfig only once per refresh.
            me.today = me.dayTime.startOfDay(me.calendar?.dateTimeNow || new Date());
            // Create day cell child array. DomSync ignores null array entries which is what
            // getDayDomConfig returns for hidden days.
            for (const date = new Date(me.startDate); date < me.endDate; date.setDate(date.getDate() + 1)) {
                children.push(me.getDayDomConfig(date));
            }
            DomSync.sync({
                targetElement : me.dayContainerElement,
                domConfig     : {
                    onlyChildren : true,
                    children,
                    // Match existing data-date elements first and ensure DOM order matches
                    // children order.
                    syncOptions : {
                        syncIdField      : 'date',
                        releaseThreshold : 0,
                        strict           : true
                    }
                }
            });
            // In case height has changed since last refresh.
            me.refreshDayBackground();
            me.refreshCount = (me.refreshCount || 0) + 1;
            me.syncCurrentTimeIndicator();
            /**
             * Fires when this DayView refreshes.
             * @param {Calendar.widget.DayView} source The triggering instance.
             * @event refresh
             */
            me.trigger('refresh');
        }
    }
    /**
     * Gets the full event load which the passed date contains for this view. Includes events
     * in the all day row as well as in the main day area.
     * @param {Date|String} date The date or `YYYY-MM-DD` key for which to gather events.
     * @param {Date} startDate To sync this view's range with the caller's range. Used by
     * this view's {@link #property-allDayEvents} view.
     * @returns {Scheduler.model.EventModel[]}
     * @private
     */
    getEventsForDay(date, startDate) {
        const
            me               = this,
            key              = typeof date === 'string' ? date : me.dayTime.dateKey(date),
            { allDayEvents } = me;
        // Ensure we are in sync with our caller
        if (startDate && me.startDate - startDate) {
            me._cellMap?.clear();
            me.startDate = startDate;
        }
        const result = me.cellMap.get(key)?.events?.slice() || [];
        if (allDayEvents) {
            if (allDayEvents.startDate - me.startDate) {
                allDayEvents._cellMap?.clear();
                allDayEvents.startDate = me.startDate;
            }
            result.push.apply(result, allDayEvents.cellMap.get(key)?.renderedEvents || emptyArray);
        }
        // Some events arrays are raw EventModels, some are event info blocks.
        // We extract into an array of EventModels.
        return result.map(extractEventRecord);
    }
    getBaseDayDomConfig(date) {
        const
            me      = this,
            key     = me.dayTime.dateKey(date),
            day     = date.getDay(),
            // Skip hidden, nonworking days (unless we're a single day view).
            // Skip empty days if configured to do so.
            skipDay = (me.hiddenNonWorkingDays[day] && me.duration !== 1) || (me.hideEmptyDays && !me.getEventsForDay(key).length);
        // Return nullish for skipped days.
        if (!skipDay) {
            const
                cellData       = me.cellMap.get(key) || me.createCellData(date), // dayCellRenderer & timeRanges need one
                nonWorkingDays = me.nonWorkingDays || me.month.nonWorkingDays;
            return [cellData, {
                dataset : {
                    date : key
                },
                className : {
                    'b-dayview-day-detail'   : 1,
                    [me.dayCellCls]          : 1,
                    [me.nonWorkingDayCls]    : nonWorkingDays[day] || false,
                    [me.weekendCls]          : DH.weekends[day],
                    [me.todayCls]            : !(date - me.today),
                    'b-last-cell'            : !(date - me.lastVisibleDate),
                    [`b-day-of-week-${day}`] : 1
                },
                style    : {},
                children : {
                    inset : {
                        className : {
                            'b-dayview-inset' : 1
                        }
                    },
                    events : {
                        className : {
                            'b-dayview-event-container' : 1
                        },
                        children : []
                    }
                },
                // Match existing data-event-id elements first and ensure DOM order matches
                // children order.
                syncOptions : {
                    syncIdField      : 'eventId',
                    releaseThreshold : 0,
                    strict           : true
                }
            }];
        }
    }
    getDayDomConfig(date) {
        const
            me   = this,
            base = me.getBaseDayDomConfig(date);
        if (base) {
            const
                { dayCellRenderer } = me,
                [cellData, dayDomConfig] = base;
            if (me.coreHours) {
                // If our coreHours yields a callable function, ask it the coreHours for this date.
                // If it is an array, extract the coreHours for the day.
                // Set up the background image to create the effect.
                dayDomConfig.style.backgroundImage = me.getCoreHoursGradient(me.getCoreHoursForDate(date));
            }
            // Render events if any. Call even if we have no events to allow beforeLayoutEvents event to fire and
            // listeners to act (e.g., timeRanges)
            me.renderEvents(cellData, dayDomConfig);
            return DomHelper.normalizeChildren(
                dayCellRenderer ? me.callback(dayCellRenderer, me, [dayDomConfig, cellData]) : dayDomConfig);
        }
    }
    /**
     * Calculates A CSS `linear-gradient` specification from a {@link #config-coreHours} definition object.
     *
     * A core hours object consists ot two properties, `start` and `end`. It may also be an array containing
     * multiple core hours definitions.
     * @param {Object} coreHours
     * @param {Number|String} coreHours.start The start hour or start time string `HH:MM` of the core working hours.
     * @param {Number|String} coreHours.end The start hour or end time string `HH:MM` of the core working hours.
     * @returns A String containing a `linear-gradient` style which shades non-working times using the
     * `--dayview-outside-core-hours-color` CSS variable.
     * @internal
     */
    getCoreHoursGradient(coreHours) {
        // No background image gradient if no core hours specified.
        if (!coreHours) {
            return '';
        }
        const
            me          = this,
            { dayTime } = me,
            dayEnd      = dayTime.duration('h'),
            result      = ['linear-gradient(to bottom'];
        // Convert a single time block into an array, we need to handle multiple
        // time blocks.
        coreHours = ArrayHelper.asArray(coreHours);
        let lastEnd = 0;
        for (let i = 0, { length } = coreHours; i < length; i++) {
            const
                c       = coreHours[i],
                start   = Math.max(dayTime.delta(c.start, 'h'), 0)    / dayEnd * 100,
                end     = Math.min(dayTime.delta(c.end, 'h'), dayEnd) / dayEnd * 100;
            // If the working block for the day is the whole day, we don't need a background
            if (length === 1 && start === 0 && end === 100) {
                return '';
            }
            // Blocked hours prior to this working block
            if (lastEnd < start) {
                result.push(`var(--dayview-outside-core-hours-color) ${DomHelper.percentify(lastEnd)}, var(--dayview-outside-core-hours-color) ${DomHelper.percentify(start)}`);
            }
            result.push(`transparent ${DomHelper.percentify(start)}, transparent ${DomHelper.percentify(end)}`);
            lastEnd = end;
        }
        // Closing blocked out hours if any
        if (lastEnd < 100) {
            result.push(`var(--dayview-outside-core-hours-color) ${DomHelper.percentify(lastEnd)}, var(--dayview-outside-core-hours-color) 100%`);
        }
        return result.join(',') + ')';
    }
    getCoreHoursForDate(date) {
        const { coreHours } = this;
        // It's an array for each day
        if (coreHours.length === 7) {
            return coreHours[date.getDay()];
        }
        // Object or array of objects
        if (typeof coreHours === 'object') {
            // Core hours as a simple object only applies to dates if overlayDay set.
            return coreHours.overlayDay ? coreHours : null;
        }
        // Must be a callback
        return this.callback(coreHours, this, [date]);
    }
    /**
     * Refreshes the event layout inside a day column in case the stacking order becomes incorrect.
     * @param {Date} date
     * @internal
     */
    refreshDayEvents(date) {
        const me = this;
        // Promote YYYY-MM-DD keys to Dates
        date = me.changeEndDate(date);
        // getDayDomConfig reads this.
        me.today = me.dayTime.startOfDay(new Date());
        const domConfig = me.getDayDomConfig(date);
        // Opt out of animations while we refresh to avoid reused elements resizing
        DomHelper.addTemporaryClass(me.element, 'b-no-transitions', 200, me);
        domConfig.onlyChildren = true;
        DomSync.sync({
            targetElement : me.getDayElement(date),
            domConfig
        });
    }
    /**
     * Pushes event bar DomConfig definitions into the `children` property of the passed
     * `dayDomConfig` object
     * @param {DayCell} cellData An object containing information about the day cell being created.
     * @param {DomConfig} dayDomConfig The DomConfig element definition for the day cell.
     * @internal
     */
    renderEvents(cellData, dayDomConfig) {
        const
            me                    = this,
            context               = me.eventLayout.layoutEvents(cellData, dayDomConfig),
            { items }             = context,
            { eventSpacing, rtl } = me;
        for (const item of items) {
            const eventDomConfig = me.createEventDomConfig({ eventRecord : item.eventRecord });
            me.insertActionButtons(eventDomConfig, item.eventRecord, cellData);
            // Set the classes for the event overflowing the *visible* day if it's cropped.
            eventDomConfig.className['b-starts-above'] = item.startsBefore;
            eventDomConfig.className['b-ends-below'] = item.endsAfter;
            if (item.cluster.items.length > 1) {
                eventDomConfig.className['b-cal-in-cluster'] = 1;
            }
            Object.assign(eventDomConfig.style, {
                // Leave eventSpacing pixels at the bottom by reducing the available content height.
                // The event-wrap's default background-color is transparent.
                // This is to create the appearance of the event-spacing config.
                [eventSpacing ? 'padding-bottom' : ''] : `${eventSpacing}px`,
                ...item.getStyles(rtl)
            });
            dayDomConfig.children.events.children.push(eventDomConfig);
        }
        me.trigger('renderEvents', {
            context
        });
    }
    insertActionButtons(eventDomConfig, eventRecord, cellData) {
        const { actionButtons } = this;
        if (actionButtons) {
            eventDomConfig.children.push({
                className : 'b-event-action-buttons',
                children  : actionButtons.map((actionButton, index) => {
                    return {
                        tag       : 'button',
                        className : {
                            'b-tool'           : 1,
                            'b-icon'           : 1,
                            [actionButton.cls] : 1
                        },
                        dataset : {
                            index,
                            btip : actionButton.tooltip
                        }
                    };
                })
            });
        }
    }
    onEventClick({ domEvent }) {
        const button = domEvent.target.closest('.b-event-action-buttons [data-index]');
        if (button) {
            this.callback(this.actionButtons[Number(button.dataset.index)].handler, this, arguments);
            return false;
        }
    }
    get overflowElement() {
        // The vertical scrolling element
        return this.dayContentElement;
    }
    get cellMap() {
        // If the cellMap has not been populated, create it.
        return this._cellMap?.populated ? this._cellMap : this.createCellMap();
    }
    get bodyConfig() {
        const
            me            = this,
            {
                allDayEvents,
                maxAllDayHeight
            }             = me;
        return Object.assign(super.bodyConfig, {
            children : {
                alldayRowElement : allDayEvents ? {
                    className : {
                        'b-dayview-allday-row' : 1
                    },
                    style : {
                        [maxAllDayHeight ? 'maxHeight' : ''] : DomHelper.setLength(maxAllDayHeight)
                    },
                    children : {
                        cornerElement : {
                            className : {
                                'b-dayview-allday-row-start'  : 1,
                                'b-dayview-allday-autoheight' : allDayEvents.autoHeight
                            },
                            children : {
                                allDayTextElement : {
                                    className : 'b-dayview-allday-text',
                                    html      : me.L('L{EventEdit.All day}')
                                },
                                allDayToggleIcon : {
                                    tag       : 'button',
                                    className : 'b-expand-allday-button b-icon b-icon-expand-row',
                                    dataset   : {
                                        btip : 'L{DayView.expandAllDayRow}'
                                    }
                                }
                            }
                        },
                        allDayEvents : allDayEvents?.element,           // May have been configured away
                        $scrollerPad : DomHelper.scrollBarPadElement    // $ prefix -> not a reference
                    }
                } : null,
                dayContentElement : {
                    tabIndex  : -1,
                    className : {
                        'b-dayview-day-content' : 1
                    },
                    children : {
                        timeAxisElement : {
                            children  : me.getTimeElementConfigs(),
                            className : {
                                'b-timeaxis-container' : 1
                            }
                        },
                        dayContainerElement : {
                            className : {
                                'b-dayview-day-container' : 1
                            }
                        }
                    }
                },
                horizontalScrollerElement : {
                    className : 'b-virtual-scrollers b-hide-display',
                    children  : [{
                        className : 'b-virtual-scroller'
                    }, DomHelper.scrollBarPadElement]
                }
            }
        });
    }
    onDayContentMousewheel(e) {
        if (e.ctrlKey) {
            e.preventDefault();
            const
                pointerOffsetY = e.clientY - this.scrollable.element.getBoundingClientRect().y,
                reqDelta       = -Math.min(Math.abs(e.deltaY), 10) * Math.sign(e.deltaY);
            this.zoomBy(reqDelta, pointerOffsetY);
        }
    }
    /**
     * Zooms the timeline by incrementing the {@link #property-hourHeight} by the requested pixel delta.
     * @param {Number} reqDelta The number of pixels by which to increment the {@link #property-hourHeight}
     * @param {Number|String} [zoomCenter] The center time to zoom in to. This may be a number of pixels
     * down the DayView viewport, or it may be a time to use as the center in the format `HH:MM:ss`.
     * __If omitted, the visual central time in the viewport is used__.
     *
     * Note that this will usually require a layout update which happens in the next animation frame
     * so to postprocess the new state of the view, the returned Promise must be awaited.
     * @async
     */
    zoomBy(reqDelta, zoomCenter = Math.min(this.scrollable.scrollHeight, this.scrollable.clientHeight) / 2 - 1) {
        return new Promise(resolve => {
            // Ensure the layout is up to date before we measure things
            this.updateElementLayout.cancel();
            const
                me             = this,
                {
                    scrollable,
                    hourHeight : oldHourHeight
                }              = me,
                // Round the zoom center date to our configured snap point so that the view stays steady.
                // Use x as center to 99% likely avoid Sundays which are DST switch days which break fixed timepoint
                zoomCenterTime = DH.round(typeof zoomCenter === 'number' ? me.getDateFromPosition(me.dayContainerElement.offsetWidth / 2, zoomCenter + scrollable.y, true) : DH.parse(zoomCenter, 'HH:mm:ss'), me.increment),
                hoursInDay     = me.getDayLength('h'),
                newHourHeight  = Math.max(oldHourHeight + reqDelta, me.dayContentClientHeight / hoursInDay),
                pointerOffsetY = me.getPositionFromTime(zoomCenterTime) - scrollable.y;
            if (oldHourHeight !== newHourHeight) {
                // When the day layout has been done, keep the zoom center time still.
                me.ion({
                    layoutUpdate : () => {
                        scrollable.scrollTo(null, me.getPositionFromTime(zoomCenterTime) - pointerOffsetY);
                        // Sync partners synchronously so there's no delay in a multi-view mode like ResourceView
                        scrollable.syncPartners(false, true);
                        resolve();
                    },
                    once : true,
                    prio : 1000
                });
                // Zooming obviously breaks fitting the hours into the space.
                me.fitHours = false;
                // Invalidate the day layout
                me.hourHeight = newHourHeight;
            }
            else {
                resolve();
            }
        });
    }
    /**
     * Zooms the timeline by setting the {@link #property-hourHeight} to the requested pixel value.
     * @param {Number} newHourHeight The new {@link #property-hourHeight} in pixels.
     * @param {Number|String} [zoomCenter] The center time to zoom in to. This may be a number of pixels
     * down the DayView viewport, or it may be a time to use as the center in the format `HH:MM:ss`.
     * __If omitted, the visual center of the viewport is used__.
     *
     * Note that this will usually require a layout update which happens in the next animation frame
     * so to postprocess the new state of the view, the returned Promise must be awaited.
     * @async
     */
    zoomTo(newHourHeight, zoomCenter) {
        return this.zoomBy(newHourHeight - this.hourHeight, zoomCenter);
    }
    /**
     * Zooms to fit all visible events within the vertical scroll viewport.
     * @param {Object} [options] How to scroll.
     * @param {Number} [options.edgeOffset] edgeOffset A margin around the target to bring into view.
     * @param {Object|Boolean|Number} [options.animate] Set to `true` to animate the scroll by 300ms,
     * or the number of milliseconds to animate over, or an animation config object.
     * @param {Number} [options.animate.duration] The number of milliseconds to animate over.
     * @param {String} [options.animate.easing] The name of an easing function.
     * @returns {Promise} A promise which is resolved when the target has been scrolled into view.
     * @async
     */
    async zoomToFit(options) {
        const
            me     = this,
            events = me.eventStore.getEvents({
                startDate : me.startDate,
                endDate   : me.endDate,
                filter    : e => !e.isInterDay
            }),
            [start, end] = events.reduce((r, e) => {
                // Round times to nearest half hour
                const
                    startHour = DH.getTimeOfDay(DH.floor(e.startDate, '0.5h'), 'h'),
                    endHour   = DH.getTimeOfDay(DH.ceil(e.endDate, '0.5h'), 'h');
                return [(startHour < r[0]) ? startHour : r[0], (endHour > r[1]) ? endHour : r[1]];
            }, [24, 0]);
        await me.zoomTo(Math.floor(me.scrollable.clientHeight / (end - start)));
        await me.scrollTo(start, options);
    }
    createCellMap() {
        const { showAllDayHeader } = this;
        // We only want events which start and end *in* each date.
        // These will not propagate forwards anyway, so skip propagation.
        // By default filter by our dayFilter which skips events in non working days
        // and events outside of our configured start/end time range.
        // We must also honour any configured eventFilter
        return super.createCellMap({
            dayTime : this.dayTime,
            // Normally, DayView only displays intraday events, so only those which start *on*
            // the cell's date and only those which fit completely *inside* the cell's date.
            // showDayHeader flips those assumptions because all intersecting events need to
            // be collected because we have nowhere else to show interday events.
            startOnly     : showAllDayHeader,
            allowPartial  : !showAllDayHeader,
            skipPropagate : true,
            rawEvents     : true,
            filter        : this.cellMapEventFilter
        });
    }
    cellMapEventFilter(eventRecord) {
        const { dayTime, hiddenNonWorkingDays, showAllDayHeader } = this;
        // DayView rejects events which span days unless showAllDayHeader is false.
        // In which case we have to show them across multiple day cells.
        return (!showAllDayHeader || (dayTime.isIntraDay(eventRecord) && !eventRecord.allDay)) &&
            // Event is eligible if it's within our view's day start/end range
            dayTime.intersects(eventRecord) &&
            // AND it's not in a hidden nonworking day
            !hiddenNonWorkingDays[dayTime.dayOfWeek(eventRecord.startDate)];
    }
    getDayLength(as = 'ms') {
        return this.dayTime.duration(as);
    }
    changeIncrement(increment) {
        if (typeof increment === 'string') {
            increment = DH.parseDuration(increment);
            return DH.as('ms', increment.magnitude, increment.unit);
        }
        return increment;
    }
    get dayStartHour() {
        return this.dayStartTime == null ? 0 : Math.floor(this.dayStartTime / MILLIS_PER_HOUR);
    }
    get dayEndHour() {
        return this.dayEndTime == null ? 24 : Math.ceil(this.dayEndTime / MILLIS_PER_HOUR);
    }
    get dayStartMs() {
        return this.dayStartTime == null ? 0 : this.dayStartTime;
    }
    get dayEndMs() {
        return this.dayEndTime == null ? 24 * MILLIS_PER_HOUR : this.dayEndTime;
    }
    /**
     * This is the increment in milliseconds from the floored day start hour to the configured day start time.
     *
     * This is used to calculate the offset by which to move the timeAxis ticks when the start is not on an hour boundary.
     * @internal
     */
    get dayStartOffset() {
        return (this.dayTime.startTimeOffsetMs / MILLIS_PER_HOUR) * this.hourHeight;
    }
    changeEventFilter(filter) {
        if (filter === undefined) {
            filter = event => !this.isAllDayEvent(event);
        }
        return filter;
    }
    changeEventLayout(config, existing) {
        return DayLayout.reconfigure(existing, config, {
            owner    : this,
            defaults : {
                owner : this
            }
        });
    }
    updateHideEmptyDays(hideEmptyDays) {
        if (!this.isConfiguring) {
            this._allDayEvents && (this._allDayEvents.hideEmptyDays = hideEmptyDays);
        }
    }
    updateNonWorkingDays(nonWorkingDays) {
        const me = this;
        super.updateNonWorkingDays?.(nonWorkingDays);
        me._allDayEvents && (me._allDayEvents.nonWorkingDays = nonWorkingDays);
        if (!me.isConfiguring) {
            me._cellMap?.clear();
            me.refresh();
        }
    }
    updateMaxAllDayHeight(maxAllDayHeight) {
        if (this.alldayRowElement) {
            DomHelper.setLength(this.alldayRowElement, 'max-height', maxAllDayHeight);
        }
    }
    get hideNonWorkingDays() {
        // Hiding non working days is only valid for multi day views
        return (this._hideNonWorkingDays === true && this.duration === 1) ? false : this._hideNonWorkingDays;
    }
    set hideNonWorkingDays(hideNonWorkingDays) {
        super.hideNonWorkingDays = hideNonWorkingDays;
    }
    updateHideNonWorkingDays(hideNonWorkingDays) {
        const
            me                = this,
            { _allDayEvents } = me;
        super.updateHideNonWorkingDays(hideNonWorkingDays);
        // Pass out *property* here, because if we're a single day view, hideNonWorkingDays
        // always yields false. Hiding non working days is only valid for multi day views
        _allDayEvents && (_allDayEvents.hideNonWorkingDays = me.duration === 1 && !_allDayEvents.fullWeek ? false : me._hideNonWorkingDays);
        if (!me.isConfiguring) {
            me._cellMap?.clear();
            me.refresh();
        }
    }
    syncHours() {
        const me = this;
        me._cellMap?.clear();
        DomSync.sync({
            targetElement : me.timeAxisElement,
            domConfig     : {
                onlyChildren : true,
                children     : me.getTimeElementConfigs()
            }
        });
        // We need to update and measure immediately, before the refresh
        me.updateElementLayout.now();
        me.refresh();
    }
    syncCurrentTimeIndicator() {
        const
            me          = this,
            now         = me.calendar?.dateTimeNow || new Date(),
            dayElement  = me.getDayElement(now),
            {
                currentTimeIndicator,
                dayTime,
                endDate,
                startDate
            }           = me;
        if (startDate && endDate) {
            if (dayElement && DH.betweenLesser(now, startDate, endDate) && dayTime.contains(now)) {
                const
                    dayLengthMs = me.getDayLength('ms'),
                    nowMS       = dayTime.delta(now, 'ms');
                if (currentTimeIndicator.parentNode !== dayElement) {
                    dayElement?.appendChild(currentTimeIndicator);
                }
                currentTimeIndicator.style.top = DomHelper.percentify(nowMS / dayLengthMs * 100);
            }
            else {
                currentTimeIndicator.remove();
            }
        }
    }
    get currentTimeIndicator() {
        return this._currentTimeIndicator || (this._currentTimeIndicator = DomHelper.createElement(this.callback(this.currentTimeIndicatorRenderer, this)));
    }
    getTimeElementConfigs() {
        const
            me            = this,
            result        = [],
            { dayTime }   = me,
            { startHour } = dayTime,
            date          = new Date(2000, 5, 15, startHour, 0, 0),
            hours         = dayTime.duration('h');
        for (let h, i = 1; i <= hours; i++) {
            // Ticks come from the previous hour.
            // The hour value is the ending tick of the hour cell
            const ticks = me.createLeafTicks(date);
            // Move to the ending hour
            date.setHours(h = (date.getHours() + 1));
            date.setMinutes(0);
            result.push({
                className : `b-dayview-timeaxis-time b-dayview-timeaxis-time-${h < 10 ? '0' : ''}${h}`,
                children  : ticks.concat([{
                    className : 'b-dayview-timeaxis-tick b-dayview-hour-tick',
                    text      : DH.format(date, me.timeFormat)
                }])
            });
        }
        // TimeAxis can only show the core hours if they are the same for every day
        if (me.coreHours && me._simpleCoreHours) {
            result.unshift({
                className : 'b-dayview-timeaxis-background',
                style     : {
                    backgroundImage : me.getCoreHoursGradient(me.coreHours)
                }
            });
        }
        return result;
    }
    createLeafTicks(date) {
        return this[this.sixMinuteTicks ? 'createSixMinuteTicks' : 'createFiveMinuteTicks'](date);
    }
    createFiveMinuteTicks(date) {
        const result = [];
        for (let i = 1; i < 12; i++) {
            date.setMinutes(i * 5);
            result.push({
                className : {
                    'b-dayview-timeaxis-tick' : 1,
                    'b-dayview-tick-level-4'  : 1,          // Five minutes
                    'b-dayview-tick-level-3'  : !(i & 1),   // Ten minutes
                    'b-dayview-tick-level-2'  : !(i % 3),   // Fifteen minutes
                    'b-dayview-tick-level-1'  : i === 6     // Half hour
                },
                text : DH.format(date, this.timeFormat)
            });
        }
        return result;
    }
    createSixMinuteTicks(date) {
        const result = [];
        for (let i = 1; i < 10; i++) {
            date.setMinutes(i * 6);
            result.push({
                className : {
                    'b-dayview-timeaxis-tick' : 1,
                    'b-dayview-tick-level-3'  : 1,          // Six minutes
                    'b-dayview-tick-level-2'  : !(i & 1),   // Twelve minutes
                    'b-dayview-tick-level-1'  : i === 5     // Half hour
                },
                text : DH.format(date, this.timeFormat)
            });
        }
        return result;
    }
    changeCoreHours(coreHours) {
        const me = this;
        // Same core hours for every day
        me._simpleCoreHours = ObjectHelper.isObject(coreHours) || (Array.isArray(coreHours) && coreHours.length !== 7);
        if (me._simpleCoreHours) {
            // In case we are sharing this via modeDefaults. We must own a copy
            coreHours = ObjectHelper.clone(coreHours);
            coreHours._overlayDay = coreHours.overlayDay;
            Object.defineProperty(coreHours, 'overlayDay', {
                set(v) {
                    this._overlayDay = v;
                    me.updateCoreHours();
                },
                get(v) {
                    return this._overlayDay;
                }
            });
        }
        return coreHours;
    }
    updateCoreHours() {
        if (!this.isConfiguring) {
            this.syncHours();
        }
    }
    changeStartDate() {
        // Base class gets a chance to veto the change
        return this.dayOfDate(super.changeStartDate(...arguments));
    }
    updateStartDate(startDate) {
        // Keep all day row in time sync
        this._allDayEvents?.setConfig({
            startDate
        });
        super.updateStartDate(...arguments);
    }
    changeEndDate() {
        // Base class gets a chance to veto the change
        return this.dayOfDate(super.changeEndDate(...arguments));
    }
    updateEndDate(endDate) {
        super.updateEndDate?.(...arguments);
        // Keep all day row in time sync
        if (!this.updatingRange) {
            this._allDayEvents?.setConfig({
                date : this.date,
                endDate
            });
        }
    }
    changeMinDayWidth(minDayWidth) {
        // null must be returned to mean no minWidth because undefined signals no change.
        return typeof minDayWidth === 'number' ? Math.max(minDayWidth, 50) : typeof minDayWidth === 'string' ? minDayWidth : null;
    }
    updateMinDayWidth(minDayWidth) {
        this.element.style.setProperty('--min-day-width', minDayWidth ? DomHelper.setLength(minDayWidth) : null);
        this._allDayEvents && (this._allDayEvents.minDayWidth = minDayWidth);
        this.updateElementLayout();
    }
    updateHourHeight(hourHeight) {
        // Set up milestone height initially as half the hour Height, but at least 22px
        if (this.isConfiguring) {
            this.milestoneHeight = Math.max(Math.min(hourHeight / 2, this.shortEventHeight), 22);
        }
        this.milestoneDuration = 3600000 / (hourHeight / this.milestoneHeight);
        if (!this.isConfiguring) {
            this.shortEventDuration = 3600000 / (hourHeight / this.shortEventHeight);
            // All we need to do is recalculate our CSS properties and hour height class indicator
            this.updateElementLayout();
            // hourHeight may have changed, some events may change their b-short-event state
            if (this.fitHours) {
                this.refresh();
            }
        }
    }
    updateShortEventHeight(shortEventHeight) {
        this.updateHourHeight(this.hourHeight);
    }
    updateMinEventHeight(minEventHeight) {
        this.contentElement.style.setProperty('--dayview-min-event-height', DomHelper.setLength(minEventHeight));
    }
    get hourHeight() {
        // Order of the boolean expression is important:
        // We do not want to interrogate this.fitHours if we are configuring.
        return !this.isConfiguring && this.fitHours ? Math.max(this.dayContentClientHeight / this.getDayLength('hour'), this.minHourHeight) : this._hourHeight;
    }
    changeFitHours(fitHours) {
        if (fitHours?.minHeight) {
            // Set a hard minimum. Even a phone in portrait mode could show a schedule at 9px per hour
            this.minHourHeight = Math.max(fitHours.minHeight, 9);
        }
        else {
            // If they don't specify a minimum, we use 17.
            // Odd numbers are better. Avoids fractional pixels in background line generation.
            this.minHourHeight = 17;
        }
        return fitHours;
    }
    get dayContentClientHeight() {
        return DomHelper.floorPx(Rectangle.client(this.dayContentElement).height);
    }
    updateFitHours(fitHours) {
        const me = this;
        if (!me.isConfiguring) {
            // We only need to change the hourHeight if we are moving *to* fitting
            fitHours && (me.hourHeight = Math.max(me.dayContentClientHeight / me.getDayLength('hour'), me.minHourHeight));
        }
        me.element.classList.toggle('b-fit-hours', Boolean(fitHours));
        me.monitorResize = DomHelper.scrollBarWidth || fitHours;
    }
    updateEventSpacing() {
        if (!this.isConfiguring) {
            this._cellMap?.clear();
            this.refresh();
        }
    }
    onInternalPaint({ firstPaint }) {
        const
            me = this,
            {
                dayContainerElement,
                horizontalScrollerElement
            }  = me,
            {
                scrollBarWidth
            }  = DomHelper;
        if (firstPaint) {
            // This will be the first read of the allDayEvents property and will
            // trigger ingestion of allDayEvents and its upgrade into an instance of CalendarRow
            const { allDayEvents } = me;
            /**
             * A Scroller which encapsulates horizontal scrolling of the view in case a {@link #config-minDayWidth}
             * setting causes the days to overflow the available width.
             * @member {Core.helper.util.Scroller} horizontalScroller
             */
            me.horizontalScroller = new Scroller({
                widget            : me,
                element           : dayContainerElement,
                overflowX         : scrollBarWidth ? 'hidden-scroll' : true,
                overflowY         : 'clip',
                propagateSync     : true,
                internalListeners : {
                    overflowChange : 'updateElementLayout',
                    thisObj        : me
                }
            });
            if (allDayEvents) {
                allDayEvents.element.classList.remove('b-dayview-initializing');
                me.horizontalScroller.addPartner(allDayEvents.headerScroller);
            }
            ResizeMonitor.addResizeListener(me.dayContainerElement, me.onDayContainerResize.bind(me));
            // We need the scroller even if the UI is overlay scrollbars.
            // Because the scrollbar needs to be docked at the bottom while content scrolls.
            me.scrollbarScroller = new Scroller({
                widget      : me,
                element     : horizontalScrollerElement.firstChild,
                scrollWidth : dayContainerElement.scrollWidth - scrollBarWidth,
                overflowX   : true,
                overflowY   : false
            });
            me.horizontalScroller.addPartner(me.scrollbarScroller);
            me.refresh();
            me.updateElementLayout.now();
            me.setInterval(me.syncCurrentTimeIndicator.bind(me), 30 * 1000, 'syncCurrentTimeIndicator');
            if (allDayEvents) {
                EventHelper.on({
                    click : {
                        element : me.cornerElement,
                        handler : 'onCornerClick'
                    },
                    // The scrollbar padding must not bubble any mousemove events to dragdrop.
                    // This is because a mouseover of the allDay row *may*, if there are no
                    // all day events, cause expansion of the allDay row, which *may* cause
                    // vertical overflow, which *may* on some platforms cause this padding element
                    // to pop into visibility below the cursor which would then cause
                    // a dragleave.
                    mousemove : {
                        element : me.allDayEvents.element.nextSibling,
                        handler : stopEvent,
                        capture : true
                    },
                    thisObj : me
                });
                // If, through window resizing, or changing hourHeight, the scrollbar status flips
                // we have to run the scrollbar syncing.
                me.scrollable.ion({
                    overflowChange : 'syncScrollbarPadding',
                    thisObj        : me
                });
            }
            me.scrollToVisibleStartTime();
        }
    }
    scrollToVisibleStartTime() {
        if (this.visibleStartTime != null) {
            const
                me             = this,
                { scrollable } = me,
                startScrollPos = me.getPositionFromTime(me.visibleStartTime),
                doScroll       = () => {
                    scrollable.scrollTo(null, startScrollPos, {
                        animate : false,
                        block   : 'start'
                    });
                };
            // If at this startup phase, the scroll was not possible due to the clientHeight
            // being too high, it *may* become possible after the allDayEvents animates to
            // a new height because of its events, so wait a short time for that scenario.
            if (startScrollPos > scrollable.maxY && !me.scrollToStartTimer && me.allDayEvents) {
                me.scrollToStartTimer = me.allDayEvents.ion({
                    heightChange : doScroll,
                    once         : true,
                    expires      : {
                        delay : 5000,
                        alt   : doScroll
                    },
                    name : 'scrollToVisibleStartTime'
                });
            }
            else {
                doScroll();
            }
        }
    }
    relayAllDayEvents(event) {
        // Don't relay every type of event
        if (!blockedAllDayEvents[event.type]) {
            return this.trigger(event.eventName, event);
        }
    }
    async onCornerClick() {
        const { allDayEvents } = this;
        if (!allDayEvents.isAnimating) {
            await allDayEvents.toggleExpandCollapse();
            if (!this.isDestroyed) {
                this.allDayToggleIcon.dataset.btip = allDayEvents.expanded ? this.L('L{collapseAllDayRow}') : this.L('L{expandAllDayRow}');
            }
        }
    }
    syncScrollbarPadding() {
        // configuredHiddenVerticalScroll means that we must not show a scrollbar, so none of this
        // must execute. ResourceView uses this and docks a fake scroller at its trailing edge.
        // We only have anything to sync if we have an allDayEvents widget.
        if (!this.configuredHiddenVerticalScroll && this.allDayEvents) {
            if (DomHelper.scrollBarWidth) {
                if (!this.allDayEvents.isAnimating) {
                    const
                        {
                            allDayEvents,
                            alldayRowElement,
                            scrollable
                        }                 = this,
                        fitHours          = this.fitHours && scrollable.clientHeight / this.getDayLength('hour') >= this.minHourHeight,
                        // fitHours means we'll never overflow
                        hasOverflow       = !fitHours && scrollable.hasOverflow(),
                        {
                            headerElement,
                            scrollable : verticalScroller
                        }                 = allDayEvents,
                        expanded          = allDayEvents.expanded || allDayEvents.autoHeight,
                        // Will only have overflow if it's expanded
                        allDayHasOverflow = Boolean(expanded && verticalScroller.hasOverflow());
                    // Day view AND all day view both have vertical overflow.
                    // Only the all day view *header* has to fake one to match.
                    if (hasOverflow && allDayHasOverflow) {
                        headerElement.classList.add('b-show-yscroll-padding');
                        alldayRowElement.classList.remove('b-show-yscroll-padding');
                        scrollable.overflowY = verticalScroller.overflowY = 'auto';
                    }
                    // Both in different overflowY states.
                    else if (allDayHasOverflow !== hasOverflow) {
                        // ONLY the all day view overflows, so allday *header* must show padding
                        // and the day view must show a scrollbar
                        if (allDayHasOverflow) {
                            headerElement.classList.add('b-show-yscroll-padding');
                            verticalScroller.overflowY = 'auto';
                            scrollable.overflowY = 'scroll';
                        }
                        // ONLY the day view overflows, so that all day must show its scrollbar padding
                        // But the all day header must not.
                        else {
                            headerElement.classList.remove('b-show-yscroll-padding');
                            alldayRowElement.classList.add('b-show-yscroll-padding');
                            verticalScroller.overflowY = 'hidden';
                            scrollable.overflowY = !fitHours && 'auto';
                        }
                    }
                    // Neither of the two views overflow
                    else {
                        alldayRowElement.classList.remove('b-show-yscroll-padding');
                        headerElement.classList.remove('b-show-yscroll-padding');
                        scrollable.overflowY = !fitHours && 'auto';
                        verticalScroller.overflowY = 'hidden';
                    }
                }
            }
            // If scrollbars are overlayed when scrolling we can always have the two views scrollable
            else {
                this.scrollable.overflowY = this.allDayEvents.scrollable.overflowY = 'auto';
            }
        }
    }
    onInternalResize(element, width, height, oldWidth, oldHeight) {
        const { _allDayEvents : allDayEvents } = this;
        super.onInternalResize(element, width, height, oldWidth, oldHeight);
        // Don't update on the initial render resize from no dimensions.
        // That is handled by onInternalPaint({ firstPaint })
        if (oldHeight) {
            allDayEvents?.performResizeRefresh(allDayEvents.eventsPerCell, allDayEvents.eventContainerTop);
            if (height !== oldHeight) {
                this.updateElementLayout();
                this.syncScrollbarPadding();
            }
        }
    }
    onThemeChange() {
        this.updateElementLayout();
    }
    // Only the day container *height* is of interest generally.
    // Unless we have had a minDayWidth set and we show scrollbars, in which case horizontal scrolling
    // may come and go and the horizontalScrollerElement which shows a scrollbar might need toggling.
    onDayContainerResize(el, { height : oldHeight } = {}, { height }) {
        if (oldHeight && (height !== oldHeight || (DomHelper.scrollBarWidth && this.minDayWidth))) {
            this.updateElementLayout();
            // Must recalculate the hour height if fitHours is set.
            if (this.fitHours) {
                this.updateFitHours(this.fitHours);
            }
        }
    }
    async updateElementLayout() {
        if (!this.refreshCount || this.owner?.isDestroyed) {
            return;
        }
        const
            me                 = this,
            {
                dayContainerElement,
                horizontalScrollerElement,
                horizontalScroller,
                allDayEvents
            }                  = me,
            dayElements        = dayContainerElement.querySelectorAll('[data-date]'),
            { scrollBarWidth } = DomHelper;
        let i, end;
        // AllDayEVents expander needs to know whether there's overflow
        if (allDayEvents) {
            const cornerElementClassList = me.cornerElement.classList;
            if (allDayEvents.hasOverflow) {
                cornerElementClassList.add('b-has-cell-overflow');
                cornerElementClassList.toggle('b-expanded', Boolean(allDayEvents.expanded));
            }
            else {
                cornerElementClassList.remove('b-has-cell-overflow');
            }
        }
        // Hour lines now. We are in an AF.
        me.refreshDayBackground.now();
        for (i = 0, end = dayElements.length - 1; i <= end; i++) {
            dayElements[i].classList.toggle('b-last-cell', i === end);
        }
        // Hide/show the pseudo horizontal scrollbar that we show on scrollbar displaying platforms.
        if (allDayEvents) {
            me.syncScrollbarPadding();
        }
        if (scrollBarWidth) {
            horizontalScrollerElement.classList.toggle('b-show-yscroll-padding', me.scrollable.hasOverflow());
        }
        // Handle DayViews with zero days. These can be used as standalone TimeAxes.
        // Horizontal scrollbar has to be present if there's overflow even if scrollbars overlayed
        // in this case they are position:absolute.
        if (dayElements.length) {
            const
                r           = Rectangle.from(dayElements[dayElements.length - 1], dayContainerElement),
                clientWidth = DomHelper.roundPx(Rectangle.client(dayContainerElement).width),
                scrollWidth = DomHelper.roundPx(me.rtl ? clientWidth - r.left : r.right - 1);
            // Forced synchronous layout here, but we need to ascertain horizontal overflow state
            if (scrollWidth > clientWidth) {
                horizontalScrollerElement.classList.remove('b-hide-display');
                me.scrollbarScroller.scrollWidth = scrollWidth;
                horizontalScroller.overflowX = 'hidden-scroll';
            }
            else {
                horizontalScrollerElement.classList.add('b-hide-display');
                me.scrollbarScroller.scrollWidth = null;
                horizontalScroller.overflowX = false;
            }
        }
        /**
         * Fires when this DayView changes an aspect of its layout. This happens when changing
         * {@link #property-hourHeight}, {@link #property-minDayWidth}, and when the available
         * day container height changes.
         * @event layoutUpdate
         * @param {Calendar.widget.DayView} source The triggering instance.
         */
        me.trigger('layoutUpdate');
    }
    refreshDayBackground() {
        const
            me                       = this,
            {
                contentElement,
                hourHeight,
                dayStartOffset,
                _hourHeightLevel,
                hourHeightLevel
            }                        = me,
            dayContentCls            = me.dayContentElement.classList,
            dayLength                = me.getDayLength('hour'),
            dayHeight                = hourHeight * dayLength,
            halfHourHeight           = (hourHeight - 2) / 2,
            fifteenMinuteHeight      = (hourHeight - 4) / 4,
            twelveMinuteHeight       = (hourHeight - 5) / 5,
            tenMinuteHeight          = (hourHeight - 6) / 6,
            sixMinuteHeight          = (hourHeight - 10) / 10,
            fiveMinuteHeight         = (hourHeight - 12) / 12,
            contentElementStyle      = contentElement.style,
            // Take the longest possible date and format it with the `timeFormat`, then estimate approximate space
            // required for the time.
            timeAxisWidth            = Math.ceil(DH.format(widestDate, me.timeFormat).length / 2);
        dayContentCls.remove('b-dayview-hourheight-level-1', 'b-dayview-hourheight-level-2', 'b-dayview-hourheight-level-3', 'b-dayview-hourheight-level-4');
        hourHeightLevel.level && dayContentCls.add(`b-dayview-hourheight-level-${hourHeightLevel.level}`);
        contentElement.classList.toggle('b-six-minute-ticks', Boolean(me.sixMinuteTicks));
        // The allDayRow needs to know this width
        me.element.style.setProperty('--time-axis-width', `${timeAxisWidth}em`);
        contentElementStyle.setProperty('--day-length', dayLength);
        contentElementStyle.setProperty('--day-height', `${dayHeight}px`);
        contentElementStyle.setProperty('--hour-height', `${hourHeight}px`);
        contentElementStyle.setProperty('--half-hour-height', `${halfHourHeight}px`);
        contentElementStyle.setProperty('--fifteen-minute-height', `${fifteenMinuteHeight}px`);
        contentElementStyle.setProperty('--twelve-minute-height', `${twelveMinuteHeight}px`);
        contentElementStyle.setProperty('--ten-minute-height', `${tenMinuteHeight}px`);
        contentElementStyle.setProperty('--six-minute-height', `${sixMinuteHeight}px`);
        contentElementStyle.setProperty('--five-minute-height', `${fiveMinuteHeight}px`);
        contentElementStyle.setProperty('--leaf-tick-height', `${me.sixMinuteTicks ? sixMinuteHeight : fiveMinuteHeight}px`);
        contentElementStyle.setProperty('--day-start-offset', `${dayStartOffset}px`);
        if (hourHeightLevel.level !== _hourHeightLevel?.level) {
            /**
             * Triggered when changing the {@link #property-hourHeight} causes an
             * {@link #config-hourHeightBreakpoints hour height breakpoint} to be crossed
             * and the displayed subTick intervals in the time axis change.
             *
             * @event tickChange
             * @param {Object} old The old hour height tick resolution object.
             * @param {Number} old.level The level `0` to `4` where `0` is the default with no
             * subTick times displayed, and `4` means every 5 minute time is displayed.
             * @param {String} old.step The time duration of visible subTicks. This is a string in the format
             * required by {@link Core.helper.DateHelper#function-parseDuration-static}
             * @param {Object} new The new hour height tick resolution object.
             * @param {Number} new.level The level `0` to `4` where `0` is the default with no
             * subTick times displayed, and `4` means every 5 minute time is displayed.
             * @param {String} new.step The time duration of visible subTicks. This is a string in the format
             * required by {@link Core.helper.DateHelper#function-parseDuration-static}
             */
            me.trigger('tickChange', {
                old : _hourHeightLevel || hourHeightLevelZero,
                new : hourHeightLevel
            });
        }
    }
    updateSixMinuteTicks(sixMinuteTicks) {
        if (!this.isConfiguring) {
            this.updateElementLayout();
        }
    }
    changeHourHeightBreakpoints(hourHeightBreakpoints) {
        // Convert from 5.2.x object format
        if (!Array.isArray(hourHeightBreakpoints)) {
            return Object.values(hourHeightBreakpoints);
        }
        return hourHeightBreakpoints;
    }
    updateHourHeightBreakpoints() {
        if (!this.isConfiguring) {
            this.updateElementLayout();
        }
    }
    get hourHeightLevel() {
        const
            me   = this,
            {
                hourHeight,
                hourHeightBreakpoints,
                sixMinuteTicks
            }     = me,
            steps = sixMinuteTicks ? sixMinuteSteps : fiveMinuteSteps;
        // Convert object format
        for (let i = sixMinuteTicks ? 2 : 3; i >= 0; i--) {
            if (hourHeight >= hourHeightBreakpoints[i]) {
                return me._hourHeightLevel = {
                    level : i + 1,
                    step  : steps[i]
                };
            }
        }
        return me._hourHeightLevel = hourHeightLevelZero;
    }
    updateLocalization() {
        if (!this.isConfiguring && this.allDayTextElement) {
            DomSync.sync({
                targetElement : this.allDayTextElement,
                domConfig     : {
                    html : this.L('L{EventEdit.All day}')
                }
            });
        }
        super.updateLocalization();
    }
}
DayView.initClass();
DayView._$name = 'DayView';