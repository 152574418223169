import EventList from '../../Calendar/widget/EventList.js';
import DH from '../../Core/helper/DateHelper.js';
import ArrayHelper from '../../Core/helper/ArrayHelper.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import Responsive from '../../Core/widget/mixin/Responsive.js';
import GridRowModel from '../../Grid/data/GridRowModel.js';
import EventSorter from '../util/EventSorter.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import '../column/AgendaColumn.js';
/**
 * @module Calendar/widget/AgendaView
 */
const
    isMouseOverOut = {
        mouseover : 1,
        mouseout  : 1
    },
    isMouseInteraction = {
        mousedown   : 1,
        mouseup     : 1,
        click       : 1,
        dblclick    : 1,
        contextmenu : 1
    };
/**
 * This is normally used as a {@link Calendar.view.Calendar#config-modes mode} of a Calendar (as seen in the live
 * demo below) but may be used standalone as a regular Widget.
 *
 * {@inlineexample Calendar/widget/CalendarAgendaView.js}
 *
 * As a standalone widget, it will lack the capabilities of the {@link Calendar.view.Calendar Calendar}
 * class, such as keyboard-based event to event navigation and drag/drop features. As seen in this demo:
 *
 * {@inlineexample Calendar/widget/AgendaView.js}
 *
 * A Grid which displays an agenda view of the events in an EventStore.
 *
 * When used as a {@link Calendar.view.Calendar#config-modes mode} of a Calendar, the configured
 * {@link #config-range} is snapped to encapsulate the Calendar's current
 * {@link Calendar.view.Calendar#config-date}.
 *
 * If configured with an explicit {@link #config-startDate} and {@link #config-endDate}, the
 * {@link #config-range} is not used. When setting the {@link #property-date}, the duration
 * of the configured range is preserved, but the range is shifted backwards or forwards in time
 * just enough to bring the passed `Date` into view.
 *
 * The AgendaView offers a floating settings button to allow the user to change the range type. This
 * may be disabled by configuring the {@link #config-listRangeMenu} as `null`.
 *
 * ### Column renderer
 * The content of each agenda cell is created by the {@link Calendar.column.AgendaColumn}'s
 * {@link Calendar.column.AgendaColumn#function-defaultRenderer}
 *
 * To inject content or manipulate the cell's DOM, you may configure the column with a renderer:
 *
 * ```javascript
 * modes : {
 *     agenda : {
 *         columns : {
 *             agenda : {
 *                 renderer({ cellElement, record : cellData }) {
 *                     // Manipulate the cell as we need
 *                     cellElement.classList.toggle('is-sunday', cellData.day === 0);
 *
 *                     // We need the system-provided cell rendering
 *                     return this.defaultRenderer(...arguments);
 *                 }
 *             }
 *         }
 *     }
 * }
 * ```
 *
 * @extends Calendar/widget/EventList
 * @classtype agendaview
 * @classtypealias agenda
 * @typingswidget
 */
export default class AgendaView extends EventList.mixin(Responsive) {
    static $name = 'AgendaView';
    static type = 'agendaview';
    static configurable = {
        eventSorter : EventSorter.interDaySorterFn,
        /**
         * The spacing between event bars in the default rendering of a day cell.
         * @config {Number|String}
         * @default
         */
        eventRowSpacing : 8,
        title : 'L{Agenda}',
        range : 'year',
        /**
         * By default, long running events are repeated in all rows that the event covers.
         *
         * Configure this as `true` to only see the start of a long running event in its
         * start day.
         * @config {Boolean}
         */
        hideEventOverflow : null,
        /**
         * This view lines up the textual content of event bars by shifting event bars of
         * events which start before the bar's cell leftwards by the arrow width.
         *
         * Set this config to `false` to prevent this.
         * @prp {Boolean}
         * @default
         */
        offsetStartsBeforeEvents : true,
        // We handle this internally using the notifications from the GridElementEvents mixin
        handlePointerInteraction : false,
        /**
         * Specify `false` to display column headers
         * @config {Boolean}
         * @default
         * @category Misc
         */
        hideHeaders : true,
        /**
         * Column definitions.
         *
         * By default, a single {@link Calendar.column.AgendaColumn} is configured which creates
         * the default cell content for one day's events.
         *
         * You may configure the default agenda column away, and provide a custom column type
         * to produce the day's content where the `record` passed is a
         * {@link Calendar.widget.mixin.DayCellCollecter#typedef-DayCell}.
         *
         * Because cells may contain varying numbers of events, all columns in an AgendaView are
         * set to {@link Grid.column.Column#config-autoHeight}
         *
         * ```javascript
         * class MyAgendaColumn extends Column {
         *     // So we automatically get b-myagenda-cell class on the cells
         *     static get type() {
         *         return 'myagenda';
         *     }
         *
         *     renderer({ cellElement, record : cellData }) {
         *         // Create a DomHelper element configuration object here using cellData
         *         // cellData contains date contextual info and an events array.
         *     }
         * }
         *
         * ...
         *
         * {
         *     columns : {
         *         agenda : null,
         *         {
         *             type : 'mycolumntype'
         *         }
         *     }
         * }
         *
         * // Register this Column type so that in the app we can use type : 'myagendacolumn'
         * ColumnStore.registerColumnType(MyAgendaColumn);
         *```
         * @config {Object|Object[]}
         * @default { agenda : { type : 'agendacolumn' } }
         */
        columns : {
            // We knock out the columns we inherit from EventList.
            name      : null,
            startDate : null,
            endDate   : null,
            resources : null,
            agenda    : {
                type : 'agendacolumn'
            }
        },
        /**
         * A function, or name of a function in the ownership hierarchy which is used to create
         * the time output next to event bars in an agenda cell.
         *
         * @config {Function|String}
         * @param {Scheduler.model.EventModel} eventRecord The event record for which to create a time string.
         * @param {Date} date The date of the cell in which the event is being rendered.
         * @returns {String}
         */
        eventTimeRenderer : null,
        enableSticky                  : true,
        preserveScrollOnDatasetChange : true,
        positionMode                  : 'position',
        settings : {},
        cellTabIndex : null,
        rowCls : {
            'b-cal-agenda-grid-row' : 1
        },
        cellCls : {
            'b-calendar-cell' : 1
        },
        eventBarContainerCls : 'b-cal-event-bar-container',
        // No GridNavigation key events in an AgendaView. It is natural, event-to-event navigation.
        keyMap : null,
        // Our rows encapsulate dates and *contain* events, so they are raw GridRowModels
        store : {
            modelClass : GridRowModel
        },
        listRangeMenu : {
            align : {
                align : 't100-b100'
            }
        }
    };
    /**
     * Returns the resource associated with this agenda view when used inside a {@link Calendar.widget.ResourceView}
     * @readonly
     * @member {Scheduler.model.ResourceModel} resource
     */
    construct(config) {
        const
            me           = this,
            featuresProp = ObjectHelper.getPropertyDescriptor(this, 'features');
        // Disable GridFeatures in AgendaView.
        // This is necessary in the built version where the features are all included
        // because of the needs of the docs app to use Grid for its own UI and because
        // many documentation examples run Grid examples.
        // This should change when https://github.com/bryntum/bryntum-suite/issues/1475 is addressed
        Object.defineProperty(me, 'features', {
            get : () => {
                return featuresProp.get.call(this);
            },
            set : features => {
                const f = GridFeatureManager.getInstanceDefaultFeatures(this);
                // Kill all the defaults
                for (const ft in f) {
                    f[ft] = false;
                }
                // We want to pass print config to the agenda view or simply enable print
                // feature if it is enabled on the calendar
                f.print = features?.print || Boolean(this.calendar?.features.print);
                featuresProp.set.call(this, f);
            },
            configurable : true
        });
        super.construct(config);
        // CalendarNavigation focuses event bars
        me.bodyContainer.removeAttribute('tabIndex');
    }
    onResponsiveStateChange({ state, oldState }) {
        super.onResponsiveStateChange?.(...arguments);
        // Moving between small and non-small state requires a refresh because the cell
        // layout changes and the rows need remeasuring and repositioning.
        if (oldState && (oldState === 'small' || state === 'small')) {
            this.refresh();
        }
    }
    changeColumns() {
        const result = super.changeColumns(...arguments);
        // Custom columns and AgendaColumns with custom renderer will be autoHeight
        result?.forEach?.(c => {
            c.autoHeight = Boolean(!c.isAgendaColumn || (c.renderer || this.eventRenderer));
        });
        return result;
    }
    // Override these because CalendarNavigation focuses and navigates *events*, not grid cells.
    onFocusGesture() {}
    onGridElementFocus() {}
    onGridBodyFocusIn() {}
    focusCell() {}
    setHoveredRow() {}
    onElementKeyDown() {}
    onElementMouseDown() {}
    editAutoCreatedEvent(event, eventRecord) {
        // Uniquely, AgendaView has to regenerate its Grid store on event add so that
        // there is an event element to edit by.
        this.populateStoreSoon.now();
        super.editAutoCreatedEvent(event, eventRecord);
    }
    handleEvent(event) {
        const { type } = event;
        super.handleEvent(event);
        // Implement eventMouseover/eventMouseout.
        // All else is handled at the EventList level.
        if (isMouseOverOut[event.type]) {
            this.onEventMouseOverOut(event);
        }
        else if (isMouseInteraction[type]) {
            this.onCalendarPointerInteraction(event);
        }
    }
    getCellDataFromEvent(domEvent) {
        if (domEvent.target.closest('.b-grid-cell')) {
            const result = super.getCellDataFromEvent(domEvent);
            result && (result.record = this.getEventRecord(domEvent.target));
            return result;
        }
    }
    updateOffsetStartsBeforeEvents(offsetStartsBeforeEvents) {
        // Must case to Boolean because undefined defaults to true
        this.element.classList.toggle('b-offset-continues-past', Boolean(offsetStartsBeforeEvents));
    }
    updateEventRowSpacing(eventRowSpacing) {
        this.contentElement.style.setProperty('--event-row-spacing', DomHelper.setLength(eventRowSpacing));
    }
    updateSuppressLongEvents() {
        this.fillFromMaster();
    }
    onCalendarStoreChange({ action, oldCount, records, removed, added }) {
        const me = this;
        if (me.isPainted) {
            // A filter which resulted in no filtering. Ignore it.
            if (action === 'filter' && !removed?.length && !added?.length) {
                return;
            }
            // Draw on project refresh instead of on dataset
            if (action === 'dataset') {
                return;
            }
            // Single record remove just updates the generated records that the event covers
            if (action === 'remove' && records.length === 1 && records[0].isEventModel) {
                const
                    { store }    = me,
                    eventRecord  = records[0],
                    date         = DH.clearTime(eventRecord.startDate),
                    endDate      = DH.clearTime(eventRecord.endDate);
                do {
                    const
                        key          = DH.makeKey(date),
                        cellData     = me.cellMap.get(key);
                    if (cellData) {
                        ArrayHelper.remove(cellData.events, eventRecord);
                        // Update the row for this date
                        if (cellData.events.length) {
                            me.onStoreUpdateRecord({
                                source  : store,
                                record  : me.store.getById(key),
                                changes : {}
                            });
                        }
                        // No events on this date
                        else {
                            me.cellMap.delete(key);
                            delete me.dateIndex[key];
                            store.remove(key, true);
                        }
                    }
                    date.setDate(date.getDate() + 1);
                } while (date < endDate);
                return;
            }
        }
        me.populateStoreSoon();
    }
    get cellMap() {
        const me = this;
        // If the cellMap has not been populated, create it.
        return me._cellMap?.populated ? me._cellMap : me.createCellMap({
            // If we are being set a startDate and endDate, as opposed to using a fixed "range"
            // around a date, then to provide a more intuitive interface, we *include* the endDate
            // for EventLists
            endDate       : DH.add(me.endDate, me.range ? 0 : 1, 'd'),
            rawEvents     : true,
            skipPropagate : true
        });
    }
    populateStore() {
        this._cellMap?.clear();
        const
            me = this,
            {
                store,
                eventStore,
                rowManager
            }             = me,
            { rowHeight } = rowManager,
            rowCount      = rowManager.rows?.length,
            eventHeight   = isNaN(me.eventHeight) ? 25 : me.eventHeight;
        me.eventCount = 0;
        if (!me.date) {
            // Avoid recursion into populateStore
            me.isConfiguring = true;
            me.date = eventStore.map(r => r.startDate).sort((lhs, rhs) => lhs.valueOf() - rhs.valueOf())[0];
            me.isConfiguring = false;
        }
        const
            { cellMap }    = me,
            cellMapEntries = [...cellMap.values()];
        me.dateIndex = {};
        for (let i = 0, { length } = cellMapEntries; i < length; i++) {
            const
                cellData         = cellMapEntries[i],
                { events, date } = cellData;
            // Count unique events
            for (let j = 0, { length } = events; j < length; j++) {
                const event = events[j];
                if (!me.isAllDayEvent(event) || !i || DH.clearTime(event.startDate).valueOf() === date.valueOf()) {
                    me.eventCount++;
                }
            }
            // build date index
            me.dateIndex[cellData.id] = cellMapEntries[i] = store.createRecord(cellData);
        }
        const avgEventsPerCell = me.eventCount ? cellMapEntries.map(e => e.events.length).reduce((a, b) => a + b, 0) / cellMapEntries.length : 0;
        store.suspendEvents();
        store.loadData(cellMapEntries);
        store.resumeEvents();
        // Give RowManager a clue so that it can calculate an appropriate rowCount.
        // If the rows are tall, we do not need many to cover the viewport.
        rowManager._rowHeight = 20;
        // RowManager#set rowHeight does not tolerate no rows.
        if (store.count) {
            rowManager.rowHeight = Math.max(avgEventsPerCell * (eventHeight + me.eventSpacing), 70);
        }
        // Setting the rowHeight does a refresh if there are existing rows and the height actually changed.
        // Otherwise, we explicitly refresh now.
        if (!rowCount || !store.count || rowManager.rowHeight === rowHeight) {
            rowManager.calculateRowCount();
            rowManager.estimateTotalHeight(true);
        }
        me.refreshCount = (me.refreshCount || 0) + 1;
        /**
         * Fires when this AgendaView refreshes.
         * @param {Calendar.widget.AgendaView} source The triggering instance.
         * @event refresh
         */
        me.trigger('refresh');
        // The owning Calendar's UI may need to sync with the new state
        me.calendar?.syncUIWithActiveView(me);
        me.columns.forEach(c => c.constructor.exposeProperties?.());
        // Evaluate this late so that it doesn't change the order of date config evaluation
        // Ensure that the menu stays aligned if scrollbar causes button movement.
        me.settings?._menu?.realign();
    }
    get count() {
        return this.eventCount;
    }
    collectEvents(options) {
        // Only the first cell, or !hideEventOverflow needs overflows flowing into it.
        options.getDateIndex = date => date > this.startDate && this.hideEventOverflow ? 'startDate' : 'date';
        return super.collectEvents(options);
    }
    changeStore(store) {
        store = super.changeStore(store);
        if (store) {
            this.nonWorkingDaysFilter = store.addFilter({
                id       : `${this.id}-nonworkingday-filter`,
                filterBy : rec => !rec.isNonWorking,
                disabled : !this.hideNonWorkingDays
            }, true);
            this.detachListeners('agendaStoreFilter');
            store.ion({
                name    : 'agendaStoreFilter',
                filter  : 'onAgendaStoreFilter',
                thisObj : this
            });
        }
        return store;
    }
    onAgendaStoreFilter() {
        const me = this;
        // Count unique events
        me.eventCount = me.store.reduce((result, rec, i) => {
            const { events, date } = rec;
            for (let j = 0, { length } = events; j < length; j++) {
                const event = events[j];
                if (!me.isAllDayEvent(event) || !i || DH.clearTime(event.startDate).valueOf() === date.valueOf()) {
                    result++;
                }
            }
            return result;
        }, 0);
    }
    updateHideEventOverflow() {
        this.populateStore();
    }
    // We must implement the CalendarMixin interface.
    // All views must expose a doRefresh method.
    // Override from EventList. We need to repopulate our store to create day cells.
    doRefresh() {
        this.populateStore();
    }
    createCellData(date) {
        return Object.assign(this.cellMonth.getCellData(date, this.month), {
            id     : DH.makeKey(date),
            events : []
        });
    }
    set cellRenderer(cellRenderer) {
        this._cellRenderer = cellRenderer;
    }
    changeSettings(settings) {
        const { listRangeMenu : menu } = this;
        return settings && menu && super.changeSettings({
            menu
        });
    }
}
AgendaView.initClass();
AgendaView._$name = 'AgendaView';