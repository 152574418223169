import CalendarFeature from './CalendarFeature.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import MessageDialog from '../../Core/widget/MessageDialog.js';
/**
 * @module Calendar/feature/LoadOnDemand
 */
/**
 * Loads the host Calendar's {@link Scheduler.view.mixin.SchedulerStores#config-crudManager CrudManager} on demand
 * as the date range required to produce the UI changes.
 *
 * Passes the requested `startDate` and `endDate` as extra HTTP parameters along with the load request.
 *
 * By default, the HTTP parameters are called `'startDate'` and `'endDate'`. This is configurable using the
 * {@link #config-startDateParamName} and {@link #config-endDateParamName} configs.
 *
 * The date values are formatted according to the {@link #config-dateFormat} config.
 *
 * Usage:
 *
 * ```javascript
 * new Calendar({
 *     features : {
 *         loadOnDemand : true
 *     }
 * });
 * ```
 *
 * ## Using recurring events
 * When using this feature when recurring events are in the database, *all recurring events* which
 * started before the requested start date, and have not yet finished recurring MUST be sent as part
 * of the return packet so that the Calendar is able to populate its UI.
 *
 * Only the base recurring event *definition* is stored in the Calendar's EventStore.
 *
 * When asked to yield a set of events for a certain date range for creating a UI, the EventStore
 * *automatically* interpolates any occurrences of recurring events into the results. They do not
 * occupy slots in the EventStore for every date in their repetition range (that would be very
 * inefficient, and *might* be infinite).
 *
 * ## Handling data load failures
 * If a network or server error is detected, the {@link Calendar.view.Calendar} will fire a
 * {@link #event-loadOnDemandFail} event so that an application can produce an error UI and
 * handle the situation.
 *
 * A handler should return `false` to prevent the default provided error UI from showing.
 *
 * If there is no handler, or the handler __does not__ return `false`, a default error UI is
 * shown using `{@link Core.widget.MessageDialog#function-alert MessageDialog}`.
 *
 * ## Using with `showEvents` in the sidebar date picker.
 * If you have configured the Calendar's {@link Calendar.view.Calendar#config-datePicker} to
 * {@link Calendar.widget.CalendarDatePicker#config-showEvents}, the date picker will also
 * initiate a request whenever it navigates forward or backward in time.
 *
 * This could interfere with the current Calendar `mode`, so in this case, this feature extends
 * the requested date range to include the date range which encapsulates both widgets' required
 * ranges.
 *
 * This __may__ mean that a large block of events could be loaded if the date picker is navigated
 * in time a long way while leaving the Calendar's main view date the same.
 *
 * The {@link #config-alwaysLoadNewRange} property must not be set in this use case because *both*
 * widgets will initiate requests, and this would cause an infinite recursion of event requests.
 *
 * This feature is **disabled** by default.
 *
 * @extends Calendar/feature/CalendarFeature
 * @classtype loadOnDemand
 * @feature
 */
export default class LoadOnDemand extends CalendarFeature {
    static $name = 'LoadOnDemand';
    static type = 'loadOnDemand';
    static get configurable() {
        return {
            /**
             * The name of the HTTP parameter which contains the start date of the view requiring new data.
             * @config {String}
             * @default
             */
            startDateParamName : 'startDate',
            /**
             * The name of the HTTP parameter which contains the end date of the view requiring new data.
             * @config {String}
             * @default
             */
            endDateParamName : 'endDate',
            /**
             * The {@link Core.helper.DateHelper#function-format-static DateHelper} format string to use to
             * encode the start date and end date of the events to load when the view requires a new date range.
             * @config {String}
             * @default
             */
            dateFormat : 'YYYY-MM-DD',
            /**
             * A function, or name of a function in the ownership hierarchy which may be called
             * to mutate the `options` packet that is passed to the {@link Scheduler.data.CrudManager}
             * {@link Scheduler.crud.AbstractCrudManagerMixin#function-load} method.
             * One possible use of this function is to mutate the `options.request.params` object to add extra
             * parameters for the server.
             *
             * @config {Function|String}
             * @param {Object} options The `options` parameter to be sent to the {@link Scheduler.data.CrudManager}
             *   {@link Scheduler.crud.AbstractCrudManagerMixin#function-load} method.
             * @param {Object} options.dateRangeRequested An object containing the start and end dates of the range to load.
             * @param {Date} options.dateRangeRequested.startDate The start date of the range to request.
             * @param {Date} options.dateRangeRequested.endDate The end date of the range to request. **Note that Dates are timestamps**.
             * @param {Object} options.request A configuration object for the CrudManager load request
             * @param {Object} options.request.params An object where the property name is the HTTP parameter name and the property value is the parameter value.
             * @returns {void}
             */
            beforeRequest : null,
            /**
             * By default, if a view requests a date range that we have already loaded, no
             * network request is made, and the events will be loaded from the current content
             * of the event store.
             *
             * To make the feature load a new event block on every request for a __new__ date range,
             * configure this as `true`.
             * @config {Boolean}
             * @default false
             */
            alwaysLoadNewRange : null,
            /**
             * Configure this as `true` to clear the event store when a new date range has been requested
             * instead of leaving it until the load of the new data to correct the store contents,
             *
             * Setting this to true clears the event store prior to requesting the data load.
             * @config {Boolean}
             * @default false
             */
            clearOnNewRange : null
        };
    }
    construct(config) {
        const { client } = config;
        // The purpose of this feature is to load fresh data, so it should not use syncDataOnLoad.
        // This can be automatically set by framework wrapper, so we must ensure it is false.
        client.eventStore.syncDataOnLoad = false;
        // When the client requests a range of dates from its eventStore, we get notified.
        // If the eventStore has not successfully loaded that range, we load that range.
        client.ion({
            dateRangeRequested : 'onClientDateRangeRequested',
            thisObj            : this
        });
        // This to register the date range that the operation just successfully loaded.
        // We register the loaded date range *before* the response is applied so that
        // when UI requests which emanate from the impending application of the new dataset
        // ask for date ranges requests, the range will be detected as already present.
        client.crudManager.ion({
            beforeLoadApply : 'onCrudManagerBeforeApply',
            thisObj         : this,
            prio            : 9999
        });
        super.construct(...arguments);
    }
    // Tests if the CrudManager has an outstanding load request which would satisfy the passed date range
    hasOutstandingLoadFor(startDate, endDate) {
        const { load } = this.client.crudManager.activeRequests;
        if (load) {
            const otherDateRangeRequested = load.options?.dateRangeRequested;
            // If the outstanding load request encompasses ours, allow it to go through
            // We do not need to do anything.
            if (otherDateRangeRequested && DateHelper.timeSpanContains(
                otherDateRangeRequested.startDate,
                otherDateRangeRequested.endDate,
                startDate,
                endDate
            )) {
                return true;
            }
        }
    }
    // We observe our CrudManager's successful loads and register the date range requested.
    // This is so that when a client dateRangeChange event is encountered, we can only
    // trigger a load if the date range is not already loaded unless alwaysLoadNewRange is set..
    onCrudManagerBeforeApply({ response, options }) {
        // If it was a full load from a mode which doesn't know about this feature such as
        // a Scheduler, that will have requested an unranged load and the last range loaded
        // will be unknown. In this case the next request will *always* trigger a load.
        this.lastRangeLoaded = response.success && options?.dateRangeRequested;
    }
    onClientDateRangeRequested({
        new : {
            startDate,
            endDate
        },
        changed
    }) {
        const
            me         = this,
            { client } = me;
        // alwaysLoadNewRange is only valid for responding to *new* date range requests.
        // Otherwise it responds even if the request was satisfied, and an infinite
        // load->refresh->getEvents->rangeRequested->load loop would occur.
        if (!changed && me.alwaysLoadNewRange) {
            return;
        }
        const { lastRangeLoaded } = me;
        // Check for whether we have already loaded the requested range unless we are configured to always load.
        if (lastRangeLoaded && !me.alwaysLoadNewRange) {
            const {
                startDate : lastStartDate,
                endDate   : lastEndDate
            } = me.lastRangeLoaded;
            // Our loaded range already contains this range
            if (DateHelper.timeSpanContains(lastStartDate, lastEndDate, startDate, endDate)) {
                return;
            }
        }
        // Setting to clear the store down on range change.
        // Long running events from one month will show up in the next month.
        // Set this to clear the store. Of course the same events will only get loaded again
        // because they are long running and intrude into the new month but
        // this is a cosmetic issue for app developers to choose.
        if (changed) {
            DomHelper.addTemporaryClass(client.element, 'b-notransition', 100, client);
            if (me.clearOnNewRange) {
                client.eventStore.clear(true);
                me.lastRangeLoaded = null;
            }
        }
        if (!me.disabled && !me.hasOutstandingLoadFor(startDate, endDate)) {
            // Register the range that the view needs.
            // All requested date ranged will be merged into one load
            me.loadDateRange(startDate, endDate);
        }
    }
    /**
     * Reloads the currently loaded date range.
     *
     * If your app detects that the data may be stale, or needs to periodically refresh the data,
     * this method may be used to issue a server request to reload the currently loaded date range.
     */
    refresh() {
        const { lastRangeLoaded } = this;
        if (lastRangeLoaded) {
            this.loadDateRange(lastRangeLoaded.startDate, lastRangeLoaded.endDate);
        }
    }
    loadDateRange(startDate, endDate) {
        const
            me            = this,
            {
                pendingLoad,
                client
            }              = me,
            { datePicker } = client;
        // If there's a DatePicker which is showing events, the requested date range must
        // encapsulate both the DatePicker's demands and the activeView's demands.
        if (datePicker?.showEvents) {
            const { activeView } = client;
            startDate = new Date(Math.min(activeView.startDate, datePicker.startDate));
            endDate   = new Date(Math.max(activeView.endDate,   datePicker.endDate));
        }
        // We gather the widest date range that is asked for, and the final range
        // is requested in the next time frame.
        if (pendingLoad) {
            pendingLoad.startDate = DateHelper.min(startDate, pendingLoad.startDate);
            pendingLoad.endDate = DateHelper.max(endDate, pendingLoad.endDate);
        }
        else {
            me.pendingLoad = {
                startDate,
                endDate
            };
            me.client.requestAnimationFrame(() => me.load());
        }
    }
    async load() {
        const
            me = this,
            {
                client,
                beforeRequest,
                dateFormat
            }  = me,
            {
                crudManager
            }  = client,
            {
                load
            }  = crudManager.activeRequests,
            {
                startDate,
                endDate
            }  = me.pendingLoad;
        const options = {
            dateRangeRequested : me.pendingLoad,
            request            : {
                params : {
                    [me.startDateParamName] : DateHelper.format(startDate, dateFormat),
                    [me.endDateParamName]   : DateHelper.format(endDate, dateFormat)
                }
            }
        };
        // Ensure that subsequent loadDateRange requests queue up.
        me.pendingLoad = null;
        // allow app developers to mutate the request
        if (beforeRequest) {
            me.callback(beforeRequest, client, [options]);
        }
        if (load) {
            // If there is already a load request in flight which would satisfy the date range
            // Alow it to go through.
            if (me.hasOutstandingLoadFor(startDate, endDate)) {
                return;
            }
            try {
                await crudManager.cancelRequest(load.desc, load.reject);
            }
            catch (e) {
                // swallow rejected load Promise exception
            }
        }
        let result;
        try {
            result = await crudManager.load(options);
        }
        catch (e) {
            /**
             * Fires when the {@link Calendar.feature.LoadOnDemand} feature detects that a request
             * for data from the server has failed.
             *
             * An event listener handler may produce an error UI.
             *
             * If no handler returns `false`, then a default error UI is shown
             * using `{@link Core.widget.MessageDialog#function-alert MessageDialog}`.
             * @event loadOnDemandFail
             * @param {Response} rawResponse The HTTP `fetch` response object.
             * @param {Object} request The CrudManager load data block.
             * @param {Object} response The decoded JSON response.
             * @on-owner
             */
            if (!client.isDestroyed && !e.cancelled && client.trigger('loadOnDemandFail', e) !== false) {
                MessageDialog.alert({
                    title   : client.L('L{Calendar.loadFail}'),
                    message : e.message
                });
            }
        }
        /**
         * Fires when the {@link Calendar.feature.LoadOnDemand} feature has loaded a range of events.
         * @event dateRangeLoad
         * @param {Object} response The decoded JSON response.
         * @param {Object} options The options object passed into the CrudManager {@link Scheduler.data.CrudManager}
         * {@link Scheduler.crud.AbstractCrudManagerMixin#function-load} method.
         * @param {Date} startDate The start date of the range to request.
         * @param {Date} endDate The end date of the range to request. **Note that Dates are timestamps**.
         * @on-owner
         */
        (result && !client.isDestroyed) && client.trigger('dateRangeLoad', { response : result.response, options, startDate, endDate });
    }
}
// Register this feature type with its Factory
LoadOnDemand.initClass();
LoadOnDemand._$name = 'LoadOnDemand';