import Tooltip from '../../Core/widget/Tooltip.js';
import ClockTemplate from '../../Scheduler/tooltip/ClockTemplate.js';
import DH from '../../Core/helper/DateHelper.js';
import FunctionHelper from '../../Core/helper/FunctionHelper.js';
import RecurrenceLegend from '../../Scheduler/data/util/recurrence/RecurrenceLegend.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import StringHelper from '../../Core/helper/StringHelper.js';
import ResourceChipView from '../widget/ResourceChipView.js';
/**
 * @module Calendar/widget/EventTip
 */
const hasEventStore = w => w.eventStore;
/**
 * Displays a tooltip containing extra info and options on either event click or event hover.
 *
 * See the {@link Calendar.feature.EventTooltip} feature for more information and an example of
 * customizing the displayed event tooltip.
 *
 * The EventTip is provided with two standard {@link #config-tools}:
 *
 * * `edit` - A tool which is linked to the {@link Calendar.feature.EventEdit EventEdit} feature
 * to initiate editing when clicked.
 * * `delete` - A tool which removes the current event record
 * function of the active view to enable event deletion.
 *
 * New tools may be added, or properties of existing tools may be changed by configuring the
 * {@link Calendar.feature.EventTooltip} feature.
 *
 * Content may be customized using the {@link #config-titleRenderer} and {@link #config-renderer}
 * which may create complex content by returning a {@link DomConfig}:
 *
 * ```javascript
 *     features : {
 *         eventTooltip : {
 *             tools : {
 *                 // Just override handler of existing tool - all else is OK
 *                 edit : {
 *                     handler : () => console.log(`Handle editing ${this.eventRecord.name} our way`);
 *                 },
 *                 // Add a new tool for our own operation
 *                 newTool : {
 *                     cls     : 'b-icon-add',
 *                     tooltip : 'Test',
 *                     handler() {
 *                         console.log(`Test ${this.eventRecord.name}`);
 *                     }
 *                 }
 *             },
 *             renderer({ eventRecord }) {
 *                 return {
 *                     text : `From ${DateHelper.format(eventRecord.startDate, 'l')} to ${DateHelper.format(eventRecord.endDate, 'l')}`
 *                 },
 *             },
 *             titleRenderer( eventRecord ) {
 *                 return {
 *                     text      : `${eventRecord.name} ${eventRecord.resource.name}`,
 *                     className : {
 *                         'b-urgent'    : eventRecord.isUrgent,
 *                         'b-completed' : eventRecord.isCompleted
 *                     }
 *                 };
 *             }
 *         }
 *     }
 * ```
 *
 * @extends Core/widget/Tooltip
 * @classtype eventTooltip
 */
export default class EventTip extends Tooltip {
    //region Config
    static get $name() {
        return 'EventTip';
    }
    static get type() {
        return 'eventtip';
    }
    static get configurable() {
        return {
            localizableProperties : ['timeFormat'],
            align : {
                // Needed because we show on click meaning the tip persists and may need
                // to adjust to UI shape changes.
                monitorResize : true
            },
            /**
             * A {@link Core.helper.DateHelper} format string used to format dates displayed in this tooltip.
             *
             * @config {String}
             * @default
             */
            dateFormat : 'll',
            /**
             * The event which the tooltip feature has been shown for.
             * @member {Scheduler.model.EventModel} eventRecord
             * @readonly
             */
            /**
             * The event record for this tip to display
             * @config {Scheduler.model.EventModel} eventRecord
             */
            eventRecord : {
                $config : 'nullify',  // ensure we remove our afterChange hook
                value : null
            },
            recurrenceHint : '',
            /**
             * A {@link Core.helper.DateHelper} format string used to format the time displayed in this tooltip.
             *
             * @config {String}
             * @default 'LST'
             */
            timeFormat : {
                value   : 'LST',
                $config : {
                    localeKey : 'L{timeFormat}'
                }
            },
            // Allow it to fall back from its initial alignment axis to the cross axis.
            // So if aligned l-r or r-l and shown by a long multiday event, it allows
            // itself to be fall back to being aligned t-b
            axisLock : 'flexible',
            closable     : null,
            forSelector  : '.b-cal-event-wrap',
            maxWidth     : '30em',
            minWidth     : '14em',
            scrollAction : 'realign',
            cls : {
                'b-sch-event-tooltip' : 1
            },
            tools : {
                edit : {
                    cls     : 'b-icon-edit',
                    handler : 'up.onEditClick',
                    tooltip : 'L{EventEdit.Edit event}',
                    weight  : 100
                },
                delete : {
                    cls     : 'b-icon-trash',
                    handler : 'up.onDeleteClick',
                    tooltip : 'L{SchedulerBase.Delete event}',
                    weight  : 0
                },
                maximize : null
            },
            /**
             * A function, or name of a function in the ownership hierarchy which is used to create
             * the content of the tooltip's header.
             *
             * @prp {Function|String}
             * @param {Scheduler.model.EventModel} eventRecord The event record
             * @returns {String|DomConfig} The content of the header element.
             */
            titleRenderer : eventRecord => StringHelper.encodeHtml(eventRecord.name),
            /**
             * A function, or name of a function in the ownership hierarchy which is used to create
             * the content of the tooltip's body.
             *
             * @prp {Function|String}
             * @param {Object} data Contextual data about the current tooltip activation
             * @param {HTMLElement} data.activeTarget The event bar element being aligned to
             * @param {Scheduler.model.EventModel} data.eventRecord The event record
             * @param {Event} data.event The DOM event which initiated the tooltip show
             * @param {Calendar.widget.EventTip} data.tip The tooltip instance being shown
             * @returns {String|DomConfig} The content of the body element.
             */
            renderer : null,
            /**
             * By default, the end date of an all day event is displayed in the tooltip UI as
             * the last calendar date on which the event falls. For most end users, this is the
             * expected value.
             *
             * Technically, the {@link Scheduler.model.EventModel#field-endDate} is a timestamp
             * which represents the exact point in time at which an event ends. To use this instead,
             * configure `extendAllDayEndDay` as `true`.
             *
             * To be clear, this would mean that an {@link Scheduler.model.EventModel#field-allDay}
             * event starting and ending on the 7th of February 2020, would show the end date in the
             * tooltip as 8th of February 2020.
             * @prp {Boolean}
             * @default false
             */
            extendAllDayEndDay : null,
            activeClient : null,
            /**
             * By default, if the tip's target event is in a cluster of overlapping events and therefore
             * narrow, activating the tip will expand it to full width temporarily.
             *
             * Configure this as `false` to disable this.
             * @prp {Boolean}
             * @default true
             */
            revealEventsInCluster : true
        };
    }
    static get delayable() {
        return {
            refreshContent : 20
        };
    }
    clockTemplate = new ClockTemplate({});
    //endregion
    get owner() {
        return Tooltip.fromElement(this.activeTarget) || this.ownerFeature.client;
    }
    onEditClick() {
        this.trigger('edit');
    }
    onDeleteClick() {
        this.trigger('delete');
    }
    getHtml(data) {
        const
            me              = this,
            { eventRecord } = me;
        if (eventRecord) {
            data.eventRecord = eventRecord;
            me.title = me.titleRenderer ? me.callback(me.titleRenderer, me, [eventRecord]) : '';
            return me.callback(me.renderer || me.internalRenderer, me, [{
                ...data,
                eventRecord
            }]);
        }
    }
    internalRenderer({ eventRecord }) {
        const
            {
                clockTemplate,
                dateFormat,
                recurrenceHint,
                timeFormat
            }                = this,
            {
                allDay,
                endDate,
                startDate,
                recurrence
            }                = eventRecord,
            // For allDay events round eg 2020-10-18T12:00 up to 2020-10-19T00:00:00.000
            lastDay          = allDay ? (this.extendAllDayEndDay ? DH.ceil(endDate, '1 day') : DH.add(endDate, -1, 'day')) : endDate,
            startMidnight    = DH.clearTime(startDate),
            lastMidnight     = DH.clearTime(lastDay),
            multiDay         = startMidnight < lastMidnight,
            hasTime          = !allDay && startMidnight < startDate || lastMidnight < lastDay,
            format           = hasTime ? (multiDay ? `${dateFormat} ${timeFormat}` : timeFormat) : dateFormat,
            recurrenceLegend = recurrence ? RecurrenceLegend.getLegend(recurrence) : recurrenceHint,
            duration         = DH.formatDelta(DH.diff(startDate, endDate), allDay ? { precision : 'd' } : null),
            onlyStartDate    = eventRecord.isMilestone || (allDay && !multiDay);
        clockTemplate.mode = multiDay ? 'day' : 'hour';
        return (
            clockTemplate.template({
                date : startDate,
                text : DH.format(startDate, format),
                cls  : 'b-sch-tooltip-startdate'
            }) +
            (onlyStartDate ? '' : clockTemplate.template({
                date : lastDay,
                text : DH.format(lastDay, format),
                cls  : 'b-sch-tooltip-enddate'
            })) +
            (eventRecord.isMilestone ? '' : `<div class="b-cal-tooltip-duration b-icon b-icon-duration">${duration}</div>`) +
            (recurrenceLegend ? `<div class="b-cal-tooltip-recurrence b-icon b-icon-recurring">${recurrenceLegend}</div>` : '')
        );
    }
    showByEvent(event, element = event.target) {
        const me = this;
        me.activeTarget = element;
        me.pointerEvent = event;
        me.updateContent();
        if (event.type === 'contextmenu') {
            return me.showBy({
                domEvent : event,
                anchor   : false
            });
        }
        return me.showBy(element);
    }
    afterShowByTarget() {
        const { delete : deleteTool } = this.tools;
        // Capture the active client only when we are visible.
        this.activeClient = Tooltip.fromElement(this.activeTarget)?.closest(hasEventStore);
        super.afterShowByTarget();
        // The delete tool is disabled if the Calendar is readOnly
        deleteTool && (deleteTool.disabled = this.ownerFeature.client.readOnly);
    }
    afterHide() {
        super.afterHide(...arguments);
        this.activeClient = null;
    }
    updateActiveTarget(element, was) {
        super.updateActiveTarget(...arguments);
        was?.classList.remove('b-cal-event-reveal');
        if (element) {
            // The activating event element is lifted to the top of the z-index stack while
            // it is the target of the tooltip.
            if (element.classList.contains('b-cal-in-cluster') && this.revealEventsInCluster) {
                element.classList.add('b-cal-event-reveal');
            }
        }
        else {
            this.eventRecord = null;
        }
    }
    refreshContent() {
        // This method is just a buffered wrapper of updateContent.
        // We might be hidden by the time it's called.
        this.eventRecord && this.updateContent();
    }
    updateEventRecord(eventRecord) {
        const me = this;
        me._changeHook?.();
        me._changeHook = eventRecord && FunctionHelper.after(eventRecord, 'afterChange', me.refreshContent, me);
        if (eventRecord) {
            if (me.tools.delete) {
                me.tools.delete.hidden = eventRecord.readOnly;
            }
            if (me.tools.edit) {
                me.tools.edit.hidden = eventRecord.readOnly;
            }
        }
    }
    updateContent() {
        const
            { clockTemplate, element, eventRecord } = this,
            result                                 = super.updateContent(),
            startElement                           = element.querySelector('.b-sch-tooltip-startdate'),
            endElement                             = element.querySelector('.b-sch-tooltip-enddate'),
            { edit, delete : del }                 = this.tools,
            { client }                             = this.ownerFeature,
            { eventEdit }                          = client.features,
            readOnly                               = client.readOnly || eventRecord.readOnly;
        startElement && clockTemplate.updateDateIndicator(startElement, eventRecord.startDate);
        endElement && clockTemplate.updateDateIndicator(endElement, eventRecord.endDate);
        // Hide the edit tool if the Calendar or event is readOnly or there is no editFeature or it's disabled
        edit && (edit.hidden = readOnly || !eventEdit?.enabled);
        // Hide delete tool if the Calendar or event is readOnly
        del && (del.hidden = readOnly);
        if (this.resources) {
            const { chipView } = this;
            chipView.items = eventRecord.resources;
            this.contentElement.querySelector('.b-resourcechipview')?.remove();
            this.contentElement.appendChild(chipView.element.cloneNode(true));
        }
        return result;
    }
    realign() {
        const { lastAlignSpec } = this;
        // If the event bar to which we were attached is recycled by another event
        // Our tenure in the current guise is over.
        if (this.isVisible && lastAlignSpec?.aligningToElement && lastAlignSpec.target.dataset.eventId !== String(this.eventRecord?.id)) {
            this.hide();
        }
        super.realign(...arguments);
    }
    updateActiveClient(activeClient) {
        this.detachListeners('clientOverflowChange');
        // We may have to hide when the active client changes its possible overflow threshold
        if (activeClient) {
            activeClient.ion({
                name                : 'clientOverflowChange',
                eventsPerCellChange : 'onClientOverflowChange',
                thisObj             : this
            });
        }
    }
    onClientOverflowChange() {
        // If that caused the target to no longer be visible, we must hide.
        // For example aligned to an event which is now in overflow.
        // Or aligned to an event in the overflow popup which has now hidden.
        if (!DomHelper.isVisible(this.lastAlignSpec.target)) {
            this.hide();
        }
    }
    onDocumentMouseDown({ event }) {
        // Ignore mousedown on active element if we show on a click.
        if (!event.button && this.ownerFeature.showOn === 'click' && this.activeTarget.contains(event.target)) {
            return;
        }
        return super.onDocumentMouseDown(...arguments);
    }
    get chipView() {
        const me = this;
        if (!me._chipView) {
            me._chipView = new ResourceChipView({
                parent : me
            });
            // The List class only refreshes itself when visible, so
            // since this is an offscreen, rendering element
            // we have to fake visibility.
            Object.defineProperty(me.chipView, 'isVisible', {
                get() {
                    return true;
                }
            });
            // Complete the initialization, which is finalized on first paint.
            // In particular the lazy scrollable config is ingested on paint.
            me.chipView.triggerPaint();
        }
        return me._chipView;
    }
}
// Register this feature type with its Factory
EventTip.initClass();
EventTip._$name = 'EventTip';