import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import SchedulerCrudManager from '../../Scheduler/data/CrudManager.js';
import ProjectModel from '../model/ProjectModel.js';
import TimeRangeModel from '../model/TimeRangeModel.js';
/**
 * @module Calendar/data/CrudManager
 */
// noinspection JSClosureCompilerSyntax
/**
 * A Calendar-specific implementation of a `CrudManager`.
 *
 * For more information, see {@link Scheduler.data.CrudManager}.
 *
 * @extends Scheduler/data/CrudManager
 *
 * @typings Scheduler.data.CrudManager -> Scheduler.data.SchedulerCrudManager
 */
export default class CrudManager extends SchedulerCrudManager {
    static $name = 'CrudManager';
    //region Config
    static configurable = {
        projectClass   : ProjectModel,
        timeRangeClass : TimeRangeModel
    };
    //endregion
    buildProjectConfig() {
        const
            ret = super.buildProjectConfig(),
            { timeRangeStore } = this;
        if (timeRangeStore) {
            ret.timeRangeStore = timeRangeStore;
        }
        return ret;
    }
    //region Stores
    get timeRangeStore() {
        return super.timeRangeStore;
    }
    set timeRangeStore(store) {
        if (store && ObjectHelper.isObject(store)) {
            store = ObjectHelper.assign({
                modelClass : this.timeRangeClass
            }, store);
        }
        this.setFeaturedStore('_timeRangeStore', store, this.project?.timeRangeStoreClass);
    }
    //endregion
};
CrudManager.initClass();
CrudManager._$name = 'CrudManager';