import CalendarFeature from './CalendarFeature.js';
import Widget from '../../Core/widget/Widget.js';
import EventTip from '../widget/EventTip.js';
import StringHelper from '../../Core/helper/StringHelper.js';
import { parseAlign } from '../../Core/helper/util/Rectangle.js';
import '../../Scheduler/view/recurrence/RecurrenceConfirmationPopup.js';
/**
 * @module Calendar/feature/EventTooltip
 */
const
    hasEventStore = w => w.eventStore,
    ownConfigs = [
        'activeElement',
        'client',
        'clientListeners',
        'disabled',
        'showOn',
        'tooltip'
    ];
/**
 * A feature which displays a {@link #config-tooltip} containing extra information. The tooltip can
 * be triggered by clicking or hovering an event bar element (see {@link #config-showOn}).
 *
 * Configuration options from this feature are used to configure the {@link Calendar.widget.EventTip}
 * instance:
 *
 * ```javascript
 * new Calendar({
 *    features : {
 *        eventTooltip : {
 *            tools : {
 *                // Do not show the Delete tool in the tooltip header
 *                delete : false,
 *                // Add a new tool for our own operation
 *                newTool : {
 *                    cls     : 'b-icon-add',
 *                    tooltip : 'Test',
 *                    handler() {
 *                        console.log(`Test ${this.eventRecord.name}`);
 *                    }
 *                }
 *            },
 *            renderer({ eventRecord }) {
 *                return {
 *                    text : `From ${DateHelper.format(eventRecord.startDate, 'l')} to ${DateHelper.format(eventRecord.endDate, 'l')}`
 *                },
 *            },
 *            titleRenderer( eventRecord ) {
 *                return {
 *                    text      : `${eventRecord.name} ${eventRecord.resource.name}`,
 *                    className : {
 *                        'b-urgent'    : eventRecord.isUrgent,
 *                        'b-completed' : eventRecord.isCompleted
 *                    }
 *                };
 *            }
 *        }
 *    }
 * })
 * ```
 *
 * You can hide tools conditionally:
 * ```javascript
 * new Calendar({
 *     features : {
 *         eventTooltip : {
 *             listeners : {
 *                 beforeShow({ source }) {
 *                     source.tools.delete = false;
 *                 }
 *             }
 *         }
 *     }
 * })
 * ```
 *
 * This feature is **enabled** by default.
 *
 * {@inlineexample Calendar/feature/EventTooltip.js}
 *
 * @demo Calendar/tooltips
 *
 * @extends Calendar/feature/CalendarFeature
 * @classtype eventTooltip
 * @feature
 *
 * @typings Scheduler.feature.EventTooltip -> Scheduler.feature.SchedulerEventTooltip
 */
export default class EventTooltip extends CalendarFeature {
    static get $name() {
        return 'EventTooltip';
    }
    static get type() {
        return 'eventTooltip';
    }
    static get configurable() {
        return {
            /**
             * The gesture which activates the event tooltip. Defaults to `'click'`, but may be set to
             * `'contextmenu'` or `'mouseover`'. The tip persists until closed.
             *
             * If set to `'hover'`, the tip shows on mouseover and hides on mouseout.
             *
             * If set to `'contextmenu'`, the tip shows at the mouse/touch contact point, otherwise
             * it aligns to the target event bar.
             * @config {'click'|'contextmenu'|'mouseover'|'hover'}
             * @default
             */
            showOn : 'click',
            closable : true,
            // Allow it to fall back from its initial alignment axis to the cross axis.
            // So if aligned l-r or r-l and shown by a long multiday event, it allows
            // itself to be fall back to being aligned t-b
            axisLock : 'flexible',
            /**
             * Gets the Tooltip instance that this feature is using.
             * @member {Calendar.widget.EventTip} tooltip
             * @readonly
             */
            /**
             * This config is used to directly configure the associated {@link Calendar.widget.EventTip tooltip}.
             * @config {Calendar.widget.EventTip}
             */
            tooltip : {
                $config : ['lazy', 'nullify'],
                value : {
                    type : 'eventTip'
                }
            },
            /**
             * A function, or the *name* of a function which, when passed the active
             * {@link Scheduler.model.EventModel}, returns a value to use as the tooltip's
             * {@link Core.widget.Panel#config-title}.
             *
             * The function may return either an HTML string, or a {@link DomConfig} object.
             * _Defaults to using the event `name`_
             *
             * @config {Function|String}
             * @param {Scheduler.model.EventModel} record Event record
             * @returns {String|DomConfig}
             */
            titleRenderer : eventRecord => StringHelper.encodeHtml(eventRecord.name),
            /**
             * A function, or the *name* of a function called to update the tooltip's content when the
             * cursor is moved over a target.
             *
             * It receives one argument containing context about the tooltip and show operation.
             * The function may return either an HTML string, or a {@link DomConfig} object, or
             * a Promise yielding one of these.
             *
             * ```javascript
             * new Calendar({
             *     features : {
             *         eventTooltip : {
             *             renderer : 'up.getEventTip'
             *         }
             *     },
             *     getEventTip({ eventRecord }) {
             *         return {
             *             className : 'tooltip-content',
             *             text      : eventRecord.name
             *         }
             *     }
             * });
             * ```
             *
             * or
             *
             * ```javascript
             * new Calendar({
             *     features : {
             *         eventTooltip : {
             *             renderer : 'up.getEventTip'
             *         }
             *     },
             *     getEventTip({ eventRecord }) {
             *         return '<div class="tooltip-content> + eventRecord.name + '</div>';
             *     }
             * });
             * ```
             *
             * or
             *
             * ```javascript
             * new Calendar({
             *     features : {
             *         eventTooltip : {
             *             renderer : 'up.getEventTip'
             *         }
             *     },
             *     getEventTip : async function({ eventRecord }) {
             *         // Use a web service which returns a JSON DomConfig block
             *         const response = await fetch(`getEventTipContent?event=${eventRecord.id}`);
             *         return response.json();
             *     }
             * });
             * ```
             *
             * @config {Function|String}
             * @param {Object} context
             * @param {Scheduler.model.EventModel} context.eventRecord The event record which the tooltip is being shown for.
             * @param {Core.widget.Tooltip} context.tip The tooltip instance
             * @param {HTMLElement} context.element The Element for which the Tooltip is monitoring mouse movement
             * @param {HTMLElement} context.activeTarget The target element that triggered the show
             * @param {Event} context.event The raw DOM event
             * @returns {String|Promise|DomConfig}
             */
            renderer : null,
            /**
             * This config is used to directly configure the associated recurrence confirmation popup used
             * when a delete is requested.
             * @config {RecurrenceConfirmationPopupConfig}
             * @private
             */
            recurrenceConfirmation : {
                $config : ['lazy', 'nullify'],
                value : {
                    type : 'recurrenceconfirmation'
                }
            },
            /**
             * By default, the end date of an all day event is displayed in the tooltip UI as
             * the last calendar date on which the event falls. For most end users, this is the
             * expected value.
             *
             * Technically, the {@link Scheduler.model.EventModel#field-endDate} is a timestamp
             * which represents the exact point in time at which an event ends. To use this instead,
             * configure `extendAllDayEndDay` as `true`.
             *
             * To be clear, this would mean that an {@link Scheduler.model.EventModel#field-allDay}
             * event starting and ending on the 7th of February 2020, would show the end date in the
             * tooltip as 8th of February 2020.
             * @config {Boolean}
             * @default false
             */
            extendAllDayEndDay : null,
            /**
             * Defines how to align the EventTooltip to its event.
             *
             * The value can be either a simple string or a full configuration object.
             *
             * When using a simple string, the format is `'[trblc]n-[trblc]n'` and it specifies tooltip edge and
             * the event edge plus optional offsets from 0 to 100 along the edges to align to. Also supports direction
             * independent edges horizontally, `s` for start and `e` for end (maps to `l` and `r` for LTR, `r` and `l`
             * for RTL).
             *
             * For more details about using the object form, see {@link Core.widget.Widget#function-showBy}.
             *
             * Once set, this is stored internally in object form.
             * @config {AlignSpec|String} align
             * @default 't-b'
             */
            align : null,
            /**
             * By default, if the tip's target event is in a cluster of overlapping events and therefore
             * narrow, activating the tip will expand it to full width temporarily.
             *
             * Configure this as `false` to disable this.
             * @prp {Boolean}
             * @default true
             */
            revealEventsInCluster : true,
            resources : null
        };
    }
    static get pluginConfig() {
        return {
            chain : ['render']
        };
    }
    // Because we insert ourself into the owner hierarchy, isVisible consults us
    get isVisible() {
        return true;
    }
    changeRecurrenceConfirmation(recurrenceConfirmation, existingInstance) {
        if (recurrenceConfirmation) {
            recurrenceConfirmation.rootElement = this.owner.rootElement;
        }
        return Widget.reconfigure(existingInstance, recurrenceConfirmation, {
            owner : this
        });
    }
    changeTooltip(config, existingInstance) {
        return Widget.reconfigure(existingInstance, config, {
            owner : this,
            setup : 'setupTooltip'
        });
    }
    setupTooltip(config) {
        const
            me  = this,
            cfg = me.config;
        // Copy appropriate configs from this Feature into the tooltip.
        // We only pass them in if there are valid configs for this class, or they were in our configuration.
        Object.keys(cfg).forEach(k => {
            if (!ownConfigs[k] && (me.hasConfig(k) || (k in me.initialConfig))) {
                config[k] = cfg[k];
            }
        });
        return EventTip.mergeConfigs({
            ownerFeature : me,
            forElement   : me.client.element,
            id           : `${me.client.id}-event-tip`,
            // The Tooltip must activate on embedded widgets from other product families
            forSelector       : me.showOn === 'hover' && '.b-cal-event-wrap,.b-sch-event-wrap,.b-gantt-task-wrap,.b-taskboard-card',
            resources         : me.resources,
            disabled          : me.disabled,
            internalListeners : {
                thisObj     : me,
                delete      : 'onDeleteClick',
                edit        : 'onEditClick',
                pointerOver : 'onTipPointerOver'
            }
        }, config);
    }
    render() {
        const
            me              = this,
            clientListeners = {
                eventsPerCellChange : 'onClientEventsPerCellChange',
                navigate            : 'onClientNavigate',
                renderEvents        : 'onRenderEvents',
                thisObj             : me
            };
        if (me.showOn === 'hover') {
            // The tooltip needs to exist to take care of its own show/hide lifecycle if
            // we are configured to use the hover gesture.
            me.getConfig('tooltip');
        }
        else {
            // Some embedded widget from other product families use 'taskClick'
            clientListeners[`event${me.showOn}`] = clientListeners[`task${me.showOn}`] = 'onClientTooltipGesture';
        }
        me.client.ion(clientListeners);
    }
    get owner() {
        if (this.client.activeView._overflowPopup?.containsFocus) {
            return this.client.activeView.overflowPopup;
        }
        else {
            return this.client;
        }
    }
    //region Events
    addListener(...args) {
        // Add listener to the `tooltip` instance
        this.tooltip?.addListener(...args);
    }
    removeListener(...args) {
        // Remove listener from the `tooltip` instance
        this.tooltip?.removeListener(...args);
    }
    onEditClick() {
        const
            { client, activeClient, tooltip } = this,
            { overflowPopup } = activeClient,
            eventEdit = activeClient?.features?.eventEdit || client.features.eventEdit,
            fromPopup = overflowPopup?.element?.contains(tooltip.activeTarget),
            target    = fromPopup ? overflowPopup.targetCell : tooltip.activeTarget;
        if (eventEdit && !eventEdit.disabled) {
            eventEdit.editEvent(this.eventRecord, null, target, false);
            tooltip.hide();
        }
    }
    onDeleteClick() {
        this.activeClient.calendar.removeEvents([this.eventRecord], () => this.tooltip?.hide(), this.tooltip);
    }
    onClientEventsPerCellChange() {
        // Overflow popup hides on this, so we must hide too.
        // Access the property directly so as not to create the tooltip.
        // We got here through a ResizeObserver so we must allow the notification to reach the
        // overflow popup before it hides to avoid the nonsensically fatal
        // "ResizeObserver loop completed with undelivered notifications" on FF.
        // Delayable.requestAnimationFrame uses itself as the thisObj,
        // so using the function reference will work.
        this._tooltip?.requestAnimationFrame(this._tooltip?.hide);
    }
    onClientNavigate(navEvent) {
        // Access the property directly so as not to create the tooltip
        const { _tooltip } = this;
        // If we are exiting the calendar or navigating to a new item that is not in the tooltip, hide the tooltip
        if (_tooltip?.activeTarget && (!this.owner.owns(navEvent.event) || (navEvent.item && navEvent.item !== _tooltip.activeTarget)) && !_tooltip.owns(navEvent.event?.relatedTarget)) {
            _tooltip.hide();
        }
    }
    onRenderEvents(e) {
        const { _tooltip } = this;
        // If the target element is no longer part of the activating view, hide the tooltip
        if (_tooltip?.activeTarget && !this.activeClient.element.contains(this.tooltip.activeTarget)) {
            _tooltip.hide();
        }
    }
    onClientTooltipGesture({ domEvent, event, eventElement, eventRecord, source : owningCalendarWidget }) {
        const { tooltip } = this;
        // Foreign objects (like a Scheduler) may not use "domEvent", so fallback to "event" if necessary:
        domEvent = domEvent || event;
        // Pass already known parts from the Widget gesture into onTipPointerOver
        if (this.onTipPointerOver({ event : domEvent, target : eventElement, eventRecord, owningCalendarWidget }) !== false) {
            // If the event is part of a cluster (overlaps others, and will expand)
            // and tooltip is aligned to the side, then delay the show to avoid a realign after expand.
            if (eventElement.classList.contains('b-cal-in-cluster') && parseAlign(tooltip.align.align).edgeAligned === 2) {
                tooltip.setTimeout({
                    fn    : 'showByEvent',
                    args  : [domEvent, eventElement],
                    delay : 130
                });
            }
            else {
                // Handle products where the eventElement is passed as the "inner" instead of the wrap
                tooltip.showByEvent(domEvent, eventElement.closest('[data-event-id],[data-task-id]'));
            }
        }
    }
    onTipPointerOver({
        event,
        target,
        owningCalendarWidget,
        eventRecord
    }) {
        const { tooltip, client } = this;
        if (!owningCalendarWidget) {
            owningCalendarWidget = Widget.fromElement(target)?.closest(hasEventStore);
        }
        if (!eventRecord) {
            eventRecord = owningCalendarWidget.getEventRecord(target);
        }
        if (client.features?.eventEdit?.isEditing || client.activeView.features?.cellEdit?.isEditing) {
            return false;
        }
        // Embedded views from other products may be showing their own editors
        if (client.floatRoot.querySelector('.b-eventeditor,.b-taskeditor')) {
            return false;
        }
        if (owningCalendarWidget) {
            tooltip.triggeredByEvent = event;
            tooltip.activeTarget = target;
            this.activeClient = owningCalendarWidget;
            eventRecord = owningCalendarWidget.getEventRecord(target);
            /**
             * The event which the tooltip feature has been activated for.
             * @member {Scheduler.model.EventModel} eventRecord
             * @readonly
             */
            tooltip.eventRecord = this.eventRecord = eventRecord;
        }
        return eventRecord != null;
    }
    //endregion
    //region Internal
    updateDisabled(disabled, was) {
        super.updateDisabled(disabled, was);
        if (this._tooltip) {
            this._tooltip.disabled = disabled;
        }
    }
    //endregion
}
// Register this feature type with its Factory
EventTooltip.initClass();
EventTooltip._$name = 'EventTooltip';