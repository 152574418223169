import Popup from '../../Core/widget/Popup.js';
import DH from '../../Core/helper/DateHelper.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import DomSync from '../../Core/helper/DomSync.js';
/**
 * @module Calendar/widget/OverflowPopup
 */
/**
 * This class is not supposed to be used directly. It is used by Calendar views which need to show
 * more events than will fit into a day cell.
 *
 * A Popup which displays events which will not fit into their container in a View
 *
 * @extends Core/widget/Popup
 * @classtype overflowpopup
 */
export default class OverflowPopup extends Popup {
    static get $name() {
        return 'OverflowPopup';
    }
    // Factoryable type name
    static get type() {
        return 'overflowpopup';
    }
    static configurable = {
        textContent : false,
        autoShow    : false,
        floating    : false,
        cls         : 'b-cal-event-list',
        closable    : true,
        draggable   : {
            handleSelector : ':not(.b-cal-event-wrap)'
        },
        anchor : true,
        layout : 'vbox',
        /**
         * An empty function by default, but provided so that you can override it.
         *
         * This function is called each time an event is rendered to allow developers to mutate
         * the cell metadata, or the CSS classes to be applied to the event element.
         *
         * It's called with the event record, and a eventData object which allows you to mutate event metadata
         * such as 'cls', 'style'.
         *
         * The cls property is an object whose property names will be added to the event element if the property
         * value is truthy.
         *
         * The style property is an object containing style properties for the event element.
         *
         * A non-null return value from the renderer is used as the event body content. A nullish
         * return value causes the default renderer to be used which just uses the event name.
         *
         * If a string is returned, it is used as the HTML content of the event body element.
         *
         * If an object is returned, it is used as a {@link Core.helper.DomHelper#typedef-DomConfig} object to
         * create complex content in the event body element.
         *
         * ```javascript
         *  eventRenderer({ eventRecord, renderData }) {
         *      if (eventRecord.name === 'Doctors appointment') {
         *          eventData.style.fontWeight = 'bold';
         *          eventData.cls['custom-cls'] = 1;
         *
         *          return 'Special doctors appointment';
         *      }
         *  }
         * ```
         * IMPORTANT: When returning content, be sure to consider how that content should be encoded to avoid XSS
         * (Cross-Site Scripting) attacks. This is especially important when including user-controlled data such as
         * the event's `name`. The function {@link Core.helper.StringHelper#function-encodeHtml-static} as well as
         * {@link Core.helper.StringHelper#function-xss-static} can be helpful in these cases.
         *
         * For example:
         * ```javascript
         *  eventRenderer({ eventRecord }) {
         *      return StringHelper.xss`Event: ${eventRecord.name}`;
         *  }
         * ```
         * @config {Function} eventRenderer
         * @param {Object} detail An object that contains data about the event being rendered.
         * @param {Scheduler.model.EventModel} detail.eventRecord The event record
         * @param {Scheduler.model.ResourceModel} detail.resourceRecord The event record
         * @param {Object} detail.renderData A data object containing properties that will be used to create the event element.
         * @param {Object} detail.renderData.style The style property is an object containing style properties for
         * the event element.
         * @param {Object} detail.renderData.cls The cls property is an object whose property names will be added to
         * the event element if the property value is truthy.
         * @param {String} detail.renderData.eventColor Color to be applied to the event
         * @returns {String|DomConfig|DomConfig[]|null} A simple string, or a DomConfig object defining the actual HTML
         */
        eventRenderer : null,
        /**
         * A function which compares events to decide upon rendering order.
         *
         * By default, this class uses the sorted order of its owning view and does *not* perform
         * a sort, but this may be configured to override that behaviour.
         *
         * Note that the two objects to compare may either be {@link Scheduler.model.EventModel}s
         * or {@link EventBar}s which __contain__ an `eventRecord` property which is the {@link Scheduler.model.EventModel}.
         * @config {Function}
         * @param {Scheduler.model.EventModel|EventBar} lhs The left side value to conpare.
         * @param {Scheduler.model.EventModel|EventBar} rhs The right side value to conpare.
         * @returns {Number}
         */
        eventSorter : null,
        /**
         * A {@link Core.widget.Widget} config object used to show the inner list of overflowing events
         * shown in this popup.
         *
         * This widget has a {@link Core.widget.Widget#config-weight} of `500`, so to insert widgets above
         * this, use `weight` less than 500, and to insert widgets below it use `weight` greater than 500.
         * @config {Object}
         * @default
         */
        eventList : {
            type   : 'widget',
            cls    : 'b-cal-event-bar-container',
            weight : 500
        },
        items : {},
        tools : {
            maximize : null
        },
        /**
         * The {@link Core.helper.DateHelper#function-format-static DateHelper} format string to use to
         * create the {@link #config-title} of this dialog.
         * @config {String}
         * @default
         */
        dateFormat : 'dddd, MMM DD',
        activeDate : {
            $config : {
                equal : 'date'
            },
            value : null
        },
        scrollAction : 'realign',
        align        : {
            axisLock         : 'flexible',
            constrainPadding : 20,
            minHeight        : 400
        },
        scrollable : {
            overflowY : 'auto'
        },
        /**
         * By default an event overflow popup shows all the events for the activated date.
         *
         * Configure this as `true` to only display the events which were hidden due to overflow.
         * @config {Boolean}
         */
        onlyShowOverflow : null
    };
    calendarHitTest(domEvent) {
        const
            target = DomHelper.getEventElement(domEvent),
            closest = target.closest('.b-cal-event-wrap');
        let eventRecord;
        // It's only an event hit if the id exists in the event store.
        // May be a transient event added solely to the UI and not backed by the store.
        if (closest && (eventRecord /* assignment */ = this.owner.eventStore.getById(closest.dataset.eventId))) {
            return {
                eventRecord,
                type         : 'event',
                eventElement : closest,
                view         : this
            };
        }
        return null;
    }
    onPanelHeaderClick({ event : domEvent }) {
        if (domEvent.target.closest('.b-header-title')) {
            this.owner?.trigger('dayNumberClick', {
                domEvent,
                date              : this.activeDate,
                cellData          : this.cellData,
                source            : this.owner,
                fromOverflowPopup : true
            });
        }
        else {
            super.onPanelHeaderClick(...arguments);
        }
    }
    get focusElement() {
        const activeElement = DomHelper.getActiveElement(this);
        return this.element.contains(activeElement)
            ? activeElement
            : (this.eventList?.element?.firstElementChild || super.focusElement);
    }
    getDateFromPosition() {
        // This View only shows one date, so the answer is easy.
        return this.activeDate;
    }
    getEventElement(eventRecord) {
        return this.contentElement.querySelector(`[data-event-id="${eventRecord.id}"]`);
    }
    showOverflow(cell, cellData) {
        /**
         * The date for which overflow is being shown.
         * @readonly
         * @member {Date} activeDate
         */
        const date = this.activeDate = cellData.date;
        /**
         * A data block containing information about the day for which overflow is being shown.
         * @readonly
         * @member {DayCell} cellData
         */
        this.cellData = cellData;
        this.targetCell = cell;
        this.refresh(cellData);
        this.showBy(cell);
        if (this.isVisible) {
            // Fired on the owning view. Documented in DayCellRenderer
            this.owner?.trigger('showOverflowPopup', { cell, cellData, date, overflowPopup : this });
        }
    }
    // Owning view calls this from its own onCalendarStoreChange implementation
    onCalendarStoreChange() {
        const me = this;
        if (me.isVisible) {
            me.cellData = me.owner.cellMap.get(DH.makeKey(me.activeDate));
            me.refresh(me.cellData);
        }
    }
    refresh(cellData) {
        // eventList might be configured away
        if (!this.eventList) {
            return;
        }
        // Allow the caller to update the overflow state
        if (cellData) {
            this.cellData = cellData;
        }
        else {
            cellData = this.cellData;
        }
        const
            me          = this,
            {
                owner,
                eventRenderer,
                eventSorter
            }           = me,
            { element } = me.eventList,
            // Slice using eventsPerCell - 1 because if there's one event that won't fit,
            // the last event fitting will be evicted to make room for the overflow indicator.
            // If there's no overflow indicator (like in YearView), eventsPerCell will be
            // zero, so sanitize value up to 0.
            slicePoint = me.onlyShowOverflow ? Math.max(owner.eventsPerCell - 1, 0) : 0,
            // Because of long running events overflowing, allDay events and intraday
            // events could be interleaved in the cells slots.
            // In the event list, we sort them into the required order with
            // oldest and longest at the top.
            events      = cellData?.renderedEvents.slice(slicePoint),
            children    = [];
        // We can delete events from the Popup, so when we've exhausted the events, we must hide.
        // Also, if a refresh was called from the owner's resize handling, that may have caused
        // the overflow state to have changed to not overflowing.
        // YearView can't not overflow. It's just a heat map.
        if (!events?.length || (!cellData?.hasOverflow && !owner.isYearView)) {
            return me.hide();
        }
        // If we have been configured with an overriding eventSorter, apply it now.
        // Otherwise, we are using the data as shown in our owning view.
        if (eventSorter) {
            events.sort(eventSorter);
        }
        for (let i = 0, eventRow = 0, { length } = events; i < length; i++, eventRow++) {
            const renderedEvent = events[i];
            // If the slot is occupied, add a child event element.
            // These just flow down the Popup's bodyElement
            if (renderedEvent) {
                const eventDomConfig = owner.createEventDomConfig(renderedEvent, eventRenderer);
                Object.assign(eventDomConfig.className, {
                    // The event started in a previous day
                    'b-continues-past' : renderedEvent.eventRecord.startDate < cellData.date,
                    // The event ends in a future day
                    'b-continues-future' : renderedEvent.eventRecord.endDate > cellData.tomorrow
                });
                eventDomConfig.style.marginBottom = `${owner.eventSpacing}px`;
                children.push(eventDomConfig);
            }
        }
        DomSync.sync({
            domConfig : {
                children
            },
            targetElement : element
        });
        // Fix up cell's time info.
        element.dataset.date = cellData.key;
        element.classList.add(`b-day-of-week-${cellData.day}`);
        element.classList.remove(`b-day-of-week-${me.lastRefreshDay}`);
        me.lastRefreshDay = cellData.day;
        me.realign();
    }
    changeItems(items) {
        const { eventList } = this;
        if (eventList) {
            items.eventList = eventList;
        }
        const result = super.changeItems(items);
        if (eventList) {
            this.eventList = this.widgetMap.eventList;
        }
        return result;
    }
    changeActiveDate(activeDate, oldActiveDate) {
        activeDate = typeof date === 'string' ? DH.parse(activeDate) : new Date(activeDate);
        if (isNaN(activeDate)) {
            throw new Error('OverflowPopup date ingestion must be passed a Date, or a YYYY-MM-DD date string');
        }
        return activeDate;
    }
    updateActiveDate(activeDate) {
        this.element.dataset.date = DH.makeKey(activeDate);
        this.title = DH.format(activeDate, this.dateFormat);
    }
    onDocumentMouseDown({ event }) {
        const
            alignedTo  = this.lastAlignSpec?.target,
            { target } = event;
        // On mousedown of our own activating element, do not focusout
        if (alignedTo?.contains(target)) {
            const cellOverflowButton = target.closest('.b-cal-cell-overflow');
            // If it's a click inside the overflowing cell, or inside the overflow button
            // then ignore it, we're clicking on our own data or overflow button.
            if (target.closest('.b-cal-event-bar-container') || target.matches('.b-cal-cell-overflow') || (cellOverflowButton?.children.length === 1)) {
                return event.preventDefault();
            }
        }
        super.onDocumentMouseDown(...arguments);
    }
}
OverflowPopup.initClass();
OverflowPopup._$name = 'OverflowPopup';