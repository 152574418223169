import DateHelper from '../../Core/helper/DateHelper.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import StringHelper from '../../Core/helper/StringHelper.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import Toast from '../../Core/widget/Toast.js';
import Grid from '../../Grid/view/Grid.js';
import CalendarMixin from '../../Calendar/widget/mixin/CalendarMixin.js';
import '../../Grid/column/DateColumn.js';
import '../../Core/widget/DateTimeField.js';
import Store from '../../Core/data/Store.js';
import Button from '../../Core/widget/Button.js';
import Config from '../../Core/Config.js';
import DayCellCollecter from './mixin/DayCellCollecter.js';
import DaySelectable from './mixin/DaySelectable.js';
import DateRangeOwner from './mixin/DateRangeOwner.js';
import Collection from '../../Core/util/Collection.js';
/**
 * @module Calendar/widget/EventList
 */
const
    byStartDate  = (lhs, rhs) => lhs.startDate.valueOf() - rhs.startDate.valueOf(),
    mergeColumns = (configuredColumns, defaultColumns) => {
        if (defaultColumns) {
            // Support  columns : { data : { name : { field : 'name', text : 'Name' }}}
            // or       columns : { data : [{ field : 'name', text : 'Name' }] }
            configuredColumns = configuredColumns.data || configuredColumns;
            // Object form { name : { field : 'name', text : 'Name' }}
            if (ObjectHelper.isObject(configuredColumns)) {
                const result = defaultColumns.slice();
                for (const id in configuredColumns) {
                    const
                        newColumn = configuredColumns[id],
                        // Match incoming column with existing by field name if there, or, if not, by name
                        matchIndex = result.findIndex(c => c.id === id || c.field === id);
                    // Configuration wants to add or override matched column
                    if (newColumn) {
                        const v = Config.merge(newColumn, result[matchIndex]);
                        // So that it can be looked up in future merges
                        v.id = id;
                        result[matchIndex === -1 ? result.length : matchIndex] = v;
                    }
                    // Configuration wants to remove matched column
                    else if (matchIndex > -1) {
                        result.splice(matchIndex, 1);
                    }
                }
                return result;
            }
            // Array form  [{ field : 'name', text : 'Name' }]
            else {
                defaultColumns = new Collection({
                    values     : defaultColumns,
                    idProperty : 'field'
                });
                // Iterate the configured column set
                for (let i = 0, { length } = configuredColumns; i < length; i++) {
                    const
                        newColumn             = configuredColumns[i],
                        matchedExistingColumn = defaultColumns.get(newColumn.field);
                    // Configuration wants to override matched column
                    if (matchedExistingColumn) {
                        configuredColumns[i] = Config.merge(newColumn, matchedExistingColumn);
                        defaultColumns.remove(matchedExistingColumn);
                    }
                }
                // Now append the remaining default columns
                configuredColumns.push(...defaultColumns.values);
                return configuredColumns;
            }
        }
        else {
            return configuredColumns;
        }
    };
/**
 * A Grid view of the EventStore.
 *
 * This shows a configured range of events from the event store.
 *
 * When used as a {@link Calendar.view.Calendar#config-modes mode} of a Calendar, the configured
 * {@link #config-range} is snapped to encapsulate the Calendar's current
 * {@link Calendar.view.Calendar#config-date}.
 *
 * If configured with an explicit {@link #config-startDate} and {@link #config-endDate}, the
 * {@link #config-range} is not used. When setting the {@link #property-date}, the duration
 * of the configured range is preserved, but the range is shifted backwards or forwards in time
 * just enough to bring the passed `Date` into view.
 *
 * The EventList's header context menu is extended to allow the user to change the range type. This
 * may be disabled by configuring the {@link #config-listRangeMenu} as `null`.
 *
 * For further information about features available to be configured directly into `EventList`
 * calendar views, see the {@link Grid.view.Grid} documentation and the associated examples.
 *
 * {@inlineexample Calendar/widget/ListView.js}
 *
 * @demo Calendar/listview
 *
 * @extends Grid/view/Grid
 * @mixes Core/widget/mixin/Responsive
 * @mixes Calendar/widget/mixin/CalendarMixin
 * @mixes Calendar/widget/mixin/DayCellCollecter
 * @mixes Calendar/widget/mixin/DateRangeOwner
 * @classtype eventlist
  */
export default class EventList extends Grid.mixin(CalendarMixin, DayCellCollecter, DaySelectable, DateRangeOwner) {
    static get $name() {
        return 'EventList';
    }
    static get type() {
        return 'eventlist';
    }
    static get configurable() {
        return {
            preserveScrollOnDatasetChange : true,
            cls : 'b-slide-vertical',
            eventFilter : null,
            localizableProperties : ['title', 'text'],
            title : 'L{List}',
            range : '1 month',
            // We have a Month utility object.
            // It helps us with week values and week change events
            month : true,
            /**
             * Column definitions to add to the default columns, or column definitions which override
             * the provided, default columns.
             *
             * By default, the `EventList` shows three columns:
             *
             * ```javascript:
             * columns : [{
             *     text  : 'Name',
             *     field : 'name'
             * }, {
             *     text  : 'Start',
             *     field : 'startDate'
             * }, {
             *     text  : 'End',
             *     field : 'endDate'
             * }]
             * ```
             *
             * Your `columns` config is appended to the default columns *unless you provide
             * columns for the `name`, `startDate` and `endDate` fields*, in which case your
             * column definitions are merged into the default column definitions.
             *
             * In this way you can provide a `renderer` for the default columns, or otherwise
             * configure them in a customizable way.
             *
             * You can also supply columns keyed by field, to reconfigure / remove / add similar to how you would manage
             * items in containers and menus:
             *
             * ```javascript
             * columns : {
             *   // Remove the name column
             *   name : null,
             *   // Change the format of the startDate column
             *   startDate : {
             *      format : 'YYYY'
             *   },
             *   // Add a description column
             *   description : {
             *      renderer : ({ record }) => record.description.substring(0, 10)
             *   }
             * }
             * ```
             *
             * @config {Object<String,GridColumnConfig>|GridColumnConfig[]}
             */
            columns : {
                $config : {
                    // Columns with matching field names are merged.
                    // Non-matching columns are added
                    merge : mergeColumns
                },
                value : [{
                    text  : 'L{EventEdit.Name}',
                    field : 'name',
                    flex  : '1 0 12em'
                }, {
                    text   : 'L{EventList.Start}',
                    //type       : 'startDate', // When column is hoisted from Gantt to Scheduler
                    type   : 'date',
                    editor : {
                        type      : 'datetime',
                        dateField : {
                            step : null
                        },
                        timeField : {
                            step : null
                        }
                    },
                    field  : 'startDate',
                    width  : '16.5em',
                    format : 'L LT',
                    renderer({ record, value }) {
                        return record.allDay ? DateHelper.format(value, 'L') : this.defaultRenderer(...arguments);
                    }
                }, {
                    text   : 'L{EventList.Finish}',
                    //type       : 'endDate', // When column is hoisted from Gantt to Scheduler
                    type   : 'date',
                    editor : {
                        type      : 'datetime',
                        dateField : {
                            step : null
                        },
                        timeField : {
                            step : null
                        }
                    },
                    field  : 'endDate',
                    width  : '16.5em',
                    format : 'L LT',
                    renderer({ record, value }) {
                        return record.allDay ? DateHelper.format(value, 'L') : this.defaultRenderer(...arguments);
                    }
                }, {
                    type       : 'widget',
                    text       : 'Resources',
                    field      : 'resources',
                    minWidth   : 250,
                    autoHeight : true,
                    widgets    : [{
                        type           : 'chipview',
                        valueProperty  : 'items',
                        displayField   : 'name',
                        itemsFocusable : false,
                        navigator      : null,
                        closable       : false,
                        style          : {
                            flexFlow : 'row nowrap',
                            display  : 'flex',
                            padding  : '5px 0 3px 0'
                        },
                        scrollable : {
                            overflowX : 'hidden-scroll',
                            overflowY : false
                        },
                        getItemCls   : r => DomHelper.isNamedColor(r.eventColor) ? ` b-sch-${r.eventColor}` : '',
                        getItemStyle : r => `color:#fff;${r.eventColor && !DomHelper.isNamedColor(r.eventColor) ? `background-color:${r.eventColor}` : ''}`
                    }],
                    editor : false
                }]
            },
            features : {
                group        : false,
                rowCopyPaste : false,
                columnPicker : {
                    createColumnsFromModel : true
                }
            },
            internalListeners : {
                beforeCellEditStart({ editorContext }) {
                    const { editor, record } = editorContext;
                    if (record.isRecurring || record.isOccurrence) {
                        Toast.show({
                            html : 'Please use EventEdit to edit recurring events'
                        });
                        return false;
                    }
                    // Hide event tooltip if present.
                    this.calendar?.features.eventTooltip?._tooltip?.hide();
                    editor.timeField?.[record.allDay ? 'hide' : 'show']();
                }
            },
            settings : {
                $config : 'lazy',
                value   : null
            },
            /**
             * A {@link Core.widget.Menu#configs MenuConfig} block which configures the {@link #property-listRangeMenu} property which
             * is the range choosing menu provided which by default selects one of the following:
             * - day
             * - week
             * - month
             * - year
             * - decade
             *
             * This may be used to either reconfigure that menu, or, by configuring it as `null`, could remove
             * the menu entirely if the date range of this view is controlled by other means.
             *
             * The individual range menu items are under the `items` property and have the following
             * property names:
             * - `listRangeDayItem`
             * - `listRangeWeekItem`
             * - `listRangeMonthItem`
             * - `listRangeYearItem`
             * - `listRangeDecadeItem`
             *
             * These may be reconfigured:
             *
             * ```javascript
             * calendar = new Calendar({
             *     ...
             *     modes : {
             *         agenda : {
             *             listRangeMenu : {
             *                 items : {
             *                     // We don't want the decade range option
             *                     listRangeDecadeItem : null
             *                 }
             *             }
             *         }
             *     }
             * });
             * ```
             *
             * @config {MenuConfig} listRangeMenu
             */
            listRangeMenu : {
                $config : 'lazy',
                value   : {
                    anchor : true,
                    items  : {
                        listRangeDayItem : {
                            range : 'day',
                            text  : 'L{EventEdit.day}',
                            _unit : 'day'
                        },
                        listRangeWeekItem : {
                            range : 'week',
                            text  : 'L{EventEdit.week}',
                            _unit : 'week'
                        },
                        listRangeMonthItem : {
                            range : 'month',
                            text  : 'L{EventEdit.month}',
                            _unit : 'month'
                        },
                        listRangeYearItem : {
                            range : 'year',
                            text  : 'L{EventEdit.year}',
                            _unit : 'year'
                        },
                        listRangeDecadeItem : {
                            range : 'decade',
                            text  : 'L{EventEdit.decade}',
                            _unit : 'decade'
                        }
                    }
                }
            },
            /**
             * A {@link Core.helper.DateHelper} format string used to format the time displayed
             * next to event bars in the custom columns which {@link Calendar.widget.EventList}
             * based views use - {@link Calendar.widget.EventList} and {@link Calendar.widget.AgendaView}.
             * @config {String}
             * @default
             */
            eventListTimeFormat : 'HH:mm'
        };
    }
    /**
     * Returns the resource associated with this event list when used inside a {@link Calendar.widget.ResourceView}
     * @readonly
     * @member {Scheduler.model.ResourceModel} resource
     */
    static get delayable() {
        return {
            populateStoreSoon : {
                type              : 'raf',
                cancelOutstanding : true
            }
        };
    }
    construct(...args) {
        const
            me           = this,
            featuresProp = ObjectHelper.getPropertyDescriptor(this, 'features');
        // Enable print feature on EventList view if it was configured directly or on the calendar
        Object.defineProperty(me, 'features', {
            get : () => {
                return featuresProp.get.call(this) || {};
            },
            set : features => {
                if (features === true) {
                    features = {};
                }
                if (!features.print) {
                    features.print = Boolean(this.calendar?.features.print);
                }
                featuresProp.set.call(this, features);
            },
            configurable : true
        });
        super.construct(...args);
    }
    // We must implement the CalendarMixin interface.
    // All views must expose a doRefresh method.
    doRefresh() {
        this.refreshRows();
    }
    getEventElement(eventRecord, date = Math.max(eventRecord.startDate, this.firstVisibleDate || this.startDate)) {
        // Try as a base grid EventList first, then fall back to separate event *bars* being rendered.
        return this.eventContentElement.querySelector(`.b-grid-row[data-event-id="${eventRecord.id}"][data-date="${DateHelper.makeKey(date)}"]`) ||
            super.getEventElement(...arguments);
    }
    // Returns true is this EventList encapsulates this date
    containsDate(date) {
        const dateContainmentFn = this.range ? 'betweenLesser' : 'betweenLesserEqual';
        return DateHelper[dateContainmentFn](date, this.startDate, this.endDate);
    }
    /**
     * This method produces the event time details next to the event bar in
     * {@link Calendar.widget.EventList} and {@link Calendar.widget.AgendaView}
     * as a {@link DomConfig} object.
     *
     * The content is encapsulated in an element with CSS class `b-cal-eventlist-event-time`.
     * For multi day events, the ending date is shown. For intra-day events, the start and end
     * times are shown.
     * @param {Scheduler.model.EventModel} eventRecord
     * @returns {DomConfig}
     */
    eventListEventTimeRenderer(eventRecord) {
        const
            me         = this,
            daySpan    = DateHelper.diff(DateHelper.clearTime(eventRecord.startDate), DateHelper.clearTime(eventRecord.endDate), 'day'),
            timeString = me.eventTimeRenderer ? me.callback(me.eventTimeRenderer, me, arguments)
                : daySpan > 1
                    ? me.L('L{Calendar.endsOn}', DateHelper.format(eventRecord.endDate, 'DD MMM'))
                    : eventRecord.allDay
                        ? me.L('L{Calendar.allDay}')
                        : `${DateHelper.format(eventRecord.startDate, me.eventListTimeFormat)} - ${DateHelper.format(eventRecord.endDate, me.eventListTimeFormat)}`;
        return {
            className : {
                'b-cal-eventlist-event-time' : 1
            },
            text : timeString
        };
    }
    /**
     * This method produces the date details block in {@link Calendar.widget.AgendaView} cells
     * as a {@link DomConfig} object.
     *
     * The content is encapsulated in an element with CSS class `b-cal-agenda-date`.
     * @param {Date} date
     * @returns {DomConfig}
     */
    agendaEventDateRenderer(date) {
        return {
            className : {
                'b-day-name'        : 1,
                'b-cal-agenda-date' : 1,
                'b-today'           : !Boolean(DateHelper.clearTime(new Date()) - DateHelper.clearTime(date))
            },
            children : [{
                className : {
                    'b-cal-agenda-date-date-number' : 1
                },
                text : date.getDate()
            }, {
                className : {
                    'b-cal-agenda-date-date-text' : 1
                },
                children : [{
                    text : DateHelper.format(date, 'dddd')
                }, {
                    text : DateHelper.format(date, 'MMM YYYY')
                }]
            }]
        };
    }
    // Override because our Featurable mixin which comes before GridBase sets features to null
    // to destroy them, so we must ensure the inherited Grid features are cleaned up.
    destroy() {
        for (const feature of Object.values(this.features)) {
            feature.destroy?.();
        }
        super.destroy();
    }
    populateHeaderMenu({ items }) {
        const { listRangeMenu : menu } = this;
        if (menu) {
            items.listRangeItem = {
                weight : -1000,
                icon   : 'b-icon-calendar-week',
                text   : 'List Range',
                menu
            };
        }
        super.populateHeaderMenu(...arguments);
    }
    refreshVirtualScrollbars() {
        super.refreshVirtualScrollbars();
        // Our floating settings button needs to be inset a little more if there is a visible scrollbar
        this.bodyElement.classList[DomHelper.scrollBarWidth && this.hasVerticalOverflow ? 'add' : 'remove']('b-has-scrollbar');
    }
    /**
     * This property yields a {@link Core.widget.Menu} config object which encapsulates the range choices
     * which this widget may be set to encapsulate:
     * - day
     * - week
     * - month
     * - year
     * - decade
     *
     * By default a `list` view adds these choices to the header context menu.
     * An `agenda` view creates a floating settings button which offers this menu.
     * The property may be used to create a custom UI for changing the range.
     * The value yielded by the default `get listRangeMenu()` implementation looks like this:
     *
     *```javascript
     * {
     *     items : {
     *         listRangeDayItem    : {config for DAY range menu item },
     *         listRangWeekItem    : {config for WEEK range menu item },
     *         listRangMonthItem   : {config for MONTH range menu item },
     *         listRangeYearItem   : {config for YEAR range menu item },
     *         listRangeDecadeItem : {config for DECADE range menu item }
     *     }
     * }
     *```
     *
     * A subclass can override `get listRangeMenu()` and mutate the object returned by the `super` call.
     *
     * For example, it could `delete` properties of the `items` block to limit which ranges may be selected.
     *
     * @member {MenuConfig} listRangeMenu
     * @readonly
     */
    changeListRangeMenu(listRangeMenu) {
        if (listRangeMenu) {
            const
                me          = this,
                toggleGroup = `${me.id}-range-items`;
            listRangeMenu = ObjectHelper.merge({
                onToggle : e => {
                    if (e.checked) {
                        me.range = e.item.range;
                        e.bubbles = false;
                    }
                    // We may lose or gain a scrollbar which will move the button
                    if (DomHelper.scrollBarWidth) {
                        e.menu.realign();
                    }
                },
                items : {
                    listRangeDayItem : {
                        toggleGroup
                    },
                    listRangeWeekItem : {
                        toggleGroup
                    },
                    listRangeMonthItem : {
                        toggleGroup
                    },
                    listRangeYearItem : {
                        toggleGroup
                    },
                    listRangeDecadeItem : {
                        toggleGroup
                    }
                }
            }, listRangeMenu);
            return listRangeMenu;
        }
    }
    get listRangeMenu() {
        const
            me            = this,
            listRangeMenu = me._listRangeMenu;
        if (listRangeMenu) {
            for (const i of Object.values(listRangeMenu.items)) {
                if (i) {
                    i.checked = i._unit === me.range?.unit;
                }
            }
        }
        return listRangeMenu;
    }
    updateRowManager(rowManager) {
        super.updateRowManager?.(...arguments);
        // Make sure rows which represent EventModels have [data-event-id="?"] stamped into them.
        rowManager.ion({
            beforeRenderRow : 'onBeforeRenderRow',
            thisObj         : this
        });
    }
    onBeforeRenderRow({ row, record }) {
        const
            { _elementsArray } = row,
            { id, startDate }  = record;
        if (record.isSpecialRow) {
            // If it's a group header, cleanse it of any connection to an eventRecord
            row.eachElement(e => delete e.dataset.eventId);
        }
        // Only if it represents an EventModel.
        // In AgendaView, there are event bars with the event id, and the cells encapsulate dates.
        else if (record.isEventModel) {
            for (let i = 0, { length } = _elementsArray; i < length; i++) {
                _elementsArray[i].dataset.eventId = id;
                _elementsArray[i].dataset.date = DateHelper.makeKey(startDate);
            }
            row.cls[`b-day-of-week-${startDate.getDay()}`] = 1;
        }
    }
    // Scheduler interface usually routes this to handleEvent.
    // If we're a Grid, GridElementEvents routes events to handleEvent, so override it to do nothing.
    // Otherwise keydown events would be handled twice
    onInternalKeyDown(domEvent) {}
    handleEvent(domEvent) {
        // Don't cause scroll when clicking in event list
        this.preventScroll = true;
        super.handleEvent(domEvent);
        this.preventScroll = false;
    }
    get displayName() {
        let s = this.title;
        if (typeof s !== 'string') {
            s = this.type;
        }
        return StringHelper.capitalize(s);
    }
    /**
     * Scrolls vertically to bring an event or a time into view.
     * @param {Scheduler.model.EventModel|Date} target The event to scroll to or a `Date` to scroll to.
     * @param {Object} [options] How to scroll.
     * @param {String} [options.block] How far to scroll the target: `start/end/center/nearest`.
     * @param {Number} [options.edgeOffset] edgeOffset A margin around the target to bring into view.
     * @param {Object|Boolean|Number} [options.animate] Set to `true` to animate the scroll by 300ms,
     * or the number of milliseconds to animate over, or an animation config object.
     * @param {Number} [options.animate.duration] The number of milliseconds to animate over.
     * @param {String} [options.animate.easing] The name of an easing function.
     * @param {Boolean|Function} [options.highlight] Set to `true` to highlight the resulting element
     * when it is in view. May be a function which is called passing the resulting element
     * to provide customized highlighting.
     * @param {Boolean} [options.focus] Set to `true` to focus the element when it is in view.
     * @param {Boolean} [options.y] Pass as `false` to disable scrolling in the `Y` axis.
     * @returns {Promise} A promise which is resolved when the target has been scrolled into view.
     */
    async scrollTo(target, options = { animate : true }) {
        const me = this;
        if (target instanceof me.store.modelClass) {
            // Scroll the passed record into view
            await me.scrollRowIntoView(target, Object.assign({}, options, { highlight : false }));
            if (options.highlight) {
                target = me.getEventElement(target, target.startDate);
                if (typeof options.highlight === 'boolean') {
                    DomHelper.highlight(target, me);
                }
                else {
                    (me.widget || me).callback(options.highlight, null, [target]);
                }
            }
        }
        else if (ObjectHelper.isDate(target) && me.store.count) {
            const
                index = me.dateIndex,
                key   = DateHelper.makeKey(target);
            // We have a row for this date...
            if (index[key]) {
                await me.scrollRowIntoView(index[key], options);
                me.scrolledToDate = target;
            }
            // Otherwise, find the closest in time
            else {
                let closest = Number.MAX_SAFE_INTEGER;
                const keys = Object.keys(index);
                for (let i = 0, { length } = keys; i < length; i++) {
                    const d = DateHelper.parseKey(keys[i]);
                    if (Math.abs(d - target) < Math.abs(closest - target)) {
                        closest = d;
                    }
                }
                // Recurse with a known-to-exist date
                await me.scrollTo(closest);
            }
        }
    }
    updateStartDate() {
        const { refreshCount } = this;
        // Prevent this.onMonthChange from trying to scroll when we update our month's active date
        this.preventScroll = true;
        super.updateStartDate(...arguments);
        this.preventScroll = false;
        // Superclass's updateStartDate will most likely cause a refresh due to changing the date.
        // But if we're moving to a larger encapsulating range (eg 1w to 1y), the date may
        // not be forced to change, so kick off a refresh if it has not been done.
        if (!this.isConfiguring && this.refreshCount === refreshCount) {
            this.populateStoreSoon();
        }
    }
    updateEndDate() {
        const { refreshCount } = this;
        super.updateEndDate?.(...arguments);
        // Superclass's updateEndDate will most likely cause a refresh due to changing the date.
        // But if we're moving to a larger encapsulating range (eg 1w to 1y), the date may
        // not be forced to change, so kick off a refresh if it has not been done.
        if (!this.isConfiguring && this.refreshCount === refreshCount) {
            this.populateStoreSoon();
        }
    }
    updateRange() {
        const { refreshCount } = this;
        super.updateRange?.(...arguments);
        // Superclass's updateRange will most likely cause a refresh due to changing the date.
        // But if we're moving to a larger encapsulating range (eg 1w to 1y), the date may
        // not be forced to change, so kick off a refresh if it has not been done.
        if (!this.isConfiguring && this.refreshCount === refreshCount) {
            this.populateStoreSoon();
        }
    }
    // Interface method used by the Describable Scheduler mixin which renders the date(s) of a view
    // If the result is an array, it creates a range description based on the descriptionFormat.
    get dateBounds() {
        return [this.startDate, this.endDate];
    }
    // We default the range description format to using the shortDateFormat each side (from and to)
    // but this can be configured.
    get descriptionFormat() {
        return super.descriptionFormat || [this.shortDateFormat, `S{${this.shortDateFormat}} - E{${this.shortDateFormat}}`];
    }
    set descriptionFormat(descriptionFormat) {
        super.descriptionFormat = descriptionFormat;
    }
    descriptionRenderer() {
        const
            me = this,
            {
                date,
                startDate,
                endDate,
                range,
                count
            }  = me;
        let rangeDesc;
        if (range?.magnitude === 1) {
            switch (range.unit) {
                case 'day':
                    rangeDesc = DateHelper.format(date, 'L');
                    break;
                case 'week':
                {
                    const
                        startMonth = startDate.getMonth(),
                        endMonth   = endDate.getMonth(),
                        monthDesc  = startMonth === endMonth ? DateHelper.format(startDate, 'MMMM') : `${DateHelper.format(startDate, 'MMM')} - ${DateHelper.format(endDate, 'MMM')}`,
                        week       = me.month.getWeekNumber(startDate);
                    rangeDesc = `${me.L('L{Object.Week}')} ${week[1]}, ${monthDesc} ${week[0]}`;
                    break;
                }
                case 'month':
                    rangeDesc = DateHelper.format(date, 'MMMM, YYYY');
                    break;
                case 'year':
                    rangeDesc = DateHelper.format(date, 'YYYY');
                    break;
                case 'decade':
                    rangeDesc = `${DateHelper.format(startDate, 'YYYY')}s`;
            }
        }
        else {
            rangeDesc = me.formattedDescription;
        }
        return `${rangeDesc}. ${me.L('eventCount', count)}`;
    }
    /**
     * The number of events that this View currently encapsulates in its {@link #config-range date range}.
     * @member {Number}
     * @readonly
     */
    get count() {
        return this.store.count - (this.store.groupRecords?.count || 0);
    }
    get stepUnit() {
        const { range } = this;
        // If just one unit, return the unit name
        return range ? range.magnitude === 1 ? range.unit : this.range : this.L('L{days}', this.duration);
    }
    get store() {
        return super.store;
    }
    set store(store) {
        if (store && !store.modelClass && this.project) {
            store.modelClass = this.project.eventStore.modelClass;
        }
        super.store = store;
    }
    /**
     * When an EventStore arrives, chain off a slave store from that which we can then
     * filter to only show the week we are focused upon.
     */
    updateEventStore(eventStore) {
        super.updateEventStore?.(eventStore);
        // We monitor changes to the EventStore and repopulate our store if it needs it.
        eventStore.ion({
            refresh : 'onCalendarStoreChange',
            thisObj : this
        });
        this.populateStoreSoon();
    }
    changeStore(store) {
        const me = this;
        if (store) {
            store = Store.mergeConfigs({
                useRawData : {
                    ignoreDefaults        : true,
                    disableDefaultValue   : true,
                    disableTypeConversion : true,
                    enabled               : true
                },
                modelClass : me.modelClass
            }, store);
        }
        store = super.changeStore(store);
        // Ensure our subclass, AgendaView is able to use its own filter
        if (store?.modelClass.isEventModel) {
            me.nonWorkingDaysFilter = store.addFilter({
                id       : `${me.id}-nonworkingday-filter`,
                filterBy : event => !me.dayTime.isIntraDay(event) || !me.hiddenNonWorkingDays[event.startDate.getDay()],
                disabled : !me.hideNonWorkingDays
            }, true);
        }
        return store;
    }
    updateHideNonWorkingDays(hideNonWorkingDays) {
        const
            me        = this,
            { store } = me;
        super.updateHideNonWorkingDays(hideNonWorkingDays);
        // We do it by filtering the generated day records by the isNonWorking flag
        if (store) {
            me.nonWorkingDaysFilter.disabled = !hideNonWorkingDays;
            store.filter();
        }
        me.trigger('refresh');
    }
    afterRemove({ records }) {
        // If they remove records from the Grid Store, assume they want to remove the underlying
        // events from the eventStore.
        this.eventStore.remove(records);
        super.afterRemove(...arguments);
    }
    get modelClass() {
        return this.eventStore.modelClass;
    }
    /**
     * This is called when our store needs to be repopulated from the eventStore
     * @private
     */
    populateStoreSoon() {
        const
            me          = this,
            { project } = me;
        // Only refresh immediately if we are visible.
        if (me.isVisible) {
            // Only populate if initial commit is performed and change is not triggered by project writing back data
            if ((project.isInitialCommitPerformed && !project.isWritingData) || !project.eventStore.count) {
                me.populateStore();
            }
        }
        // Otherwise wait till next time we get painted (shown, or a hidden ancestor shown)
        else {
            me.whenVisible('populateStore');
        }
    }
    get duration() {
        // If we are in startDate->endDate mode, as opposed to using a fixed "range"
        // around a date, then to provide a more intuitive interface, we *include* the endDate
        // for EventLists
        return super.duration + (this.range ? 0 : 1);
    }
    populateStore() {
        const
            me           = this,
            { cellEdit } = me.features,
            ec           = cellEdit?.editorContext,
            events       = me.eventStore.getEvents({
                startDate : me.startDate,
                // If we are being set a startDate and endDate, as opposed to using a fixed "range"
                // around a date, then to provide a more intuitive interface, we *include* the endDate
                // for EventLists
                endDate : DateHelper.add(me.endDate, me.range ? 0 : 1, 'd'),
                filter  : me.eventFilter
            }).sort(byStartDate);
        // Create our date index by which we can scroll to dates.
        me.dateIndex = events.reduce((result, event) => {
            const key = DateHelper.makeKey(event.startDate);
            if (!result[key]) {
                result[key] = event;
            }
            return result;
        }, {});
        // The complete data replacement disturbs CellEdit
        // If editing is in progress, cache the context and cancel it
        // to resume after the refresh.
        if (ec) {
            cellEdit.cancelEditing();
        }
        me.store.data = events;
        me.trigger('refresh');
        if (ec) {
            cellEdit.startEditing(ec);
        }
        // The owning Calendar's UI may need to sync with the new state
        me.calendar?.syncUIWithActiveView(this);
    }
    /**
     * Creates the Month utility object. We use it to track what week we are looking at.
     */
    changeMonth() {
        const result = super.changeMonth(...arguments);
        // Update when the month changes.
        result.ion({
            dateChange : 'onMonthChange',
            thisObj    : this
        });
        return result;
    }
    /**
     * When the date we have been told to look at changes, recalculate the date range.
     * If the date range changes, this will cause a refill of our grid store from the
     * master event store.
     */
    onMonthChange({ newDate }) {
        const
            me            = this,
            { startDate } = me,
            newStartDate  = me.changeStartDate(newDate);
        // Move range so that it encapsulates the target date if necessary
        if (!startDate || (newStartDate - startDate && !me.containsDate(newStartDate))) {
            me.updateRange(me.range);
        }
        // Only attempt if the store got populated and we are visible.
        if (!me.preventScroll && me.isVisible && me.store.count) {
            // Ensure any store rebuild triggered by reconfiguring is flushed before
            // we attempt to scroll to that date
            me.populateStoreSoon.flush();
            me.scrollTo(newDate);
        }
    }
    /**
     * This is added as a listener by the CalendarStores mixin.
     *
     * Our store is chained off of the EventStore; refill it if the EventStore changes.
     * The store will fire events which cause UI update.
     * We must fire a refresh event so that the encapsulating Calendar view knows about this.
     */
    onCalendarStoreChange({ source : eventStore }) {
        if (!this.date) {
            this.date = eventStore.map(r => r.startDate).sort((lhs, rhs) => lhs.valueOf() - rhs.valueOf())[0];
        }
        // Only refresh immediately if we are visible.
        this.populateStoreSoon();
    }
    changeSettings(settings, oldSettings) {
        return settings && Button.reconfigure(oldSettings, Button.mergeConfigs({
            type       : 'button',
            positioned : true,
            icon       : 'b-icon-cog',
            menuIcon   : null,
            cls        : 'b-blue b-cal-widget-settings-button',
            appendTo   : this.bodyElement
        }, settings), this);
    }
    //region Current config
    getCurrentConfig(options) {
        const result = super.getCurrentConfig(options);
        // Internally assigned
        delete result.store;
        delete result.data;
        return result;
    }
    //endregion
}
EventList.initClass();
EventList._$name = 'EventList';