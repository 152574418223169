import Base from '../../../Core/Base.js';
import DomHelper from '../../../Core/helper/DomHelper.js';
import AsyncHelper from '../../../Core/helper/AsyncHelper.js';
/**
 * @module Scheduler/view/mixin/SchedulerScroll
 */
const
    defaultScrollOptions = {
        block      : 'nearest',
        edgeOffset : 20
    };
/**
 * Functions for scrolling to events, dates etc.
 *
 * @mixin
 */
export default Target => class SchedulerScroll extends (Target || Base) {
    static get $name() {
        return 'SchedulerScroll';
    }
    //region Scroll to event
    /**
     * Scrolls an event record into the viewport.
     * If the resource store is a tree store, this method will also expand all relevant parent nodes to locate the event.
     *
     * This function is not applicable for events with multiple assignments, please use #scrollResourceEventIntoView instead.
     *
     * @param {Scheduler.model.EventModel} eventRecord the event record to scroll into view
     * @param {BryntumScrollOptions} [options] How to scroll.
     * @returns {Promise} A Promise which resolves when the scrolling is complete.
     * @async
     * @category Scrolling
     */
    async scrollEventIntoView(eventRecord, options = defaultScrollOptions) {
        const
            me        = this,
            resources = eventRecord.resources || [eventRecord];
        if (resources.length > 1) {
            throw new Error('scrollEventIntoView() is not applicable for events with multiple assignments, please use scrollResourceEventIntoView() instead.');
        }
        if (!resources.length) {
            console.warn('You have asked to scroll to an event which is not assigned to a resource');
        }
        await me.scrollResourceEventIntoView(resources[0], eventRecord, options);
    }
    /**
     * Scrolls an assignment record into the viewport.
     *
     * If the resource store is a tree store, this method will also expand all relevant parent nodes
     * to locate the event.
     *
     * @param {Scheduler.model.AssignmentModel} assignmentRecord A resource record an event record is assigned to
     * @param {BryntumScrollOptions} [options] How to scroll.
     * @returns {Promise} A Promise which resolves when the scrolling is complete.
     * @category Scrolling
     */
    scrollAssignmentIntoView(assignmentRecord, ...args) {
        return this.scrollResourceEventIntoView(assignmentRecord.resource, assignmentRecord.event, ...args);
    }
    /**
     * Scrolls a resource event record into the viewport.
     *
     * If the resource store is a tree store, this method will also expand all relevant parent nodes
     * to locate the event.
     *
     * @param {Scheduler.model.ResourceModel} resourceRecord A resource record an event record is assigned to
     * @param {Scheduler.model.EventModel} eventRecord An event record to scroll into view
     * @param {BryntumScrollOptions} [options] How to scroll.
     * @returns {Promise} A Promise which resolves when the scrolling is complete.
     * @category Scrolling
     * @async
     */
    async scrollResourceEventIntoView(resourceRecord, eventRecord, options = defaultScrollOptions) {
        const
            me                  = this,
            { store, timeAxis } = me,
            eventStart          = eventRecord.startDate,
            eventEnd            = eventRecord.endDate,
            eventIsOutside      = eventRecord.isScheduled && eventStart < me.timeAxis.startDate | ((eventEnd > me.timeAxis.endDate) << 1);
        if (arguments.length > 3) {
            options = arguments[3];
        }
        let el;
        if (options.edgeOffset == null) {
            options.edgeOffset = 20;
        }
        // Make sure event is within TimeAxis time span unless extendTimeAxis passed as false.
        // The EventEdit feature passes false because it must not mutate the TimeAxis.
        // Bitwise flag:
        //  1 === start is before TimeAxis start.
        //  2 === end is after TimeAxis end.
        if (eventIsOutside && options.extendTimeAxis !== false) {
            const currentTimeSpanRange = timeAxis.endDate - timeAxis.startDate;
            // Event is too wide, expand the range to encompass it.
            if (eventIsOutside === 3) {
                await me.setTimeSpan(
                    new Date(eventStart.getTime() - currentTimeSpanRange / 2),
                    new Date(eventEnd.getTime() + currentTimeSpanRange / 2)
                );
            }
            else if (me.infiniteScroll) {
                const
                    { visibleDateRange } = me,
                    visibleMS            = visibleDateRange.endMS - visibleDateRange.startMS,
                    // If event starts before time axis, scroll to a date one full viewport after target date
                    // (reverse for an event starting after time axis), to allow a scroll animation
                    sign                 = eventIsOutside & 1 ? 1 : -1;
                await me.setTimeSpan(
                    new Date(eventStart.getTime() - currentTimeSpanRange / 2),
                    new Date(eventStart.getTime() + currentTimeSpanRange / 2),
                    {
                        visibleDate : new Date(eventEnd.getTime() + (sign * visibleMS))
                    }
                );
            }
            // Event is partially or wholly outside but will fit.
            // Move the TimeAxis to include it. That will maintain visual position.
            else {
                // Event starts before
                if (eventIsOutside & 1) {
                    await me.setTimeSpan(
                        new Date(eventStart),
                        new Date(eventStart.getTime() + currentTimeSpanRange)
                    );
                }
                // Event ends after
                else {
                    await me.setTimeSpan(
                        new Date(eventEnd.getTime() - currentTimeSpanRange),
                        new Date(eventEnd)
                    );
                }
            }
        }
        if (me.isDestroyed) {
            return;
        }
        if (store.tree) {
            // If resources are in a tree, ensure parents are expanded first
            await me.expandTo?.(resourceRecord);
        }
        if (store.isGrouped) {
            // If resources are grouped, ensure the relevant group is expanded first
            await store.expand(store.getGroupHeaderForRecord(resourceRecord));
        }
        // Handle nested events too
        if (me.features.nestedEvents?.enabled && eventRecord.parent && !eventRecord.parent.isRoot) {
            await me.scrollEventIntoView(eventRecord.parent);
        }
        // Establishing element to scroll to
        el = me.getElementFromEventRecord(eventRecord, resourceRecord);
        if (el) {
            // It's usually the event wrapper that holds focus
            if (!DomHelper.isFocusable(el)) {
                el = el.parentNode;
            }
            const scroller = me.timeAxisSubGrid.scrollable;
            // Scroll into view with animation and highlighting if needed.
            await scroller.scrollIntoView(el, options);
            if (me.isDestroyed) {
                return;
            }
            let element;
            do {
                element = me.getElementFromEventRecord(eventRecord, resourceRecord);
                // need to await a frame for event to be rendered again
                // (it seems it can be un-rendered during the scroll somehow)
                if (!element) {
                    await AsyncHelper.animationFrame();
                }
                if (me.isDestroyed) {
                    return;
                }
            } while (!element);
        }
        // If event is fully outside the range, and we are not allowed to extend
        // the range, then we cannot perform the operation.
        else if (eventIsOutside === 3 && options.extendTimeAxis === false) {
            console.warn('You have asked to scroll to an event which is outside the current view and extending timeaxis is disabled');
        }
        else if (!eventRecord.isOccurrence && me.eventStore.isFilteredOut(eventRecord)) {
            console.warn('You have asked to scroll to an event which is not available');
        }
        else if (eventRecord.isScheduled) {
            // Event scheduled but not rendered, scroll to calculated location
            await me.scrollUnrenderedEventIntoView(resourceRecord, eventRecord, options);
        }
        else {
            // Event not scheduled, just scroll resource row into view
            await me.scrollResourceIntoView(resourceRecord, options);
        }
    }
    /**
     * Scrolls an unrendered event into view. Internal function used from #scrollResourceEventIntoView.
     * @private
     * @category Scrolling
     */
    scrollUnrenderedEventIntoView(resourceRec, eventRec, options = defaultScrollOptions) {
        // We must only resolve when the event's element has been painted
        // *and* the scroll has fully completed.
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async resolve => {
            const
                me               = this,
                scroller         = me.timeAxisSubGrid.scrollable,
                scrollerViewport = scroller.viewport,
                { rowManager }   = me,
                initialY         = scroller.y;
            // Event may fall on a time not included by workingTime settings
            if (!scrollerViewport) {
                resolve();
                return;
            }
            let eventElement, delta, counter = 0;
            do {
                // don't risk with the infinite loop, better to throw
                if (++counter >= 50) {
                    throw new Error(`Too many preparational scrolls during 'scrollIntoView' for event id = ${eventRec.id}`);
                }
                // note, that this box is only a rough estimation of the event position
                // it will be precise only in trivial cases
                // in general case, it indicates the direction in which we should scroll
                // and our best guess on the distance
                // this is because we don't know the row heights (even if `preCalculateHeightLimit` is used)
                // row height might change because of:
                // - horizontal scroll (predictable with certain effort)
                // - row height set in some column renderer (completely unpredictable)
                // so we scroll several times, until we have the event's element (meaning the event is rendered)
                const box = me.getResourceEventBox(eventRec, resourceRec);
                // Event may fall on a time not included by workingTime settings
                if (!box) {
                    resolve();
                    return;
                }
                // In case of subPixel position, scroll the whole pixel into view
                box.x = Math.ceil(box.x);
                box.y = Math.ceil(box.y);
                if (me.rtl) {
                    // RTL scrolls in negative direction but coordinates are still LTR
                    box.translate(-me.timeAxisViewModel.totalSize + scrollerViewport.width, 0);
                }
                // Note use of scroller.scrollLeft here. We need the natural DOM scrollLeft value
                // not the +ve X position along the scrolling axis.
                box.translate(scrollerViewport.x - scroller.scrollLeft, scrollerViewport.y - scroller.y);
                const instantScrollOptions = Object.assign({}, defaultScrollOptions);
                // only interested in the direction of initial jump
                if (delta === undefined) {
                    delta = scroller.getDeltaTo(box, instantScrollOptions);
                }
                const scrollPromise = scroller.scrollIntoView(box, instantScrollOptions);
                await scrollPromise;
                if (scrollPromise.cancelled || me.isDestroyed) {
                    resolve();
                    return true;
                }
                await AsyncHelper.animationFrame();
                if (me.isDestroyed) {
                    resolve();
                    return true;
                }
                eventElement = me.getElementFromEventRecord(eventRec, resourceRec);
            } while (!eventElement);
            // now we have arrived to the local area of the event
            // probably we don't need to suspend/resume events on scroller, since DOM `scroll` event is fired
            // asynchronously, and we resume the events right away in the synchronous flow below
            scroller.suspendEvents();
            // position the scroller above/below of all rows, but not exceeding the initial Y position
            if (delta.yDelta >= 0) {
                scroller.y = Math.max(rowManager.topRow.top - scroller.viewport.height, initialY);
            }
            else {
                scroller.y = Math.min(rowManager.bottomRow.bottom, initialY);
            }
            // this is a quite important call, which fixes the internal scroller state after the scrolls above
            me.fixElementHeights();
            scroller.resumeEvents();
            // now make a final animated scroll, pretending we arrived there from the very 1st jump
            const scrollPromise2 = scroller.scrollIntoView(
                eventElement,
                Object.assign({}, options, { elementAfterScroll : () => me.getElementFromEventRecord(eventRec, resourceRec) })
            );
            await scrollPromise2;
            if (scrollPromise2.canceled || me.isDestroyed) {
                resolve();
                return true;
            }
            // final await for animation frame for the event element rendering to complete
            await AsyncHelper.animationFrame();
            resolve();
        });
    }
    /**
     * Scrolls the specified resource into view, works for both horizontal and vertical modes.
     * @param {Scheduler.model.ResourceModel} resourceRecord A resource record an event record is assigned to
     * @param {BryntumScrollOptions} [options] How to scroll.
     * @returns {Promise} A promise which is resolved when the scrolling has finished.
     * @category Scrolling
     */
    scrollResourceIntoView(resourceRecord, options = defaultScrollOptions) {
        if (this.isVertical) {
            return this.currentOrientation.scrollResourceIntoView(resourceRecord, options);
        }
        return this.scrollRowIntoView(resourceRecord, options);
    }
    //endregion
    // This does not need a className on Widgets.
    // Each *Class* which doesn't need 'b-' + constructor.name.toLowerCase() automatically adding
    // to the Widget it's mixed in to should implement thus.
    get widgetClass() {}
};
