import Exporter from '../../../../Grid/feature/export/exporter/Exporter.js';
import TemplateHelper from '../../../../Core/helper/TemplateHelper.js';
import Widget from '../../../../Core/widget/Widget.js';
import BrowserHelper from '../../../../Core/helper/BrowserHelper.js';
/**
 * @module Calendar/feature/print/printer/Printer
 */
/**
 * Base class for all printers
 * @extends Grid/feature/export/exporter/Exporter
 * @internal
 */
export default class Printer extends Exporter {
    restoreState({ client }) {}
    saveState({ client }) {}
    cloneElement(element, target = this.element, clear = true) {
        if (clear) {
            target.innerHTML = '';
        }
        target.appendChild(element.cloneNode(true));
    }
    /**
     * Template of an extracted page.
     * @param {Object} data Data for the page template
     * @returns {String}
     */
    pageTpl(data) {
        const
            {
                config,
                title,
                header,
                footer,
                styles,
                htmlClasses,
                bodyStyle,
                bodyClasses = [`b-${this.constructor.type}`, ...this.owner.bodyClasses, ...Widget.outerCls],
                paperHeight,
                paperWidth,
                html
            }          = data,
            // Safari's print returns immediately, so we can't immediately remove the window.
            // It is removed if ever needed again.
            afterPrint = BrowserHelper.isSafari ? '' : 'window.removeElement && window.removeElement();',
            // Don't run print script in debug mode
            script     = !config?.debug ? `<script type="text/javascript">window.addEventListener('load', function() { window.print(); ${afterPrint} });</script>` : '';
        return TemplateHelper.tpl`
            <!DOCTYPE html>
            <html class="${htmlClasses}" style="width: ${paperWidth}in; height: ${paperHeight}in;">
                <head>
                    <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
                    <title>${title}</title>
                    ${styles.join('')}
                </head>
                <body class="b-print ${bodyClasses.join(' ')}" style="width: ${paperWidth}in; height: ${paperHeight}in; ${bodyStyle}">
                    <div class="b-print-content">
                        ${header && `<div class="b-print-header" style="width: 100%">${header}</div>`}
                        <div class="b-print-body"><div class="b-print-viewport">${html}</div></div>
                        ${footer && `<div class="b-print-footer" style="width: 100%">${footer}</div>`}
                    </div>
                    ${script}
                </body>
            </html>`;
    }
}
Printer._$name = 'Printer';