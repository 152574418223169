import SchedulerEventEditor from '../../Scheduler/view/EventEditor.js';
/**
 * @module Calendar/widget/EventEditor
 */
/**
 * Provided event editor dialog. This is not supposed to be used directly.
 *
 * Using the {@link Scheduler.feature.EventEdit#config-items items} config of the EventEdit feature
 * it is possible to reconfigure the layout of the event editor. See the {@link Calendar.feature.EventEdit}
 * for more information and an example of doing this.
 *
 * @extends Scheduler/view/EventEditor
 * @private
 *
 * @typings Scheduler.view.EventEditor -> Scheduler.view.SchedulerEventEditor
 */
export default class EventEditor extends SchedulerEventEditor {
    //region Config
    static get $name() {
        return 'EventEditor';
    }
    // Factoryable type name
    static get type() {
        return 'calendareventeditor';
    }
    static get configurable() {
        return {
            align : {
                monitorResize : true // Needed because DayView events change size
            }
        };
    }
    //endregion
    updateRecord(record) {
        this.fixTimeFieldVisibility(record.allDay);
        super.updateRecord(record);
    }
    onAllDayChange({ value, userAction }) {
        this.fixTimeFieldVisibility(value, userAction);
    }
    fixTimeFieldVisibility(allDay, fromUI) {
        const
            hideShowMethod  = allDay ? 'hide' : 'show',
            {
                startDateField,
                startTimeField,
                endDateField,
                endTimeField
            } = this.widgetMap;
        // Times hidden for allDay events
        startTimeField?.[hideShowMethod]();
        endTimeField?.[hideShowMethod]();
        // Restore the time fields from the underlying data if the UI checkbox is cleared.
        // If the record was promoted to being allDay, the time portion does not get cleared,
        // just the date getters return snapped values. So the underlying data restores the fields
        // to the originally set start and end times so that the save operation will work as expected.
        if (!allDay && fromUI) {
            startDateField && (startDateField.value = startTimeField.value = this.record.getData('startDate'));
            endDateField && (endDateField.value = endTimeField.value = this.record.getData('endDate'));
        }
    }
    onBeforeShow() {
        const { deleteButton } = this.widgetMap;
        // Superclass has opinions at this time.
        super.onBeforeShow(...arguments);
        // If superclass has not already hidden it for its owm reasons
        // We hide the delete button for newly created events.
        // The cancel button removes it.
        if (deleteButton && !deleteButton.hidden) {
            if (this.record.isCreating) {
                deleteButton.hide();
            }
        }
    }
    onFocusOut() {
        super.onFocusOut(...arguments);
        this.owner = this.initialConfig.owner;
    }
}
// Register this widget type with its Factory
EventEditor.initClass();
EventEditor._$name = 'EventEditor';