import Base from '../../Core/Base.js';
import CalendarFeature from './CalendarFeature.js';
import Draggable from '../../Core/mixin/Draggable.js';
import Droppable from '../../Core/mixin/Droppable.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import EventHelper from '../../Core/helper/EventHelper.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
import Delayable from '../../Core/mixin/Delayable.js';
import Widget from '../../Core/widget/Widget.js';
/**
 * @module Calendar/feature/ExternalEventSource
 */
const oneHour = {
    magnitude : 1,
    unit      : 'hour'
};
class ExternalZone extends Base.mixin(Draggable, Droppable) {
    static get $name() {
        return 'ExternalZone';
    }
    static get configurable() {
        return {
            // Default to dragging grid rows
            dragItemSelector : '.b-grid-row',
            dragProxy : {
                type : 'default',
                open(drag) {
                    // Use the Feature's openProxy
                    const
                        feature = this.owner.owner,
                        proxyEl = feature.openProxy(drag);
                    drag.cleaners.push(() => {
                        feature.insertionPoint = null;
                        proxyEl.remove();
                    });
                    return proxyEl;
                },
                dragMove(drag) {
                    // Use the Feature's moveProxy
                    return this.owner.owner.moveProxy(drag);
                },
                close() {
                    this.owner.owner.proxyEl?.remove();
                }
            }
        };
    }
    beforeDrag(drag) {
        return this.owner.onDragStart(drag);
    }
    dragEnter(drag) {
        if (drag.source.isMoving) {
            drag.element = drag.targetElement;
            this.dragProxy.open(drag);
        }
    }
    dragMove(drag) {
        if (drag.source.isMoving) {
            this.dragProxy.dragMove(drag);
        }
        if (drag.source.isMoving || drag.source.isExternalZone) {
            return this.owner.onDragMove(drag);
        }
    }
    dragDrop(drag) {
        if (drag.source.isMoving) {
            this.dragProxy.close();
        }
        // Only react if we're dropping on our own ExternalZone.
        // If dropping on the Calendar, we listen to its beforeDragMoveEnd and dragMoveEnd events
        if (drag.target === this && (drag.source.isMoving || drag.source.isExternalZone)) {
            return this.owner.onDragDrop(drag);
        }
    }
}
/**
 * A Calendar feature which allows new events to be dragged into the Calendar from an external source.
 *
 * The default source type is a Bryntum {@link Grid.view.Grid grid} which is loaded with
 * {@link Scheduler.model.EventModel event records}.
 *
 * Optionally, the source can be specified by configuring a {@link #config-dragRootElement}
 * and a {@link #config-dragItemSelector} which together, identify elements which represent
 * draggable events.
 *
 * In this case, a {@link #config-getRecordFromElement} may be specified to yield the details of
 * the record to be dragged.
 *
 * In the simplest case the `textContent` of the identified element is used as the event name and
 * {@link #config-getRecordFromElement} is not required. The event duration in this case will be
 * that specified in the receiving Calendar's {@link Calendar.view.Calendar#config-autoCreate} setting.
 *
 * When dropping an unscheduled event (An event that has no start and end date specified) into
 * a day cell (For example a MonthView or YearView), the start *time* set within the day cell
 * will default to the `startHour` property of the receiving Calendar's {@link Calendar.view.Calendar#config-autoCreate} setting.
 *
 * This feature is **disabled** by default.
 *
 * {@inlineexample Calendar/feature/ExternalEventSource.js}
 *
 * @demo Calendar/dragfromgrid
 *
 * @extends Calendar/feature/CalendarFeature
 * @classtype externalEventSource
 * @feature
 */
export default class ExternalEventSource extends CalendarFeature.mixin(Delayable) {
    static get $name() {
        return 'ExternalEventSource';
    }
    static get delayable() {
        return {
            onCalendarPaint : 'raf'
        };
    }
    static get type() {
        return 'externalEventSource';
    }
    static get configurable() {
        return {
            /**
             * The grid, or `id` of a grid from which events are to be dragged.
             * @config {Grid.view.Grid|String}
             */
            grid : {
                $config : ['lazy'],
                value   : null
            },
            /**
             * If not dragging from a grid, which is the default mode, then an element from which
             * dragging can take place must be supplied in the `dragRootElement` config.
             *
             * May also be specified as a selector which matches a unique element, or a simple element id.
             *
             * In this case a {@link #config-dragItemSelector} string, and {@link #config-getRecordFromElement}
             * function must be supplied to allow event records to be sourced from the element, for example:
             *
             * ```javascript
             * features : {
             *     externalEventSource : {
             *         dragRootElement  : '#mySourceElementId',
             *         dragItemSelector : '.my-item-class'
             *     }
             * }```
             * @config {HTMLElement|String}
             */
            dragRootElement : null,
            /**
             * If not dragging from a grid, which is the default mode, then a selector which identifies
             * draggable elements within the {@link #config-dragRootElement}.
             *
             * In the simplest case, the identified element may contain simply a string which is used
             * as the event name, for example:
             *
             * ```javascript
             * features : {
             *     externalEventSource : {
             *         dragRootElement  : '#mySourceElementId',
             *         dragItemSelector : '.my-item-class'
             *     }
             * }```
             * @config {String}
             */
            dragItemSelector : null,
            /**
             * If not dragging from a grid, which is the default mode, then a function which returns
             * an event record to drag from a passed element must be supplied.
             *
             * In this case a {@link #config-dragRootElement} and a {@link #config-dragItemSelector} string
             * may be supplied to allow event records to be sourced from the element.
             *
             * If the element identified by the {@link #config-dragItemSelector} just contains an event
             * name to create, this configuration is optional. A new event will be created by that name, for example:
             *
             * ```javascript
             * features : {
             *     externalEventSource : {
             *         dragRootElement  : '#mySourceElementId',
             *         dragItemSelector : '.my-item-class',
             *         getRecordFromElement(element) {
             *             // Return an object from which an EventModel can be created.
             *             // Same format as loading an EventStore. { name : 'name', startDate: ''} etc
             *             return myController.createRecordFromElement(element);
             *         }
             *     }
             * }```
             *
             * @config {Function|String}
             * @param {HTMLElement} element HTML element
             * @returns {Scheduler.model.EventModel|null}
             */
            getRecordFromElement : function(element) {
                const grid = this.grid || (this.grid = Widget.fromElement(element, 'grid'));
                if (grid) {
                    return grid.getRecordFromElement(element);
                }
                // The simplest implementation is that the elements identified by
                // the selector yield the event name.
                return element.innerText;
            },
            /**
             * By default, the proxy shown when "picking up" the grid row is hidden
             * when dragging over the calendar because the {@link Calendar.feature.CalendarDrag}
             * feature automatically shows a drop position indicator which shows where the
             * proposed new event will be.
             * @config {Boolean}
             * @default
             */
            hideExternalProxy : true,
            /**
             * An object which overrides or augments the default configuration for the
             * {@link Core.mixin.Draggable} which handles picking up events.
             *
             * This is only necessary if there is no {@link #config-grid} specified.
             * @config {Object} [draggable]
             * @default
             */
            draggable : {
                $config : ['lazy'],
                value   : {}
            },
            /**
             * An object which, if present, causes creation of a {@link Core.mixin.Droppable} which
             * handles dropping events *from* the Calendar into the external location.
             *
             * In the simplest configuration, configure this as `true`.
             *
             * __Important:__ when dropping on an external source, the record is _not_ removed
             * from the Calendar's `eventStore`. You will need to listen for the {@link #event-dropExternal} event.
             * @config {Object|Boolean} [droppable]
             * @default
             */
            droppable : {
                $config : ['lazy'],
                value   : null
            },
            /**
             * Used when {@link #config-droppable} is used, and the external source is a Grid. This
             * allows us to track the over row to highlight the insertion point.
             * @private
             */
            insertionPoint : null
        };
    }
    // This is deferred to the next AF to allow the configured grid ID to be available
    onCalendarPaint({ source : calendar, firstPaint }) {
        // Ingestion of Draggable, and within that, grid is deferred until the host Calendar is painted
        if (firstPaint) {
            const {
                    draggable = {},
                    droppable = {}
                }    = this,
                zone = new ExternalZone({
                    view : this.grid,
                    ...draggable,
                    ...droppable
                });
            if (this._draggable) {
                this._draggable = zone;
            }
            if (this._droppable) {
                this._droppable = zone;
            }
        }
        // We neede to hook into the CalendarDrag's move ending events which are fired through the client.
        // We need to get in at that point so that we can veto the "before" event and fire our own
        // completion event after the event transfer has been completed.
        calendar.ion({
            beforeDragMoveEnd : 'onBeforeDragMoveEnd',
            dragMoveEnd       : 'onDragMoveEnd',
            thisObj           : this
        });
    }
    onBeforeDragMoveEnd({ drag }) {
        // A before drop on the Calendar. We only react if it is in fact an external drop.
        if (drag.source === this.draggable) {
            drag.dropOnCalendar = true;
            const
                gridStore = this.grid?.store,
                result    = this.client.trigger('dropExternal', drag);
            // Handler may change state. If a chained store, it may need refilling
            gridStore?.isChained && gridStore.fillFromMaster();
            return result;
        }
    }
    onDragMoveEnd({ drag }) {
        // An after drop on the Calendar. We only react if it is in fact an external drop.
        if (drag.source === this.draggable) {
            return this.client.trigger('afterDropExternal', drag);
        }
    }
    onDragStart(drag) {
        const
            me             = this,
            {
                client,
                grid
            }              = me,
            { eventStore } = client,
            { modelClass } = eventStore;
        // If mousedown was not on our Draggable's dragItemSelector, veto drag start
        if (!drag.itemElement) {
            return false;
        }
        let eventRecord = me.callback(me.getRecordFromElement, me, [drag.itemElement]);
        if (eventRecord.isModel) {
            // CalendarDrag interrogates this to see if it should "removeFromExternalStore"
            if (grid) {
                drag.set('sourceStore', grid.store);
            }
            if (!eventRecord.isEventModel) {
                eventRecord = eventStore.createRecord(eventRecord.data);
            }
        }
        else {
            if (typeof eventRecord === 'string') {
                const
                    autoCreate = client.activeView.autoCreate || client.autoCreate,
                    duration   = DateHelper.parseDuration(autoCreate?.duration || oneHour);
                eventRecord = {
                    [modelClass.getFieldDataSource('name')]         : eventRecord,
                    [modelClass.getFieldDataSource('duration')]     : duration.magnitude,
                    [modelClass.getFieldDataSource('durationUnit')] : duration.unit
                };
            }
            eventRecord = eventStore.createRecord(eventRecord);
        }
        drag.set('sourceStore', me.grid?.store);
        drag.set('eventRecord', eventRecord);
        drag.eventRecord = eventRecord;
    }
    onDragMove(drag) {
        const { grid } = this;
        if (grid) {
            const
                row    = grid.rowManager.getRowFor(drag.targetElement),
                record = row && grid.store.getAt(row.index);
            // If we over a row, that is *not* the row for the dragging record...
            if (row) {
                const isAbove = drag.lastMoveEvent.clientY < row.getRectangle(Object.keys(grid.subGrids)[0]).center.y;
                drag.overIndex  = row.index;
                drag.overRecord = record;
                drag.isAbove    = isAbove;
                this.insertionPoint = record === drag.peek('eventRecord') ? {} : { row, isAbove };
            }
            // If not over a row, see if we're dropping in free space after the bottom row (if present)
            // After the headerContainer if no rows present.
            else if (grid.contains(drag.targetElement)) {
                const hcCls = grid.headerContainer.classList;
                drag.overIndex = -1;
                this.insertionPoint = {
                    row : grid.rowManager.bottomRow || {
                        addCls    : c => hcCls.add(c),
                        removeCls : c => hcCls.remove(c)
                    }
                };
            }
            // We've exited the Grid
            else {
                drag.overIndex = NaN;
                this.insertionPoint = null;
            }
        }
        drag.eventRecord = drag.data.get('eventRecord');
        /**
         * This event is fired on the owning Calendar when dragging an event from the calendar over the
         * external source __if the {@link #config-droppable} was configured__.
         *
         * __If a {@link #config-grid} was configured as the external source, a dropping
         * insertion point will be displayed in the grid__.
         *
         * If the external source is simply a set of HTML elements, your application must
         * process this gesture.
         * @event dragMoveExternal
         * @on-owner
         * @param {Scheduler.model.EventModel} eventRecord The event record being dragged.
         * @param {HTMLElement} itemElement The element in which the drag gesture started.
         * @param {HTMLElement} targetElement The current over element.
         * @param {Event} domEvent The pointer event associated with the drag point.
         * @param {Number} overIndex *If {@link #config-grid} was specified*, the index of the row
         * being moved over;
         * @param {Core.data.Model} overRecord *If {@link #config-grid} was specified*, the record
         * being moved over;
         * @param {Boolean} isAbove *If {@link #config-grid} was specified*, `true` if the pointer
         * position is above the halfway line of the over row.
         * @param {Boolean} altKey `true` if the Alt key was down when the last event was processed.
         * @param {Boolean} ctrlKey `true` if the Ctrl key was down when the last event was processed.
         * @param {Boolean} metaKey `true` if the Meta key was down when the last event was processed.
         * @param {Boolean} shiftKey `true` if the Shift key was down when the last event was processed.
         */
        this.client.trigger('dragMoveExternal', drag);
    }
    changeInsertionPoint(ip, was) {
        if (ip?.row !== was?.row || ip?.isAbove !== was?.isAbove) {
            return ip;
        }
    }
    updateInsertionPoint(ip, was) {
        was?.row?.removeCls(was.isAbove ? 'b-drop-above' : 'b-drop-below');
        ip?.row?.addCls(ip.isAbove ? 'b-drop-above' : 'b-drop-below');
    }
    async onDragDrop(drag) {
        const
            {
                client,
                grid
            }               = this,
            { eventStore }  = client,
            { eventRecord } = drag,
            gridStore       = grid?.store,
            sameStore       = gridStore === eventStore || gridStore?.masterStore === eventStore;
        /**
         * This event is fired on the owning Calendar when dropping an event from the calendar on the
         * external source __if the {@link #config-droppable} was configured__. Returning `false`
         * prevents the gesture from being completed.
         *
         * __If a {@link #config-grid} was configured as the external source, the record will be
         * removed from the Calendar's event store and inserted to the grid's store__.
         *
         * If the external source is simply a set of HTML elements, your application must
         * process this gesture.
         * @event dropExternal
         * @preventable
         * @on-owner
         * @param {Scheduler.model.EventModel} eventRecord The event record being dragged.
         * @param {Boolean} dropOnCalendar `true` if the drop gesture is over the client Calendar.
         * This feature also allows drag *out* of the Calendar and onto the external event source
         * if the {@link #config-droppable} config is set.
         * @param {HTMLElement} itemElement The element in which the drag gesture started.
         * @param {HTMLElement} targetElement The current over element.
         * @param {Event} domEvent The pointer event associated with the drag point.
         * @param {Number} overIndex *If {@link #config-grid} was specified*, the index of the row
         * being moved over;
         * @param {Core.data.Model} overRecord *If {@link #config-grid} was specified*, the record
         * being moved over;
         * @param {Boolean} isAbove *If {@link #config-grid} was specified*, `true` if the pointer
         * position is above the halfway line of the over row.
         * @param {Boolean} altKey `true` if the Alt key was down when the last event was processed.
         * @param {Boolean} ctrlKey `true` if the Ctrl key was down when the last event was processed.
         * @param {Boolean} metaKey `true` if the Meta key was down when the last event was processed.
         * @param {Boolean} shiftKey `true` if the Shift key was down when the last event was processed.
         */
        if (client.trigger('dropExternal', drag) === false) {
            // Handler may change state. If a chained store, it may need refilling
            gridStore?.isChained && gridStore.fillFromMaster();
            return;
        }
        // It is a drop on an external Grid
        if (!isNaN(drag.overIndex)) {
            // The event is being dragged out from the Calendar's eventStore
            // and our store is *not* based on the Calendar's eventStore.
            // If the source view is a resource-specific view, this gesture is
            // just a deassign, so only remove if the source view is not resource-specific.
            if (eventStore.includes(eventRecord) && !sameStore && !drag.source.view.resource) {
                eventStore.remove(eventRecord);
            }
            // The source store is chained off the main calendar eventStore.
            // In this case (Assuming the dropExternal handler set the record state to change its
            // filtered in/out status), this is a move event.
            if (sameStore) {
                eventStore.move(eventRecord, eventStore.getAt(drag.overIndex + drag.isAbove ? 1 : 0));
            }
            else {
                gridStore.insert(drag.overIndex + (drag.isAbove ? 0 : 1), eventRecord);
            }
            gridStore?.isChained && gridStore.fillFromMaster();
        }
        client.trigger('afterDropExternal', drag);
    }
    openProxy(drag) {
        const
            { grid } = this,
            sourceEl = drag.element.closest(this.draggable.dragItemSelector) || drag.element,
            nameCell = grid ? sourceEl.querySelector('[data-column="name"]') : sourceEl,
            proxyEl  = this.proxyEl || (this.proxyEl = DomHelper.createElement({
                className : 'b-grid-to-cal-drag-proxy'
            }));
        proxyEl.innerHTML = drag.peek('eventRecord')?.name || nameCell?.innerHTML || this.owner.L('newEvent');
        this.proxyOffset = EventHelper.getClientPoint(drag.event).getDelta(Rectangle.from(sourceEl));
        (grid?.element || this.dragRootElement).parentNode.appendChild(proxyEl);
        return proxyEl;
    }
    moveProxy(drag) {
        const { proxyEl } = this;
        if (proxyEl) {
            // Hide the proxy if the target droppable is a Calendar and we are configured to do so
            if (this.client.owns(drag.targetElement) && this.hideExternalProxy) {
                proxyEl.classList.add('b-hide-display');
            }
            else {
                // Align the proxy to [10, 10] from the pointer
                proxyEl.classList.remove('b-hide-display');
                DomHelper.alignTo(proxyEl, EventHelper.getClientPoint(drag.event).translate(10, 10), {
                    align : 't0-t0'
                });
            }
        }
    }
    changeDragRootElement(dragRootElement) {
        if (typeof dragRootElement === 'string') {
            dragRootElement = document.querySelector(dragRootElement) || document.getElementById(dragRootElement);
        }
        return dragRootElement;
    }
    changeDraggable(draggable) {
        const
            { grid }             = this,
            dragRootElement      = grid?.contentElement || this.dragRootElement,
            { dragItemSelector } = this;
        draggable = ExternalEventSource.mergeConfigs({
            owner : this,
            grid
        }, draggable);
        if (dragRootElement) {
            draggable.dragRootElement = dragRootElement;
        }
        if (dragItemSelector) {
            draggable.dragItemSelector = dragItemSelector;
        }
        return draggable;
    }
    changeDroppable(droppable) {
        const
            { grid }             = this,
            dropRootElement      = grid?.contentElement || this.dropRootElement;
        if (droppable) {
            droppable = ExternalEventSource.mergeConfigs({
                owner : this,
                grid
            }, droppable);
            if (dropRootElement) {
                droppable.dropRootElement = dropRootElement;
            }
        }
        return droppable;
    }
    changeGrid(grid) {
        if (typeof grid === 'string') {
            grid = Widget.getById(grid);
        }
        return grid;
    }
}
ExternalEventSource.initClass();
ExternalEventSource._$name = 'ExternalEventSource';