import DateHelper from '../../Core/helper/DateHelper.js';
import Responsive from '../../Core/widget/mixin/Responsive.js';
import Panel from '../../Core/widget/Panel.js';
import '../../Calendar/widget/CalendarDatePicker.js';
import '../../Scheduler/widget/ResourceFilter.js';
import '../../Core/widget/FilterField.js';
/**
 * @module Calendar/widget/Sidebar
 */
/**
 * This class is not supposed to be used directly. This widget provides the utility UI as the
 * {@link Calendar.view.Calendar#config-sidebar} of a {@link Calendar.view.Calendar Calendar} widget.
 *
 * Sidebar items provided by default:
 *
 * | Widget ref       | Type                                         | Weight | Description                                 |
 * |------------------|----------------------------------------------|--------|---------------------------------------------|
 * | `datePicker`     | {@link Calendar.widget.CalendarDatePicker}   | 100    | Used to pick Calendar's active date         |
 * | `eventFilter`    | {@link Core.widget.FilterField}              | 150    | Used to filter events by name               |
 * | `resourceFilter` | {@link Scheduler.widget.ResourceFilter}      | 200    | Used to select resources to show events for |
 *
 * The configuration of these items may be overridden:
 *
 * ```javascript
 * new Calendar({
 *     sidebar : {
 *         items : {
 *             datePicker : {
 *                 // Never go to dates in the past
 *                 minDate : new Date()
 *             },
 *             eventFilter : {
 *                 // Let's have this at the top
 *                 weight : 50
 *             },
 *             resourceFilter : {
 *                 store : {
 *                     sorters : [{
 *                         field     : 'name',
 *                         // By default this is in ascending name order
 *                         // Let's change that round.
 *                         ascending : false
 *                     }]
 *                 }
 *             }
 *         }
 *     }
 * });
 * ```
 *
 * The {@link #config-bbar} is used to hold the navigation buttons ("Today" and next/previous) when the owning
 * `Calendar` is configured with {@link Calendar.view.Calendar#config-navigatorPlacement} set to `'sidebar'`. The
 * location of the `bbar`, as well as other options, can be changed via the `bbar` config, like so:
 *
 * ```javascript
 *  new Calendar({
 *      sidebar : {
 *          bbar : {
 *              dock : 'top'
 *          }
 *      }
 *  });
 * ```
 *
 * {@inlineexample Calendar/widget/Sidebar.js}
 *
 * @extends Core/widget/Panel
 * @mixes Core/widget/mixin/Responsive
 * @classtype sidebar
 */
export default class Sidebar extends Panel.mixin(Responsive) {
    static get $name() {
        return 'Sidebar';
    }
    // Factoryable type name
    static get type() {
        return 'sidebar';
    }
    static get configurable() {
        return {
            focusable            : false,
            ignoreParentReadOnly : true,
            bbar : {
                cls    : 'b-cal-nav-item',
                layout : {
                    justify : 'center'
                },
                items : {
                    todayButtonSidebar : {
                        text    : 'L{Calendar.Today}',
                        onClick : 'up.shiftToNow',
                        weight  : 200
                    },
                    prevButtonSidebar : {
                        onClick : 'up.shiftPrevious',
                        cls     : 'b-borderless',
                        icon    : 'b-icon-previous',
                        weight  : 300
                    },
                    nextButtonSidebar : {
                        onClick : 'up.shiftNext',
                        cls     : 'b-borderless',
                        icon    : 'b-icon-next',
                        weight  : 400
                    }
                }
            },
            items : {
                datePicker : {
                    type              : 'datepicker',
                    weight            : 100,
                    internalListeners : {
                        selectionChange : 'up.onDatePickerSelectionChange'
                    }
                },
                eventFilter : {
                    type        : 'filterfield',
                    field       : 'name',
                    // We want this in second position
                    weight      : 150,
                    placeholder : 'L{Filter events}',
                    localeClass : this
                },
                resourceFilter : {
                    ignoreParentReadOnly : true
                }
            },
            date : {
                $config : {
                    equal : 'date'
                },
                value : null
            },
            eventStore : null,
            responsive : {},  // brand us as responsive so "collapsed" is tracked per responsive state
            stepUnitText : null,
            /**
             * May be configured with `'left'` or `'right'` to dock the Sidebar to either side.
             *
             * Defaults to `'left'`.
             * @config {'left'|'right'}
             * @default
             */
            side : 'left',
            collapsible : {
                tool : null
            },
            /**
             * The resource filtering widget which hides and shows events based upon
             * whether they are assigned to the selected resources.
             * @member {Scheduler.widget.ResourceFilter} resourceFilter
             */
            /**
             * A configuration object specifying options to change how the sidebar's resourcefilter is configured.
             * @config {ResourceFilterConfig}
             */
            resourceFilter : {
                type       : 'resourcefilter',
                flex       : '1 1 auto',
                weight     : 200,
                minHeight  : 110,
                scrollable : {
                    overflowY : 'auto'
                },
                store : {
                    sorters : [{
                        field     : 'name',
                        ascending : true
                    }]
                },
                // We must only see the resources that are filtered in
                masterFilter(r) {
                    return this.eventStore.resourceStore.isAvailable(r);
                }
            }
        };
    }
    setupWidgetConfig(widgetConfig, type) {
        let result = super.setupWidgetConfig(...arguments);
        // A string becomes the defaultType (see below) with the html set to the string.
        if (result.ref === 'eventFilter') {
            result.store = this.initialConfig.eventStore;
        }
        // Apply our configuration to the resourceFilter if it has not been configured to be
        // a completely different type of widget
        if (result.ref === 'resourceFilter') {
            if (!result.type || result.type === this.resourceFilter.type) {
                result = Sidebar.mergeConfigs(this.resourceFilter, result);
            }
        }
        // Sidebar has to know these so it can set an optimum minWidth to prevent
        // the DatePicker from being crushed into a bad UX.
        if (result.type === 'datepicker') {
            this.element.classList.add('b-has-datepicker');
            this.element.classList.toggle('b-datepicker-with-events', Boolean(result.events));
            // Monitor rangeChange events from EventLists
            this.up('calendar')?.ion({
                rangeChange : 'onCalendarViewRangeChange',
                thisObj     : this
            });
        }
        return result;
    }
    updateSide(side, oldSide) {
        const { classList } = this.element;
        classList.remove(`b-sidebar-${oldSide}`);
        classList.add(`b-sidebar-${side}`);
        if (this.collapsible) {
            this.collapsible.direction = side;
        }
    }
    updateStepUnitText(stepUnitText) {
        const
            me                         = this,
            { prevButtonSidebar, nextButtonSidebar } = me.widgetMap;
        if (prevButtonSidebar) {
            prevButtonSidebar.tooltip = stepUnitText ? me.L('L{Calendar.previous}', stepUnitText) : '';
            prevButtonSidebar.disabled = !stepUnitText;
        }
        if (nextButtonSidebar) {
            nextButtonSidebar.tooltip = stepUnitText ? me.L('L{Calendar.next}', stepUnitText) : '';
            nextButtonSidebar.disabled = !stepUnitText;
        }
    }
    updateEventStore(eventStore) {
        const { resourceFilter, datePicker } = this.widgetMap;
        if (resourceFilter) {
            resourceFilter.eventStore = eventStore;
        }
        if (datePicker) {
            datePicker.eventStore = eventStore;
        }
    }
    // Called when a DateRangeOwner mode changes its range. The event is relayed by the Calendar.
    // If the mode is an EventList in startDate->endDate mode, the date picker should match it
    onCalendarViewRangeChange({ source, new : { startDate, endDate } }) {
        const { datePicker } = this.widgetMap;
        // If the source is an event list, and *not* being driven by a fixed range around a date
        // and if datePicker is selecting a range, then datePicker must match the view's range.
        if (source.isEventList && !source.range && datePicker?.multiSelect === 'range') {
            datePicker.selection = [startDate, endDate];
        }
    }
    onDatePickerSelectionChange({ source, selection : [startDate, endDate], userAction }) {
        this.UIdateChange = userAction;
        // If the datePicker is selecting a range, apply the range to ListView modes
        // which are in startDate->endDate mode as opposed to showing a fixed range.
        if (source.multiSelect === 'range') {
            this.up('calendar')?.eachView(v => {
                if (v.isEventList && !v.range) {
                    v.setConfig({
                        startDate,
                        endDate
                    });
                }
            });
        }
        this.date = startDate;
        this.UIdateChange = false;
    }
    changeDate(date, oldDate) {
        date = typeof date === 'string' ? DateHelper.parse(date) : new Date(date);
        if (isNaN(date)) {
            throw new Error('Calendar widget date ingestion must be passed a Date, or a YYYY-MM-DD date string');
        }
        date = DateHelper.clearTime(date);
        // Protect the setter from processing a no-change.
        if (!oldDate || (date - oldDate)) {
            return date;
        }
    }
    updateDate(date, oldDate) {
        const { datePicker } = this.widgetMap;
        datePicker && (datePicker.value = date);
        this.trigger('dateChange', {
            date,
            oldDate,
            userAction : Boolean(this.UIdateChange)
        });
    }
}
Sidebar.initClass();
Sidebar._$name = 'Sidebar';