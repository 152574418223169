import Base from '../../../Core/Base.js';
import DomClassList from '../../../Core/helper/util/DomClassList.js';
import DomHelper from '../../../Core/helper/DomHelper.js';
import StringHelper from '../../../Core/helper/StringHelper.js';
import DH from '../../../Core/helper/DateHelper.js';
/**
 * @module Calendar/widget/mixin/EventRenderer
 */
const emptyString = new String('');
/**
 * Mixin that can be used to generate DomConfig blocks for events.
 *
 * @mixin
 */
export default Target => class EventRenderer extends (Target || Base) {
    static $name = 'EventRenderer';
    static configurable = {
        /**
         * A function, or the name of a function in the ownership hierarchy which you
         * can specify to customize event DOM content.
         *
         * This function is called each time an event is rendered to allow developers to mutate
         * the cell metadata, or the CSS classes to be applied to the event element.
         *
         * It's called with the event record, and a eventData object which allows you to mutate event metadata
         * such as `cls`, `style`.
         *
         * The `cls` property is an object whose property names will be added to the event element if the property
         * value is truthy.
         *
         * The `style` property is an object containing style properties for the event element.
         *
         * A non-null return value from the renderer is used as the event body content. A nullish
         * return value causes the default renderer to be used which just uses the event name.
         *
         * If a string is returned, it is used as the HTML content of the event body element.
         *
         * If an object is returned, it is used as a {@link Core.helper.DomHelper#typedef-DomConfig} object to
         * create complex content in the event body element.
         *
         * ```javascript
         *  eventRenderer({ eventRecord, renderData }) {
         *      if (eventRecord.name === 'Doctors appointment') {
         *          eventData.style.fontWeight = 'bold';
         *          eventData.cls['custom-cls'] = 1;
         *
         *          return 'Special doctors appointment';
         *      }
         *  }
         * ```
         *
         * IMPORTANT: When returning content, be sure to consider how that content should be encoded to avoid XSS
         * (Cross-Site Scripting) attacks. This is especially important when including user-controlled data such as
         * the event's `name`. The function {@link Core.helper.StringHelper#function-encodeHtml-static} as well as
         * {@link Core.helper.StringHelper#function-xss-static} can be helpful in these cases.
         *
         * For example:
         *
         * ```javascript
         *  eventRenderer({ eventRecord }) {
         *      return StringHelper.xss`Event: ${eventRecord.name}`;
         *  }
         * ```
         *
         * @param {Object} detail An object that contains data about the event being rendered
         * @param {Calendar.widget.mixin.CalendarMixin} detail.view The view rendering the event
         * @typings detail.view -> {typeof CalendarMixin}
         * @param {Scheduler.model.EventModel} detail.eventRecord The event record
         * @param {Scheduler.model.ResourceModel} detail.resourceRecord The event record
         * @param {Object} detail.renderData A data object containing properties that will be used to create the event element
         * @param {Object} detail.renderData.style The style property is an object containing style properties for the event element
         * @param {Object} detail.renderData.cls The cls property is an object whose property names will be added to
         * the event element if the property value is truthy
         * @param {Object} detail.renderData.iconStyle The iconStyle property is an object containing style properties for
         * the icon element if an icon element is to be used
         * @param {Object} detail.renderData.iconCls The iconCls property is an object whose property names will be added to
         * the icon element. Initially set from the event record's {@link Scheduler.model.EventModel#field-iconCls}.
         * Can be mutated by the renderer. If null, or no properties are set, no icon will be rendered
         * @param {String} detail.renderData.eventColor Color to be applied to the event
         * @param {Object} detail.renderData.dataset An object which produces the `dataset` of the resulting event bar
         * @param {Boolean} detail.renderData.solidBar This is valid for views which create event bars.
         * This is set to `true` by default for all day and interday events so that these appear as a solid block of background color.
         * An eventRenderer may mutate this flag to change in what manner the event bar is coloured - as a solid bar of colour,
         * or using the foreground colour (text and icons) such as the MonthView, the CalendarRow (all day events in a DayView), and OverflowPopups
         * @param {String} detail.renderData.bodyColor When used in a {@link Calendar.widget.DayView},
         * this color is applied to the body of the event element. Note that this must be light enough
         * that the text colour (From the SASS variable `$dayview-event-color`) is visible
         * @param {Boolean} detail.showBullet If there is no `iconCls`, and the event is not recurring, then by default
         * a "bullet" circular icon is shown if the view's {@link Calendar.widget.mixin.CalendarMixin#config-showBullet}
         * if set. Setting this property in an event renderer overrides this behaviour.
         * @returns {DomConfig|DomConfig[]|String|null}
         * @config {Function|String}
         */
        eventRenderer : null,
        /**
         * If this is set to `true`, then when determining which assigned resource of a multi assigned event
         * to use to create the event UI, the first resource which is still selected in the
         * {@link Calendar.widget.Sidebar#property-resourceFilter} is used.
         *
         * The default is to use the first resource in the assigned list.
         *
         * The resource's {@link Scheduler.model.ResourceModel#field-eventColor} is used as the colour for the
         * event unless the event has its own {@link Scheduler.model.EventModel#field-eventColor}.
         *
         * The resource's {@link Scheduler.model.ResourceModel#field-eventStyle} is used as the base of the style
         * for the event, and the event's {@link Scheduler.model.EventModel#field-style} is applied to it.
         * @prp {Boolean}
         */
        filterEventResources : null,
        /**
         * A Function (or name of a function in the ownership hierarchy) which returns the
         * {@link Scheduler.model.ResourceModel resource record} to use to create the UI for an event.
         *
         * The resource's {@link Scheduler.model.ResourceModel#field-eventColor} is used as the colour for the
         * event unless the event has its own {@link Scheduler.model.EventModel#field-eventColor}.
         *
         * The resource's {@link Scheduler.model.ResourceModel#field-eventStyle} is used as the base of the style
         * for the event, and the event's {@link Scheduler.model.EventModel#field-style} is applied to it.
         *
         * For views which are linked to a single resource such as `ResourceView` and `DayResourceView`, the
         * default implementation always returns the view's resource.
         *
         * If multi assignment is used, the default is to pick the first resource in the assigned resource list.
         *
         * If this view's {@link #property-filterEventResources} is `true`,
         * then the first assigned event which is still filtered __in__ is chosen.
         *
         * An implementation of this function may be configured in to your {@link Calendar.view.Calendar#config-modes}
         * to customize how the resource is chosen from a multi assigned event.
         *
         * @config {Function|String} [getPrimaryResource]
         * @param {Scheduler.model.EventModel} eventRecord The event from which to extract the primary resource.
         * @returns {Scheduler.model.ResourceModel} The resource to be used to render the event.
         */
        getPrimaryResource({ resources }) {
            // If we are a view type which shows events only for one resource, choose that
            // as the primary resource for the event
            if (this.resource) {
                return this.resource;
            }
            // Normally, just pick the first resource.
            // If filterEventResources is set, we pick the first one which is still selected in the ResourceFilter
            if (resources.length) {
                const resourceFilter = this.filterEventResources && this.calendar?.widgetMap?.resourceFilter;
                return resourceFilter ? resources.find(r => resourceFilter.selected.includes(r)) : resources[0];
            }
        }
    };
    // Must refresh when renderer changes
    updateEventRenderer() {
        this.refreshSoon();
    }
    /**
     * This is the standard way to create a {@link Core.helper.DomHelper#typedef-DomConfig}
     * element definition object for creating event bars in all view types.
     *
     * This may be used by application code which needs to create DOM structure for event bars, such
     * as in custom cell renderers in an {@link Calendar.widget.AgendaView}.
     *
     * @param {Object} renderData Context for the event bar config creation.
     * @param {Scheduler.model.EventModel} renderData.eventRecord The event record to create a {@link DomConfig} block for.
     * @param {Boolean} renderData.minimal If this is set, no inner content is rendered, only
     * the wrap and body element. This is to enable rendering placeholders such as bullets
     * to represent the presence of events.
     * @param {Date} renderData.date The date to create th DOM config for.
     * @param {Date} [renderData.eventEndDate] An optional override to the event's ending date.
     * @param {Function} [eventRenderer] Optionally a function which created content HTML for the
     * event body. Defaults to any {@link #config-eventRenderer} configured into this view.
     * @returns {DomConfig} A {@link Core.helper.DomHelper#typedef-DomConfig} element definition object
     */
    createEventDomConfig(renderData, eventRenderer = this.eventRenderer) {
        const
            me = this,
            {
                eventHeight,
                intradayCls,
                alldayCls,
                solidBarCls,
                pastEventCls,
                showTime,
                timeFormat,
                rtl
            }               = me,
            calendar        = me.up('calendar'),
            {
                eventRecord,
                minimal
            }               = renderData,
            eventEndDate    = renderData.eventEndDate || eventRecord.endingDate,
            resourceRecord  = me.callback(me.getPrimaryResource, me, [eventRecord.isOccurrence ? eventRecord.recurringEvent : eventRecord]),
            isRecurring     = eventRecord.isRecurring || eventRecord.isOccurrence,
            isAllDay        = ('isAllDay' in renderData) ? renderData.isAllDay : me.isAllDayEvent?.(eventRecord),
            eventInnerStyle = {
                height : eventHeight !== 'auto' ? DomHelper.setLength(eventHeight) : null
            },
            eventSelectedCls = calendar?.eventSelectedCls,
            dataset          = renderData.dataset = {
                eventId : eventRecord.id
            },
            eventStyle       = Object.assign(DomHelper.parseStyle(resourceRecord?.eventStyle), DomHelper.parseStyle(eventRecord.style));
        let color = eventRecord.color || eventRecord.eventColor || resourceRecord?.eventColor || emptyString;
        // Make DomClassList copies for renderers to mutate.
        // We add our essential classes in after the renderer has run
        // then use these in the DomConfig object
        renderData.cls = eventRecord.cls.clone();
        renderData.solidBar = isAllDay || minimal;
        renderData.iconStyle = me.iconTarget === 'header' && me.showTime && !DomHelper.isNamedColor(color) ? { color } : {};
        renderData.iconCls = new DomClassList(eventRecord.iconCls); // Not a DomClassList, so not cloneable
        renderData.style = eventStyle;
        renderData.eventColor = color;
        renderData.eventHeight = eventHeight;
        renderData.cls[me.shortEventCls] = eventRecord.durationMS <= me.shortEventDuration;
        renderData.bodyStyle = {};
        if (resourceRecord?.cls) {
            renderData.cls.add(resourceRecord.cls);
        }
        // Allow subclasses to create body content differently.
        // DayView will create different content layout.
        let bodyContent    = me.internalBodyContentRenderer(eventRecord, resourceRecord, renderData, !minimal && eventRenderer) ?? '',
            complexContent = typeof bodyContent !== 'string';
        if (eventRenderer && !minimal) {
            // Allow renderer to change the event height
            if (renderData.eventHeight !== eventHeight) {
                eventInnerStyle.height = DomHelper.setLength(renderData.eventHeight);
            }
            // If the renderer has replaced the DomClassList with a string, promote back to a DomClassList
            if (typeof renderData.cls === 'string') {
                renderData.cls = new DomClassList(renderData.cls);
            }
            // Same goes for iconCls
            if (typeof renderData.iconCls === 'string') {
                renderData.iconCls = new DomClassList(renderData.iconCls);
            }
            // If the renderer set it to be a string, reinstate it as an object so we can add our essential styles
            if (typeof renderData.style === 'string') {
                renderData.style = DomHelper.parseStyle(renderData.style);
            }
            // If the renderer set it to be a string, reinstate it as an object so we can add our essential styles
            if (typeof renderData.bodyStyle === 'string') {
                renderData.bodyStyle = DomHelper.parseStyle(renderData.bodyStyle);
            }
        }
        bodyContent = [{
            className : {
                'b-cal-event-desc'         : 1,
                'b-cal-event-desc-complex' : complexContent
            },
            [complexContent ? 'children' : 'html'] : bodyContent
        }];
        // Add essential classes for eventWrap *after* the renderer has run
        Object.assign(renderData.cls, {
            'b-cal-event-wrap' : 1,
            'b-iscreating'     : eventRecord.isCreating,
            'b-readonly'       : eventRecord.readOnly,
            'b-minimal'        : minimal,
            'b-milestone'      : eventRecord.isMilestone && !isAllDay,
            [alldayCls]        : alldayCls && isAllDay,
            [solidBarCls]      : solidBarCls && renderData.solidBar,
            [intradayCls]      : intradayCls && !isAllDay,
            [pastEventCls]     : pastEventCls && eventEndDate < new Date(),
            [eventSelectedCls] : eventSelectedCls && calendar?.isEventSelected(eventRecord),
            'b-rtl'            : rtl
        });
        color = renderData.eventColor;
        const { bodyColor } = renderData;
        if (bodyColor?.length) {
            renderData.cls['b-custom-body-color'] = 1;
            renderData.bodyStyle['--dayview-body-background-color'] = bodyColor;
        }
        if (color?.length) {
            // DomHelper.createColorStyle takes Non-CSS colors to be one of the predefined colors
            eventStyle['--cal-event-color'] = DomHelper.createColorStyle(color);
        }
        // All day event *bars* don't have a header.
        // But DayView can now be configured to not show the allDayHeader which means allDay events
        // go into tye day schedule as normal intraday event elements which *do* have a header.
        if (showTime && !(isAllDay && this.showAllDayHeader)) {
            bodyContent.unshift({
                className : 'b-event-header',
                children  : [
                    {
                        className : 'b-event-time',
                        html      : DH.format(eventRecord.startDate, timeFormat)
                    }
                ]
            });
        }
        const
            // Presence of icon classes triggers inclusion of an icon
            hasIcon             = Boolean(renderData.iconCls?.length),
            useIconAsRecurrIcon = !hasIcon && isRecurring,
            showBullet          = ('showBullet' in renderData) ? renderData.showBullet : me.showBullet === true || ((me.showBullet?.bar && renderData.solidBar) || (me.showBullet?.noBar && !renderData.solidBar)),
            showCircle          = !hasIcon && !isRecurring && showBullet,
            iconElement         = {
                tag       : 'i',
                className : Object.assign({
                    'b-cal-event-icon'      : !useIconAsRecurrIcon,
                    'b-cal-recurrence-icon' : useIconAsRecurrIcon,
                    'b-icon'                : 1,
                    'b-fw-icon'             : 1,
                    'b-icon-circle'         : showCircle,
                    'b-icon-recurring'      : useIconAsRecurrIcon
                }, renderData.iconCls),
                style : renderData.iconStyle
            },
            eventInnerContent   = [{
                className : 'b-cal-event-body',
                children  : bodyContent,
                style     : renderData.bodyStyle
            }],
            iconParent          =
                me.iconTarget === 'header' && me.showTime
                    ? (bodyContent[0].children.length > 0 ? bodyContent[0].children : bodyContent)
                    : eventInnerContent;
        // Only insert the icon DOM if we are showing any kind of icon, either
        // from an iconCls spec, or a recurrence icon, or showing a bullet via the showBullet config.
        if (hasIcon || useIconAsRecurrIcon || showCircle) {
            if (me.iconTarget === 'header' && me.showTime) {
                iconParent.push(iconElement);
            }
            else {
                iconParent.unshift(iconElement);
            }
        }
        // If the event had its own icon and is recurring, the recurrence icon is extra
        if (hasIcon && isRecurring) {
            iconParent.push({
                tag       : 'i',
                className : {
                    'b-cal-recurrence-icon' : 1,
                    'b-icon'                : 1,
                    'b-fw-icon'             : 1,
                    'b-icon-recurring'      : 1
                },
                style : renderData.iconStyle
            });
        }
        return {
            // Events are tabbable unless minimally rendered (dots)
            tabIndex : minimal ? null : 0,
            dataset,
            className : renderData.cls,
            style     : renderData.style,
            children  : [{
                className : 'b-cal-event',
                style     : eventInnerStyle,
                children  : minimal ? null : eventInnerContent
            }]
        };
    }
    internalBodyContentRenderer(eventRecord, resourceRecord, renderData, eventRenderer) {
        const
            me                      = this,
            { showResourceAvatars } = me,
            defaultBodyContent      = eventRenderer && me.callback(eventRenderer, me, [{
                view : me,
                eventRecord,
                resourceRecord,
                renderData
            }]) ||
            // If creating a DayView event block, we inject <br> for newlines
            StringHelper[me.eventHeight === 'auto' ? 'encodeHtmlBR' : 'encodeHtml'](eventRecord.name);
        // Only attempt to show avatars if there is an assignment Set.
        // It has to share with defaultBodyContent, so we will be creating a children array.
        // showResourceAvatars may specify whether it goes before or after the default content
        if (showResourceAvatars && eventRecord.assigned?.size) {
            const
                content = [{
                    class    : 'b-cal-event-resource-avatars',
                    children : eventRecord.resources.map(resource => me.getResourceAvatar(resource))
                }],
                spliceArgs = [showResourceAvatars === 'last' ? 0 : 1, 0];
            if (eventRenderer) {
                // Assume it's HTML
                if (typeof defaultBodyContent === 'string') {
                    spliceArgs.push({
                        tag  : 'span',
                        html : defaultBodyContent
                    });
                }
                // Else it's DomConfig
                else {
                    spliceArgs.push[Array.isArray(defaultBodyContent) ? 'apply' : 'call'](spliceArgs, defaultBodyContent);
                }
            }
            else {
                spliceArgs.push({
                    class : 'b-cal-event-name',
                    text  : defaultBodyContent
                });
            }
            content.splice(...spliceArgs);
            return content;
        }
        // Just return whatever the renderer returned or the default textual event name
        else {
            return defaultBodyContent;
        }
    }
};
