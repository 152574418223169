import Base from '../../../Core/Base.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
import Month from '../../../Core/util/Month.js';
/**
 * @module Calendar/widget/mixin/DateRangeOwner
 */
const
    validRangeUnits = {
        day    : 1,
        week   : 1,
        month  : 1,
        year   : 1,
        decade : 1
    },
    compareRange = (r1, r2) => r1?.unit === r2?.unit && r1?.magnitude === r2?.magnitude;
/**
 * Mixin that provides the ability to collect encapsulate a range of dates specified by a
 * {@link #config-startDate} and a {@link #config-range}.
 *
 * @mixin
 */
export default Target => class DateRangeOwner extends (Target || Base) {
    static $name = 'DateRangeOwner';
    static configurable = {
        month : true,
        /**
         * Setting this property may change the encapsulated range if the date is outside the current
         * range.
         *
         * It also causes this view to scroll the view to the passed date, or closest date.
         * See {@link Calendar.widget.mixin.CalendarMixin#function-scrollTo}
         * @member {Date} date
         */
        /**
         * The date to orient this view's {@link #config-range} around.
         *
         * When using a {@link #config-range} of weeks, months, years or decades, the {@link #config-startDate} snaps
         * to the closest lower range boundary, and the end date snaps to the closest larger
         * range boundary.
         *
         * When using a {@link #config-range} of days, the {@link #config-startDate} is set to the passed date.
         * @config {Date}
         */
        date : {
            $config : {
                equal : 'date'
            },
            value : null
        },
        /**
         * The time range encapsulated by the current {@link #property-date}.
         *
         * When a range is used, changing the {@link #config-date} snaps the {@link #config-startDate}
         * to the closest starting date of the range. For Example if the range was configured as `'1 week'`
         * then setting the date to the date of next Wednesday would mean that the {@link #property-startDate}
         * would be the __start__ of next week, and an entire week would be encapsulated by this view.
         * @member {DurationConfig} range
         */
        /**
         * The time range around the {@link #config-date} to display events for.
         *
         * Valid values are:
         * - day
         * - week
         * - month
         * - year
         * - decade
         *
         * This may also be specified as a duration with a magnitude part and a unit part. For
         * example `'1m'` would mean one month, and `'4w'` would mean four weeks.
         * See {@link Core.helper.DateHelper#function-parseDuration-static} for details
         * of syntax.
         *
         * When using a range of weeks, months, years or decades, then when this widget's
         * {@link #config-date} is synced with its owning {@link Calendar.view.Calendar}'s
         * {@link Calendar.view.Calendar#property-date}, this widget's {@link #config-startDate}
         * is snapped to the closest start point of the range which encompasses that date.
         *
         * So if using `range : '1w'`, then setting the date to Thursday, 28th October 2021
         * Would mean that the `startDate` snaps to Sunday 24th October 2021 (assuming the locale
         * uses Sunday as the week start day).
         *
         * If configured to use a range of *days*, no snapping is done. There's no defined start point
         * so the {@link #config-startDate} is set to the incoming Calendar date.
         *
         * __Note:__ If an {@link #config-endDate} is specified, any range is ignored. The encompassed range
         * will be specified by the {@link #config-startDate} and {@link #config-endDate}, and when the
         * {@link #config-startDate} changes, the {@link #config-endDate} is changed to keep the duration
         * the same.
         * @config {String|DurationConfig}
         */
        range : {
            $config : {
                lazy  : true,
                equal : compareRange
            },
            value : null
        },
        /**
         * Gets the start date of the {@link #config-range} that this view covers.
         * @member {Date} startDate
         * @readonly
         */
        /**
         * The start date (Time component is zeroed) of this view.
         * @config {Date}
         */
        startDate : {
            $config : {
                equal : 'date'
            }
        },
        /**
         * Gets the end date of the {@link #config-range} that this view covers.
         * Note that Date objects are time points, not a representation of a 24 hour period,
         * So `{startDate : '2020-10-24', endDate : '2020-10-25' }` spans the __single__ day
         * 24th October 2020. The end point is `2020-10-25T00:00:00`
         * @member {Date} endDate
         * @readonly
         */
        /**
         * The end date (Time component is zeroed) of this view. Note that in terms of full days,
         * this is exclusive, ie: 2020-01-012 to 2020-01-08 is *seven* days. The end is 00:00:00 on
         * the 8th.
         *
         * __Note:__ This configuration takes precedence over any {@link #config-range} specified.
         * If used, the {@link #config-range} is ignored, and after configuration, the `endDate` is
         * locked to the {@link #config-startDate} when the {@link #config-startDate} is changed.
         * @config {Date}
         */
        endDate : {
            $config : {
                equal : 'date'
            }
        }
    };
    /**
     * Interface method used by an encapsulating Calendar view to implement the "prev" button.
     */
    previous() {
        const { range } = this;
        if (range) {
            this.date = DateHelper.add(this.date, -range.magnitude, range.unit);
        }
        else {
            this.startDate = DateHelper.add(this.startDate, -this.duration, 'day');
        }
    }
    /**
     * Interface method used by an encapsulating Calendar view to implement the "next" button.
     */
    next() {
        const { range } = this;
        if (range) {
            this.date = DateHelper.add(this.date, range.magnitude, range.unit);
        }
        else {
            this.startDate = DateHelper.add(this.startDate, this.duration, 'day');
        }
    }
    changeDate(date) {
        date = super.changeDate(date || this.startDate);
        if (this.isConfiguring || this.isValidRange(this.range, date)) {
            return date;
        }
    }
    updateDate(date) {
        const
            me           = this,
            {
                startDate,
                _month
            }            = me,
            newStartDate = me.changeStartDate(date),
            generation   = _month?.generation;
        // Move range so that it encapsulates the target date if necessary
        if (!startDate || (newStartDate - startDate)) {
            // Having an endDate configured takes precedence over a range.
            // Shift the range forward or back so that the target date is in
            if (me.hasConfig('endDate')) {
                const
                    { endDate, duration } = me,
                    // For EventLists, when we are using a startDate->endDate range as opposed to a fixed
                    // range, the dates are *inclusive*, so pick the correct date containment function.
                    dateContainmentFn = me.isEventList && !me.range ? 'betweenLesserEqual' : 'betweenLesser';
                if (!me.isConfiguring || !DateHelper[dateContainmentFn](date, startDate, endDate)) {
                    // Need to scroll left
                    if (!startDate || !endDate || date < startDate) {
                        me.startDate = date;
                    }
                    // Need to scroll right.
                    // EventList range is *inclusive*
                    else if (me.isEventList ? (date > endDate) : (date >= endDate)) {
                        me.startDate = DateHelper.add(date, -(duration - 1), 'day');
                    }
                }
            }
            // If there's no endDate, we MUST be configured with a range, so snap
            // the date to the closest range start.
            else {
                me.startDate = DateHelper.floor(date, me.range, me.startDate, me.weekStartDay);
            }
        }
        // If we have not already updated our month by setting startDate above
        // then update the month now.
        // We must only update it once because we react to month mutation to refresh the UI.
        if (_month && (_month.generation === generation)) {
            _month.date = date;
        }
        super.updateDate?.(...arguments);
    }
    changeStartDate(startDate, oldStartDate) {
        return super.changeStartDate(this.snapDate(this.ingestDate(startDate)), oldStartDate);
    }
    updateStartDate(startDate, oldStartDate) {
        const
            me               = this,
            {
                refreshCount,
                _month
            } = me;
        if (!me.date) {
            me.date = startDate;
        }
        if (_month) {
            _month.date = startDate;
        }
        // Some views inherit startDate
        super.updateStartDate?.(...arguments);
        // If we are bounded by an endDate configuration, but are not in the process of being passed
        // a new endDate (unless we are at configure time), keep the end date synced with current duration.
        if (me.hasConfig('endDate') && (!me.peekConfig('endDate') || this.isConfiguring)) {
            const duration = DateHelper.diff(oldStartDate || startDate, me.endDate, 'day');
            me.endDate = DateHelper.add(startDate, duration, 'day');
        }
        if (!me.isConfiguring) {
            // If that changed the end date, the updater will have done a refresh.
            // If there was no change to the endDate, so no refresh, we have to refresh here.
            if (me.refreshCount === refreshCount) {
                me._cellMap?.clear();
                me.refresh();
            }
        }
        me.triggerRangeChange(startDate, me.endDate);
    }
    updateEndDate(endDate) {
        super.updateEndDate?.(...arguments);
        this.triggerRangeChange(this.startDate, endDate);
    }
    triggerRangeChange(startDate, endDate) {
        const { lastRangeAnnounced } = this;
        if (!lastRangeAnnounced || (lastRangeAnnounced.startDate - startDate) || (lastRangeAnnounced.endDate - endDate)) {
            /**
             * Fired when the range of dates encapsulated by this view changes.
             *
             * This will be when initially configured with a {@link #config-startDate} and {@link #config-endDate},
             * and when moving a view in time by changing its {@link #property-date}, or its {@link #property-range},
             * or its {@link #property-startDate}, or its {@link #property-endDate}.
             *
             * This will happen when moving in time using the Calendar's previous and next
             * buttons in its {@link Calendar.view.Calendar#property-tbar}.
             * @event rangeChange
             * @param {Scheduler.view.TimelineBase} source This Scheduler/Gantt instance.
             * @param {Object} [old] The old date range __if any__.
             * @param {Date} old.startDate the old start date.
             * @param {Date} old.endDate the old end date.
             * @param {Object} new The new date range
             * @param {Date} new.startDate the new start date.
             * @param {Date} new.endDate the new end date.
             */
            this.trigger('rangeChange', {
                old : lastRangeAnnounced,
                new : this.lastRangeAnnounced = {
                    startDate,
                    endDate
                }
            });
        }
    }
    get range() {
        return this.hasConfig('endDate') ? null : this._range;
    }
    get endDate() {
        const me = this;
        return me.hasConfig('endDate')
            ? me._endDate
            : me.startDate && DateHelper.add(me.startDate, me.range.magnitude, me.range.unit);
    }
    // Snap the passed date to the start or end of our configured range block if we have one.
    snapDate(date, end) {
        const
            me = this,
            range = me._endDate || me.peekConfig('endDate') ? null : me.range;
        // If we have been configured with a range which needs snapping, snap the date to the required end
        return range && range.unit !== 'day' &&  date
            ? DateHelper[end ? 'ceil' : 'floor'](date, range, undefined, me.weekStartDay)
            : date;
    }
    changeRange(range) {
        if (range && !this.hasConfig('endDate')) {
            // '1d' or '1 day' or '4 weeks', '1десятилетие' etc.
            // We parse to an object.
            if (typeof range === 'string') {
                if (DateHelper.parseTimeUnit(range)) {
                    range = {
                        magnitude : 1,
                        unit      : range
                    };
                }
                else {
                    range = DateHelper.parseDuration(range);
                }
            }
            else if (typeof range === 'number') {
                return {
                    magnitude : range,
                    unit      : 'day'
                };
            }
            // range : '100ms' would be invalid.
            if (!validRangeUnits[range.unit]) {
                throw new Error('Range must be in days, weeks, months, years or decades');
            }
            // Veto invalid navigation
            if (this._date && !this.isValidRange(range)) {
                return;
            }
        }
        return range;
    }
    isValidRange(range, date = this.date) {
        const
            minDate = this.minDate || this.calendar?.minDate,
            maxDate = this.maxDate || this.calendar?.maxDate;
        // Only do date arithmetic if we need to.
        if (range && !isNaN(minDate) || !isNaN(maxDate)) {
            const newRange = this.calculateDateRange(range, date);
            if (!isNaN(minDate)) {
                // Veto navigation to before minDate.
                if (newRange.startDate < minDate) {
                    return false;
                }
            }
            if (!isNaN(maxDate)) {
                // Veto navigation to after maxDate.
                if (newRange.endDate > maxDate) {
                    return false;
                }
            }
        }
        return true;
    }
    updateRange(range) {
        const
            me       = this,
            { date } = me;
        // Change the start and end dates depending on the range size around the current date
        if (range && date && !me.hasConfig('endDate')) {
            // If we have a range, then endDate is derived
            me.startDate = date;
        }
    }
    calculateDateRange(range, date) {
        // Calculate the start and end dates depending on the range size around the requested date
        if (date) {
            // Only snap for units with definite start points
            if (range.unit !== 'day') {
                return {
                    startDate : DateHelper.floor(date, range, undefined, this.weekStartDay),
                    endDate   : DateHelper.ceil(DateHelper.add(date, 1, 'day'), range, undefined, this.weekStartDay)
                };
            }
            return {
                startDate : date,
                endDate   : DateHelper.add(date, range.magnitude, 'day')
            };
        }
    }
    changeMonth(month) {
        const
            me       = this,
            { date } = me;
        // MonthView, based on CalendarPanel has its own opinions
        if (super.changeMonth) {
            return super.changeMonth(...arguments);
        }
        if (!month?.isMonth) {
            month = new Month({
                date,
                weekStartDay       : me.weekStartDay,
                hideNonWorkingDays : me.hideNonWorkingDays,
                nonWorkingDays     : me.nonWorkingDays
            });
            if (me.nonWorkingDays == null) {
                me.nonWorkingDays = month.nonWorkingDays;
            }
            if (me.weekStartDay == null) {
                me.weekStartDay = month.weekStartDay;
            }
        }
        return month;
    }
    /**
     * Returns the range of included dates in the range as a two-element array, i.e., `[0]` is {@link #config-startDate}
     * and `[1]` is {@link #property-lastDate}.
     * @member {Date[]}
     * @internal
     */
    get dateBounds() {
        return [this.startDate, this.lastDate];
    }
    get duration() {
        const { range } = this;
        return range ? DateHelper.as('d', range.magnitude, range.unit) : super.duration;
    }
    /**
     * The last day that is included in the date range. This is different than {@link #config-endDate} since that date
     * is not inclusive. For example, an `endDate` of 2022-07-21 00:00:00 indicates that the time range ends at that
     * time, and so 2022-07-21 is _not_ in the range. In this example, `lastDate` would be 2022-07-20 since that is the
     * last day included in the range.
     * @member {Date}
     * @internal
     */
    get lastDate() {
        const lastDate = this.endDate;
        // endDate is "exclusive" because it means 00:00:00 of that day, so subtract 1
        // to keep description consistent with human expectations.
        return lastDate && DateHelper.add(lastDate, -1, 'day');
    }
};
