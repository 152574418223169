import CalendarFeature from './CalendarFeature.js';
import SchedulerScheduleMenu from '../../Scheduler/feature/ScheduleMenu.js';
/**
 * @module Calendar/feature/ScheduleMenu
 */
/**
 * A feature which adds a context menu to the unoccupied parts of calendar views.
 *
 * There is one predefined {@link #config-items item} provided by default:
 *
 * - `addEvent` Adds an event at the time point indicated by the click
 *
 * If the timepoint has only date-level granularity (Such as when clicking in a MonthView or
 * AgendaView, or YearView), the start time of the event will default to the configured
 * {@link Calendar.view.Calendar#config-autoCreate}'s `startHour` property.
 *
 * If the {@link Calendar.feature.EventEdit EventEdit} feature is enabled, the new event is passed for editing.
 *
 * {@inlineexample Calendar/feature/ScheduleMenu.js}
 *
 * The {@link #config-items} set can be reconfigured by providing an `items` property to this feature:
 *
 *```javascript
 * features : {
 *     scheduleMenu : {
 *         items : {
 *             // Knocks out the predefined addEvent item
 *             addEvent : null,
 *
 *             // Add our own custom item
 *             disableDate : {
 *                  icon    : 'b-fa b-fa-calendar-times',
 *                  text    : 'Mark date unavailable',
 *
 *                  // Will look up ownership chain and find the Calendar
 *                  handler : 'up.makeDateUnavailable'
 *             }
 *         }
 *     }
 * },
 * makeDateUnavailable({ date }) {
 *     // Pass to our own app logic
 *     this.disableDate(date);
 * }
 *```
 *
 * This feature is **enabled** by default.
 *
 * @demo Calendar/custom-menus
 * @extends Scheduler/feature/ScheduleMenu
 * @classtype scheduleMenu
 * @feature
 *
 * @typings Scheduler.feature.ScheduleMenu -> Scheduler.feature.SchedulerScheduleMenu
 */
export default class ScheduleMenu extends SchedulerScheduleMenu {
    static get $name() {
        return 'ScheduleMenu';
    }
    static get type() {
        return 'scheduleMenu';
    }
    getDataFromEvent(event) {
        const
            { client }     = this,
            { activeView } = client,
            // Calendar has a defaultCalendar property for defaulting the resource
            resourceRecord = client.resolveResourceRecord(event) || client.defaultCalendar;
        // Base class is SchedulerScheduleMenu
        if (activeView.isScheduler) {
            this.client = activeView;
        }
        const result = super.getDataFromEvent(event);
        // Only inject the resourceRecord as the Calendar's detected version if the superclass didn't find one.
        // We may be hosting a Scheduler as a view which has its own opinions on this.
        if (!result.resourceRecord) {
            result.resourceRecord = resourceRecord;
        }
        this.client = client;
        return result;
    }
    shouldShowMenu({ date, targetElement }) {
        const
            { client }     = this,
            { activeView } = client;
        if (activeView.isEventList && !activeView.isAgendaView) {
            return false;
        }
        // Base class is SchedulerScheduleMenu
        if (activeView.isScheduler) {
            this.client = activeView;
            const result = super.shouldShowMenu(...arguments);
            this.client = client;
            return result;
        }
        // We need a date and *not* an event element.
        return Boolean(date && !targetElement.closest(client.eventSelector));
    }
    populateScheduleMenu({ items, domEvent, date }) {
        super.populateScheduleMenu(...arguments);
        const
            { client }   = this,
            { addEvent } = items;
        if (addEvent) {
            addEvent.disabled = this.disableAddEvent;
            addEvent.onItem = () => {
                const resourceRecord = client.activeView.getResourceRecord(domEvent);
                // We must offer the preventable beforeAutoCreate event
                if (client.activeView.trigger('beforeAutoCreate', { domEvent, date, resourceRecord }) !== false) {
                    client.createEvent(date, resourceRecord);
                }
            };
        }
    }
    get disableAddEvent() {
        const { resourceRecord, date } = this.menuContext;
        return resourceRecord ? (resourceRecord.readOnly || !resourceRecord.isWorkingTime(date)) : false;
    }
    changeMenu() {
        const
            me     = this,
            result = super.changeMenu(...arguments);
        // Set the owner to be the currently active view (or its active OverflowPopup)
        // so that focus stays in that view's ownership tree.
        result?.ion({
            beforeShow({ source }) {
                const
                    { activeSubView }  = me.client,
                    { _overflowPopup } = activeSubView;
                source.owner = _overflowPopup?.containsFocus ? _overflowPopup : activeSubView;
            }
        });
        return result;
    }
}
// Register this feature type with its Factory
CalendarFeature.register(ScheduleMenu.type, ScheduleMenu, true);
ScheduleMenu._$name = 'ScheduleMenu';