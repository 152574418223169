import CalendarFeature from '../CalendarFeature.js';
import CalendarPrinter from './printer/CalendarPrinter.js';
import ObjectHelper from '../../../Core/helper/ObjectHelper.js';
import DomHelper from '../../../Core/helper/DomHelper.js';
import '../../../Grid/feature/export/Print.js';
/**
 * @module Calendar/feature/print/Print
 */
const emptyArray = Object.freeze([]);
/**
 * This feature enables printing of the current Calendar mode.
 *
 * ## Usage
 *
 * ```javascript
 * const calendar = new Calendar({
 *     features : {
 *         print : true
 *     }
 * });
 *
 * // Simple print
 * calendar.print();
 * ```
 *
 * This feature is **disabled** by default.
 *
 * @demo Calendar/basic
 * @classtype print
 * @feature
 * @typings Grid.feature.export.Print -> Grid.feature.export.GridPrint
 * @typings Scheduler.feature.export.Print -> Scheduler.feature.export.SchedulerPrint
 */
export default class Print extends CalendarFeature {
    static get $name() {
        return 'Print';
    }
    static get type() {
        return 'print';
    }
    static get configurable() {
        return {
            /**
             * Config for printer.
             * @config {Object}
             * @private
             */
            printer : {
                $config : ['lazy', 'nullify'],
                value   : {
                    type : 'calendar'
                }
            },
            /**
             * By default, a {@link Calendar.widget.MonthView} is rendered with the week rows
             * shrinkwrapping their event content. That is, rows with no events are small, and rows
             * containing events are expanded to make all events visible.
             *
             * To duplicate the on-screen appearance in terms of week row heights where empty rows
             * are flexed to consume an equal amount of height, configure this as `true`.
             * @config {Boolean}
             * @default
             */
            wysiwyg : false,
            /**
             * The function to use to create the [&lt;title&gt;](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/title) element for the print document.
             * Defaults to using the {@link Scheduler.view.mixin.Describable#config-descriptionRenderer}
             * of the active view. Note that this does not process HTML source.
             *
             * @config {Function|String}
             * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
             * @typings source -> {typeof CalendarMixin}
             * @returns {String}
             * @default
             */
            titleRenderer : activeView => activeView.description,
            /**
             * Map of printer classes to use in print feature
             * @config {Map}
             * @private
             * @default
             */
            printers : new Map([[CalendarPrinter.type, CalendarPrinter]]),
            /**
             * This config is passed to the Grid print feature. Print dialog is ignored, instead Grid is printed with
             * best default configs.
             * @config {Object}
             * @private
             */
            gridPrintConfig : null
        };
    }
    construct() {
        super.construct(...arguments);
        this.client.print = this.print.bind(this);
    }
    changePrinter(config) {
        const
            me          = this,
            printersMap = me.printersMap || (me.printersMap = new Map());
        if (config) {
            let printer;
            const { type } = config;
            if (printersMap.has(type)) {
                printer = printersMap.get(type);
                printer.setConfig(config);
            }
            else {
                const printerClass = me.printers.get(type);
                if (!printerClass) {
                    throw new Error(`Printer type ${type} is not found. Make sure you've configured it`);
                }
                config = ObjectHelper.clone(config);
                config.owner = me;
                printer = new printerClass(config);
                printer.relayAll(me);
                printersMap.set(type, printer);
            }
            return printer;
        }
        else {
            printersMap.forEach(printer => printer.destroy());
        }
    }
    get bodyClasses() {
        if (this.shrinkwrapRowHeights) {
            return ['b-shrinkwrap-row-heights'];
        }
        return emptyArray;
    }
    /**
     * Prints the current active view of the Calendar
     * @privateparam config A config object for the Printer
     */
    async print(config = {}) {
        const
            me = this,
            {
                client,
                printer
            }  = me,
            iframeId = `${client.id}-print-iframe`;
        /**
         * Fires on the owning Calendar before print starts. Return `false` to cancel the print.
         * @event beforePrint
         * @on-owner
         * @preventable
         * @param {Object} config Print config
         */
        if (client.trigger('beforePrint', { config, printer }) !== false) {
            if (client.activeView.isEventList) {
                await client.activeView.print(ObjectHelper.assign({
                    exporterType           : 'multipagevertical',
                    useRenderedColumnWidth : true
                }, this.gridPrintConfig || {}));
                client.trigger('print');
            }
            else {
                config.client = client;
                const pages = await me.printer.export(config);
                if (config.debug) {
                    client.trigger('print', pages);
                }
                else {
                    // Remove existing iframe if it's there.
                    // Safari can't wait until after print has finished, so it does not remove iframe after print
                    document.getElementById(iframeId)?.remove();
                    const iframe = DomHelper.createElement({
                        id     : iframeId,
                        class  : 'b-calendar-print-iframe',
                        tag    : 'iframe',
                        parent : document.body
                    });
                    iframe.contentWindow.removeElement = () => {
                        iframe.remove();
                        /**
                         * Fires when print has finished
                         * @event print
                         */
                        client.trigger('print', pages);
                    };
                    iframe.contentWindow.document.write(pages[0].html);
                    iframe.contentWindow.document.close();
                    return pages;
                }
            }
        }
    }
}
Print.initClass();
Print._$name = 'Print';