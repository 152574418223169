import CalendarFeature from './CalendarFeature.js';
import FunctionHelper from '../../Core/helper/FunctionHelper.js';
import DomHelper from '../../Core/helper/DomHelper.js';
/**
 * @module Calendar/feature/WeekExpander
 */
/**
 *
 * A Feature which, when present, adds a week row expansion tool to week rows in
 * {@link Calendar.widget.MonthView MonthView}s so that a row which contains overflowing cells
 * can be expanded to show all events with no overflow.
 *
 * ```javascript
 * const calendar = new Calendar({
 *     appendTo : 'container',
 *
 *     // Start life looking at this date
 *     date : new Date(2020, 9, 12),
 *
 *     features : {
 *         weekExpander : true
 *     },
 *
 *     // CrudManager arranges loading and syncing of data in JSON form from/to a web service
 *     crudManager : {
 *         transport : {
 *             load : {
 *                 url : 'data/data.json'
 *             }
 *         },
 *         autoLoad : true
 *     }
 * });```
 *
 * <div class="note">
 * *Expanding* a week row using this feature may cause the MonthView to have vertical overflow.
 * </div>
 *
 * <div class="note">
 * When combined with  {@link Calendar.widget.MonthView#config-autoRowHeight}, *collapsing* a
 * week row sets the `autoRowHeight` property to `false`. Detect this state change using the
 * {@link Calendar.widget.MonthView#event-weekFlex} event.
 * </div>
 *
 * This feature is **disabled** by default
 * @extends Calendar/feature/CalendarFeature
 * @classtype weekExpander
 * @feature
 */
export default class WeekExpander extends CalendarFeature {
    static get $name() {
        return 'WeekExpander';
    }
    static get type() {
        return 'weekExpander';
    }
    static get pluginConfig() {
        return {
            chain : ['render']
        };
    }
    render() {
        const
            { client } = this,
            monthView  = this.monthView = client.isMonthView ? client : client.modes.month;
        if (monthView) {
            const { weekRenderer } = this;
            monthView.showWeekColumn = true;
            monthView.weekRenderer = monthView.weekRenderer ? FunctionHelper.createSequence(monthView.weekRenderer, weekRenderer) : weekRenderer;
            monthView.ion({
                weeknumberclick : 'onWeekNumberClick',
                thisObj         : this,
                // We must get in first so we can veto the switch to week view if we toggle a row.
                prio : 10000
            });
        }
    }
    weekRenderer(parent, [year, week]) {
        if (!parent.querySelector('.b-week-toggle-tool-wrap')) {
            DomHelper.createElement({
                parent,
                className : 'b-week-toggle-tool-wrap',
                children  : [{
                    className : 'b-icon b-week-toggle-tool',
                    dataset   : {
                        btip : this.L('L{WeekExpander.expandTip}')
                    }
                }]
            });
        }
    }
    onWeekNumberClick({ domEvent, date }) {
        const toggleTool = domEvent.target.closest('.b-week-toggle-tool');
        if (toggleTool && !this.disabled) {
            const weekRow = domEvent.target.closest('.b-calendar-row');
            // Shrinkwrapped means the row is expanded, toggle it to collapsed
            if (weekRow.classList.contains('b-shrinkwrapped')) {
                this.monthView.flexWeekRow(date, true);
                toggleTool.dataset.btip = this.L('L{expandTip}');
            }
            // And if it's collapsed, toggle it to expanded
            else if (weekRow.classList.contains('b-has-overflow')) {
                this.monthView.shrinkwrapWeekRow(date);
                toggleTool.dataset.btip = this.L('L{collapseTip}');
            }
            return false;
        }
    }
    updateDisabled(disabled, was) {
        super.updateDisabled(disabled, was);
        // Remove class when disabled. Eliminates toggling UI.
        this.client.element.classList.toggle('b-weekexpander', !disabled);
    }
}
// Register this feature type with its Factory
WeekExpander.initClass();
WeekExpander._$name = 'WeekExpander';