import Base from '../../Core/Base.js';
import CalendarFeature from './CalendarFeature.js';
import DH from '../../Core/helper/DateHelper.js';
import DomSync from '../../Core/helper/DomSync.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import EventHelper from '../../Core/helper/EventHelper.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
import DomClassList from '../../Core/helper/util/DomClassList.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import StringHelper from '../../Core/helper/StringHelper.js';
import Draggable from '../../Core/mixin/Draggable.js';
import Droppable from '../../Core/mixin/Droppable.js';
import Hoverable from '../../Core/mixin/Hoverable.js';
import Widget from '../../Core/widget/Widget.js';
import RecurrenceConfirmationPopup from '../../Scheduler/view/recurrence/RecurrenceConfirmationPopup.js';
import CalendarZone from './CalendarZone.js';
import '../../Core/util/drag/DragTipProxy.js';
import '../widget/EventTip.js';
/**
 * @module Calendar/feature/CalendarDrag
 */
/**
 * An immutable object that describes a calendar drag mode. These objects are used to simplify detecting the drag mode
 * to apply appropriate actions.
 *
 * @typedef {Object} CalendarDragMode
 * @property {'create'|'move'|'resize'} type The value `'create'`, `'move'`, or `'resize'`.
 * @property {Boolean} create The value `true` if `type === 'create'`, otherwise `false`.
 * @property {Boolean} move The value `true` if `type === 'move'`, otherwise `false`.
 * @property {Boolean} resize The value `true` if `type === 'resize'`, otherwise `false`.
 */
const
    tentativeCls      = 'b-cal-tentative-event',
    SECONDS           = 1000,
    MINUTES           = 60 * SECONDS,
    YYYY_MM_DD        = 'YYYY-MM-DD',
    edgeRe            = /^b-hover-(top|right|bottom|left)$/,
    eventDragSym      = Symbol('eventDragMode'),
    appendEventFooter = (domConfig, footer) => {
        let ret;
        if (domConfig.className === 'b-cal-event-body') {
            domConfig.children.push(ret = footer);
        }
        else if (Array.isArray(domConfig.children)) {
            domConfig.children.forEach(c => {
                if (!ret && c) {
                    ret = appendEventFooter(c, footer);
                }
            });
        }
        return ret;
    },
    makeMode          = type => Object.freeze({
        type,
        create : false,
        move   : false,
        resize : false,
        [type] : true
    }),
    modeDescriptor    = {
        create : {
            finisher    : 'finishDropCreate',
            mode        : makeMode('create'),
            validatorFn : 'validateCreateFn'
        },
        move : {
            finisher    : 'finishDropMove',
            mode        : makeMode('move'),
            validatorFn : 'validateMoveFn'
        },
        resize : {
            finisher    : 'finishDropResize',
            mode        : makeMode('resize'),
            validatorFn : 'validateResizeFn'
        }
    },
    isAllDayView      = {
        calendarrow            : true,
        monthview              : true,
        dayresourcecalendarrow : true
    };
/*
 Base class for all zones, however, not all zones support all capabilities. DayView and WeekView are all of these
 things. Their events can be dragged between days, resized to change times of day, and an event can be dropped on
 to the calendar cells. While the YearView does not have events presented for dragging and so is not actually
 draggable, it is droppable since an event can be dropped on a day cell. MonthView is draggable and droppable,
 however, it is not hoverable since its events cannot be "resized". Finally, events in the OverflowPopup can only
 be dragged.
 */
class Zone extends CalendarZone.mixin(Draggable, Droppable, Hoverable) {
    static get $name() {
        return 'Zone';
    }
    static get configurable() {
        return {
            // We limit dropping to all *children* of the Droppable, not the Droppable element itself.
            // So that we avoid triggering "over" or "drop" on borders which would give incorrect
            // positional calculations.
            droppableSelector : '*',
            droppable : true,
            hoverable : null,
            hoverAnimationCls : 'b-hover-anim',
            days : {
                $config : {
                    // DayResourceView includes the resource as part of the days config.
                    equal : (d1, d2) => ObjectHelper.isEqual(d1, d2) && d1?.resource === d2?.resource
                },
                value : null
            },
            dragProxy : {
                type    : 'tip',
                tooltip : null  // borrowed from the Feature instance's tooltip config
            },
            eventRecord : null,
            hit : null,
            dragItemSelector : '.b-cal-event-wrap',
            overflow : {
                $config : 'nullify',
                value : null
            },
            rootElement : null
        };
    }
    get dayTime() {
        return this.view?.dayTime;
    }
    clearTime(date) {
        return this.dayTime.startOfDay(date);
    }
    findRootElement(view) {
        return view.contentElement;
    }
    getDateFromPosition(clientX, clientY) {
        return this.view.getDateFromPosition(clientX, clientY, false, this.view.dayTime);
    }
    // Draggable behaviors
    get dragEventer() {
        return this.owner;
    }
    beforeDrag(drag) {
        const
            me          = this,
            { owner }   = me,
            hit         = me.hitTest(drag),
            eventRecord = hit?.eventRecord,
            insetProp   = me.view.rtl ? 'right' : 'left';
        if (!hit || eventRecord?.readOnly || eventRecord?.isCreating) {
            return false;
        }
        hit.date && drag.set('date', hit.date);
        hit.resource && drag.set('resource', hit.resource);
        let mode, veto;
        switch (hit.type) {
            case 'event':
                drag.set('eventRecord', eventRecord);
                drag.set('eventSourceHit', hit);
                drag.set('eventInset', hit.eventElement.style[insetProp]);
                drag.set('eventWidth', hit.eventElement.offsetWidth);
                mode = 'move';
                veto = !owner.draggable || !eventRecord.isDraggable || me.draggable === false;
                if (!veto) {
                    me.captureDragOffset(eventRecord, hit, drag);
                }
                break;
            case 'resize':
                drag.set('eventRecord', eventRecord);
                drag.set('eventSourceHit', hit);
                drag.set('eventInset', hit.eventWrap.style[insetProp]);
                drag.set('eventWidth', hit.eventWrap.offsetWidth);
                mode = 'resize';
                veto = !owner.resizable || !eventRecord.resizable || me.resizable === false;
                break;
            case 'schedule':
            case 'dayNumber':
                drag.set('eventCreate', hit);
                mode = 'create';
                veto = !owner.creatable || me.creatable === false;
                break;
            default:
                return false;
        }
        if (veto) {
            return false;
        }
        drag.set('eventDragMode', drag[eventDragSym] = modeDescriptor[mode].mode);
    }
    dragStart() {
        const
            me                               = this,
            { dragging : drag, owner, view } = me,
            { client }                       = owner,
            callback                         = () => {
                drag.lastMoveEvent && drag.move(drag.lastMoveEvent);
            },
            config                           = {
                scrollables : client.scrollManager ? [
                    {
                        element : client.viewContainer.element,
                        callback
                    }
                ] : []
            };
        // NOTE: Adding b-resizing kicks in display:none which focuses the <body>, so capture it first:
        if (me.isResizing || me.isMoving) {
            me.refocus = owner.client.captureFocus();
        }
        if (me.isResizing) {
            drag.itemElement?.classList.add('b-resizing');
        }
        if (view.scrollable) {
            config.scrollables.push({
                element : view.scrollable.element,
                callback
            });
        }
        // DayViews may have an extra scroller
        if (view.horizontalScroller) {
            config.scrollables.push({
                element : view.horizontalScroller.element,
                callback
            });
        }
        client.scrollManager?.startMonitoring(config);
    }
    captureDragOffset() {
        // empty by default
    }
    cleanupDrag() {
        const
            me                      = this,
            { dragProxy, dragging } = me,
            { tooltip }             = dragProxy,
            view                    = me.view.isOverflowPopup ? me.view.owner : me.view,
            { client }              = me.owner,
            activeElement           = DomHelper.getActiveElement(client);
        dragging?.itemElement?.classList.remove('b-resizing');
        if (dragging?.aborted) {
            me.days = null;
        }
        // Do not refocus immediately. The UI will not be in its new state.
        // Refocus after the impending refresh.
        view.afterRefresh(() => {
            me.eventRecord && (me.days = null);  // day view resize uses days/times as drag feedback
            // Try to refocus element without scrolling unless focus has moved since the drop.
            if (DomHelper.getActiveElement(client) === activeElement) {
                me.refocus?.(false, true);
            }
            me.refocus = null;
        });
        if (tooltip) {
            tooltip.hide();
            dragProxy.tooltip = null;
        }
    }
    dragEnd() {
        this.owner.client?._scrollManager?.stopMonitoring();
        this.cleanupDrag();
    }
    makeDays(startDate, endDate) {
        const
            { dayTime, firstVisibleDate, lastVisibleDate } = this.view,
            days = [];
        endDate = DH.add(endDate, -1, 'd');  // endDate is exclusive, but lastVisibleDate is inclusive
        [startDate, endDate] = [startDate, endDate].map(
            d => d < firstVisibleDate ? firstVisibleDate : (lastVisibleDate < d ? lastVisibleDate : d));
        let date = dayTime.startOfDay(startDate);
        // We do ceil(endDate) to handle events that start/end on the same day
        // Note: "!(end < date)" ==> "date <= end" but works for Date since they are never ==
        for (const end = dayTime.ceil(endDate); !(end < date); date = DH.add(date, 1, 'day')) {
            days.push(dayTime.dateKey(date));
        }
        return days;
    }
    onShowOverflowPopup({ overflowPopup }) {
        if (!this.overflow) {
            this.overflow = new OverflowZone({
                owner : this.owner,
                view  : overflowPopup
            });
        }
    }
    // Droppable behaviors
    get calendarCellSelector() {
        return this.view.visibleCellSelector;
    }
    get calendarCells() {
        const container = this.dropRootElement;
        return container && DomHelper.children(container, this.calendarCellSelector);
    }
    get isCreating() {
        return this.dropping?.[eventDragSym]?.create;
    }
    get isMoving() {
        const mode = this.dropping?.[eventDragSym] || this.dragging?.[eventDragSym];
        return mode ? mode.move : Boolean(this.dropping?.peek('eventRecord'));
    }
    /**
     * Returns true if a resize operation is active
     * @property {Boolean}
     * @readonly
     */
    get isResizing() {
        return this.dragging?.[eventDragSym]?.resize;
    }
    get recurrable() {
        return this.isDayZone || this.isMonthZone;
    }
    cleanupDrop() {
        this.eventRecord = this.eventDom = this.days = null;
        this.noTip = false;
    }
    createEvent(data, dropping = this.dropping) {
        // We can get here for an AllDay zone when the day detail zone is creating a multi-day event, but that's the
        // only time we won't be processing our own drop.
        const mode = dropping?.[eventDragSym] || modeDescriptor.create.mode;
        this.setupEvent(data, mode.create);
    }
    async dragDrop(drag) {
        if (this.isResizing || drag.target === this) {
            // finalizer is a Promise that the DragContext (which is a Finalizable) awaits in its finalize() method.
            return drag.finalizer = this.finishDrop(drag);
        }
    }
    dragEnter(drag) {
        const
            me   = this,
            mode = drag[eventDragSym],
            hit  = (drag.source === me && drag.peek('eventSourceHit')) || me.hitTest(drag);
        if (me.isMoving) {
            // we need to know the hit target at all times during drag, but we're not ready to process updateHit until
            // we get past setupEvent
            me._hit = hit;
            me.startMove(drag.peek('eventRecord'));
        }
        else if (!mode || drag.source !== me) {
            return false;
        }
        else if (mode.resize) {
            me._hit = hit;
            me.startResize(hit);
        }
        else if (mode.create) {
            me._hit = hit;
            me.startCreate(drag.peek('date'), drag.peek('eventCreate'));
        }
        else {
            return false;
        }
    }
    dragLeave() {
        if (!this.isResizing) {
            this.cleanupDrop();
        }
    }
    dragMove(drag) {
        this.hit = this.pickDropTarget(drag);  // see updateHit for side effects
    }
    dropHitMove(drag, hit, eventRecord) {
        const
            me                     = this,
            { endDate, startDate } = eventRecord.isScheduled ? eventRecord : me.eventRecord,
            durationSec            = DH.diff(startDate, endDate, 's');
        let { date, resource } = hit;
        date = me.clearTime(date);
        if (!eventRecord.allDay) {
            date = DH.add(date, DH.diff(me.clearTime(startDate), startDate, 's'), 's');
        }
        date = me.applyDragOffset(date, drag);
        const data = {
            startDate : date,
            endDate   : DH.add(date, durationSec, 's')
        };
        // If this is a resource-specific view, hit testing will include the resource.
        if (resource?.eventColor) {
            data.eventColor = resource.eventColor;
        }
        me.setEventData(data);
    }
    dropHitNowhere() {
        this.days = null;
    }
    dropHitResize(drag, hit, eventHit) {
        const
            me                     = this,
            { eventRecord }        = me,
            { startDate, endDate } = eventRecord,
            date                   = me.clearTime(hit.date);
        let changes, end;
        if (eventHit.atEnd) {
            end = DH.add(date, 1, 'd');
            changes = {
                startDate,
                endDate : (startDate < end) ? end : DH.add(startDate, 1, 'd')
            };
        }
        else {
            changes = {
                startDate : (date < endDate) ? date : DH.add(endDate, -1, 'd'),
                endDate
            };
        }
        changes.duration = DH.diff(changes.startDate, changes.endDate, eventRecord.durationUnit);
        me.setEventData(changes);
    }
    async finishDrop(drag) {
        const
            me             = this,
            {
                eventRecord,
                resource,
                owner,
                view
            }              = me,
            { eventStore } = view,
            mode           = drag[eventDragSym],
            descriptor     = modeDescriptor[mode?.type || 'move'];
        if (descriptor && me.hit) {
            const validation = descriptor.validatorFn
                ? await owner.callback(owner[descriptor.validatorFn], owner, [{
                    drag,
                    eventRecord,
                    event : drag.event
                }])
                : true;
            if (validation !== false) {
                await me[descriptor.finisher](drag, owner, eventRecord, eventStore, validation,
                    async(eventName, callback, callbackFalse) => {
                        let info = {
                            drag,
                            eventRecord    : drag.peek('eventRecord') || eventRecord,
                            newStartDate   : eventRecord.startDate,
                            newEndDate     : eventRecord.endDate,
                            resourceRecord : resource,
                            validation,
                            event          : drag.event,
                            feature        : owner,
                            view           : drag[me.isResizing ? 'source' : 'target'].view
                        };
                        // First trigger a preventable beforeXXX event to allow outside world to veto this operation
                        const result = await owner.client.trigger('before' + StringHelper.capitalize(eventName), info);
                        if (result === false) {
                            info = false;
                            await callbackFalse?.();
                        }
                        else {
                            await callback?.();
                            delete info.newStartDate;
                            delete info.newEndDate;
                            owner.client.trigger(eventName, info);
                        }
                        // return the event info object to allow event handlers to return data by poking on to that
                        // object.
                        return info;
                    });
            }
        }
        me.isFinishing = true;
        me.cleanupDrop();
    }
    async finishDropCreate(drag, owner, eventRecord, eventStore, validation, triggerFn) {
        const add = validation?.add !== false;
        /**
         * This event fires on the owning Calendar before a drag creation gesture is completed. Return `false` to
         * immediately veto the operation or a Promise yielding `true` or `false` for async vetoing.
         * @event beforeDragCreateEnd
         * @preventable
         * @on-owner
         * @async
         * @param {Calendar.view.Calendar} source The Calendar instance that fired the event.
         * @param {Core.util.drag.DragContext} drag The drag create context.
         * @param {Event} event The browser event.
         * @param {Scheduler.model.EventModel} eventRecord The `EventModel` record being created that has not yet been added in the store.
         * @param {Date} newStartDate The new start date.
         * @param {Date} newEndDate The new end date.
         * @param {Scheduler.model.ResourceModel} [resourceRecord] The `ResourceModel` record if the gesture was performed
         * in a resource-type view.
         * @param {Calendar.feature.CalendarDrag} feature The Calendar drag feature instance.
         * @param {Boolean|ValidateCreateResult} validation The result of the {@link #config-validateCreateFn} if one
         * was provided.
         * @param {Core.widget.Widget} view The Calendar widget in which the drag completed.
         */
        /**
         * This event fires on the owning Calendar when a drag creation gesture is completed.
         * @event dragCreateEnd
         * @on-owner
         * @param {Calendar.view.Calendar} source The Calendar instance that fired the event.
         * @param {Core.util.drag.DragContext} drag The drag create context.
         * @param {Event} event The browser event.
         * @param {Scheduler.model.EventModel} eventRecord The new `EventModel` record added in the store.
         * @param {Scheduler.model.ResourceModel} [resourceRecord] The `ResourceModel` record if the gesture was performed
         * in a resource-type view.
         * @param {Calendar.feature.CalendarDrag} feature The Calendar drag feature instance.
         * @param {Boolean|ValidateCreateResult} validation The result of the {@link #config-validateCreateFn} if one
         * was provided.
         * @param {Core.widget.Widget} view The Calendar widget in which the drag completed.
         */
        // If any handler was async, the promise will be returned.
        // If not presented with a Promise, await yields the immediate value.
        const result = await triggerFn('dragCreateEnd');
        if (result === false) {
            if (add) {
                eventStore.remove(eventRecord);
            }
        }
        else if (add && !eventStore.includes(eventRecord)) {
            eventStore.add(eventRecord);
        }
    }
    async finishDropMove(drag, owner, eventRecord, eventStore, validation, triggerFn) {
        let dropRec = await drag.get('eventRecord');
        const
            me           = this,
            { view }     = me,
            storeRec     = dropRec.isOccurrence ? dropRec.recurringTimeSpan : dropRec,
            { source }   = drag,
            fromResource = await drag.get('resource'),
            toResource   = me.hit.resource,
            interView    = source !== me,
            // If the sourceStore is not the same as the destination store, maybe they would like the
            // record to be removed on successful drop depending on how removeFromExternalStore is set.
            sourceStore  = interView && source.view?.eventStore && view.eventStore && source.view.eventStore !== view.eventStore ? source.view.eventStore : await drag.get('sourceStore'),
            isReassign   = toResource && toResource !== fromResource;
        // Return if we detect that it's a no-op.
        if (!isReassign && drag.source === drag.target &&
                DH.isEqual(eventRecord.startDate, dropRec.startDate) &&
                DH.isEqual(eventRecord.endDate, dropRec.endDate)) {
            if (dropRec.eventStore === eventStore) {
                return;
            }
        }
        /**
         * This event fires on the owning Calendar before a drag move gesture is completed. Return `false` to immediately veto the operation
         * or a Promise yielding `true` or `false` for async vetoing.
         * @event beforeDragMoveEnd
         * @preventable
         * @on-owner
         * @async
         * @param {Calendar.view.Calendar} source The Calendar instance that fired the event.
         * @param {Core.util.drag.DragContext} drag The drag create context.
         * @param {Event} event The browser event.
         * @param {Scheduler.model.EventModel} eventRecord The `EventModel` record that has not yet been updated in the store.
         * @param {Date} newStartDate The new start date.
         * @param {Date} newEndDate The new end date.
         * @param {Scheduler.model.ResourceModel} [resourceRecord] The `ResourceModel` record if the gesture was performed
         * in a resource-type view.
         * @param {Calendar.feature.CalendarDrag} feature The Calendar drag feature instance.
         * @param {Boolean|ValidateCreateResult} validation The result of the {@link #config-validateMoveFn} if one was
         * provided.
         * @param {Core.widget.Widget} view The Calendar widget in which the drag completed.
         */
        /**
         * This event fires on the owning Calendar when a drag move gesture is completed. The `eventRecord` has already been added
         * to the `eventStore` of the owning calendar.
         * @event dragMoveEnd
         * @on-owner
         * @param {Calendar.view.Calendar} source The Calendar instance that fired the event.
         * @param {Core.util.drag.DragContext} drag The drag create context.
         * @param {Event} event The browser event.
         * @param {Scheduler.model.EventModel} eventRecord The updated `EventModel` record.
         * @param {Scheduler.model.ResourceModel} [resourceRecord] The `ResourceModel` record if the gesture was performed
         * in a resource-type view.
         * @param {Calendar.feature.CalendarDrag} feature The Calendar drag feature instance.
         * @param {Boolean|ValidateCreateResult} validation The result of the {@link #config-validateMoveFn} if one was
         * provided.
         * @param {Core.widget.Widget} view The Calendar widget in which the drag completed.
         */
        await triggerFn('dragMoveEnd', async() => {
            // It's a drag from an external EventStore.
            // If a handler sets isCopy in the data, we add the
            // tentative event as a copy of the original.
            if (!eventStore.getByInternalId(storeRec.internalId)) {
                if (sourceStore) {
                    // Remove from the source store if Feature is configured to do so.
                    if (me.owner.removeFromExternalStore) {
                        sourceStore.remove(dropRec);
                    }
                    // If we are keeping the source record in the external store, then
                    // the instance in this store has to be a copy so that dragging it in
                    // multiple times will create multiple events in the Calendar.
                    else {
                        dropRec = dropRec.copy();
                    }
                    // Move to the dropped at date and time
                    await me.moveEventTo(drag, dropRec);
                }
                else {
                    // Use the tentative event which is being used as the drop indicator.
                    // As long as it has a duration, its start and end will be correct.
                    dropRec = eventRecord;
                }
                delete dropRec.resourceId;
                eventStore.add(dropRec);
                // If there's no explicit toResource read from the hit, then assign the Calendar's default
                // calendar (resource) unless the record is already assigned to a valid resource
                // in our Project
                const resource = toResource ?? (!isReassign && !view.project.resourceStore.includes(dropRec.resource) && me.owner.client.defaultCalendarId);
                if (resource != null && view.project.resourceStore.includes(resource)) {
                    dropRec.assign(resource);
                }
                // If the destination's resourceStore does not include our assigned resource, deassign
                else if (!view.project.resourceStore.includes(dropRec.resource)) {
                    dropRec.resources = [];
                }
            }
            else {
                // Await any decision on converting recurring base/occurrence
                // to an exception of a new recurring base.
                dropRec = await me.finishDropConfirm(dropRec);
                if (dropRec) {
                    await me.moveEventTo(drag, dropRec);
                    // If dragging between zones, and the zones have been configured with specific, different
                    // resources, then this drag is also a reallocation of resource.
                    if (isReassign) {
                        // Assign to new resource first, so that it never drops to zero assignments
                        // because that can cause the eventRecord to exit the Project.
                        dropRec.assign(toResource);
                        dropRec.unassign(fromResource);
                    }
                }
            }
            await view.project.commitAsync();
        });
    }
    async finishDropResize(drag, owner, eventRecord, eventStore, validation, triggerFn) {
        const
            me           = this,
            { view }     = me;
        /**
         * This event fires on the owning Calendar before a drag resize gesture is completed. Return `false` to immediately veto the operation
         * or a Promise yielding `true` or `false` for async vetoing.
         * @event beforeDragResizeEnd
         * @preventable
         * @on-owner
         * @async
         * @param {Calendar.view.Calendar} source The Calendar instance that fired the event.
         * @param {Core.util.drag.DragContext} drag The drag create context.
         * @param {Event} event The browser event.
         * @param {Scheduler.model.EventModel} eventRecord The `EventModel` record that has not yet been updated in the store.
         * @param {Date} newStartDate The new start date.
         * @param {Date} newEndDate The new end date.
         * @param {Calendar.feature.CalendarDrag} feature The Calendar drag feature instance.
         * @param {Boolean|ValidateCreateResult} validation The result of the {@link #config-validateResizeFn} if one
         * was provided.
         * @param {Core.widget.Widget} view The Calendar widget in which the drag completed.
         */
        /**
         * This event fires on the owning Calendar when a drag resize gesture is completed.
         * @event dragResizeEnd
         * @on-owner
         * @param {Calendar.view.Calendar} source The Calendar instance that fired the event.
         * @param {Core.util.drag.DragContext} drag The drag create context.
         * @param {Event} event The browser event.
         * @param {Scheduler.model.EventModel} eventRecord The updated `EventModel` record.
         * @param {Calendar.feature.CalendarDrag} feature The Calendar drag feature instance.
         * @param {Boolean|ValidateCreateResult} validation The result of the {@link #config-validateResizeFn} if one
         * was provided.
         * @param {Core.widget.Widget} view The Calendar widget in which the drag completed.
         */
        await triggerFn('dragResizeEnd', async() => {
            let { eventRecord : editRec } = await drag.get('eventSourceHit');
            editRec = await this.finishDropConfirm(editRec);
            editRec && editRec.set({
                startDate : eventRecord.startDate,
                endDate   : eventRecord.endDate,
                duration  : DH.diff(eventRecord.startDate, eventRecord.endDate, editRec.durationUnit)
            });
            await view.project.commitAsync();
        });
    }
    async finishDropConfirm(eventRecord) {
        if (eventRecord.isOccurrence || eventRecord.isRecurring) {
            return new Promise(resolve => {
                const dialog = new RecurrenceConfirmationPopup({
                    owner : this.owner
                });
                dialog.confirm({
                    actionType : 'update',
                    eventRecord,
                    cancelFn() {
                        resolve(null);
                    },
                    changerFn(eventRec) {
                        resolve(eventRec);
                    }
                });
            });
        }
        return eventRecord;
    }
    async moveEventTo(drag, eventRecord) {
        const me = this;
        let date = me.view.getDateFromElement(drag.targetElement);
        // If we were not dragging an unscheduled event (for example from an external source)
        // then copy in its time values.
        if (eventRecord.startDate) {
            date = DH.copyTimeValues(me.clearTime(date), eventRecord.startDate);
        }
        date = me.applyDragOffset(date, drag);
        // If it's being movedTo from another store, and it was unscheduled originally...
        if (!me.view.eventStore.getByInternalId(eventRecord.internalId) && !eventRecord.startDate) {
            const autoCreate = drag.target.view.autoCreate || me.owner.client.autoCreate;
            // Move to the autoCreate time if we can find an autoCreate config to give us a hint.
            date.setHours(autoCreate?.startHour || 8);
        }
        await me.setStartDate(eventRecord, date);
    }
    pickDropTarget(drag) {
        let hit = this.hitTest(drag);
        if (!hit && this.isResizing) {
            hit = this.hit;
        }
        return hit?.date ? hit : null;
    }
    setStartDate(eventRecord, date) {
        return eventRecord.set({
            startDate : date,
            endDate   : DH.add(date, eventRecord.fullDuration)
        });
    }
    // Hoverable
    getHoverHandleCls(other) {
        return other ? '' : 'b-gripper-vert';
    }
    hoverEnter() {
        const
            me          = this,
            hit         = me.hitTest(me.hoverTarget),
            { gripper } = me.owner,
            cls         = me.getHoverHandleCls(),
            otherCls    = me.getHoverHandleCls(true);
        otherCls && gripper.classList.remove(otherCls);
        if (hit?.eventRecord?.resizable !== false && !hit?.eventRecord?.readOnly) {
            cls && gripper.classList.add(cls);
            me.hoverTarget.appendChild(gripper);
        }
    }
    hoverLeave(leaving) {
        const
            me          = this,
            { gripper } = me.owner,
            cls         = me.getHoverHandleCls();
        // We may have 2 hoverable zones each grabbing our shared gripper element, so check if we are the current
        // owner and only cleanup if we are:
        if (gripper.parentNode === leaving) {
            cls && gripper.classList.remove(cls);
            leaving.removeChild(gripper);
        }
    }
    // Misc
    applyDragOffset(date, drag) {
        const eventOffset = drag.peek('eventOffset');
        if (date && eventOffset?.[0]) {
            if (drag.source.constructor === drag.target?.constructor) {
                date = DH.add(date, -eventOffset[0], eventOffset[1]);
            }
        }
        return date;
    }
    hitTest(at) {
        const
            me            = this,
            isDragContext = at?.isDragContext,
            event         = isDragContext ? at.event : at,
            target        = (isDragContext && at.targetElement) || DomHelper.getEventElement(event);
        let src = me.view,
            hit = null,
            edge, wrapEl;
        if (target) {
            if (!src.calendarHitTest) {
                src = me.owner.client;
            }
            const horizontalStartEdge = src.rtl ? 'right' : 'left';
            hit = src.calendarHitTest(target);
            if (hit) {
                hit.eventWrap = wrapEl = target.closest('.b-cal-event-wrap');
                if (target.classList.contains('b-gripper')) {
                    hit = {
                        type        : 'resize',
                        cell        : hit.cell,
                        date        : hit.date,
                        edge        : edge = {},
                        eventRecord : hit.eventRecord,
                        resource    : hit.resource,
                        eventWrap   : wrapEl,
                        gripper     : target,
                        view        : hit.view
                    };
                    DomClassList.normalize(wrapEl.className, 'array').forEach(c => {
                        c = edgeRe.exec(c);
                        c && (edge[c[1]] = true);
                    });
                    hit.atEnd = !(edge.top || edge[horizontalStartEdge]);
                }
                else if (!hit.eventRecord) {
                    wrapEl = null;
                }
                if (wrapEl) {
                    hit.eventTop = wrapEl.style.top;
                }
                if (target !== event) {
                    hit.date = me.getDateFromPosition(event.clientX, event.clientY) || hit.date;
                }
            }
        }
        return hit;
    }
    renderEvent(eventRecord, first, last) {
        const { view } = this;
        // Make the DOM reflect the view's defaultCalendar.
        // We cannot assign in the normal way when the event is not part of a project
        // so we need to override the assigned property just while we create the DOM.
        Object.defineProperty(eventRecord, 'assigned', this.assignmentsProperty);
        // If we are moving into a view which only renders inter-day events
        // Set the cell info block's isAllDay flag.
        const dom = view.createEventDomConfig({
            eventRecord,
            isAllDay : isAllDayView[view.type]
        });
        dom.className['b-cal-tentative-event'] = 1;
        dom.className['b-cal-tentative-event-first'] = first;
        dom.className['b-cal-tentative-event-last'] = last;
        // Now we can remove the temp resource property getter
        delete eventRecord.assigned;
        if (last) {
            const { footer } = this.owner;
            if (footer) {
                appendEventFooter(dom, ObjectHelper.assign({
                    html : DH.format(eventRecord.endDate, view.timeFormat)
                }, footer));
            }
        }
        return dom;
    }
    setEventData(data, creating) {
        const
            me                      = this,
            { eventRecord, view }   = me,
            { duration, startDate } = data;
        if (startDate && duration != null && !data.endDate) {
            data = {
                ...data,
                endDate : DH.add(startDate, duration, eventRecord.durationUnit)
            };
        }
        eventRecord.set(data);
        // Honour the view's view on what constitutes a short event
        if (me.eventDom) {
            me.eventDom.className[view.shortEventCls] = eventRecord.durationMS <= view.shortEventDuration;
        }
        else {
            if (creating) {
                const
                    newName   = me.owner.newName || view.autoCreate.newName,
                    newNameFn = me.view.resolveCallback(newName, me.view, false);
                eventRecord.set('name', newNameFn?.handler.call(view, eventRecord) || newName);
            }
            me.eventDom = me.renderEvent(eventRecord);
        }
    }
    setupEvent(data, creating) {
        const
            me           = this,
            { owner }    = me,
            {
                defaultCalendar,
                eventStore
            }            = me.view,
            { tooltip }  = owner,
            eventRecord  = me.eventRecord = owner.eventRecord = eventStore.createRecord({
                // must pass empty object for CalendarStores hook of createRecord() to set resourceId
                // and because the data passed is field *names*, not dataSources
            }),
            assignments  = creating ? new Set([new eventStore.assignmentStore.modelClass({
                event    : eventRecord,
                resource : defaultCalendar
            })]) : data.assigned;
        // The assignment Set to be used when calling renderEvent so that a fully correct rendition
        // taking into account the resource can be applied.
        me.assignmentsProperty = {
            configurable : true,
            value        : assignments
        };
        delete data.assigned;
        me.setEventData(data, creating);
        // Assign the new event to the EventStore's default calendar
        if (me.isCreating && defaultCalendar) {
            eventStore.assignmentStore.assignEventToResource(eventRecord, defaultCalendar);
        }
        const { eventDom } = me;
        eventDom.className[tentativeCls] = 1;
        if (!me.noTip && !tooltip.disabled && me.dragging?.has('eventCreate')) {
            tooltip.eventRecord = eventRecord;
            tooltip.recurrenceHint = (creating && me.recurrable) ? owner.recurrenceTip : '';
            me.dragProxy.tooltip = tooltip;
        }
        return eventDom;
    }
    startCreate(date) {
        this.createEvent({
            allDay       : true,
            startDate    : date,
            durationUnit : this.durationUnit,
            endDate      : DH.add(date, 1, 'd')
        });
    }
    get durationUnit() {
        // Use view's dragUnit unless it's been configure away, then use the CalendarDrag class's default
        return this.view.dragUnit || this.owner.durationUnit;
    }
    startMove(eventRecord) {
        const
            me      = this,
            data    = ObjectHelper.clone(eventRecord.dataByFieldName),
            drag    = me.dropping,
            { hit } = me,
            // prefer the resource color of the drop and fallback to the drag
            color   = hit?.resource?.eventColor ?? drag.peek('resource')?.eventColor;
        if (color && !data.eventColor) {
            data.eventColor = color;
        }
        // Include the Set of assignments in the data for the tentative event
        data.assigned = eventRecord.assigned;
        // If an unscheduled event is grabbed (for example from an external source),
        // we have to normalize it according to current pointer context in order
        // for the view's createEventDomConfig to be able to process it to create
        // our drop indicator.
        if (!data.startDate) {
            data.startDate = hit?.date || new Date();
            data.endDate = DH.add(data.startDate, eventRecord.duration || 1, eventRecord.durationUnit || 'h');
        }
        // Dragging must always know its resource
        if (hit?.resource) {
            data.resourceId = hit.resource.id;
        }
        if (!data.resourceId) {
            data.resourceId = drag.peek('resourceId') || me.owner.client?.defaultCalendarId;
        }
        // So as not to have duplicate data-event-id="eventId" nodes in the DOM.
        delete data.id;
        me.setupEvent(data);
    }
    startResize(eventHit) {
        const
            { eventRecord } = eventHit,
            data            = eventRecord.data;
        this.createEvent({
            ...data,
            id             : `dragResize-event-${data.id}`,
            eventColor     : data.eventColor || eventRecord.resource?.eventColor,
            recurrenceRule : null,
            realEventId    : data.id
        });
    }
    // Configs
    configureListeners(drag) {
        const listeners = super.configureListeners(drag);
        // Listen to the events on the root element
        listeners.element = this.view.rootElement;
        return listeners;
    }
    updateHit(hit) {
        const
            me   = this,
            drag = me.dropping,
            mode = hit && drag[eventDragSym];
        if (hit) {
            if (me.isMoving) {
                me.dropHitMove(drag, hit, drag.peek('eventRecord'));
            }
            else if (mode?.create) {
                me.dropHitCreate(drag, hit, drag.peek('eventCreate'));
            }
            else if (mode?.resize) {
                me.dropHitResize(drag, hit, drag.peek('eventSourceHit'));
            }
        }
        else {
            me.dropHitNowhere(drag);
        }
    }
    updateDays(days) {
        const
            me                                     = this,
            { calendarCells, eventDom, dayValues } = me,
            newDayValues                           = calendarCells && {};
        let first = true,
            cell, date, dayValue, i, lastCell;
        me.dayValues = newDayValues;
        if (calendarCells && eventDom) {
            for (i = 0; i < calendarCells.length; ++i) {
                cell = calendarCells[i];
                if (days?.includes(cell.dataset.date)) {
                    lastCell = cell;
                }
            }
            for (i = 0; i < calendarCells.length; ++i) {
                cell = calendarCells[i];
                date = cell.dataset.date;
                if (days?.includes(date)) {
                    if (!(dayValue = dayValues?.[date])) {
                        dayValue = me.includeDay(date, cell, first, cell === lastCell) || true;
                        first = false;
                    }
                    else {
                        delete dayValues[date];
                    }
                    newDayValues[date] = dayValue;
                }
            }
            if (dayValues) {
                const cleanDom = () => {
                    for (i in dayValues) {
                        me.removeDay(i, dayValues[i], me.isCreating);
                    }
                };
                // If creating, and we're not aborted, remove the drag coverage after the
                // refresh has happened so there's no apparent blink when the coverage is removed.
                if (!days && me.isCreating && !me.dragging.aborted) {
                    // Clean the dom when the refresh is done. Wait for a max of 100ms for an upcoming
                    // refresh before going ahead anyway.
                    me.view.afterRefresh(cleanDom);
                }
                else {
                    cleanDom();
                }
            }
        }
    }
    updateEventRecord(record) {
        this.owner.eventRecord = record;
    }
    updateOverflow(value, instance) {
        if (!value && instance) {
            instance.destroy();
        }
        return value;
    }
    updateOwner(owner) {
        this.hoverIgnoreElement = owner?.gripper;
    }
    updateRootElement(rootEl) {
        const me = this;
        me.dragRootElement = rootEl;
        me.dropRootElement = me.droppable ? rootEl : null;
        me.hoverRootElement = me.hoverable ? rootEl : null;
    }
    updateView(view, was) {
        super.updateView(view, was);
        const me = this;
        me.rootElement = view ? me.findRootElement(view) : null;
        me._overflowDetacher?.();
        // Only listen for overflow popup being shown if the view itself offers the event.
        // We must not listen to the relayed version from the owning DayView of a CalendarRow
        // otherwise we'd end up with a DayZone owning a duplicate OverflowZone in addition
        // to the AllDayZone.
        if (view?.isDayCellRenderer) {
            me._overflowDetacher = view.ion({
                thisObj           : me,
                showOverflowPopup : 'onShowOverflowPopup'
            });
        }
    }
}
Zone.prototype._eventRecord = null;
//====================================================================================================
// DayView
class BaseDayZone extends Zone {
    static get $name() {
        return 'BaseDayZone';
    }
    static get configurable() {
        return {
            hoverSelector : '.b-cal-event-wrap',
            draggingClsSelector : '.b-dayview-content'
        };
    }
    getHoverHandleCls(other) {
        let vert = this.isAllDayZone;
        if (other) {
            vert = !vert;
        }
        return `b-gripper-${vert ? 'vert' : 'horz'}`;
    }
}
//-------------------------------------------------------------------------
class AllDayZone extends BaseDayZone {
    static get $name() {
        return 'AllDayZone';
    }
    static get configurable() {
        return {
            hoverEdges : 'lr'
        };
    }
    // Drag handling
    dragEnter(drag) {
        // If there's no space in which to display the drop indicator,
        // temporarily expand the gutter to allow for appearance of the drop indicator.
        if (!this.view.eventsPerCell) {
            this.view.expandGutter();
        }
        return super.dragEnter(drag);
    }
    dragLeave(drag) {
        this.view.collapseGutter();
        super.dragLeave(drag);
    }
    captureDragOffset(eventRecord, hit, drag) {
        drag.set('eventOffset', [
            Math.max(Math.floor(DH.diff(eventRecord.startDate, hit.date, 'd')), 0),
            'd'
        ]);
    }
    // Drop handling
    dropHitCreate(drag, hit, dragFrom) {
        let endDate   = this.clearTime(hit.date),
            startDate = this.clearTime(dragFrom.date);
        if (endDate < startDate) {
            [startDate, endDate] = [endDate, startDate];
        }
        // Update the final duration in the units the view created the record with.
        this.setEventData({
            startDate,
            duration : DH.as(this.eventRecord.durationUnit, DH.diff(startDate, endDate, 'd') + 1, 'd')
        });
    }
    async moveEventTo(drag, dropRec) {
        const
            me      = this,
            hit     = me.hitTest(drag),
            date    = me.applyDragOffset(hit?.date, drag),
            newDate = new Date(dropRec.startDate);
        if (date) {
            // We're only changing the date component of the time.
            // Not the time of day that the event started at.
            newDate.setFullYear(date.getFullYear());
            newDate.setMonth(date.getMonth());
            newDate.setDate(date.getDate());
            // If the event does not belong in the all day zone, i.e. it's not day-spanning
            // and does not have the allDay flag set, then set the allDay flag
            if (me.view.dayTime.startShift) {
                newDate.setHours(date.getHours());
                newDate.setMinutes(date.getMinutes());
                newDate.setSeconds(date.getSeconds());
                dropRec.duration = 1;
            }
            else {
                if (!me.view.isAllDayEvent(dropRec)) {
                    dropRec.allDay = true;
                }
            }
            await me.setStartDate(dropRec, newDate);
        }
    }
    // Misc
    setEventData(data, creating) {
        super.setEventData(data, creating);
        const
            me = this,
            { eventRecord } = me;
        const { startDate, endDate } = eventRecord;
        if (creating && !me.view.dayTime.startShift) {
            eventRecord.allDay = true;
        }
        me.days = me.makeDays(startDate, endDate);
    }
    // Configs
    updateDays(days) {
        const me = this;
        let { eventEl } = me;
        if (days?.length) {
            if (!eventEl) {
                me.eventEl = eventEl = DomHelper.createElement({
                    ...me.eventDom
                });
                eventEl.classList.add('b-allday');
            }
            const
                { dropRootElement } = me,
                { visibleCellSelector, weekLength } = me.view,
                eventTop = me.dragging?.peek('eventSourceHit')?.eventTop,
                cells = DomHelper.children(dropRootElement, visibleCellSelector),
                cell = DomHelper.down(dropRootElement, `${visibleCellSelector}[data-date='${days[days.length - 1]}']`);
            // The one event element lives in the startDate cell, or the resource sub cell
            // of the startdate cell.
            DomHelper.down(cell, '.b-cal-event-bar-container').appendChild(eventEl);
            eventEl.style[me.view.rtl ? 'right' : 'left'] = DomHelper.percentify(100 * (cells.indexOf(cell) - days.length + 1) / weekLength);
            eventEl.style.width = DomHelper.percentify(100 * days.length / weekLength);
            if (eventTop) {
                eventEl.style.top = eventTop;
            }
            // Ensure element is in view in case it's scrolled, and we are dragging
            // an interday event in the main day part.
            me.view.scrollable.scrollIntoView(eventEl, true);
        }
        else if (eventEl) {
            if (eventEl.classList.contains('b-cal-tentative-event')) {
                eventEl.remove();
            }
            me.eventEl = null;
        }
    }
    updateView(view, was) {
        if (view) {
            const multiDay = DH.diff(view.startDate, view.endDate, 'd') > 1;
            this.hoverable = multiDay;
            this.draggable = multiDay || this.view.owner.isDayView;
        }
        super.updateView(view, was);
    }
}
//-------------------------------------------------------------------------
class DayZone extends BaseDayZone {
    static get $name() {
        return 'DayZone';
    }
    static get configurable() {
        return {
            dragEventId : null,
            hoverable  : true,
            hoverEdges : 'tb',
            times : {
                $config : {
                    equal : 'array'
                },
                value : null
            },
            alDayZoneClass : AllDayZone,
            // When dragging events, configure the DayZone with constrainToDay : true
            // to prevent dragging events so that they cross the day start or end boundaries.
            constrainToDay : false
        };
    }
    construct(...args) {
        super.construct(...args);
        const
            me               = this,
            { allDayEvents } = me.view;
        if (allDayEvents) {
            me.allDayZone = new me.alDayZoneClass({
                active   : me.active,
                owner    : me.owner,
                view     : allDayEvents,
                resource : me.resource
            });
        }
    }
    syncDraggingElements(eventId, active) {
        const
            { dragging : drag } = this,
            { draggingItemCls } = drag.source,
            containerEl         = drag.itemElement.closest('.b-dayview-day-container'),
            elements            = containerEl?.querySelectorAll(`[data-event-id="${eventId}"]`) ?? [];
        for (const el of elements) {
            el.classList.toggle(draggingItemCls, active);
        }
    }
    hitTest(at) {
        const
            { view }    = this,
            { dayTime } = view,
            hit         = super.hitTest(at);
        // Cache the the main date for the column we are over.
        // This is used later in applyDragOffset to constrain the exact datetime to within that date
        if (hit) {
            hit.overDate = at?.isDragContext ? view.getDateFromPosition(at.event.clientX, Rectangle.fromScreen(view.dayContainerElement).y, false, dayTime) : dayTime.startOfDay(hit.date);
        }
        return hit;
    }
    updateDragEventId(eventId, previousEventId) {
        previousEventId && this.syncDraggingElements(previousEventId, false);
        eventId && this.syncDraggingElements(eventId, true);
    }
    get eventRecord() {
        return super.eventRecord || this.allDayZone?.eventRecord;
    }
    set eventRecord(value) {
        super.eventRecord = value;
    }
    get recurring() {
        return this.isCreating && this.eventRecord?.recurrenceRule != null;
    }
    get droppingAllDay() {
        const eventRecord = this.dropping?.peek('eventRecord');
        return eventRecord?.startDate && this.view?.isAllDayEvent(eventRecord) || false;
    }
    get useAllDay() {
        // when an allDay event is being dropping on the hourly part of the day view, relay that to the allDayZone
        // if it is from an outsider (we want to retain the allDay nature of the event). If it is from _our_ allDayZone,
        // then the goal is to move it from allDay to not allDay.
        return this.droppingAllDay && this.view.showAllDayHeader && this.dropping.source !== this.allDayZone;
    }
    get wasAllDay() {
        // when an allDay event is being dropping on the hourly part of the day view, relay that to the allDayZone
        // if it is from an outsider (we want to retain the allDay nature of the event). If it is from _our_ allDayZone,
        // then the goal is to move it from allDay to not allDay.
        return this.droppingAllDay && this.dropping.source === this.allDayZone;
    }
    doDestroy() {
        this.allDayZone?.destroy();
        super.doDestroy();
    }
    dragStart() {
        super.dragStart();
        this.dragEventId = this.dragging.peek('eventRecord')?.id ?? null;
    }
    findRootElement(view) {
        return view.eventContentElement;
    }
    // Drag handling
    captureDragOffset(eventRecord, hit, drag) {
        drag.set('eventOffset', [
            Math.floor(DH.diff(eventRecord.startDate, hit.date, 'm')),
            'm'
        ]);
    }
    cleanupDrag() {
        this.dragEventId = null;
        super.cleanupDrag();
        this.allDayZone?.cleanupDrag();
    }
    // Drop handling
    cleanupDrop() {
        super.cleanupDrop();
        // Stop monitoring early. With polyfilled resize monitor scroll event
        // will fire too soon when eventRecord is nullified but monitor is not stopped
        this.owner.client?._scrollManager?.stopMonitoring();
        this.allDayZone?.cleanupDrop();
    }
    dropHitCreate(drag, hit) {
        const
            me               = this,
            { durationUnit } = me.eventRecord;
        let endTime   = hit.date,
            startTime = drag.peek('eventCreate').date,
            endDate   = endTime,
            startDate = startTime,
            duration, recurrenceCount;
        const
            sameDay   = !(me.clearTime(startTime) - me.clearTime(endTime)),
            recurring = drag.ctrlKey && !sameDay;
        if (recurring || sameDay) {
            endDate = me.clearTime(endTime);
            startDate = me.clearTime(startTime);
            // Now these are just milliseconds from midnight (note: Date - Date = millis):
            startTime = startTime - startDate;
            endTime = endTime - endDate;
            if (endDate < startDate) {
                [startDate, endDate] = [endDate, startDate];
            }
            if (endTime < startTime) {
                [startTime, endTime] = [endTime, startTime];
            }
            startDate.setTime(startDate.getTime() + startTime);
            // Convert the millisecond value of the duration to the event record
            duration = DH.as(durationUnit, Math.max(me.view.increment, Math.floor(endTime - startTime)));
            if (recurring) {
                recurrenceCount = DH.diff(me.clearTime(startDate), me.clearTime(endDate), 'd') + 1;
            }
        }
        else {
            if (endDate < startDate) {
                [startDate, endDate] = [endDate, startDate];
            }
            // Convert the millisecond value of the duration to the event record
            duration = DH.as(durationUnit, Math.floor((endDate - startDate) / MINUTES), 'm');
        }
        me.setEventData({
            startDate,
            duration,
            recurrenceRule : recurring ? `FREQ=DAILY;COUNT=${recurrenceCount}` : null
        });
    }
    dropHitMove(drag, hit, eventRecord) {
        let startDate = hit.date;
        const
            me       = this,
            { view } = me,
            dayStart = view.dayTime.startOfDay(startDate);
        if (me.useAllDay) {
            me.allDayZone.dropHitMove(drag, hit, eventRecord);
        }
        else {
            // use the internal eventRecord since it may have a different durationUnit when dragging between allDay
            // and non-allDay:
            eventRecord = me.eventRecord;
            startDate = me.applyDragOffset(startDate, drag);
            // Dragging in a DayView must not allow dragging above "midnight", otherwise the drop indicator will
            // become what looks like a 2 day event in the all day header.
            if (view.showAllDayHeader && startDate < dayStart) {
                startDate = dayStart;
            }
            me.setEventData({
                startDate,
                endDate  : DH.add(startDate, eventRecord.duration, eventRecord.durationUnit),
                duration : eventRecord.duration
            });
        }
    }
    applyDragOffset(date, drag) {
        let result = super.applyDragOffset(date, drag);
        // If we're not using an all day row, constrain the event to within the day
        if (this.constrainToDay) {
            const { overDate } = this.hit;
            result = new Date(
                Math.max(
                    Math.min(result, overDate.getTime() + this.view.dayTime.timeEnd - this.eventRecord.durationMS),
                    overDate
                )
            );
        }
        return result;
    }
    dropHitResize(drag, hit, eventHit) {
        const
            me              = this,
            { eventRecord } = me,
            date            = hit.date;
        if (eventHit.atEnd) {
            if (eventRecord.startDate < date) {
                me.setEventData({
                    endDate : date
                });
            }
        }
        else if (date < eventRecord.endDate) {
            me.setEventData({
                startDate : date
            });
        }
    }
    async moveEventTo(drag, dropRec) {
        const
            me   = this,
            hit  = me.hitTest(drag),
            date = hit?.date;
        if (date) {
            if (me.useAllDay) {
                await me.allDayZone.moveEventTo(drag, dropRec);
            }
            else {
                const
                    { view }     = me,
                    newStartDate = me.applyDragOffset(date, drag);
                // If converting from an all day event, ensure it doesn't remain as inter-day due to the
                // endDate now overrunning the end of the destination date.
                // Constrain newEndDate to the end of the destination date.
                if (me.wasAllDay) {
                    const
                        endOfDay   = DH.add(view.dayTime.startOfDay(newStartDate), 24, 'h'),
                        newEndDate = DH.add(newStartDate, dropRec.duration, dropRec.durationUnit);
                    dropRec.allDay = false;
                    // If the new start time we dropped at will cause it to overflow the end of the dropped day
                    // then convert it to be autoCreate.duration in length (Still ensuring that that does not
                    // escape the end of the day).
                    if (newEndDate > endOfDay) {
                        const
                            defaultDuration = DH.parseDuration(view.autoCreate.duration),
                            newEndDate      = new Date(Math.min(DH.add(newStartDate, defaultDuration.magnitude, defaultDuration.unit), endOfDay)),
                            newDuration     = DH.diff(newStartDate, newEndDate, dropRec.durationUnit);
                        dropRec.setDuration(newDuration, dropRec.unit);
                    }
                }
                await me.setStartDate(dropRec, newStartDate);
            }
        }
    }
    startCreate(date) {
        this.createEvent({
            duration     : 0,
            durationUnit : this.durationUnit,
            startDate    : date,
            // It's only a provisional event until gesture is completed (possibly longer if an editor dialog is shown after)
            isCreating : true
        });
    }
    // Misc
    includeDay(date, cell, first, last) {
        return DomHelper.createElement({
            parent : cell.querySelector('.b-dayview-event-container'),
            ...this.renderEvent(this.eventRecord, first, last)
        });
    }
    removeDay(date, value) {
        if (value.classList.contains('b-cal-tentative-event')) {
            value.remove();
        }
    }
    setEventData(data, creating) {
        const me = this;
        if (me.useAllDay) {
            me.allDayZone.setEventData(data, creating);
            return;
        }
        super.setEventData(data, creating);
        const
            { eventRecord } = me,
            { endDate, startDate } = eventRecord,
            { dayTime } = me.view;
        let lastDate = endDate;
        if (me.recurring) {
            // odd thing here... the way "days" works is inclusive endDate (because of events that start/stop in the
            // same day), so we need the "-1":
            lastDate = DH.add(lastDate, eventRecord.recurrence.count - 1, 'd');
        }
        me.days = me.makeDays(startDate, lastDate);  // updates rendered events for these days
        me.times = [
            dayTime.delta(startDate, 's'),
            dayTime.delta(endDate, 's')
        ];
    }
    setupEvent(data, creating) {
        const me = this;
        if (me.useAllDay) {
            me.allDayZone.setupEvent(data, creating);
        }
        else {
            if (this.wasAllDay) {
                data.allDay = false;
                data.endDate = DH.add(data.startDate, data.duration = 1, data.durationUnit = 'hour');
            }
            super.setupEvent(data, creating);
        }
    }
    // Configs
    updateDays(days, was) {
        super.updateDays(days, was);
        this.updateTimes(this.times, null);
    }
    updateTimes(times) {
        if (!times) {
            return;
        }
        const
            me = this,
            { allDayZone, dayValues, dragging, eventRecord, recurring, view } = me,
            { dayTime, eventSpacing } = view,
            insetProp   = view.rtl ? 'right' : 'left',
            { endDate, startDate } = eventRecord,
            [startOffset, endOffset] = times,
            // drag move can come from outside our calendar, so only consider the eventWidth if we are the one doing
            // the dragging:
            eventInset  = !dragging?.aborted && dragging?.peek('eventInset'),
            eventWidth  = !dragging?.aborted && dragging?.peek('eventWidth'),
            firstDay    = dayTime.dateKey(startDate),
            lastDay     = dayTime.dateKey(endDate),
            multiDay    = dayTime.startOfDay(startDate) < dayTime.startOfDay(endDate),
            heightScale = 100 / dayTime.duration('s');  // to give us percent when we multiply by this value
        let { days } = me,
            date, first, height, style, top;
        if (!days) {
            return;
        }
        // Since we may not have changed me.days (time of day only changes), we now need to update the vertical
        // aspect
        for (date in dayValues) {
            first  = date === firstDay;
            style  = dayValues[date].style;
            top    = startOffset * heightScale;
            height = (endOffset - startOffset) * heightScale;
            DomSync.sync({
                targetElement : dayValues[date],
                domConfig     : me.renderEvent(me.eventRecord, first, date === days[days.length - 1])
            });
            if (!recurring && multiDay) {
                if (first) {
                    height = 100 - top;
                }
                else if (date === lastDay) {
                    height = top + height;
                }
                else {
                    height = 100;
                }
            }
            style.top = (recurring || first) ? DomHelper.percentify(top) : 0;
            style.height = DomHelper.percentify(height);
            style.paddingBottom = DomHelper.setLength(eventSpacing);
            if (eventInset) {
                style[insetProp] = eventInset;
            }
            if (eventWidth) {
                style.width = `${eventWidth}px`;
            }
        }
        // Now sync "days" for the allDayZone:
        if (!days || days.length < 2 || recurring) {
            allDayZone?.cleanupDrop();
            days = null;
        }
        else if (allDayZone && view.showAllDayHeader) {
            if (!allDayZone.eventRecord) {
                allDayZone.noTip = true;
                const eventData = {
                    startDate : eventRecord.startDate,
                    endDate   : eventRecord.endDate
                };
                if (me.isResizing) {
                    const resizeEvent = dragging.peek('eventRecord');
                    eventData.assigned = resizeEvent.assigned;
                    eventData.eventColor = resizeEvent.eventColor;
                }
                allDayZone.createEvent(eventData, me.dropping);
            }
            allDayZone.eventRecord.set({
                startDate : eventRecord.startDate,
                endDate   : DH.add(eventRecord.startDate, days.length - 1, 'd')
            });
            allDayZone.days = days;
        }
    }
}
class ResourceCalendarRowZone extends AllDayZone {
    static $name = 'ResourceCalendarRowZone';
    static configurable = {
        droppableSelector : '.b-calendarrow-cell-container,.b-dayresourcecalendarrow-cell-resources,.b-dayresource-allday.b-cal-cell-header'
    };
    // Disable Hoverable behaviour. Resizing is not allowed.
    // Event bars are discontiguous, so resizing an event across multiple
    // cells has ambiguous semantics. We just disallow it.
    // Events in this view may only be *moved*
    syncHoverListeners() {}
    beforeDrag(drag) {
        const hit = this.hitTest(drag);
        // Resizing and drag-creating not valid in this view.
        // Event bars are discontiguous, so resizing an event across multiple
        // cells has ambiguous semantics. We just disallow it.
        // Events in this view may only be *moved*
        if (!hit?.resource || hit.type === 'resize' || hit.type === 'schedule') {
            return false;
        }
        return super.beforeDrag(drag);
    }
    setupEvent() {
        this.view.defaultCalendar = (this.dragging || this.dropping).peek('resource');
        return super.setupEvent(...arguments);
    }
    updateHit(hit, was) {
        if (!hit || hit.resource) {
            super.updateHit(hit, was);
        }
    }
    makeDays(startDate) {
        const
            me           = this,
            { isMoving } = me,
            drag         = me.dragging || me.dropping,
            date         = isMoving ? new Date(Math.max(startDate, me.view.firstVisibleDate)) : drag.peek('date'),
            result       = super.makeDays(date, DH.add(date, 1, 'd'));
        // Cannot create or resize across resources
        result.resource = me.hit?.resource;
        return result;
    }
    updateDays(days) {
        const me  = this;
        let { view, eventEl } = me;
        if (days?.length) {
            if (!eventEl) {
                me.eventEl = eventEl = DomHelper.createElement({
                    ...me.eventDom
                });
                eventEl.classList.add('b-allday');
            }
            const
                eventTop = me.dragging?.peek('eventSourceHit')?.eventTop,
                resourceCell = DomHelper.down(me.dropRootElement,
                    `${view.visibleCellSelector}:is(${days.map(d => `[data-date="${d}"]`).join(',')}) [data-resource-id="${days.resource.id}"] > .b-cal-event-bar-container`);
            // The one event element lives in the resource sub cell
            resourceCell.appendChild(eventEl);
            eventEl.style.width = '100%';
            if (eventTop) {
                eventEl.style.top = eventTop;
            }
            // Ensure element is in view in case it's scrolled, and we are dragging
            // an interday event in the main day part.
            view.scrollable.scrollIntoView(eventEl, true);
        }
        else if (eventEl) {
            if (eventEl.classList.contains('b-cal-tentative-event')) {
                eventEl.remove();
            }
            me.eventEl = null;
        }
    }
}
class DayResourceZone extends DayZone {
    static $name = 'DayResourceZone';
    static configurable = {
        alDayZoneClass : ResourceCalendarRowZone
    };
    beforeDrag(drag) {
        const hit = this.hitTest(drag);
        if (!hit?.resource) {
            return false;
        }
        return super.beforeDrag(drag);
    }
    setupEvent() {
        this.view.defaultCalendar = (this.dragging || this.dropping).peek('resource');
        return super.setupEvent(...arguments);
    }
    hitTest(at) {
        const
            drag   = this.dragging || this.dropping,
            result = super.hitTest(at);
        // We never resize of create across resource columns or date columns
        if (result && drag && !this.isMoving) {
            const fromDate = drag.peek('date');
            result.resource = drag.peek('resource');
            result.date.setDate(fromDate.getDate());
            result.date.setMonth(fromDate.getMonth());
            result.date.setFullYear(fromDate.getFullYear());
        }
        return result;
    }
    updateHit(hit, was) {
        if (!hit || hit.resource) {
            super.updateHit(hit, was);
        }
    }
    makeDays(startDate, endDate) {
        const
            me           = this,
            { isMoving } = me,
            drag         = me.dragging || me.dropping,
            date         = isMoving ? startDate : drag.peek('date'),
            result       = super.makeDays(date, DH.add(date, 1, 'd'));
        // Cannot create or resize across resources
        result.resource = me.hit?.resource;
        return result;
    }
    updateDays(days) {
        const me = this;
        let { eventEl } = me;
        if (days?.length) {
            const cell = DomHelper.down(me.dropRootElement,
                `:is(${days.map(d => `[data-date="${d}"]`).join(',')}) ${me.view.visibleCellSelector}[data-resource-id="${days.resource.id}"] .b-dayview-event-container`);
            // Dragging in this view never spans cells, so we only need one eventEl
            if (!eventEl) {
                me.eventEl = eventEl = DomHelper.createElement({
                    ...me.eventDom
                });
            }
            cell.appendChild(eventEl);
        }
        else if (eventEl) {
            if (eventEl.classList.contains('b-cal-tentative-event')) {
                eventEl.remove();
            }
            me.eventEl = null;
        }
        me._times = null;
    }
    updateTimes(times) {
        if (!times || !this.days) {
            return;
        }
        const
            me = this,
            { dayTime, eventSpacing } = me.view,
            [startOffset, endOffset] = times,
            heightScale = 100 / dayTime.duration('s'),  // to give us percent when we multiply by this value
            { style } = me.eventEl,
            top    = startOffset * heightScale,
            height = (endOffset - startOffset) * heightScale;
        DomSync.sync({
            targetElement : me.eventEl,
            domConfig     : me.renderEvent(me.eventRecord, true, true)
        });
        style.top = DomHelper.percentify(top);
        style.height = DomHelper.percentify(height);
        style.paddingBottom = DomHelper.setLength(eventSpacing);
        style.width = '100%';
        // We sidestep cross-date drags for now.
        // No connection into all day zone.
    }
}
//====================================================================================================
// MonthView
class MonthZone extends Zone {
    static get $name() {
        return 'MonthZone';
    }
    static get configurable() {
        return {
            coverage : {
                $config : {
                    equal : ObjectHelper.isEqual
                },
                value : null
            },
            hoverable     : true,
            hoverEdges    : 'lr',
            hoverSelector : '.b-cal-event-wrap.b-allday'
        };
    }
    findRootElement(view) {
        return view.weeksElement;
    }
    // Drag handling
    captureDragOffset(eventRecord, hit, drag) {
        drag.set('eventOffset', [
            Math.floor(DH.diff(this.clearTime(eventRecord.startDate), hit.date, 'd')),
            'd'
        ]);
    }
    cleanupDrag() {
        super.cleanupDrag();
        const cleanDom = () => this.coverage = null;
        // Delay removal of the coverage element slightly if creating so that the refresh
        // will happen first and the event element will not appear to blink.
        if (this.isCreating) {
            // Wait for a max of 100ms for a refresh to occur before cleaning the DOM
            this.view.afterRefresh(cleanDom);
        }
        else {
            cleanDom();
        }
    }
    // Drop handling
    cleanupDrop() {
        super.cleanupDrop();
        // Stop monitoring early. With polyfilled resize monitor scroll event
        // will fire too soon when eventRecord is nullified but monitor is not stopped
        this.owner.client?._scrollManager?.stopMonitoring();
        const cleanDom = () => this.coverage = null;
        // Delay removal of the coverage element slightly if creating so that the refresh
        // will happen first and the event element will not appear to blink.
        if (this.isCreating) {
            // Wait for a max of 100ms for a refresh to occur before cleaning the DOM
            this.view.afterRefresh(cleanDom);
        }
        else {
            cleanDom();
        }
    }
    dropHitCreate(drag, hit) {
        const
            me        = this,
            recurring = drag.ctrlKey,
            dragFrom  = drag.peek('eventCreate');
        let recurrenceRule = null,
            count, day1, day2, endDate, startDate, week1, week2;
        endDate = me.clearTime(hit.date);
        startDate = me.clearTime(dragFrom.date);
        day1 = dragFrom.dayNumber;
        day2 = hit.dayNumber;
        week1 = dragFrom.weekOffset;
        week2 = hit.weekOffset;
        if (week2 < week1) {
            [week1, week2] = [week2, week1];
        }
        /*
         +-----+-----+-----+-----+-----+-----+-----+
         |  S  |  M  |  Tu |  W  |  Th |  F  |  S  |
         +-----+-----+-----+-----+-----+-----+-----+
         |     |  B  |     |  A  |     |  C  |     |
         +-----+-----+-----+-----+-----+-----+-----+
         |     |     |     |     |     |     |     |
         +-----+-----+-----+-----+-----+-----+-----+
         |     |  D  |     |  x  |     |  E  |     |
         +-----+-----+-----+-----+-----+-----+-----+
         |     |     |     |     |     |     |     |
         +-----+-----+-----+-----+-----+-----+-----+
         |     |  F  |     |  G  |     |  H  |     |
         +-----+-----+-----+-----+-----+-----+-----+
         startDate   day1
         A   > endDate   = day2
         B   > endDate   > day2
         C   > endDate   < day2
         D   > endDate   > day2
         E   < endDate   < day2
         F   < endDate   > day2
         G   < endDate   = day2
         H   < endDate   < day2
         */
        if (recurring) {
            // In this mode, the interval [day1, day2] is used to draw the days of the week for each week, so it
            // must be that day1 <= day2.
            count = week2 - week1 + 1;
            recurrenceRule = (count > 1) ? `FREQ=WEEKLY;COUNT=${count}` : null;
            if (endDate < startDate) {  // if (A, B, C or D)
                startDate = endDate;  // only startDate matters for recurrence...
                if (day1 < day2) {  // if (C)
                    startDate = DH.add(startDate, day1 - day2, 'd');
                }
            }
            else if (day2 < day1) {  // if (F)
                startDate = DH.add(startDate, day2 - day1, 'd');
            }
            if (day2 < day1) {  // if (B, D or F)
                [day1, day2] = [day2, day1];
            }
        }
        // In this mode, day1 is the day of week the event starts and day2 is the day of week for the end of the
        // event, so they must adhere to startDate/endDate.
        else if (endDate < startDate) {
            [startDate, endDate] = [endDate, startDate];
            [day1, day2] = [day2, day1];
        }
        me.setEventData({
            startDate,
            duration : DH.as(me.eventRecord.durationUnit, (recurring ? day2 - day1 : DH.diff(startDate, endDate, 'd')) + 1, 'd'),
            recurrenceRule
        });
    }
    dropHitNowhere(drag) {
        super.dropHitNowhere(drag);
        this.coverage = null;
    }
    // Misc
    setEventData(data, creating) {
        super.setEventData(data, creating);
        const
            { dropping, eventRecord, view } = this,
            { visibleCellSelector }         = view,
            weekEls                         = DomHelper.children(view.weeksElement, '> .b-calendar-week'),
            coverage                        = {
                // weekNumber : String[] describing the days for a weekEl (in order of weekEls)
            },
            add                             = event => {
                const { startDate, endDate } = event;
                for (let cells, cover, k, n, i = 0; i < weekEls.length; ++i) {
                    cells = DomHelper.children(weekEls[i], visibleCellSelector);  // not immediate descendants
                    n = cells.length;
                    // cover is a string w/day numbers for every day that intersects the event 0 to N-1 where N is
                    // the number of days in the week. Day number 0 is preceded by '<' if the event started before
                    // the week. Day number N-1 is followed by '>' if the event extends beyond the end of the week.
                    // Ex: '0123' means the event occurs on the first 4 days of the week.
                    // Ex: '<012' means the event started in the prior week and occurs on the first 3 days.
                    cover = '';
                    for (k = 0; k < n; ++k) {
                        const
                            dayStart = view.getDateFromElement(cells[k]),
                            dayEnd   = DH.add(dayStart, 1, 'd');
                        if (startDate < dayEnd && dayStart < endDate) {  // if (day intersects event)
                            if (!k && startDate < dayStart) {
                                cover = '<';
                            }
                            cover += k;  // k is converted to a string since cov is a string
                            if (k === n - 1 && dayEnd < endDate) {
                                cover += '>';
                            }
                        }
                    }
                    if (cover) {
                        (coverage[i] || (coverage[i] = [])).push(cover);
                    }
                }
            };
        if (dropping?.has('eventRecord') || !eventRecord.recurrence) {
            add(eventRecord);
        }
        else {
            eventRecord.recurrence.forEachOccurrence(view.startDate, view.endDate, add);
        }
        this.coverage = coverage;
    }
    // Configs
    updateCoverage(coverage) {
        const
            me               = this,
            {
                dragging,
                weekValues,
                view
            }                = me,
            { rtl }          = view,
            {
                visibleCellSelector
            }                = view,
            eventSourceHit   = coverage && dragging?.peek('eventSourceHit'),
            eventTop         = eventSourceHit?.eventTop,
            newWeekValues    = {},
            eventRow         = view.getWeekElementFor(eventSourceHit?.eventElement),
            { weekElements } = view;
        let cell, cells, cov, cover, el, eventEl, extL, extR, i, k, weekEl;
        me.weekValues = newWeekValues;
        for (i = 0; i < weekElements.length; ++i) {
            if (!(cover = coverage?.[i])) {
                continue;
            }
            weekEl = weekElements[i];
            cells = DomHelper.children(weekEl, visibleCellSelector);  // not immediate descendants
            for (k = 0; k < cover.length; ++k) {
                // we keep an array of elements for each week in case we need to render some sort of recurrence
                if (!(eventEl = weekValues?.[i]?.shift())) {
                    eventEl = DomHelper.createElement(me.eventDom);
                }
                (newWeekValues[i] || (newWeekValues[i] = [])).push(eventEl);
                cov = cover[k];
                extL = cov.includes('<') ? 1 : 0;
                extR = cov.includes('>') ? 1 : 0;
                cov = cov.substr(extL, cov.length - extR - extL);  // remove the < > chars if any
                eventEl.classList[extL ? 'add' : 'remove']('b-continues-past');
                eventEl.classList[extR ? 'add' : 'remove']('b-continues-future');
                eventEl.style[rtl ? 'right' : 'left'] = DomHelper.percentify(100 * Number(cov[0]) / cells.length);
                eventEl.style.width = DomHelper.percentify(100 * cov.length / cells.length);
                if (eventTop && weekEl === eventRow) {
                    eventEl.style.top = eventTop;
                }
                // We put the el in the last cell so that it is on top of events for that day and all events on prior
                // days as well
                cell = cells[Number(cov[cov.length - 1])];
                el = DomHelper.down(cell, '.b-cal-event-bar-container');
                if (el !== eventEl.parentNode) {
                    el.appendChild(eventEl);
                }
            }
        }
        if (weekValues) {
            for (i in weekValues) {
                weekValues[i].forEach(el => {
                    if (el.classList.contains('b-cal-tentative-event')) {
                        el.remove();
                    }
                });
            }
        }
    }
}
//====================================================================================================
// OverflowZone
class OverflowZone extends Zone {
    static get $name() {
        return 'OverflowZone';
    }
    static get configurable() {
        return {
            droppable : false,
            dragProxy : {
                type : 'default',
                open(drag) {
                    const
                        me        = this,
                        { owner } = drag.source.view,
                        sourceEl  = drag.element.closest('.b-cal-event-wrap');
                    if (owner.isYearView) {
                        me.proxyEl = sourceEl.cloneNode(true);
                        me.proxyEl.classList.add('b-cal-drag-proxy');
                        me.proxyEl.style.width = `${sourceEl.offsetWidth}px`;
                        me.proxyOffset = EventHelper.getClientPoint(drag.startEvent).getDelta(Rectangle.from(sourceEl));
                        owner.contentElement.appendChild(me.proxyEl);
                    }
                },
                dragMove(drag) {
                    if (this.proxyEl) {
                        // Align the proxy to [10, 10] from the pointer
                        DomHelper.alignTo(this.proxyEl, EventHelper.getClientPoint(drag.event).translate(10, 10), {
                            align : 't0-t0'
                        });
                    }
                },
                close() {
                    this.proxyEl?.remove();
                }
            }
        };
    }
    findRootElement(view) {
        return view.contentElement;
    }
    beforeDrag(drag) {
        const hit = this.hitTest(drag);
        if (hit?.type !== 'event' || !this.owner.draggable || !hit.eventRecord.isDraggable) {
            return false;
        }
        drag.set('eventRecord', hit.eventRecord);
        drag.set('eventDragMode', drag[eventDragSym] = modeDescriptor.move.mode);
    }
    dragStart() {
        this.view.hide();
    }
}
//====================================================================================================
// YearView
class YearZone extends Zone {
    static get $name() {
        return 'YearZone';
    }
    startCreate() {
        // Overflow popup must hide during YearView drag create.
        this.view._overflowPopup?.hide();
        super.startCreate(...arguments);
    }
    // Drop handling
    dragEnter(drag) {
        const result = super.dragEnter(drag);
        if (result !== false) {
            this.view.contentElement.classList.add(this.draggingCls);
        }
        return result;
    }
    dragLeave(drag) {
        super.dragLeave(drag);
        this.view.contentElement.classList.remove(this.draggingCls);
    }
    dropHitCreate(drag, hit, dragFrom) {
        const me = this;
        let endDate   = me.clearTime(hit.date),
            startDate = me.clearTime(dragFrom.date);
        if (endDate < startDate) {
            [startDate, endDate] = [endDate, startDate];
        }
        // Helpful to use the dates because of changing DST across large date ranges
        me.setEventData({
            startDate,
            endDate : DH.add(endDate, 1, 'd')
        });
        me.days = me.makeDayRange(startDate, endDate);  // updates cell styles for these days
    }
    dropHitMove(drag, hit, eventRecord) {
        super.dropHitMove(drag, hit, eventRecord);
        const
            me      = this,
            tempRec = me.eventRecord;
        let { endDate } = tempRec;
        if (tempRec.allDay) {
            endDate = DH.add(endDate, -1, 'd');  // switch to inclusive
        }
        me.days = me.makeDayRange(tempRec.startDate, endDate);
    }
    // Misc
    includeDay(date) {
        const els = DomHelper.children(this.view.bodyElement, `[data-date='${date}']`);
        els.forEach(e => e.classList.add(`b-cal-tentative-event${this.view.hideNonWorkingDays ? ':not(.b-nonworking-day)' : ''}`));
        return els;
    }
    makeDayRange(startDate, endDate) {
        const days = [];
        for (let date = startDate; date <= endDate; date = DH.add(date, 1, 'd')) {
            days.push(DH.format(date, YYYY_MM_DD));
        }
        return days;
    }
    removeDay(date, els) {
        els.forEach(e => e.classList.remove('b-cal-tentative-event'));
    }
}
//====================================================================================================
// ResourceViewZone
class ResourceViewZone extends Base {
    static get configurable() {
        return {
            view : null,
            zones : {
                $config : ['nullify'],
                value   : []
            }
        };
    }
    updateView(view) {
        // Create sub zones for any already existent views.
        // If project had static data, they will be generated at config time.
        view.eachView(view => {
            this.onResourceViewViewCreate({ view });
        });
        // If data is loaded async, they will be created when Resources arrive.
        view.ion({
            viewCreate : 'onResourceViewViewCreate',
            thisObj    : this
        });
    }
    onResourceViewViewCreate({ view }) {
        const
            me    = this,
            {
                zones,
                owner
            }     = me,
            modes = owner.client.constructor.Modes,
            type  = owner.getViewZoneType(modes.resolveType(view.type));
        type && zones.push(owner.createZone(type, {
            view,
            resource : view.defaultCalendar
        }));
    }
    changeZones(zones, oldZones) {
        if (oldZones?.length && !zones) {
            for (let i = 0, { length } = oldZones; i < length; i++) {
                oldZones[i].destroy();
            }
        }
        return zones;
    }
}
//----------------------------------------------------------------------------------------------------
/**
 * Format expected to be returned in a `validateCreateFn`
 *
 * @typedef {Object} ValidateCreateResult
 * @property {Boolean} add Allow adding to store
 * @property {Boolean} edit Allow editor to open
 */
/**
 * This feature provides drag-based event creation and modification for Calendars. When enabled (which is the default
 * for calendars), the user can do the following via the mouse or touch screen:
 *
 *  - Create events by touching (or pressing the mouse button in) an the empty space and dragging. As the drag
 *    progresses, a tentative event is rendered. On release, the {@link Calendar.feature.EventEdit} feature displays
 *    the event edit dialog to complete the process. This can be disabled via the {@link #config-creatable} config.
 *  - Adjust the start or end times of an event in the day or week views by dragging the top or bottom of an event.
 *    This can be disabled via the {@link #config-resizable} config or the {@link Scheduler.model.mixin.EventModelMixin#field-resizable}
 *    field on a per-event basis.
 *  - Adjust the start or end date of an all-day event in the month view by dragging the left-most or right-most end
 *    of an event. This can be disabled via the {@link #config-resizable} config or the
 *    {@link Scheduler.model.mixin.EventModelMixin#field-resizable} field on a per-event basis.
 *  - Move an event from its current time (in day or week views) or date (in all views except agenda) by dragging the
 *    body of an event. This can be disabled via the {@link #config-draggable} config or via the
 *    {@link Scheduler.model.mixin.EventModelMixin#field-draggable} field on a per-event basis.
 *
 * ```javascript
 *  // change name for events created by drag to "Event":
 *  let calendar = new Calendar({
 *      features : {
 *          drag : {
 *              newName : 'Event'
 *          }
 *      }
 *  });
 * ```
 *
 * ## Asynchronous validation of resize, move and create operations
 *
 * You can easily add a confirmation step after an operation to show a dialog to the end user. This is done using the
 * {@link #event-beforeDragMoveEnd}, {@link #event-beforeDragCreateEnd} and {@link #event-beforeDragResizeEnd} events.
 *
 * ```javascript
 *  let calendar = new Calendar({
 *      listeners : {
 *          // Async event listeners allowing you to veto drag operations
 *          beforeDragMoveEnd : async({ eventRecord }) => {
 *               const result = await MessageDialog.confirm({
 *                   title   : 'Please confirm',
 *                   message : 'Is this the start time you wanted?'
 *               });
 *
 *               // Return true to accept the drop or false to reject it
 *               return result === MessageDialog.yesButton;
 *           },
 *           beforeDragResizeEnd : async({ eventRecord }) => {
 *               const result = await MessageDialog.confirm({
 *                   title   : 'Please confirm',
 *                   message : 'Is this the duration you wanted?'
 *               });
 *
 *               // Return true to accept the drop or false to reject it
 *               return result === MessageDialog.yesButton;
 *           },
 *           beforeDragCreateEnd : async({ eventRecord }) => {
 *               const result = await MessageDialog.confirm({
 *                   title   : 'Please confirm',
 *                   message : 'Want to create this event?'
 *               });
 *
 *               // Return true to accept the drop or false to reject it
 *               return result === MessageDialog.yesButton;
 *           }
 *       }
 *  });
 * ```
 *
 * ## Converting "All day" events to intra day events
 * You may drag an "All day" event out of the {@link Calendar.widget.DayView#property-allDayEvents top row}
 * of a {@link Calendar.widget.DayView} and into the body of the view.
 *
 * The event's {@link Scheduler.model.EventModel#field-duration} will be preserved if possible.
 *
 * If the newly calculated end time (based on the dropped-at time and the {@link Scheduler.model.EventModel#field-duration}
 * of the event) will overflow the end of that date then the event is converted to the duration configured
 * in the `DayView`'s {@link Calendar.widget.DayView#config-autoCreate}.
 *
 * This feature is **enabled** by default.
 *
 * The example below demonstrates configuration of the EventEdit feature and implements validation of
 * drag gestures so that no event interrupts fika from 9:30am to 10:30am.
 *
 * {@inlineexample Calendar/feature/CalendarDrag.js}
 *
 * @extends Calendar/feature/CalendarFeature
 * @classtype drag
 * @feature
 */
export default class CalendarDrag extends CalendarFeature {
    static get $name() {
        return 'CalendarDrag';
    }
    static get type() {
        return 'drag';
    }
    static get configurable() {
        return {
            disableOnReadOnly : true,
            localizableProperties : [
                'newName',
                'recurrenceTip'
            ],
            /**
             * Specify `false` to disallow creating events by drag gestures.
             * @config {Boolean}
             */
            creatable : true,
            /**
             * Specify `false` to disallow dragging events to new times or days.
             * @config {Boolean}
             */
            draggable : true,
            /**
             * The {@link Scheduler.model.EventModel#field-durationUnit} to use when drag-creating events.
             *
             * If not specified, the `dragUnit` property of the active view's
             * {@link Calendar.widget.mixin.CalendarMixin#property-autoCreate} is used.
             *
             * For {@link Calendar.widget.DayView}s, this is normally `'hour'`, for views with a granularity
             * level of one day, the default is `'day'`.
             * @config {String}
             */
            durationUnit : null,
            /**
             * A {@link Core.helper.DomHelper#typedef-DomConfig DOM config} object used to create an extra element
             * during event drag to contain the end time of the tentative event. This element contains the CSS class
             * `'b-cal-event-footer'` which can be used for styling.
             *
             * Set this to `null` to remove the end time rendering during drag operations.
             * @config {DomConfig}
             * @default
             */
            footer : {
                className : 'b-cal-event-footer'
            },
            /**
             * This is configured as a {@link Core.helper.DomHelper#function-createElement-static DomHelper}
             * specification and is promoted to an `HTMLElement` during initialization. This element is moved between
             * calendar event elements on hover in order to show drag handles on the event under the mouse.
             * @config {HTMLElement|DomConfig}
             * @private
             */
            gripper : {
                class : 'b-gripper'
            },
            /**
             * The name of new events or a function to call with the event record that will return the event name.
             *
             * If a function is supplied, it is called in the scope of (the `this` reference is set to) the
             * view being dragged within.
             *
             * Cpnfigure as `null` to use the dragged-in view's {@link Calendar.widget.mixin.CalendarMixin#config-autoCreate}
             * `newName` property.
             * @config {String|Function}
             * @param {Scheduler.model.EventModel} eventRecord The record being drag-created.
             * @returns {String} Name of new event
             */
            newName : 'L{newEvent}',
            /**
             * The text to display as a hint for creating recurring events during drag. This tip is displayed in the
             * {@link #config-tooltip} in the same place as the recurrence summary (when there is no recurrence to
             * display).
             * @config {String}
             */
            recurrenceTip : '(L{holdCtrlForRecurrence})',
            /**
             * Specify `false` to disallow dragging the edges of events to change their start or end.
             * @config {Boolean}
             */
            resizable : true,
            /**
             * The tooltip to display during a drag create process. Disabled by
             * default, set to `true`, or provide a tooltip / config object, to enable it.
             * @config {Boolean|EventTipConfig|Calendar.widget.EventTip}
             */
            tooltip : {
                $config : ['lazy', 'nullify'],
                value : {
                    type        : 'eventTip',
                    disabled    : true,
                    forSelector : null,
                    tools       : null
                }
            },
            /**
             * An empty function by default that allows you to perform custom validation on an event being created by
             * a drag gesture.
             *
             * The `drag` context contains the following data items (see {@link Core.util.drag.DragContext#function-get}):
             *
             *  - `eventDragMode` : The {@link #typedef-CalendarDragMode} object describing the drag operation.
             *  - `eventCreate` : The {@link Calendar.view.Calendar#typedef-CalendarHit} object that describes the target of the drag operation.
             *
             * Return `false` to cancel the create operation.
             *
             * This function can return a `Promise` (i.e., it can be `async`).
             *
             * Example:
             * ```javascript
             *  let calendar = new Calendar({
             *      features : {
             *          drag : {
             *              async validateCreateFn({ eventRecord, drag }) {
             *                  // This method can be async so it can make ajax requests or interact
             *                  // with the user...
             *
             *                  // if we return false, the event will be discarded
             *
             *                  // The following is equivalent to returning false:
             *                  //
             *                  // return {
             *                  //     // Do not add the event to the store
             *                  //     add  : false,
             *                  //     // Do not display the edit dialog (in the eventEdit feature):
             *                  //     edit : false
             *                  // };
             *                  //
             *                  // This simply adds the event and does not display the editor:
             *                  //
             *                  return {
             *                      edit : false
             *                  };
             *
             *                  // To do delay adding the event until the editor is done (and
             *                  // not via Cancel):
             *                  // return {
             *                  //     add : false
             *                  // };
             *              }
             *          }
             *      }
             *  });
             * ```
             * or:
             * ```javascript
             *  let calendar = new Calendar({
             *      features : {
             *          drag : {
             *              // Will resolve on the Calendar
             *              validateCreateFn : 'up.validateCreate'
             *          }
             *      },
             *      validateCreate{ eventRecord, drag } {
             *          ...
             *      }
             *  });
             * ```
             *
             * Return `true` if the event should be added to the event store and to inform the
             * {@link Calendar.feature.EventEdit eventEdit} feature to display the edit dialog.
             *
             * If this function returns an object, the `add` property can be set to `false`
             * to prevent adding to the event store, and the `edit` property can be set to `false` to inform the
             * `eventEdit` feature not to display the edit dialog.
             *
             * @config {Function|String}
             * @param {Object} info
             * @param {Core.util.drag.DragContext} info.drag The drag create context.
             * @param {Event} info.event The browser event object.
             * @param {Scheduler.model.EventModel} info.eventRecord The Event record.
             * @returns {Boolean|ValidateCreateResult} Return `false` if this event should be rejected.
             */
            validateCreateFn : () => {},
            /**
             * An empty function by default that allows you to perform custom validation on the event being moved to a
             * new date or time via a drag gesture.
             *
             * The `drag` context contains the following data items (see {@link Core.util.drag.DragContext#function-get}):
             *
             *  - `eventDragMode` : The {@link #typedef-CalendarDragMode} object describing the drag operation.
             *  - `eventRecord` : The {@link Scheduler.model.EventModel event record} being moved.
             *  - `eventSourceHit` : The {@link Calendar.view.Calendar#typedef-CalendarHit} object that describes the source of the drag operation.
             *
             * Return `false` to cancel the operation.
             *
             * This function can return a `Promise` (i.e., it can be `async`).
             *
             * Example:
             * ```javascript
             *  let calendar = new Calendar({
             *      features : {
             *          drag : {
             *              async validateMoveFn({ eventRecord, drag }) {
             *                  // This method can be async so it can make ajax requests or interact
             *                  // with the user...
             *
             *                  // if we return false, the event move will be discarded
             *              }
             *          }
             *      }
             *  });
             * ```
             * or:
             * ```javascript
             *  let calendar = new Calendar({
             *      features : {
             *          drag : {
             *              // Will resolve on the Calendar
             *              validateMoveFn : 'up.validateMove'
             *          }
             *      },
             *      validateMove{ eventRecord, drag } {
             *          ...
             *      }
             *  });
             * ```
             *
             * @config {Function|String}
             * @param {Object} info
             * @param {Core.util.drag.DragContext} info.drag The drag create context.
             * @param {Event} info.event The browser event object.
             * @param {Scheduler.model.EventModel} info.eventRecord The Event record.
             * @returns {Boolean} Return `false` if this event change should be rejected.
             */
            validateMoveFn : () => {},
            /**
             * An empty function by default that allows you to perform custom validation on the event whose `startDate`
             * or `endDate` is being modified via drag gesture.
             *
             * The `drag` context contains the following data items (see {@link Core.util.drag.DragContext#function-get}):
             *
             *  - `eventDragMode` : The {@link #typedef-CalendarDragMode} object describing the drag operation.
             *  - `eventSourceHit` : The {@link Calendar.view.Calendar#typedef-CalendarHit} object that describes the source of the drag operation.
             *
             * Return `false` to cancel the operation.
             *
             * This function can return a `Promise` (i.e., it can be `async`).
             *
             * Example:
             * ```javascript
             *  let calendar = new Calendar({
             *      features : {
             *          drag : {
             *              async validateResizeFn({ eventRecord, drag }) {
             *                  // This method can be async so it can make ajax requests or interact
             *                  // with the user...
             *
             *                  // if we return false, the event change will be discarded
             *              }
             *          }
             *      }
             *  });
             * ```
             * or:
             * ```javascript
             *  let calendar = new Calendar({
             *      features : {
             *          drag : {
             *              // Will resolve on the Calendar
             *              validateResizeFn : 'up.validateResize'
             *          }
             *      },
             *      validateResize{ eventRecord, drag } {
             *          ...
             *      }
             *  });
             * ```
             *
             * @config {Function|String}
             * @param {Object} info
             * @param {Core.util.drag.DragContext} info.drag The drag create context.
             * @param {Event} info.event The browser event object.
             * @param {Scheduler.model.EventModel} info.eventRecord The Event record.
             * @returns {Boolean|Promise} Return `false` if this event change should be rejected.
             */
            validateResizeFn : () => {},
            zoneTypes : {
                day         : DayZone,  // also covers WeekView
                month       : MonthZone,
                year        : YearZone,
                resource    : ResourceViewZone,
                dayresource : DayResourceZone
                // AgendaView is not supported (though it could be a Draggable just not a Droppable)
            },
            /**
             * By default, when an event is dragged from an external source, the event is removed from the
             * source EventStore. Configure this as `false` to leave the event in place to allow for the dragging
             * in of the same event repeatedly.
             * @prp {Boolean}
             * @default
             */
            removeFromExternalStore : true
        };
    }
    callOnFunctions = true;
    // Called as a callOnFunctions function by the firing of the beforeDragStart event.
    // The beforeAutoCreate event is also triggered by CalendarMixin's detection of its own
    // autoCreate gesture. This event gives a common point for validation of UI-initiated
    // event creation.
    onBeforeDragStart(props) {
        const
            { drag, event : domEvent, source } = props,
            resourceRecord = source.owner.activeView.getResourceRecord?.(drag.element);
        if (drag.peek('eventDragMode').type === 'create') {
            return this.client.trigger('beforeAutoCreate', { domEvent, date : drag.peek('date'), resourceRecord });
        }
    }
    changeGripper(gripper, was) {
        was?.remove();
        return gripper && DomHelper.createElement(gripper);
    }
    changeTooltip(config, existing) {
        if (config) {
            config = config === true ? this.constructor.configurable.tooltip.value : config;
            if (this.initialConfig.tooltip) {
                config.disabled = false;
            }
            config.ownerFeature = this;
        }
        return Widget.reconfigure(existing, config, /* owner = */ this);
    }
}
CalendarDrag.initClass();
CalendarDrag._$name = 'CalendarDrag';