import Base from '../../../Core/Base.js';
import DomHelper from '../../../Core/helper/DomHelper.js';
import SchedulerEventNavigation from '../../../Scheduler/view/mixin/EventNavigation.js';
/**
 * @module Calendar/view/mixin/EventNavigation
 */
const isCalendarMixin = w => w.isCalendarMixin;
/**
 * Mixin that tracks event selection by clicking on one or more events in the calendar.
 * @mixin
 * @extends Scheduler/view/mixin/EventNavigation
 *
 * @typings Scheduler.view.mixin.EventNavigation -> Scheduler.view.mixin.SchedulerEventNavigation
 */
export default Target => class EventNavigation extends SchedulerEventNavigation(Target || Base) {
    // region Hidden configs
    /**
     * @method beforeAssignmentDelete
     * @hide
     */
    // endRegion
    static get configurable() {
        return {
            /**
             * A config object to use when creating the {@link Core.helper.util.Navigator}
             * to use to perform keyboard navigation in the timeline.
             * @config {NavigatorConfig}
             * @default
             * @category Misc
             * @internal
             */
            navigator : {
                allowCtrlKey      : true,
                scrollSilently    : true,
                datasetIdProperty : 'eventId',
                // TAB navigation is used, so the Navigator *reacts* to navigation rather than
                // listens for keystrokes and moves focus.
                itemsTabbable : true,
                // Override inherited SchedulerEventNavigation handlers
                keys : {
                    ArrowUp     : null,
                    ArrowDown   : null,
                    Escape      : null,
                    Tab         : null,
                    'SHIFT+Tab' : null
                }
            }
        };
    }
    normalizeTarget(event) {
        return event.eventRecord;
    }
    changeNavigator(navigator) {
        const me = this;
        // Force evaluation to create the eventSelector
        me.getConfig('eventCls');
        return super.changeNavigator(me.constructor.mergeConfigs({
            target : me.element,
            itemSelector : `.b-cal-cell-overflow,.b-cal-cell-no-content,${me.eventSelector}:not(.b-minimal)`,
            // Calendar doesn't have a scrollable.
            skipScrollIntoView : true
        }, navigator));
    }
    onViewRefresh() {
        super.onViewRefresh?.(...arguments);
        // If the view refreshed while active, correct the active element
        if (this.navigator.activeItem && !this.activeView._overflowPopup?.containsFocus) {
            const activeEvent = this.getEventRecord(this.navigator.activeItem);
            if (activeEvent) {
                this.navigator.updateActiveItem(this.getElementFromEventRecord(activeEvent));
            }
        }
    }
    onCalendarStoreChange({ action, records }) {
        const
            me            = this,
            { navigator } = me,
            activeItem    = navigator?.activeItem;
        // Handle keeping a close activeItem if the activeItem ever leaves the store
        if (activeItem && !me.eventStore.includes(activeItem.dataset.eventId)) {
            const
                activeView         = me.constructor.fromElement(activeItem, isCalendarMixin) || me.activeView,
                { _overflowPopup } = activeView,
                nextItem           = navigator.next;
            // If we're in a contiguous range of event bars and not using syncId to map
            // events to the same element, then the following events will just "move up".
            // Only the last element will be removed, so we "navigate" to the same element.
            if (nextItem === activeItem.nextSibling && !nextItem.parentNode.syncIdMap) {
                // Trigger a navigation event because focus will remain in the document
                // so we are silently navigating to the next item.
                navigator.triggerNavigate({
                    eventPhase : Event.BUBBLING_PHASE,
                    target     : nextItem
                }, nextItem);
            }
            else {
                // First, navigate forwards or back
                let fallback = nextItem || navigator.previous;
                // But if we are in the overflow popup and that took us out because it was empty,
                // navigate forwards or back from the popup's source cell.
                if (_overflowPopup?.containsFocus && !_overflowPopup.contentElement.contains(fallback)) {
                    const { target } = _overflowPopup.lastAlignSpec;
                    // Move forward or backward from the cell.
                    fallback = navigator.getAdjacent(1, target.nextSibling) || navigator.getAdjacent(-1, target);
                }
                // If a previous or next event element was not found, push focus
                // to where it came in from with a forced outward focus reversion if they clicked straight
                // in and there was no relatedTarget.
                if (!fallback) {
                    me.widgetMap.viewContainer.revertFocus(true);
                }
                else {
                    // Capture the full context of the soon-to-be-active fallback event.
                    // We will need to recapture the corresponding element after the refresh.
                    const
                        nextActiveDate    = activeView.getDateFromElement(fallback),
                        nextActiveEventId = fallback.dataset.eventId,
                        nextActiveView    = me.constructor.fromElement(fallback).closest(isCalendarMixin);
                    // Must keep focus in the Calendar if possible.
                    // This item *should* remain undisturbed by a DomSync refresh
                    fallback.focus();
                    // After the impending refresh, restore focus
                    nextActiveView.ion({
                        refresh : () => {
                            // Recapture the matching element after the refresh
                            fallback = nextActiveView.getEventElement(nextActiveEventId, nextActiveDate);
                            // May not always be one. YearView is not yet keyboard-navigable.
                            if (fallback) {
                                if (fallback === DomHelper.getActiveElement(fallback)) {
                                    // Trigger a navigation event because focus has remained in the document
                                    // so it was a silent navigate to the next item.
                                    navigator.triggerNavigate({
                                        eventPhase : Event.BUBBLING_PHASE,
                                        target     : fallback
                                    }, fallback);
                                }
                                else {
                                    fallback.focus();
                                }
                            }
                            navigator.updateActiveItem(fallback, activeItem);
                        },
                        once : true
                    });
                }
            }
        }
        super.onCalendarStoreChange?.(...arguments);
    }
    onElementKeyDown(keyEvent) {
        if (keyEvent.target.closest(this.navigator.itemSelector)) {
            super.onElementKeyDown?.(keyEvent);
        }
    }
    /**
     * The currently focused event record.
     * @property {Scheduler.model.EventModel}
     * @readonly
     */
    get activeEvent() {
        const { activeItem } = this.navigator;
        if (activeItem) {
            return this.resolveEventRecord(activeItem);
        }
    }
    // This does not need a className on Widgets.
    // Each *Class* which doesn't need 'b-' + constructor.name.toLowerCase() automatically adding
    // to the Widget it's mixed in to should implement thus.
    get widgetClass() {}
};
