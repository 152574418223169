import SchedulerTimeRangeModel from '../../Scheduler/model/TimeRangeModel.js';
/**
 * @module Calendar/model/TimeRangeModel
 */
/**
 * This class represents a named time range with additional, Calendar-specific fields. It is used by the
 * {@link Calendar.feature.TimeRanges} feature.
 *
 * This class inherits most of its fields from {@link Scheduler.model.TimeSpan}. The most important of these fields are
 * the following:
 *
 * - {@link #field-name}
 * - {@link #field-startDate}
 * - {@link #field-endDate}
 * - {@link #field-iconCls}
 *
 * It's an indirect subclass of {@link Core.data.Model}. Please refer to documentation of those classes to become
 * familiar with the base interface of this class.
 *
 * This class defines the following fields:
 *
 * - {@link #field-alignment}
 * - {@link #field-color}
 * - {@link #field-footer}
 * - {@link #field-rotation}
 *
 * The data source of any field can be customized in the subclass. Please refer to {@link Core.data.Model} for details.
 *
 * @extends Scheduler/model/TimeRangeModel
 * @uninherit Core/data/mixin/TreeNode
 * @typings Scheduler.model.TimeRangeModel -> Scheduler.model.SchedulerTimeRangeModel
 */
export default class TimeRangeModel extends SchedulerTimeRangeModel {
    /**
     * @hidefields children, parentId, parentIndex
     */
    //region Fields
    static fields = [
        /**
         * The side of the time range on which the header will be displayed The default value is `'start'`.
         *
         * This value determines where to place the time range header relative to events. A value of `'start'` will
         * place the header on the left side, while `'end'` will place the time range on the right side (these will
         * be reversed in RTL mode).
         *
         * @field {'start'|'end'} alignment
         * @category Styling
         * @default 'start'
         */
        {
            name         : 'alignment',
            defaultValue : 'start',
            internal     : true
        },
        /**
         * The primary color of the header element.
         * @field {'red'|'pink'|'purple'|'violet'|'indigo'|'blue'|'cyan'|'teal'|'magenta'|'green'|'lime'|'yellow'|'orange'|'deep-orange'|'gray'|'gantt-green'|'light-gray'} color
         * @category Styling
         */
        {
            name     : 'color',
            internal : true
        },
        /**
         * The text for the footer.
         * @field {String} footer
         * @category Styling
         */
        {
            name     : 'footer',
            internal : true
        },
        /**
         * The direction to rotate header/footer text.
         * @field {'neg'|'pos'} rotation
         * @category Styling
         */
        {
            name     : 'rotation',
            internal : true
        }
    ];
    //endregion
}
TimeRangeModel._$name = 'TimeRangeModel';