import Widget from '../../Core/widget/Widget.js';
import DayCellCollecter from './mixin/DayCellCollecter.js';
import CalendarMixin from './mixin/CalendarMixin.js';
import DayCellRenderer from './mixin/DayCellRenderer.js';
import DH from '../../Core/helper/DateHelper.js';
import EventSlots from '../util/EventSlots.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import DomSync from '../../Core/helper/DomSync.js';
import EventHelper from '../../Core/helper/EventHelper.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import DayTime from '../../Core/util/DayTime.js';
import Scroller from '../../Core/helper/util/Scroller.js';
import Objects from '../../Core/helper/util/Objects.js';
import DateRangeOwner from './mixin/DateRangeOwner.js';
/**
 * @module Calendar/widget/CalendarRow
 */
const
    emptyObject    = Object.freeze({}),
    expandGestures = {
        shrinkwrap : 1,
        expand     : 1
    },
    formatDayPart = (format, sep, d1, d2, compress) => {
        const s1 = DH.format(d1, format);
        return d2 ? `${s1}${compress ? sep.trim() : sep}${DH.format(d2, format).slice(compress ? -1 : 0)}` : s1;
    };
/**
 * This is normally used as the "All day events" section of a {@link Calendar.widget.DayView} or
 * {@link Calendar.widget.WeekView}. But it may be used standalone as a regular Widget.
 *
 * As a standalone widget, it will lack the capabilities of the {@link Calendar.view.Calendar Calendar}
 * class, such as keyboard-based event to event navigation and drag/drop features.
 *
 * A Widget which displays a single row of calendar cells for a configured range of dates.
 *
 * Cell rendering can be customized using the {@link #config-dayCellRenderer} method.
 *
 * Event rendering can be customized using the {@link #config-eventRenderer} method.
 *
 * @extends Core/widget/Widget
 * @mixes Core/widget/mixin/Responsive
 * @mixes Calendar/widget/mixin/DayCellRenderer
 * @mixes Calendar/widget/mixin/CalendarMixin
 * @mixes Calendar/widget/mixin/DateRangeOwner
 * @classtype calendarrow
 */
export default class CalendarRow extends Widget.mixin(
    CalendarMixin, DayCellCollecter, DayCellRenderer, DateRangeOwner
) {
    static get $name() {
        return 'CalendarRow';
    }
    // Factoryable type name
    static get type() {
        return 'calendarrow';
    }
    static get configurable() {
        return {
            // region Hidden configs
            /**
             * @config dayCellRenderer
             * @hide
             */
            // endRegion
            cls : 'b-calendar-days',
            dragUnit : 'day',
            /**
             * Set this to `false` to not use transition for this Widget as it changes height
             *
             * @config {Boolean}
             * @default
             */
            animate : true,
            /**
             * By default, this widget displays a maximum of {@link #config-defaultEventRowCount} events
             * before showing overflow indicators, and becoming {@link #function-toggleExpandCollapse expandable}.
             *
             * Configuring this as `true` makes this widget accommodate all events with no overflow.
             * @config {Boolean}
             */
            autoHeight : null,
            autoRefresh : [
                'dayNameFormat',
                'dayNumberCompress',
                'dayNumberFormat',
                'daySeparator',
                'hideEmptyDays'
            ],
            descriptionFormat : [true, true],
            /**
             * Either the start hour of the day, or a *24 hour* `HH:MM` string denoting the start of the first rendered
             * daily time block.
             *
             * @config {String|Number}
             * @default 0
             */
            dayStartShift : null,
            dayTime : 0,
            /**
             * The number of events to show to define the height of this widget by default if this widget
             * {@link #config-autoHeight} is *not* defined as `true`. The widget may be expanded to accommodate all
             * the events if there are overflowing events.
             * @config {Number}
             * @default
             */
            defaultEventRowCount : 3,
            /**
             * When `true`, a full week of dates is rendered for a single day range. This should not be applied when
             * the `duration` spans multiple days.
             * @prp {Boolean}
             */
            fullWeek : null,
            /**
             * By default, the day columns flex to all fit within the width of this widget.
             *
             * configuring a `minDayWidth` means that if the day columns do not fit within this
             * widget's width, it becomes horizontally scrollable.
             * @config {Number}
             */
            minDayWidth : null,
            scrollable : {
                overflowX     : 'hidden',
                overflowY     : 'hidden',
                propagateSync : true
            },
            headerScroller : {
                $config : ['lazy', 'nullify'],
                value   : {
                    overflowX     : 'hidden',
                    overflowY     : 'hidden',
                    propagateSync : true
                }
            },
            /**
             * The {@link Core.helper.DateHelper} format string for day names (e.g., "ddd" for "Mon", "Tue", ...)
             * @config {String}
             * @default
             */
            dayNameFormat : 'ddd',
            /**
             * Specify `true` to textually compress day number pairs. For example, compress "11/12" to "11/2". Also
             * when `true`, the {@link #config-daySeparator} is trimmed of any whitespace.
             * @config {Boolean}
             * @default false
             */
            dayNumberCompress : null,
            /**
             * The {@link Core.helper.DateHelper} format string for day numbers (e.g., "D" for "9", "10", ...)
             * @config {String}
             * @default
             */
            dayNumberFormat : 'D',
            /**
             * The separator between day names and numbers. If a single string is provided, it applies to both day
             * names and day numbers. If an array of 2 strings is provided, the [0] element is the separator for day
             * names and [1] element is the separator for day numbers.
             * @config {String|String[]}
             */
            daySeparator : '-',
            responsive : {
                small : {
                    // dayNameFormat     : 'd1/d1',
                    // dayNumberFormat   : 'D/D',
                    dayNameFormat     : 'd1',
                    dayNumberCompress : true,
                    daySeparator      : '/'
                },
                '*' : {
                    // dayNameFormat     : 'ddd-ddd',
                    // dayNumberFormat   : 'D-D',
                    dayNameFormat     : 'ddd',
                    dayNumberCompress : false,
                    daySeparator      : '-'
                }
            },
            dayNameSelector : '.b-cal-cell-header',
            // We are in control of our size. Once we can calculate our maxEventCount, then from
            // that and the eventContainerTop and the defaultEventRowCount and the autoHeight and
            // expanded settings we calculate our height.
            // We have to have been through one initial refresh cycle to measure eventContainerTop
            // which is handled in onInternalPaint.
            monitorResize : false,
            /**
             * How much extra space in pixels or other DOM units to allow below the event bars to
             * facilitate drag-create gestures.
             * @config {Number|String}
             * @default
             */
            gutterHeight : null,
            expanded : null,
            /**
             * How the view responds to clicking on a `+n more` button in an overflowing day cell.
             *
             * The default value, `'popup'`, means that a small dialog box showing the full complement
             * of events for that cell is shown aligned to the cell.
             *
             * When set to `'expand'`, then clicking the `+n more` button causes the encapsulating
             * row to expand to accommodate all events in that row with no overflow.
             *
             * Navigating to a new month resets the row to its default, flexed height.
             * @config {'popup'|'expand'} overflowClickAction
             * @default
             */
            overflowClickAction : 'popup',
            /**
             * A function, or name of a function which is passed the {@link DomConfig} object which
             * will be used to sync with a day cell header.
             *
             * ```javascript
             * dayHeaderRenderer : function(headerDomConfig, cellData) {
             *     if (this.isSpecialDate(cellData.date)) {
             *         headerDomConfig.className['b-is-special-date'] = 1;
             *
             *         // Add to the content element's children
             *         headerDomConfig.children.push({
             *             text : 'Special day',
             *             className : 'b-special-day
             *         });
             *     }
             * }
             *```
             *
             * The result is used to sync the DOM of the day column.
             *
             * @config {Function|String} dayHeaderRenderer
             * @param {DomConfig} headerDomConfig A {@link DomConfig} config object which is used to sync the day header element.
             * @param {Object} headerDomConfig.className An object who's truthy property names will be applied as class names.
             * @param {Object} headerDomConfig.style A CSS style definition object.
             * @param {Object} headerDomConfig.dataset The DOM data properties to set.
             * @param {DomConfig[]} headerDomConfig.children The {@link DomConfig} definitions the header content.
             *   There will be 2 `children` encapsulating the day name and the date.
             * @param {DayCell} cellData An object that contains data about the cell.
             * @returns {String}
             */
            dayHeaderRenderer : null,
            /**
             * Configure this as `true` to hide day columns which contain no events.
             *
             * {@note}Use with care. This may result in no day columns being rendered
             * for completely empty time ranges.{/@note}
             * @prp {Boolean}
             * @default false
             */
            hideEmptyDays : null
        };
    }
    /**
     * A Promise which will be in _Pending_ state only when the `minHeight` is animating to a new value.
     * @property {Promise}
     */
    heightAnimation = Promise.resolve;
    compose() {
        return {
            children : {
                headerElement : {
                    className : 'b-calendarrow-header',
                    children  : {
                        headerCellContainer : {
                            className : 'b-calendarrow-header-container'
                        },
                        scrollPadElement : {
                            // retainElement : true, // DomSync should leave this alone
                            ...DomHelper.scrollBarPadElement
                        }
                    }
                },
                bodyElement : {
                    className : 'b-calendarrow-body',
                    children  : {
                        cellContainer : {
                            reference : 'cellContainer',
                            className : 'b-calendarrow-cell-container'
                        }
                    }
                }
            }
        };
    }
    onInternalPaint({ firstPaint }) {
        // On first paint, create measurable elements, the refresh measures and caches uninitialized
        // heights, so delete the cached values so that they get measured again
        if (firstPaint) {
            const
                me           = this,
                { fonts }    = document,
                onFirstPaint = () => {
                    // In case we are arriving here after the font load. Need to remeasure
                    // elements that are sized by text.
                    me._eventContainerTop = me._eventContainerHeight = null;
                    if (me.eventStore.count) {
                        me.performResizeRefresh(null, null, true);
                    }
                };
            // If the API is available, we need to remeasure when fonts become ready
            if (fonts?.status !== 'loaded') {
                fonts.ready.then(() => !me.isDestroyed && onFirstPaint());
            }
            onFirstPaint();
        }
    }
    changeDaySeparator(daySeparator) {
        return Array.isArray(daySeparator) ? daySeparator : [daySeparator, daySeparator];
    }
    changeElement() {
        const result = super.changeElement(...arguments);
        // Ensure header scroller is in sync in the X axis with the main scroller
        this.scrollable.addPartner(this.headerScroller, 'x');
        return result;
    }
    changeHeaderScroller(headerScroller, oldHeaderScroller) {
        if (headerScroller) {
            headerScroller = Scroller.new({
                _id     : `${this.id}-header-scroller`,
                element : this.headerCellContainer,
                widget  : this
            }, headerScroller);
        }
        else {
            oldHeaderScroller?.destroy();
        }
        return headerScroller;
    }
    /**
     * Brings an event or a time into view.
     * @param {Scheduler.model.EventModel|Date|Number} target The event to scroll to or
     * a `Date` to read the hour value from, or an hour number.
     * @param {BryntumScrollOptions} [options] How to scroll.
     * @returns {Promise} A promise which is resolved when the target has been scrolled into view.
     */
    async scrollTo(target) {
        const
            me              = this,
            { eventFilter } = me;
        // If it's an event and filtered in, and intersects our date range, but we don't have an element
        // for it, then it must be because we are collapsed. Expand so it is rendered.
        if (target.isEvent && (!eventFilter || eventFilter(target)) && DH.intersectSpans(me.startDate, me.endDate, target.startDate, target.endDate)) {
            if (!me.getEventElement(target)) {
                if (!(me.expanded || me.autoHeight)) {
                    me.toggleExpandCollapse();
                }
            }
        }
        return super.scrollTo(...arguments);
    }
    updateDate(date, oldDate) {
        const me = this;
        let el = oldDate && me.getDayHeader(oldDate);
        el?.classList.remove('b-selected-date');
        // Base class may move our range if new date is outside of current range.
        super.updateDate?.(date, oldDate);
        el = date && me.getDayHeader(date);
        el?.classList.add('b-selected-date');
    }
    getDayHeader(date) {
        if (typeof date !== 'string') {
            date = this.dateKey(date);
        }
        return this.headerCellContainer.querySelector(`.b-cal-cell-header[data-header-date="${date}"]`);
    }
    get overflowElement() {
        return this.bodyElement;
    }
    createCellMap(getEventsOptions = {}) {
        const
            me                = this,
            hiddenDays        = me.hideNonWorkingDays ? me.nonWorkingDays : emptyObject,
            { maxEventCount } = me,
            options           = ObjectHelper.assign({
                dayTime      : me.dayTime,
                // Only the first *visible* cell needs overflows flowing into it.
                // from after that, propagateCellEvents copies events forward, so
                // the getEvents will use the "startDate" index to extract events for a date.
                getDateIndex : date => date > (me.firstVisibleDate || me.startDate) ? 'startDate' : 'date'
            }, getEventsOptions),
            cellMap           = super.createCellMap(options),
            { owner }         = me;
        let newMaxEventCount = 0,
            lastVisibleCellData;
        cellMap.forEach(cellData => {
            if (!hiddenDays[cellData.day]) {
                lastVisibleCellData = cellData;
            }
            newMaxEventCount = Math.max(newMaxEventCount, cellData.renderedEvents.length);
        });
        me.maxEventCount = newMaxEventCount;
        // If we changed maxEventCount, recreate the data
        if (newMaxEventCount !== maxEventCount) {
            cellMap.clear();
            return me.createCellMap(options);
        }
        // Have to set this on the last cellData entry which is visible
        lastVisibleCellData && (lastVisibleCellData.isRowEnd = true);
        owner?.element.classList.toggle('b-has-allday-events', Boolean(cellMap.size));
        return cellMap;
    }
    collectEvents(options) {
        // Only the first cell needs overflows flowing into it.
        // from after that, propagateCellEvents copies events forward
        options = ObjectHelper.assign({
            dayTime : this.dayTime
        }, options, {
            // Only the first *visible* cell needs overflows flowing into it.
            // from after that, propagateCellEvents copies events forward, so
            // the getEvents will use the "startDate" index to extract events for a date.
            getDateIndex : date => date > (this.firstVisibleDate || this.startDate) ? 'startDate' : 'date'
        });
        return super.collectEvents(options);
    }
    createCellData(date) {
        const
            me = this,
            columnIndex = DH.diff(me.startDate, date, 'day'),
            visibleColumnIndex = columnIndex;
        return Object.assign(super.createCellData(date), {
            visibleColumnIndex,
            columnIndex,
            isRowStart : visibleColumnIndex === 0,
            isRowEnd   : false,
            // Events can forward-occupy slots if they
            // overrun their start day.
            // So the next step is to propagate forward
            // multi day events into future cells they cover.
            renderedEvents : new EventSlots()
        });
    }
    get dayStartShift() {
        return this.dayTime?.startShift ?? this._dayStartShift;
    }
    updateDayStartShift(dayStartShift) {
        // Day number circle needs to be oval to accommodate 00-00
        this.element.classList.toggle('b-shifted-day', Boolean(dayStartShift));
        this.dayTime = new DayTime(dayStartShift);
    }
    changeDayTime(dayTime) {
        if (!dayTime) {
            dayTime = new DayTime(this.dayStartShift);
        }
        // Allow DayTime config object to be passed
        if (!dayTime.isDayTime) {
            dayTime = new DayTime(dayTime);
        }
        if (!this._dayTime?.equals(dayTime)) {
            return dayTime;
        }
    }
    dayOfDate(date) {
        return this.dayTime.dayOfDate(date);
    }
    updateMinDayWidth(minDayWidth) {
        const
            {
                scrollable,
                headerScroller
            }           = this,
            { element } = scrollable,
            { style }   = this.element;
        // Need min-width : 0 to allow the element to shrink
        style.setProperty('--min-day-width', minDayWidth ? DomHelper.setLength(minDayWidth) : 0);
        style.setProperty('--cell-container-overflow', minDayWidth ? 'visible' : 'hidden');
        // Forced synchronous layout here, but we need to ascertain horizontal overflow state
        if (minDayWidth && element.scrollWidth > element.clientWidth) {
            // Overflow 'hidden-scroll' is a special Scroller setting.
            // With overlayed scrollbars it just means 'auto'
            // With space-consuming scrollbars, it hides scrollbars using CSS while using auto.
            // But if the two axes scroll, then both scrollbars should not be hidden so it will
            // fall back to using overflow:hidden and a `wheel` listener.
            scrollable.overflowX = headerScroller.overflowX = 'hidden-scroll';
        }
        else {
            scrollable.overflowX = headerScroller.overflowX = false;
        }
    }
    updateEventHeight() {
        // CalendarMixin needs to know first
        super.updateEventHeight(...arguments);
        if (!this.autoHeight && !this.isConfiguring && !this.expanded) {
            this.setEventContentHeight(this.cellContentHeight);
        }
    }
    updateGutterHeight() {
        if (!this.isConfiguring) {
            this.setEventContentHeight(this.cellContentHeight);
        }
    }
    get stepUnit() {
        return this.duration > 1 ? `${this.duration} ${this.L('L{DayView.daysUnit}')}` : this.L('L{DayView.dayUnit}');
    }
    updateNonWorkingDays() {
        this.onVisibleDateRangeChange();
    }
    updateHideNonWorkingDays() {
        super.updateHideNonWorkingDays(...arguments);
        this.onVisibleDateRangeChange();
    }
    // Override from DayCellRenderer
    // Called automatically on the CellOverflow${overflowPopupTrigger} event because of callOnFunctions
    onCellOverflowGesture({ date }) {
        if (expandGestures[this.overflowClickAction.toLowerCase()]) {
            this.expanded = true;
        }
        else {
            super.onCellOverflowGesture(...arguments);
        }
    }
    onVisibleDateRangeChange() {
        if (!this.isConfiguring) {
            this._cellMap?.clear();
            this.refresh();
        }
    }
    changeAutoHeight(autoHeight) {
        const
            me                = this,
            // Must be cached before property changes. This is calculated in this class
            { eventsPerCell } = me;
        // We need to update in the changer because we need to cache conditions that
        // applied before the change: eventsPerCell
        me._autoHeight = autoHeight;
        me.setEventContentHeight(me.cellContentHeight);
        // Calculates new values for eventsPerCell and eventContainerTop
        // and handles changes to either.
        if (!me.isConfiguring) {
            me.performResizeRefresh(eventsPerCell, me.eventContainerTop, true);
        }
    }
    async setEventContentHeight(eventContentHeight, syncingSiblings) {
        const
            me = this,
            {
                cellContainer : element,
                parent
            }  = me;
        // Reject non-changes.
        // This isn't a config because setting it is an async awaitable method.
        if (me._eventContentHeight !== eventContentHeight) {
            // Update any smaller sibling rows unless that is what is being done to us
            if (!syncingSiblings && parent?.isDayView && parent.parent?.isResourceView) {
                const siblings = parent.parent.items.map(v => v.allDayEvents).filter(v => v && v !== me);
                // If we are the allDayEvents of a DayView which has siblings, we must consult
                // all siblings which are not time axes/scrollers and find the max so that we all
                // stay in sync.
                // This may be the case in a ResourceView of several DayViews side-by-side.
                eventContentHeight = Math.max(eventContentHeight || 0, ...siblings.filter(v => !v.owner.isResourceDayViewTimeAxis).map(v => v.calculateCellContentHeight() || 0));
                siblings?.forEach(v => {
                    v.setEventContentHeight(eventContentHeight, true);
                });
            }
            const { height : oldHeight }  = me;
            let { animate } = me;
            // Set this widget to the animating state if we are configured to animate the height
            me.isAnimating = animate;
            // Once we have set the animating flag which sets the CSS class, we can detect any duration
            const duration = DomHelper.getPropertyTransitionDuration(element, 'height');
            if (!duration) {
                me.isAnimating = animate = false;
            }
            // Only show the scrollbar when we need to - if there's a space-consuming scrollbar shown.
            // We pre-change the cell heights in unanimated mode and see if there's overflow so that
            // we can set overflowY appropriately before the animation is kicked off.
            // Needs to be at least 1px so that horizontal scrolling can stay in sync
            element.style.height = `${eventContentHeight || 1}px`;
            // If it's zero height, the top border will not be shown because this border would collide
            // with the border-top of the day content element below.
            me.bodyElement.classList.toggle('b-zero-height', !Boolean(eventContentHeight));
            if (animate) {
                await (me.heightAnimation = EventHelper.waitForTransitionEnd({
                    property : 'height',
                    element,
                    duration
                }));
            }
            if (!me.isDestroyed) {
                const { height } = me;
                me.isAnimating = false;
                me._eventContentHeight = eventContentHeight;
                me.owner?.syncScrollbarPadding?.();
                /**
                 * Fires when this CalendarRow has completely changed its height.
                 * This fires *after* the animation has finished.
                 * @param {Calendar.widget.CalendarRow} source The triggering instance.
                 * @param {Number} oldHeight The old height.
                 * @param {Number} height The new height.
                 * @event heightChange
                 */
                if (height !== oldHeight) {
                    me.trigger('heightChange', {
                        height,
                        oldHeight
                    });
                }
            }
        }
    }
    get hasOverflow() {
        return !this.autoHeight && this.maxEventCount > this.defaultEventRowCount;
    }
    get eventsPerCell() {
        const
            me = this,
            { maxEventCount } = me;
        return (me.expanded || me.autoHeight) ? maxEventCount : Math.min(maxEventCount, me.defaultEventRowCount);
    }
    get cellContentHeight() {
        return this.calculateCellContentHeight();
    }
    calculateCellContentHeight() {
        // The minHeight must accommodate at least one row, but by default, defaultEventRowCount rows of events.
        // The all day section is expandable. If autoHeight, the minHeight must accommodate all events.
        const
            me = this,
            { gutterHeight } = me;
        let result = Math.ceil(me.eventContainerTop + me.eventsPerCell * (me.eventHeightInPixels + me.eventSpacing));
        // Allow gutterHeight : 1em to result in a 'calc(100px + 5em)' type value
        if (gutterHeight) {
            // The gutter is *instead of* the ${eventSpacing}px below the last event
            if (me.eventsPerCell) {
                result -= me.eventSpacing;
            }
            if (isNaN(gutterHeight)) {
                result = `calc(${result}px + ${gutterHeight})`;
            }
            else {
                result += Number(gutterHeight);
            }
        }
        return result;
    }
    getDateFromPosition(clientX, clientY, local = false, keyParser) {
        const
            me         = this,
            {
                eventContentElement
            }          = me,
            rowRect    = eventContentElement.getBoundingClientRect(),
            dx         = me.rtl ? rowRect.right - clientX : clientX - rowRect.left,
            width      = eventContentElement.scrollWidth,
            // We have to compare element position because day columns may have different widths
            overCell   = me.getDayElementFromX(clientX);
        if (dx < 0 || width < dx || clientY < rowRect.top || rowRect.bottom < clientY) {
            return null;
        }
        return me.getDateFromElement(overCell, keyParser);
    }
    /**
     * If not {@link #config-autoHeight}, this toggles between the collapsed state where it shows
     * the {@link #config-defaultEventRowCount} even if more event rows exist, and the expanded state
     * (which is equivalent to {@link #config-autoHeight}) where all event rows are shown.
     */
    async toggleExpandCollapse() {
        const { expanded } = this;
        // Do not go through the setter because this method is async and needs to be awaited
        await this.updateExpanded(!Boolean(expanded), expanded);
    }
    async updateExpanded(expanded, wasExpanded) {
        const me = this;
        // Temporarily set the property so that eventsPerCell yields the "from" count.
        me._expanded = wasExpanded;
        const { eventsPerCell } = me;
        me._expanded = expanded;
        // If we are expanding, a refresh will update the height *after* the refresh
        // which is what we want: it will "reveal" existing event bars
        if (me.expanded) {
            // If not yet visible, this will be called on paint.
            if (me.isVisible) {
                me.performResizeRefresh(eventsPerCell, me.eventContainerTop, true);
                await me.heightAnimation;
            }
        }
        // If we are collapsing however, we want to hide the overflowing event bars
        // and *only then* refresh to cut the event bars back.
        else {
            me.scrollable.overflowY = 'hidden';
            await me.setEventContentHeight(me.cellContentHeight);
            // Calculates new values for eventsPerCell and eventContainerTop
            // and handles changes to either.
            if (wasExpanded && !me.isDestroyed) {
                me.performResizeRefresh(eventsPerCell, me.eventContainerTop, true);
            }
        }
    }
    get visibleDateHeaders() {
        const me = this;
        let firstDate, lastDate;
        if (me.fullWeek) {
            firstDate = me.month.getWeekStart(me.month.getWeekNumber(me.startDate));
            while (me.hiddenNonWorkingDays[firstDate.getDay()]) {
                firstDate.setDate(firstDate.getDate() + 1);
            }
            lastDate = DH.add(firstDate, 6, 'd');  // inclusive
            while (me.hiddenNonWorkingDays[lastDate.getDay()]) {
                lastDate.setDate(lastDate.getDate() - 1);
            }
        }
        else {
            firstDate = me.firstVisibleDate;
            lastDate = me.lastVisibleDate;
        }
        return [firstDate, lastDate];
    }
    // We must implement the CalendarMixin interface.
    // All views must expose a doRefresh method.
    doRefresh() {
        this._cellMap?.clear();
        const
            me             = this,
            {
                dayTime,
                fullWeek,
                startDate,
                endDate,
                headerCellContainer,
                cellContainer,
                dayHeaderRenderer,
                hiddenNonWorkingDays,
                hideEmptyDays,
                owner
            } = me,
            [from, to]     = me.visibleDateHeaders,
            cellMap        = (startDate || endDate) && me.cellMap,
            nonWorkingDays = me.nonWorkingDays ?? me.month.nonWorkingDays,
            cellDataBlocks = [],
            eventCells     = [],
            headerCells    = [];
        if (!cellMap) {
            return;
        }
        let columnIndex = 0,
            visibleColumnIndex = 0,
            currentDay, i, lastWorkingDayCell;
        // Collect all our cell data blocks and measure our week length
        // Note: "!(to < date)" ==> "date <= to" but works for Date since they are never ==
        for (const date = from; !(to < date); date.setDate(date.getDate() + 1), columnIndex++) {
            const
                cellData     = cellMap.get(dayTime.dateKey(date)) || me.createCellData(date),
                { day, key } = cellData,
                dayEvents    = hideEmptyDays && (owner ? owner.getEventsForDay(key, me.startDate) : cellData.renderedEvents),
                skipDay      = hideEmptyDays && !dayEvents?.length;
            // There'll be no cell if it's a hidden, nonworking day or an empty date that we are hiding
            if (!(hiddenNonWorkingDays[day] || skipDay)) {
                cellData.columnIndex = cellData.cellIndex = columnIndex++;
                cellData.isRowStart = visibleColumnIndex === 0;
                cellData.visibleColumnIndex = visibleColumnIndex;
                if (!fullWeek) {
                    visibleColumnIndex++;
                }
                cellDataBlocks.push(cellData);
            }
        }
        // Needed for event bar %age width calculation in getCellDomConfig
        me.weekLength = fullWeek ? 1 : visibleColumnIndex;
        const length = cellDataBlocks.length;
        for (i = 0; i < length; i++) {
            const
                cellData     = cellDataBlocks[i],
                { day, key } = cellData,
                isNonWorking = nonWorkingDays[day],
                dayCls       = `b-day-of-week-${day}`;
            currentDay = !(cellData.date - me.date);
            cellData.cell = me.getCell(key);
            // The contract of DayCellRenderer is defined in Core/widget/CalendarPanel.
            cellData.row = me.cellContainer;
            const cellDomConfig = me.getCellDomConfig(cellData);
            // Falsy means omit the column
            if (cellDomConfig) {
                const headerDomConfig = Objects.merge({
                    dataset : {
                        // The [data-date] property marks an event content cell.
                        headerDate : key
                    }
                }, cellDomConfig.children[0]);
                headerDomConfig.className['b-selected-date'] = currentDay;
                headerDomConfig.className['b-current-date']  = fullWeek && currentDay;
                headerDomConfig.className[dayCls]            = 1;
                // Show the dayHeaderRenderer the day header domConfig
                dayHeaderRenderer && me.callback(dayHeaderRenderer, me, [headerDomConfig, cellData]);
                cellDomConfig.dataset.date = key;
                Object.assign(cellDomConfig.className, {
                    'b-dayview-allday'    : 1,
                    [me.nonWorkingDayCls] : isNonWorking,
                    [me.weekendCls]       : DH.weekends[day],
                    [me.dayCellCls]       : 1,
                    [dayCls]              : 1
                });
                // Remove header child from cell config
                cellDomConfig.children.shift();
                headerCells.push(headerDomConfig);
                if (!fullWeek || currentDay) {
                    eventCells.push(cellDomConfig);
                    if (!isNonWorking) {
                        lastWorkingDayCell = cellDomConfig;
                    }
                }
            }
        }
        // Identify last working day in row if there were any.
        lastWorkingDayCell && (lastWorkingDayCell.className['b-last-working-day'] = 1);
        DomSync.sync({
            targetElement : headerCellContainer,
            domConfig     : {
                children : headerCells,
                // Ensure DOM order matches children order.
                syncOptions : {
                    releaseThreshold : 0,
                    strict           : true
                }
            }
        });
        DomSync.sync({
            targetElement : cellContainer,
            domConfig     : {
                children : eventCells,
                // Ensure DOM order matches children order.
                syncOptions : {
                    releaseThreshold : 0,
                    strict           : true
                }
            }
        });
        me.refreshCount = (me.refreshCount || 0) + 1;
        // Must only ever be a temporary state to accommodate dropping into an empty row.
        // As soon as a refresh happens, the state must be revoked.
        me.collapseGutter();
        // Needs day elements to be in place before this can be measured and fixed.
        me.setEventContentHeight(me.cellContentHeight);
        /**
         * Fires when this CalendarRow refreshes.
         * @param {Calendar.widget.CalendarRow} source The triggering instance.
         * @event refresh
         */
        me.trigger('refresh');
    }
    get eventContainerTop() {
        return 0;
    }
    async expandGutter() {
        const me = this;
        if (!me._isTemporarilyExpanded) {
            me.gutterHeight = (me.gutterHeight || 0) + me.eventHeightInPixels + me.eventSpacing;
            await me.setEventContentHeight(me.cellContentHeight);
            me._isTemporarilyExpanded = true;
        }
    }
    async collapseGutter() {
        const me = this;
        if (me._isTemporarilyExpanded) {
            me.gutterHeight = me.gutterHeight - (me.eventHeightInPixels + me.eventSpacing);
            await me.setEventContentHeight(me.cellContentHeight);
            me._isTemporarilyExpanded = false;
        }
    }
    // addCellHeaderContent mutates the cellHeader DomConfig block.
    // And if we are to have a day name element, returns the DomConfig for it.
    // It's called from DayCellRenderer#getCellDomConfig
    addCellHeaderContent(cellHeader, cellData) {
        const
            me  = this,
            { date, day, tomorrow }  = cellData,
            { dayNameFormat, dayNumberCompress, daySeparator } = me,
            shifted = me?.dayTime?.startShift;
        cellHeader.children = [{
            className : `b-day-name-part b-day-name-day${dayNumberCompress ? ' b-day-name-short' : ''}`,
            html      : formatDayPart(dayNameFormat, daySeparator[0], date, shifted && tomorrow)
        }, {
            className : `b-day-name-part b-day-name-date`,
            html      : formatDayPart(me.dayNumberFormat, daySeparator[1], date, shifted && tomorrow, dayNumberCompress)
        }];
        cellHeader.className[me.nonWorkingDayCls] = cellData.isNonWorking;
        cellHeader.className[me.weekendCls] = DH.weekends[day];
    }
    updateLocalization() {
        if (!this.isConfiguring) {
            this.refresh();
        }
        super.updateLocalization();
    }
    updateFullWeek() {
        if (!this.isConfiguring) {
            this.refresh();
        }
    }
}
CalendarRow.initClass();
CalendarRow._$name = 'CalendarRow';