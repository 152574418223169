import Base from '../../../Core/Base.js';
import DH from '../../../Core/helper/DateHelper.js';
import DayTime from '../../../Core/util/DayTime.js';
import DomClassList from '../../../Core/helper/util/DomClassList.js';
import ArrayHelper from '../../../Core/helper/ArrayHelper.js';
import DomHelper from '../../../Core/helper/DomHelper.js';
import DomSync from '../../../Core/helper/DomSync.js';
import ObjectHelper from '../../../Core/helper/ObjectHelper.js';
import EventHelper from '../../../Core/helper/EventHelper.js';
import StringHelper from '../../../Core/helper/StringHelper.js';
import OverflowPopup from '../OverflowPopup.js';
/**
 * @module Calendar/widget/mixin/DayCellRenderer
 */
const
    emptyFn           = () => {},
    { eventNameMap }  = EventHelper;
/**
 * Mixin that provides the ability to render a calendar cell.
 *
 * This is used by the MonthView and also CalendarRow. It expects its
 * host class to include the {@link Calendar.widget.mixin.CalendarMixin} mixin.
 *
 * @mixin
 */
export default Target => class DayCellRenderer extends (Target || Base) {
    static get $name() {
        return 'DayCellRenderer';
    }
    static get configurable() {
        return {
            monitorResize : true, // we need to adjust the "+ 2 more" overflow indicator
            /**
             * This may be specified to create the content for the date and day name section of calendar cells.
             *
             * This function is called for each cell rendered. It can return an HTML string which will become the
             * content of the header section of the cell above any events.
             *
             * It can also return a {@link Core.helper.DomHelper#typedef-DomConfig} element creation object (or array of
             * same) to specify content to create in the header section of the cell.
             *
             * It also allows developers to mutate the cell metadata, or the CSS classes to be applied to the cell.
             *
             * The {@link Core.helper.DomHelper#typedef-DomConfig} definition passed as the first parameter may be
             * mutated to create a different cell header.
             *
             * ```javascript
             * dayCellRenderer : function(cellData, dayCellDomConfig) {
             *     // I don't like Mondays!
             *     if (cellData.day === 1) {
             *         dayCellDomConfig.className['hackathon-dayoff'] = true;
             *         dayCellDomConfig.style.fontWeight = 'bold';
             *
             *         cellData.isNonWorking = true;
             *
             *         return `${cellData.date.getDate()} Day off yay!`;
             *     }
             * }
             *```
             *
             * A non-null return value from the renderer is used as the content of the day number element.
             *
             * @config {Function} dayCellRenderer
             * @param {DayCell} dayCellRenderer.cellData An object that contains data about the cell.
             * @param {DomConfig} dayCellDomConfig An object to sync the day cell element.
             * @param {Object} dayCellDomConfig.className An object who's truthy property names will be applied as class
             * names.
             * @param {Object} dayCellDomConfig.style A CSS style definition object.
             * @param {Object} dayCellDomConfig.dataset The DOM data properties to set.
             * @param {DomConfig[]} dayCellDomConfig.children The {@link DomConfig} definitions for the content of the
             * cell.
             * @returns {String|Object|Object[]} The definition of the cell header content.
             */
            dayCellRenderer : null,
            // Must still be visible if dynamically set to non working to keep
            // column arrangement correct.
            dynamicHideNonWorkingdayCls : 'b-dynamic-nonworking-day',
            /**
             * The {@link Calendar.widget.OverflowPopup} instance that this view may show when events
             * for one day overflow the available space.
             * @member {Calendar.widget.OverflowPopup} overflowPopup
             * @readonly
             */
            /**
             * A config object used to create the {@link Calendar.widget.OverflowPopup} that this view
             * may show when events for one day overflow the available space.
             *
             * For example
             *
             *```javascript
             *     modes : {
             *         month : {
             *             overflowPopup : {
             *                 closable   : false,
             *                 dateFormat : 'dddd, MMM M',
             *                 eventRenderer({ eventRecord, renderData }) {
             *                     if (calendarUtils.isImportantEvent(eventRecord)) {
             *                         // Add CSS class to important events
             *                         renderData.cls['b-important'] = 1;
             *                     }
             *                 }
             *             }
             *         }
             *     }
             *```
             * @config {OverflowPopupConfig}
             */
            overflowPopup : {
                $config : ['lazy', 'nullify'],
                value : {
                    type : 'overflowpopup'
                }
            },
            /**
             * The pointer gesture which shows the popup containing any overflowing events
             * in the current view.
             *
             * This means events which will not fit into a `MonthView` day cell, or *all* events
             * for a `YearView` cell.
             *
             * Useful values are `'click'` (the default), and `'mouseover'`
             * @config {'click'|'mouseover'|'hover'}
             * @default
             */
            overflowPopupTrigger : 'click',
            eventBarContainerCls : 'b-cal-event-bar-container',
            /**
             * A function, or name of a function which is passed the {@link DomConfig} object which
             * will be used to create the "+n more" button which indicates that a day cell has
             * overflowing events.
             *
             * ```javascript
             * overflowButtonRenderer : function(domConfig) {
             *     domConfig.className['b-fa'] = domConfig.className['b-fa-list'] = 1;
             *     return domConfig;
             * }
             *```
             *
             * The result is used to create the overflow button element.
             *
             * To target the element using custom CSS, use the class name `b-cal-cell-overflow`.
             *
             * @config {Function} overflowButtonRenderer
             * @param {DomConfig} domConfig A {@link DomConfig} config object which is used to
             * create the overflow button.
             * @param {String} domConfig.tag=button The tag name of the element to create.
             * @param {Object} domConfig.className An object who's truthy property names will be applied as class names.
             * @param {String} domConfig.text The inner content of the element. **Note that this
             * will be HTML encoded for XSS safety**
             * @param {Object} domConfig.style A CSS style definition object.
             * @param {Object} domConfig.dataset The DOM data properties to set.
             * @param {Number} overflowCount The number of overflowing events.
             * @returns {DomConfig|String|null}
             */
            overflowButtonRenderer : null,
            /**
             * The {@link Core.helper.DateHelper} format string to format the day names
             * in the header part of each calendar cell.
             * @config {String}
             */
            dayCellNameFormat : null,
            /**
             * How much vertical space in pixels to leave between event bars in a cell.
             * @member {Number} eventSpacing
             */
            /**
             * How much vertical space in pixels to leave between event bars in a cell.
             * @config {Number}
             * @default
             */
            eventSpacing : 2,
            emptyCellCls : 'b-cal-empty-cell',
            /**
             * A {@link DomConfig} object which will be used to create the content of a clickable
             * element which is present when no events are in a cell.
             *
             * Or a function, or name of a function which returns a {@link DomConfig} object.
             *
             * See the {@link Calendar.view.Calendar#event-emptyCellClick} event.
             *
             * @config {Function|Object|String} emptyCellRenderer
             * @param {DayCell} dayCellRenderer.cellData An object that contains data about the cell.
             * @returns {DomConfig|null} DomConfig object representing the HTML markup
             */
            emptyCellRenderer : null
        };
    }
    onCalendarStoreChange() {
        super.onCalendarStoreChange(...arguments);
        // Ensure any overflow popup is informed immediately after its owning View has been informed.
        if (this._overflowPopup?.isVisible) {
            this._overflowPopup.onCalendarStoreChange(...arguments);
        }
    }
    // Called automatically on the CellOverflow${overflowPopupTrigger} event because of callOnFunctions
    onCellOverflowGesture({ domEvent, date }) {
        const
            me       = this,
            cellData = me.cellMap.get(DH.makeKey(date));
        if (cellData) {
            const
                { overflowPopup } = me,
                cell              = me.getCellFromEvent(domEvent);
            /**
             * Fired after an {@link Calendar.widget.OverflowPopup} has been shown when an a
             * "+ n more" overflow button is activated by an {@link #config-overflowPopupTrigger} event.
             * @event showOverflowPopup
             * @param {HTMLElement} cell The day cell for which the overflow popup is going to be shown.
             * @param {DayCell} cellData An object that contains data about the cell.
             * @param {Date} date The date which has overflowing events
             * @param {Calendar.widget.OverflowPopup} overflowPopup The overflow `Popup`.
             */
            /**
             * Fired before an {@link Calendar.widget.OverflowPopup} is shown when an a
             * "+ n more" overflow button is activated by an {@link #config-overflowPopupTrigger} event.
             * @event beforeShowOverflowPopup
             * @param {HTMLElement} cell The day cell for which the overflow popup is going to be shown.
             * @param {DayCell} cellData An object that contains data about the cell.
             * @param {Date} date The date which has overflowing events
             * @param {Calendar.widget.OverflowPopup} overflowPopup The overflow `Popup`.
             * @preventable
             */
            if (overflowPopup && me.trigger('beforeShowOverflowPopup', { cell, cellData, date, overflowPopup })) {
                overflowPopup.showOverflow(cell, cellData);
            }
        }
    }
    getCellFromEvent(domEvent) {
        return domEvent.target.closest('.b-calendar-cell[data-date]');
    }
    changeOverflowPopupTrigger(overflowPopupTrigger) {
        // Must be correctly camel cased so the we can create the `onCellOverflow${gesture}` method name.
        // So mouseover => mouseOver so that we inject an onCellOverflowMouseOver method.
        overflowPopupTrigger = overflowPopupTrigger.toLowerCase();
        if (overflowPopupTrigger === 'mouseover' || overflowPopupTrigger === 'hover') {
            overflowPopupTrigger = 'mouseOver';
        }
        return overflowPopupTrigger;
    }
    updateOverflowPopupTrigger(overflowPopupTrigger, was) {
        if (was) {
            this[`onCellOverflow${StringHelper.capitalize(was)}`] = emptyFn;
        }
        this[`onCellOverflow${StringHelper.capitalize(overflowPopupTrigger)}`] = this.onCellOverflowGesture;
    }
    onEventMouseOverOut({ target, relatedTarget, type }) {
        if (this.overflowPopupTrigger === 'mouseOver' && target.closest('.b-cal-cell-overflow') && !relatedTarget.closest('.b-cal-cell-overflow')) {
            if (type === 'mouseover') {
                // CalendarMixin's implementation triggers for overflow button gestures
                return this.onCalendarPointerInteraction(...arguments);
            }
            else {
                this._overflowPopup?.hide();
            }
        }
        return this.onCalendarPointerInteraction(...arguments);
    }
    changeOverflowPopup(config, instance) {
        const
            me         = this,
            popupOwner = me.up('calendar') || me.owner || me,
            // We use OverflowPopup instead of Widget since that will have any additional configs and will use the
            // correct merge procedure for them (if any were customized). The actual type can be derived and if so,
            // those config merge fns will not be consulted but that's very unlikely and not worth solving for in
            // general (though it could be).
            ret = OverflowPopup.reconfigure(instance, config, {
                owner    : me,  // so that we'll destroy our instances on reconfigure to null
                defaults : {
                    owner : me,
                    align : {
                        constrainTo : globalThis
                    }
                }
            });
        if (ret) {
            const
                { element }             = ret,
                { eventHeightInPixels } = me;
            // Make popup selectable
            ret.element.classList.add(`b-${me.type}-overflowpopup`);
            // OverflowPopup is not a global floater by default. It's rendered inside of the Calendar
            // element so that focus stays within, and the Navigator can follow focus.
            // If that has not been overridden by configuration, render inside calendar content.
            if (!ret.floating) {
                ret.positioned = true;
                ret.render(popupOwner.isCalendar ? popupOwner.element : popupOwner.contentElement);
            }
            // Some views, like DayView don't have a pixel event height.
            if (eventHeightInPixels) {
                element.style.setProperty('--event-height', `${eventHeightInPixels}px`);
                element.style.setProperty('--arrow-width', `${eventHeightInPixels / 3}px`);
            }
            // We have to relay interaction events upwards from the overflow popup if it's
            // not rendered into this view. If it is, then the events will bubble.
            if (ret !== instance && popupOwner !== me) {
                EventHelper.on({
                    thisObj     : me,
                    element     : ret.bodyElement,
                    delegate    : '.b-cal-event-wrap',
                    mouseover   : 'onEventMouseOverOut',
                    mouseout    : 'onEventMouseOverOut',
                    mousedown   : 'onCalendarPointerInteraction',
                    mouseup     : 'onCalendarPointerInteraction',
                    click       : 'onCalendarPointerInteraction',
                    dblclick    : 'onCalendarPointerInteraction',
                    contextmenu : 'onCalendarPointerInteraction'
                });
                // Header may have been configured away.
                if (ret.headerElement) {
                    EventHelper.on({
                        thisObj     : me,
                        element     : ret.headerElement,
                        mousedown   : 'onCalendarPointerInteraction',
                        mouseup     : 'onCalendarPointerInteraction',
                        click       : 'onCalendarPointerInteraction',
                        dblclick    : 'onCalendarPointerInteraction',
                        contextmenu : 'onCalendarPointerInteraction'
                    });
                }
            }
        }
        return ret;
    }
    onInternalResize(element, width, height, oldWidth, oldHeight) {
        const
            {
                _eventsPerCell,
                _eventContainerTop
            }  = this;
        super.onInternalResize(element, width, height, oldWidth, oldHeight);
        if (this.rendered && height !== oldHeight) {
            // Calculates new values for eventsPerCell and eventContainerTop
            // and handles changes to either.
            this.performResizeRefresh(_eventsPerCell, _eventContainerTop);
        }
    }
    performResizeRefresh(prevEventsPerCell, prevEventContainerTop, now) {
        // Invalidate the values so that they are recalculated
        this._eventsPerCell = this._eventContainerTop = this._eventContainerHeight = null;
        const
            me = this,
            {
                eventsPerCell,
                _overflowPopup
            } = me;
        if (eventsPerCell !== prevEventsPerCell) {
            /**
             * Fires when number of events which could be placed in one cell is changed.
             * Has place when the height of the Month view is changed.
             * @event eventsPerCellChange
             * @param {Calendar.widget.MonthView} source This `MonthView` instance.
             * @param {Number} value New number of events per cell.
             * @param {Number} oldValue Previous number of events per cell.
             * @private
             */
            me.trigger('eventsPerCellChange', {
                value    : eventsPerCell,
                oldValue : prevEventsPerCell
            });
        }
        // If the component height change caused the event start position to change
        // or the eventsPerCell to change, refresh.
        if (me.eventContainerTop !== prevEventContainerTop || eventsPerCell !== prevEventsPerCell) {
            // If now passed as true, use doRefresh rather than refresh which waits until visible.
            now ? me.doRefresh() : me.refresh();
            // The overflow state may have changed. If we are visible, refresh will have happened.
            // OverflowPopup will hide itself if the ext cellData block has no overflow
            if (_overflowPopup?.isVisible) {
                _overflowPopup.refresh(me._cellMap.get(DH.makeKey(_overflowPopup.activeDate)));
            }
        }
    }
    updateEventHeight() {
        // CalendarMixin class needs to know
        super.updateEventHeight(...arguments);
        // Force a recalculate on next access
        this._eventsPerCell = null;
        this.element.style.setProperty('--event-height-px', `${this.eventHeightInPixels}px`);
    }
    updateEventSpacing(eventSpacing) {
        // Force a recalculate on next access
        this._eventsPerCell = null;
        // Schedule a refresh
        if (!this.isConfiguring) {
            this.refreshSoon();
        }
        this.element.style.setProperty('--event-spacing', `${eventSpacing}px`);
    }
    get weeksElement() {
        return this.contentElement;
    }
    get eventContainerHeight() {
        // Must not bake the property in as zero if called during configuration.
        if (this._eventContainerHeight == null && this.isVisible && !this.isConfiguring) {
            const { firstVisibleCell } = this;
            this._eventContainerHeight = firstVisibleCell ? firstVisibleCell.offsetHeight - this.eventContainerTop : NaN;
        }
        return this._eventContainerHeight;
    }
    get eventContainerTop() {
        const me = this;
        // Must not bake the property in as zero if called during configuration.
        if (me._eventContainerTop == null && me.isVisible && !me.isConfiguring) {
            const
                { firstVisibleCell } = me,
                c = firstVisibleCell && firstVisibleCell.querySelector('.b-cal-cell-header'),
                top = c ? c.getBoundingClientRect().height : 0;
            me._eventContainerTop = top === 0 ? null : top;
            me.element.style.setProperty('--event-top', `${me._eventContainerTop}px`);
        }
        return me._eventContainerTop || 0;
    }
    /**
     * Returns the number of complete event bars which will fit inside the referenced cell.
     *
     * The base implementation assumes all cells are the same height. In most views, most of the time,
     * this is true.
     *
     * It's only in MonthView when some rows are shrinkwrapped round their event content (meaning
     * either expanded or contracted away from the 1/6 height default) that there may be a customized
     * eventsPerCell for a certain date. So only MonthView has an overriding implementation.
     * @internal
     */
    getEventsPerCell(date) {
        return this.eventsPerCell;
    }
    get eventsPerCell() {
        const me = this;
        if (me._eventsPerCell == null) {
            me._eventsPerCell = Math.floor((me.eventContainerHeight + me.eventSpacing) / (me.eventHeightInPixels + me.eventSpacing));
        }
        return me._eventsPerCell;
    }
    get cellMap() {
        // If the cellMap has not been populated, create it.
        return this._cellMap?.populated ? this._cellMap : this.createCellMap();
    }
    cellRenderer({ cell }) {
        const domConfig = this.getCellDomConfig(...arguments);
        DomSync.sync({
            domConfig,
            targetElement : cell
        });
    }
    getCellDomConfig({ cell, columnIndex, row, date, day, dayTime = DayTime.MIDNIGHT, visibleColumnIndex, key }) {
        const
            me        = this,
            {
                eventHeightInPixels,
                eventSpacing,
                weekLength,
                todayCls,
                emptyCellRenderer
            }               = me,
            today           = me.dayTime.startOfDay(me.calendar?.dateTimeNow || new Date()),
            isToday         = date.getTime() === today.getTime(),
            dayNumber       = date.getDate(),
            eventContainer = {
                className : me.eventBarContainerCls,
                role      : 'presentation',
                children  : [],
                // Match existing data-event-id elements first and ensure DOM order matches
                // children order.
                syncOptions : {
                    syncIdField      : 'eventId',
                    releaseThreshold : 0,
                    strict           : true
                }
            },
            cellHeader = {
                style     : {},
                className : new DomClassList({
                    'b-cal-cell-header' : true,
                    [todayCls]          : isToday
                })
            },
            domConfig = {
                dataset   : {},
                style     : {},
                className : new DomClassList(cell?.className),
                children  : [cellHeader, eventContainer]
            },
            isFirstVisibleCell = !(date - (me.firstVisibleDate || -1));
        domConfig.className[todayCls] = isToday;
        // Some views may create a day name element into which a dayCellRenderer may add content.
        // MonthView does this. CalendarRow does not. It has a separate concept of dayHeaderRenderer
        let dayName,
            dayEvents = !me.isConfiguring && me.cellMap.get(DH.makeKey(date));
        if (me.addCellHeaderContent) {
            // addCellHeaderContent mutates the cellHeader DomConfig block.
            // And if we are to have a day name element, returns the DomConfig for it.
            dayName = me.addCellHeaderContent(cellHeader, dayEvents || (dayEvents = me.createCellData(date)));
        }
        else {
            cellHeader.children = [dayName = {
                className : {
                    'b-day-name' : true
                }
            }];
        }
        let dynamicallySetToNonWorking;
        // Give the dayCellRenderer access to the configuration object for the cell header
        if (me.dayCellRenderer && !me.isConfiguring) {
            // If they have a cell renderer, there must be cell info to give it.
            if (!dayEvents) {
                dayEvents = me.createCellData(date);
            }
            // Allow dayCellRenderer to access cell style and classes
            dayEvents.style = domConfig.style;
            dayEvents.cls = domConfig.className;
            // Allow dayCellRenderer to access cell header style and classes
            dayEvents.headerStyle = cellHeader.style;
            dayEvents.headerCls = cellHeader.className;
            const
                wasNonWorking = dayEvents && dayEvents.isNonWorking,
                dayNameContent = me.callback(me.dayCellRenderer, me, [dayEvents, domConfig]);
            // If renderer changed the cls or style properties, propagate the values back into place
            if (typeof dayEvents.cls == 'string') {
                domConfig.className = new DomClassList(dayEvents.cls);
            }
            if (typeof dayEvents.style === 'string') {
                domConfig.style = DomHelper.parseStyle(dayEvents.style);
            }
            if (typeof dayEvents.headerCls == 'string') {
                cellHeader.className = new DomClassList(dayEvents.headerCls);
            }
            if (typeof dayEvents.headerStyle === 'string') {
                cellHeader.style = DomHelper.parseStyle(dayEvents.headerStyle);
            }
            if (dayNameContent != null) {
                if (typeof dayNameContent === 'string') {
                    dayName.html = dayNameContent;
                }
                // Assuming Object or Object Array.
                else {
                    dayName.text = null;
                    dayName.children = ArrayHelper.asArray(dayNameContent);
                }
            }
            dynamicallySetToNonWorking = !wasNonWorking && dayEvents.isNonWorking;
        }
        if (dayName) {
            // If there was no renderer, use the date as the textContent
            if (!(dayName.text || dayName.html)) {
                dayName.text = me.dayCellNameFormat ? DH.format(date, me.dayCellNameFormat) : dayNumber;
            }
            // If all there is in there is the date, give it the day number CSS rendition
            // This makes it a 2emX2em circular element.
            if ((dayName.text || dayName.html) == dayNumber) {
                dayName.className['b-day-num'] = true;
            }
        }
        // If there's a cell entry which is visible (within this month and not a hidden nonworking day)
        // then we add the child entries to the eventContainer's children array.
        if (dayEvents.visible) {
            const
                {
                    hasOverflow,
                    renderedEvents,
                    maxRow
                }          = dayEvents,
                events     = renderedEvents,
                { length } = events,
                children   = eventContainer.children;
            // Sync the non working day CSS class.
            // But if a dayCellRenderer changed isNonWorking to true, the cell must remain
            // visible in certain views, to maintain cell arrangement, so add extra CSS class
            // to enable views to override the rule from Core/calendarpanel.scss.
            domConfig.className[me.nonWorkingDayCls] = dayEvents.isNonWorking;
            domConfig.className[me.dynamicHideNonWorkingdayCls] = dynamicallySetToNonWorking;
            domConfig.className[me.weekendCls] = DH.weekends[day];
            // How many events we find below the eventsPerCell point
            let i = 0, eventRow = 0, overflowCount = 0;
            for (; i < maxRow; i++, eventRow++) {
                const renderedEvent = events[i];
                // If the slot is occupied, add a child event element.
                // The event's .b-cal-event-wrap is position:absolute, so its offsetParent is the closest
                // position:relative element which is the .b-calendar-days row.
                // This is so that the events can use %age widths and need no intervention
                // for width changes. The left position is the .b-cal-event-bar-container's left.
                // Only height changes which result in a change to this.eventsPerCell require
                // a refresh.
                if (renderedEvent) {
                    const
                        {
                            eventRecord,
                            isOverflow,
                            propagateEndDate
                        }                = renderedEvent,
                        eventEndDate     = renderedEvent.eventEndDate || eventRecord.endingDate,
                        renderedDaySpan  = Math.max(1, me.getDaySpan(date, propagateEndDate || eventEndDate, dayTime)),
                        daySpan          = me.getDaySpan(date, eventEndDate, dayTime, true),
                        remainingColumns = weekLength - visibleColumnIndex,
                        isHiddenOverflow = visibleColumnIndex && isOverflow,
                        width            = DomHelper.percentify(ObjectHelper.round((
                            isHiddenOverflow ? 1 : Math.min(renderedDaySpan, remainingColumns)) / weekLength * 100, 4)),
                        eventDomConfig   = me.createEventDomConfig(renderedEvent);
                    Object.assign(eventDomConfig.style, {
                        width,
                        top : `${eventRow * eventHeightInPixels + (eventRow * eventSpacing) + me.eventContainerTop}px`
                    });
                    Object.assign(eventDomConfig.className, {
                        // If flowing in from a previous cell
                        'b-overflow' : isHiddenOverflow,
                        // We're in the first cell, and it was from a previous week
                        'b-continues-past' : !isHiddenOverflow && (!visibleColumnIndex || isFirstVisibleCell) && eventRecord.startDate < date,
                        // The event ends in a future week
                        'b-continues-future' : !isHiddenOverflow && (daySpan > remainingColumns || daySpan > renderedDaySpan)
                    });
                    children.push(eventDomConfig);
                }
            }
            // Continue after the maxRow has been reached
            // keeping track of how many events are below the fold
            while (i < length) {
                if (events[i++]) {
                    overflowCount++;
                }
            }
            // The cell was found to need an overflow link by propagateCellEvents.
            // This could be because it has more renderedEvents than me.eventsPerCell
            // Or it could be because its last visible event flowed forwards into a cell
            // whose own events only *filled*
            if (hasOverflow) {
                row.classList.add('b-has-overflow');
                domConfig.children.push(me.getOverflowButtonDomConfig(overflowCount));
            }
            else if (!columnIndex) {
                row.classList.remove('b-has-overflow');
            }
            // Flag the event container element as empty if necessary
            const emptyCell = domConfig.className[me.emptyCellCls] = !events.length;
            if (emptyCell && emptyCellRenderer) {
                const
                    rType     = typeof emptyCellRenderer,
                    rCallback = rType === 'function' || rType === 'string' ? me.resolveCallback(emptyCellRenderer, me, false) : null,
                    noEvents  = rCallback?.handler ? rCallback.handler.call(rCallback.thisObj, dayEvents) : emptyCellRenderer;
                // By default, a single line no events button will appear similar to
                // a "+n more" overflow button. It will be the size and shape of an event pill.
                if (noEvents) {
                    eventContainer.children[0] = {
                        tag      : 'button',
                        class    : 'b-cal-cell-no-content',
                        children : [
                            rCallback?.handler ? rCallback.handler.call(rCallback.thisObj, dayEvents) : emptyCellRenderer
                        ]
                    };
                }
            }
        }
        return domConfig;
    }
    onCalendarPointerInteraction(domEvent) {
        const
            me         = this,
            { target } = domEvent;
        // Broadcast a click on content in an empty cell.
        if (target.closest(`.${me.emptyCellCls} .${me.eventBarContainerCls}`)) {
            // If they have an emptyCellRenderer, then that must be the click target
            if (!me.emptyCellRenderer || target.closest('.b-cal-cell-no-content')) {
                /**
                 * Fired when an empty cell content area is clicked on. If the handler returns
                 * `false` the current pointer event is not processed further.
                 * @event emptyCellClick
                 * @param {Event} domEvent The triggering DOM event.
                 * @param {Date} date The date which has no visible events
                 */
                if (me.trigger(`emptyCell${eventNameMap[domEvent.type]}`, {
                    date : me.getDateFromDomEvent(domEvent),
                    domEvent
                }) === false) {
                    return false;
                };
            }
        }
        return super.onCalendarPointerInteraction(domEvent);
    }
    getDaySpan(startDate, endDate, dayTime, ignoreNonWorkingDays) {
        if (this.hideNonWorkingDays && !ignoreNonWorkingDays) {
            let result = 0;
            for (const date = new Date(startDate), spanEnd = dayTime.ceil(endDate, '1 day'); date < spanEnd; date.setDate(date.getDate() + 1)) {
                if (!this.nonWorkingDays[date.getDay()]) {
                    result++;
                }
            }
            return result;
        }
        else {
            return Math.max(DH.diff(startDate, dayTime.ceil(endDate, '1 day'), 'day'), 1);
        }
    }
    getOverflowButtonDomConfig(overflowCount) {
        const
            { overflowButtonRenderer } = this,
            domConfig                  = {
                tag       : 'button',
                className : {
                    'b-cal-cell-overflow' : 1
                },
                text  : this.L('L{Calendar.plusMore}', overflowCount),
                style : {
                    lineHeight   : `${this.eventHeightInPixels}px`,
                    marginBottom : `${this.eventSpacing}px`
                },
                dataset : {
                    eventId : 'overflow'
                }
            };
        return overflowButtonRenderer ? this.callback(overflowButtonRenderer, this, [domConfig, overflowCount]) : domConfig;
    }
    getCell(date) {
        if (typeof date !== 'string') {
            date = this.dayTime.dateKey(date);
        }
        return this.contentElement.querySelector(`[data-date="${date}"]`);
    }
    getCellOverflowButton(date) {
        return this.getCell(date)?.querySelector('.b-cal-cell-overflow');
    }
};
