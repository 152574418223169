import SchedulerProjectModel from '../../Scheduler/model/ProjectModel.js';
import TimeRangeModel from '../model/TimeRangeModel.js';
import TimeRangeStore from '../data/TimeRangeStore.js';
/**
 * @module Calendar/model/ProjectModel
 */
// noinspection JSClosureCompilerSyntax
/**
 * This class represents a global project of your Calendar - a central place for all data.
 *
 * For more information, see {@link Scheduler.model.ProjectModel}.
 *
 * @extends Scheduler/model/ProjectModel
 *
 * @typings Scheduler.model.ProjectModel -> Scheduler.model.SchedulerProjectModel
 */
export default class ProjectModel extends SchedulerProjectModel {
    static $name = 'ProjectModel';
    //region Configs
    static configurable = {
        timeRangeStore : {
            modelClass : TimeRangeModel
        }
    };
    static defaultConfig = {
        /**
         * The constructor of the time range model class, to be used in the project. Will be set as the
         * {@link Core.data.Store#config-modelClass modelClass} property of the {@link #property-timeRangeStore}
         *
         * @config {Calendar.model.TimeRangeModel}
         * @typings {typeof TimeRangeModel}
         * @category Models & Stores
         */
        timeRangeModelClass : TimeRangeModel,
        /**
         * The constructor to create an timeRange store instance with. Should be a class, subclassing the
         * {@link Core.data.Store}
         * @config {Core.data.Store|Object}
         * @typings {typeof Store|object}
         * @category Models & Stores
         */
        timeRangeStoreClass : TimeRangeStore
    };
    //endregion
}
ProjectModel.applyConfigs = true;
ProjectModel.initClass();
ProjectModel._$name = 'ProjectModel';