import DomHelper from '../../Core/helper/DomHelper.js';
import ChipView from '../../Core/widget/ChipView.js';
/**
 * @module Calendar/widget/ResourceChipView
 */
/**
 * A ChipView used to display resources as chips in Calendar UIs.
 *
 * This class is not meant to be used directly.
 *
 * @extends Core/widget/ChipView
 * @classtype resourcechipview
 * @internal
 */
export default class ResourceChipView extends ChipView {
    static $name = 'ResourceChipView';
    // Factoryable type name
    static type = 'resourcechipview';
    static configurable = {
        navigator      : null,
        itemsFocusable : false,
        closable       : false,
        scrollable     : false,
        itemTpl(resource) {
            return DomHelper.createElement({
                className : 'b-cal-event-resource-avatar-desc',
                children  : [{
                    class : 'b-event-column-resource-name',
                    text  : resource.name
                }, resource.role?.length ? {
                    class : 'b-event-column-resource-role',
                    text  : resource.role
                } : null]
            }).outerHTML;
        }
    };
    getItemCls(r) {
        return DomHelper.isNamedColor(r.eventColor) ? ` b-sch-${r.eventColor}` : '';
    }
    getItemStyle(r) {
        return `color:#fff;${r.eventColor && !DomHelper.isNamedColor(r.eventColor) ? `background-color:${r.eventColor}` : ''}`;
    }
    iconTpl(resourceRecord) {
        return DomHelper.createElement(this.owner.ownerFeature.client.activeView.getResourceAvatar(resourceRecord)).outerHTML;
    }
}
ResourceChipView.initClass();
ResourceChipView._$name = 'ResourceChipView';