import Panel from '../../Core/widget/Panel.js';
import CalendarMixin from './mixin/CalendarMixin.js';
import Objects from '../../Core/helper/util/Objects.js';
import Bag from '../../Core/util/Bag.js';
import DayView from './DayView.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import AvatarRendering from '../../Core/widget/util/AvatarRendering.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import CalendarRow from './CalendarRow.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import ResizeMonitor from '../../Core/helper/ResizeMonitor.js';
import FunctionHelper from '../../Core/helper/FunctionHelper.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
/**
 * @module Calendar/widget/ResourceView
 */
const
    day0              = new Date(0),
    day1              = DateHelper.add(day0, 1, 'day'),
    emptyMap          = Object.freeze(new Map()),
    scrollAxesConfigs = {
        hourHeight       : 1,
        showAllDayHeader : 1,
        dayStartTime     : 1,
        dayEndTime       : 1
    },
    sharedConfigs     = ['allowOverlap', 'timeFormat', 'coreHours', 'fitHours', 'hourHeight', 'visibleStartTime', 'dateFormat', 'dayStartTime', 'dayEndTime', 'hideNonWorkingDays', 'nonWorkingDays', 'readOnly', 'zoomOnMouseWheel', 'filterEventResources'];
/**
 * A Calendar view which encapsulates a series of child Calendar views, one for each resource (often
 * referred to as "calendar") in the project.
 *
 * The type of view displayed defaults to `'week'`, but this can be changed using the {@link #config-view}
 * config object.
 *
 * Usage :
 *
 * ```javascript
 * new Calendar({
 *     appendTo : domElement,
 *
 *     // Resource avatar images are loaded from this path
 *     resourceImagePath : '../_shared/images/users/',
 *
 *     modes : {
 *         // Let's not show the default views
 *         day    : null,
 *         week   : null,
 *         month  : null,
 *         year   : null,
 *         agenda : null,
 *
 *         // Mode name can be anything if it contains a "type" property.
 *         monthResourceview : {
 *             // Type has the final say over which view type is created
 *             type : 'resource',
 *
 *             // This is a config object for the subviews; one for each resource
 *             view : {
 *                 // We show a month view for each resource in the project
 *                 type : 'month'
 *             }
 *         }
 *     }
 * });
 * ```
 *
 * {@inlineexample Calendar/widget/ResourceView.js}
 *
 * @demo Calendar/resourceview
 *
 * @extends Core/widget/Panel
 * @mixes Core/widget/mixin/Responsive
 * @mixes Calendar/widget/mixin/CalendarMixin
 * @classtype resourceview
 * @classtypealias resource
 * @typingswidget
 */
export default class ResourceView extends Panel.mixin(CalendarMixin) {
    static $name = 'ResourceView';
    static type = 'resourceview';
    static get configurable() {
        return {
            layout : 'box',
            textContent : false,
            scrollable : {
                overflowX : true
            },
            date : {
                $config : {
                    equal : 'date'
                },
                value : null
            },
            /**
             * A config object used to configure the sub views. The default `type` used is `'weekview'`
             *
             * After instantiation, while in use, changes to the `view` property, will be propagated
             * to all child {@link #config-view views}.
             *
             * This property may be used to adjust properties of every child view of the resource
             * view in one statement.
             * @prp {ContainerItemConfig|Object} view
             * @typings {ContainerItemConfig|Record<string, any>}
             */
            view : {
                type : 'weekview'
            },
            /**
             * By default, the resource views are displayed in the order that the resources appear
             * in the project's `resourceStore`, so they will appear in a stable position, meaning
             * when a view is filtered out, then filtering it in will replace it in the same position.
             *
             * If this config is `false`, re-adding a view will place it at the end.
             * @config {Boolean}
             * @default
             */
            stableResourceOrder : true,
            /**
             * The width of a resource calendar panel (view always stretches to fill window)
             * @member {Number|String} resourceWidth
             */
            /**
             * The width of a resource calendar panel (view always stretches to fill window)
             * @config {Number|String}
             */
            resourceWidth : null,
            /**
             * Set to true to hide non-working days
             * @member {Boolean} hideNonWorkingDays
             */
            /**
             * Set to true to hide non-working days
             * @config {Boolean}
             */
            hideNonWorkingDays : null,
            viewCache : {
                $config : ['lazy', 'nullify'],
                value   : true
            },
            avatarRendering : {},
            /**
             * A field name or a function returning a string to be displayed below resource name in the resource view
             * headers.
             *
             * ```javascript
             * const calendar = new Calendar({
             *     modes : {
             *         // Mode name can be anything if it contains a "type" property.
             *         weekResources : {
             *             type  : 'resource',
             *             // Get meta string to display
             *             meta : resource => resource.title
             *         }
             *     }
             * });
             * ```
             *
             * @config {String|Function}
             * @param {Scheduler.model.ResourceModel} resource Displayed resource
             * @returns {String} Text to be displayed below resource name
             */
            meta : null,
            /**
             * Display an avatar in the resource view headers, either as an image or using resource initials.
             *
             * Looks for an image name in the {@link Scheduler/model/ResourceModel#field-imageUrl} and
             * {@link Scheduler/model/ResourceModel#field-image} fields on the resource. Set
             * {@link Calendar/view/Calendar#config-resourceImagePath} on Calendar to specify where to load images from.
             * If no image is found, resource initials are displayed.
             *
             * @config {Boolean}
             * @default
             */
            showAvatars : true,
            /**
             * When {@link Scheduler.model.ResourceTimeRangeModel}s are included in the data, they
             * are only rendered in subviews of {@link Calendar.widget.ResourceView}s and normal
             * {@link Calendar.model.TimeRangeModel}s are __not__ rendered in subviews of
             * {@link Calendar.widget.ResourceView}s.
             *
             * Set this to `true` to render normal {@link Calendar.model.TimeRangeModel}s in subviews of
             * this view.
             * @config {Boolean}
             * @default false
             */
            includeTimeRanges : null
        };
    }
    // Use config system properties because these are *always available, including during construction.
    static properties = {
        sharedConfigs,
        timeAxisConfigs : ['allDayEvents'].concat(sharedConfigs)
    };
    construct() {
        super.construct(...arguments);
        // If the built-in resource filter changes the resources visible, we have to react
        // to that by refreshing which may add/remove some child views.
        // Note this is a filter operation on the eventStore.
        // We don't subscribe to the filter operation on the eventStore because that may happen
        // often for other reasons (filtering based on other criteria), and we do not want to
        // invoke this expensive refresh unnecessarily.
        this.calendar?.sidebar?.widgetMap.resourceFilter?.ion({
            change  : 'onResourceFilterSelectionChange',
            thisObj : this
        });
    }
    // This view is animating if any of its child views are animating
    get isAnimating() {
        return super.isAnimating || this.items.some(v => v.isAnimating);
    }
    get hasNonWorkingDays() {
        return true;
    }
    changeScrollable(scrollable) {
        scrollable = super.changeScrollable(...arguments);
        // Our horizontal scroll range must be within the dayViewTimeAxis and dayViewScroller
        // which are positioned sticky to the edges, thus narrowing the true visible range.
        if (scrollable) {
            Object.defineProperty(scrollable, 'viewport', {
                get : this.getScrollingViewport.bind(this)
            });
        }
        return scrollable;
    }
    // The scrolling viewport calculation method injected as the getter for the `viewport`
    // property in our scrollable. It has to account for the time axis and the scroller
    // which are docked at left and right using position : sticky.
    getScrollingViewport() {
        const
            {
                dayViewTimeAxis,
                dayViewScroller
            }             = this,
            scrollerWidth = dayViewScroller.isVisible ? dayViewScroller.width : 0,
            result        = Rectangle.client(this.scrollable.element);
        return this.rtl ? result.adjust(scrollerWidth, 0, -dayViewTimeAxis.width, 0)
            : result.adjust(dayViewTimeAxis.width, 0, -scrollerWidth, 0);
    }
    changeView(view) {
        const
            me     = this,
            result = new Proxy(ObjectHelper.assign({}, view), {
                set(target, prop, value) {
                    const result = Reflect.set(...arguments);
                    // Pass new property setting in to child views
                    me.syncViewConfig(prop, value);
                    return result;
                },
                deleteProperty(target, prop) {
                    const result = Reflect.deleteProperty(...arguments);
                    // Pass new property setting in to child views
                    me.syncViewConfig(prop, null);
                    return result;
                }
            });
        me.viewType = bryntum.Calendar.Modes.resolveType(view.type);
        return result;
    }
    /**
     * Executes the passed function for each child view corresponding to each resource in the `resourceStore`.
     * @param {Function} fn The function to call.
     * @param {Object[]} [args] The arguments to pass. Defaults to the view being called followed by its index.
     * @param {Object} [thisObj] The `this` reference for the function. Defaults to the view being called.
     */
    eachView(fn, args, thisObj = null) {
        const
            passView = args == null,
            items    = [...this.viewCache];
        for (let i = 0, { length } = items; i < length; i++) {
            const view = items[i];
            if (passView) {
                args = [view, i];
            }
            if (view.callback(fn, thisObj || view, args) === false) {
                return;
            }
        }
    }
    /**
     * Yields the views which this ResourceView owns.
     * @property {Calendar.widget.mixin.CalendarMixin[]}
     * @readonly
     */
    get views() {
        return [...this.viewCache];
    }
    updateResourceStore(resourceStore) {
        this.detachListeners('resourceViewResourceChange');
        super.updateResourceStore?.(resourceStore);
        resourceStore.ion({
            name    : 'resourceViewResourceChange',
            change  : 'onResourceStoreChange',
            sort    : 'onResourceStoreSort',
            thisObj : this
        });
        this.refreshSoon();
    }
    onResourceStoreChange() {
        this.refreshSoon();
    }
    onResourceStoreSort({ source }) {
        const
            visibleViews    = this.items.filter(v => v.isVisible && !v.isResourceDayViewTimeAxis),
            resourceIdOrder = source.map(r => this.createViewId(r)),
            viewIdOrder     = visibleViews.map(v => v.id);
        // If the visible views are out of order, remove them then refresh
        if (!ObjectHelper.isEqual(resourceIdOrder, viewIdOrder)) {
            // Remove the views (not the timeaxis and scroller).
            // refresh will add them in the right order
            this.remove(...visibleViews);
            this.refreshSoon();
        }
    }
    onResourceFilterSelectionChange() {
        this.refreshSoon();
    }
    changeViewCache(viewCache, oldViewCache) {
        if (viewCache) {
            return new Bag();
        }
        else if (oldViewCache) {
            oldViewCache.forEach(v => v.destroy());
            oldViewCache.clear();
        }
    }
    getResourceView(resource) {
        return this.viewCache.get(this.createViewId(resource)) || this.createView(resource);
    }
    doRefresh() {
        const
            me                 = this,
            { scrollBarWidth } = DomHelper,
            {
                calendar,
                _items,
                resourceStore
            }                  = me,
            resourceFilter     = calendar?.widgetMap.resourceFilter,
            // Filter available resources by the owning Calendar's ResourceFilter
            calendars          = resourceFilter ? me.resourceStore.records.filter(c => resourceFilter.value.includes(c)) : me.resourceStore.records,
            { length }         = calendars,
            toAdd              = [],
            toRemove           = [],
            usedIds            = {};
        // We need to have some resources loaded for us to know what resources we have to create views for.
        // If the Calendar is using the loadOnDemand feature, we need to "prime the pump"
        // by kicking off a load to get this information. We load a month's worth of
        // events under the assumption that yearViews will not be used.
        if (!resourceStore.count) {
            const
                { weekStartDay } = me,
                monthStart       = DateHelper.getFirstDateOfMonth(me.date),
                nextMonthStart   = DateHelper.add(monthStart, 1, 'month');
            // Load a whole visible month block as displayed in a MonthView.
            // Note that these are snapped to week starts as in a Calendar.
            me.eventStore.getEvents({
                startDate : DateHelper.add(monthStart, -(monthStart.getDay() - weekStartDay + 7) % 7, 'd'),
                endDate   : DateHelper.add(nextMonthStart, 7 - (nextMonthStart.getDay() - weekStartDay + 7) % 7, 'd')
            });
        }
        // If this was called directly, cancel any queued call.
        me.refreshSoon.cancel();
        let hitCount = 0;
        for (let i = 0; i < length; i++) {
            const
                view    = me.getResourceView(calendars[i]),
                isShown = _items.includes(view);
            // If the ResourceStore was updated and the view is orphaned
            // by the resource no longer being in there, we have to remove the view.
            if (resourceStore.getById(view.resourceId)) {
                view.title = me.titleTemplate(view.resource);
                // View is present in our items..
                if (isShown) {
                    hitCount++;
                }
                // Not in our items; add
                else {
                    toAdd.push(view);
                }
                usedIds[view.id] = 1;
            }
            else if (isShown) {
                toRemove.push(view);
            }
        }
        // Check removals, unless all records were visited above
        if (hitCount < _items.count) {
            me.eachView(view => {
                if (!usedIds[view.id]) {
                    toRemove.push(view);
                }
            });
            me.remove(toRemove);
        }
        // Ensure the docked left and right axes are present in correct positions for DayViews
        if (me.viewType.isDayView) {
            if (!_items.includes(me.dayViewTimeAxis)) {
                toAdd.unshift(me.dayViewTimeAxis);
            }
            if (scrollBarWidth && !_items.includes(me.dayViewScroller)) {
                toAdd.push(me.dayViewScroller);
            }
        }
        const y = me.items[0]?.scrollable?.y;
        me.add(...toAdd);
        // Sync incoming view with the common scroll position
        if (y != null) {
            toAdd.forEach(v => {
                // Must go directly to DOM because the config value will be equal on a re-show
                v.scrollable.element.scrollTop = y;
            });
        }
        // If we have added or removed any views, we need to sync some things.
        if (toAdd.length || toRemove.length) {
            let lastView;
            for (let i = 0, { items } = me, { length } = items; i < length; i++) {
                const
                    view           = items[i],
                    { scrollable } = view;
                // Border collisions mean that a view's CSS may need to know whether
                // it is first, last, or in between.
                view.element.classList.remove('b-last-resource-view');
                // isResourceDayViewTimeAxis is set in the dayViewTimeAxis, and the dayViewScroller
                if (!view.isResourceDayViewTimeAxis) {
                    view.element.classList[i > 1 ? 'remove' : 'add']('b-first-resource-view');
                    lastView = view;
                }
                // Keep the Y axis of any scrollers synced.
                if (scrollable) {
                    scrollable.clearPartners();
                    if (i) {
                        view.scrollable.addPartner(items[i - 1].scrollable, 'y');
                    }
                }
                // In case the view add/remove caused differences in the heights of the all day rows.
                // All views must be in sync WRT the height of their all day rows.
                view.allDayEvents?.refresh();
            }
            if (lastView) {
                lastView.element.classList.add('b-last-resource-view');
            }
            // If some views have been added or removed, we need to resync the all day row heights
            // because until all views are present, the sync cannot be valid.
            if (me.viewType.isDayView) {
                const
                    allDayRows = me.views.filter(v => v.allDayEvents && v.isVisible),
                    maxHeight  = allDayRows.length && Math.max(...allDayRows.map(v => v.allDayEvents.cellContentHeight));
                // The allDayEvents refreshes might have caused one of them to be taller
                // than any others. We have to resync them after their refresh.
                if (maxHeight) {
                    _items.forEach(v => {
                        v.allDayEvents?.setEventContentHeight(maxHeight, false, true);
                    });
                }
            }
        }
        if (me.viewType.isDayView) {
            // Show/hide our fake scroller
            me.syncDayViewScrollerVisibility();
        }
        me.refreshCount = (me.refreshCount || 0) + 1;
        /**
         * Fires when this ResourceView refreshes.
         * @param {Calendar.widget.ResourceView} source The triggering instance.
         * @event refresh
         */
        me.trigger('refresh');
    }
    // Override at this level. Child views process events
    onCalendarPointerInteraction() {}
    // Override at this level. Child views process data mutations
    onCalendarStoreChange() {}
    onChildAdd(child) {
        super.onChildAdd(child);
        // We get a look at child view config changes to see if we need to propagate them to their siblings
        FunctionHelper.before(child, 'onConfigChange', 'onChildViewConfigChange', this, { return : false });
    }
    onChildViewConfigChange({ name, value }) {
        // Propagate timeAxisConfig settings between all siblings.
        // Do not proceed if we're already responding, *or* if this config change
        // is the result of a syncViewConfig call and will be applied to all views anyway.
        if (!this.syncingChildViewConfigs && !this.syncingViewConfig && this.timeAxisConfigs.includes(name)) {
            // We need *all* sub views. Hidden (filtered out) subviews
            // from the view cache have to be included. Use Set to uniquify them.
            const items = new Set(this.items.concat([...this.viewCache]));
            this.syncingChildViewConfigs = true;
            items.forEach(i => {
                if (name in i) {
                    i[name] = value;
                }
            });
            this.syncingChildViewConfigs = false;
        }
    }
    createView(resource) {
        const
            me                  = this,
            { id : resourceId } = resource,
            {
                resourceStore,
                viewCache
            }                   = me,
            { project }         = resourceStore,
            config              = Objects.merge({
                project,
                // All views must have a reference to the Calendar
                calendar : me.calendar,
                // IDs are matched so that a minimal Container update can be done
                id  : me.createViewId(resource),
                cls : 'b-resourceview-resource',
                // The view must know which resource it is showing.
                defaultCalendar : resourceId,
                // Resource is configured in initially because it may be needed during
                // configuration before we set a getter for it.
                resource,
                resourceId,
                includeTimeRanges : me.includeTimeRanges,
                weekStartDay      : me.weekStartDay,
                parent            : me,
                date              : me.date,
                dateFormat        : me.dateFormat,
                title             : me.titleTemplate(resource)
            }, me.view);
        // Copy in shared configs like hourHeight, dateFormat, dayStartTime, readOnly etc
        if (me.calendar) {
            ObjectHelper.copyPropertiesIf(config, [...viewCache][0] || me, me.sharedConfigs);
        }
        if (me.visibleStartTime) {
            config.visibleStartTime = me.visibleStartTime;
        }
        // DayViews must not show scrollers
        if (DomHelper.scrollBarWidth && me.viewType.isDayView) {
            config.scrollable = {
                overflowX : false,
                overflowY : 'hidden-scroll'
            };
        }
        const result = bryntum.Calendar.Modes.create(config);
        result.ion({
            catchAll : 'onChildViewCatchAll',
            thisObj  : me
        });
        result.element.removeAttribute('tabIndex');
        result.element.dataset.resourceId = resourceId;
        result.contentElement?.removeAttribute('tabIndex');
        // The item's weight is its store index if we are using stableResourceOrder
        Object.defineProperty(result, 'weight', {
            get() {
                return me.stableResourceOrder ? resourceStore.allIndexOf(resourceId) : 0;
            }
        });
        // The item's resource should be dynamically accessed from the resourceStore
        Object.defineProperty(result, 'resource', {
            get() {
                return resourceStore.getById(resourceId);
            }
        });
        // The item's eventFilter filters in events assigned to its resource
        Object.defineProperty(result, 'eventFilter', {
            get() {
                return e => e.resources.includes(this.resource);
            }
        });
        viewCache.add(result);
        /**
         * Fires when a new sub view is created.
         * @param {Calendar.widget.ResourceView} source The triggering instance.
         * @param {Calendar.widget.mixin.CalendarMixin} view The newly created sub view.
         * @typings view -> {typeof CalendarMixin}
         * @event viewCreate
         */
        me.trigger('viewCreate', { view : result });
        return result;
    }
    onChildViewCatchAll(e) {
        // Inject the child view's resource into the event as the resourceRecord
        e.resourceRecord = e.source.resource;
        if (e.type !== 'paint') {
            this.trigger(e.eventName, e);
        }
        if (e.type === 'heightchange') {
            this.syncDayViewScrollerVisibility();
        }
    }
    //region Title
    changeAvatarRendering(config) {
        if (config) {
            return AvatarRendering.new({
                element : this.element
            }, config);
        }
    }
    titleTemplate(resource) {
        const
            me         = this,
            { meta }   = me,
            metaValue  = typeof meta === 'string' ? resource[meta] : meta?.(resource),
            // eventColor = #FF5555, apply as background-color
            namedColor = DomHelper.isNamedColor(resource.eventColor) && resource.eventColor,
            // eventColor = red, add b-sch-red cls
            hexColor   = !namedColor && resource.eventColor;
        return {
            class : {
                'b-resourceview-title' : 1,
                'b-has-meta'           : metaValue
            },
            children : [
                me.showAvatars && {
                    class : {
                        'b-resource-avatar-container'      : 1,
                        [`b-sch-foreground-${namedColor}`] : namedColor
                    },
                    style : {
                        color : hexColor || null
                    },
                    children : [
                        me.getResourceAvatar(resource)
                    ]
                },
                {
                    class : 'b-resource-name',
                    text  : resource.name
                },
                metaValue && {
                    class : 'b-resource-meta',
                    text  : metaValue
                }
            ]
        };
    }
    //endregion
    // Override here because we need to delegate the request to the subview for the
    // event's resource
    getEventElement(eventRecord, date = eventRecord.startDate, resourceRecord) {
        return this.getResourceView(eventRecord.resource || resourceRecord)?.getEventElement(eventRecord, date);
    }
    /**
     * This creates a specially styled DayView which matches the view config (so that
     * dayStartTime, hourHeight etc. is synced) which shows nothing but its time axis.
     * This acts as the single visible time axis on the left.
     * @private
     */
    get dayViewTimeAxis() {
        const me = this;
        if (!me._dayViewTimeAxis) {
            const viewConfig = ObjectHelper.copyProperties({}, me.view, me.timeAxisConfigs);
            me._dayViewTimeAxis = ResourceDayViewTimeAxis.create({
                ...viewConfig,
                // Must always be at the beginning
                weight : -1,
                // This view must update its shape on data change but it won't contain any events.
                // All it has to do is keep its allDayEventsHeight in sync with all its siblings
                // which happens on refresh.
                project    : me.project,
                type       : 'resourcedayviewtimeaxis',
                minWidth   : 0,
                cls        : 'b-resource-dayview-timeaxis',
                startDate  : day0,
                endDate    : day1,
                scrollable : {
                    overflowX : false,
                    overflowY : 'hidden-scroll'
                },
                // This toggles all the allDayEvents rows in synchrony
                onCornerClick() {
                    const
                        { expanded } = me.firstChild.allDayEvents,
                        toRefresh    = [];
                    let drivingView = null;
                    me.eachView(({ allDayEvents }) => {
                        // Collapsing, do the one with least overflow first
                        if (expanded) {
                            if (!drivingView || allDayEvents.maxEventCount < drivingView.maxEventCount) {
                                drivingView = allDayEvents;
                            }
                        }
                        // Expanding, we have to do the one with most overflow first
                        else {
                            if (!drivingView || allDayEvents.maxEventCount > drivingView.maxEventCount) {
                                drivingView = allDayEvents;
                            }
                        }
                    });
                    // Toggle the driving one
                    const r = drivingView.refreshCount;
                    drivingView.expanded = !expanded;
                    // If it found nothing to do, it will not have refreshed. They all must refresh eventually.
                    if (drivingView.refreshCount === r) {
                        toRefresh.push(drivingView);
                    }
                    // Now do the rest
                    me.items.forEach(v => {
                        if (v !== drivingView) {
                            const
                                { allDayEvents } = v,
                                r                = allDayEvents.refreshCount;
                            // Don't recurse into this, call the real one.
                            v.allDayEvents.expanded = !expanded;
                            // If it found nothing to do, it will not have refreshed. They all must refresh eventually.
                            if (allDayEvents.refreshCount === r) {
                                toRefresh.push(allDayEvents);
                            }
                        }
                    });
                    // Some will not have found that they needed to refresh.
                    // Force the issue so that they too correct their cellContentHeights
                    for (let i = 0, { length } = toRefresh; i < length; i++) {
                        toRefresh[i].doRefresh();
                    }
                }
            }, me);
            // If the time axis changes size, we may acquire or lose overflow
            ResizeMonitor.addResizeListener(me._dayViewTimeAxis.timeAxisElement, me.syncDayViewScrollerVisibility.bind(me));
        }
        return me._dayViewTimeAxis;
    }
    /**
     * This creates a specially styled DayView which matches the view config (so that
     * dayStartTime, hourHeight etc. is synced) which shows nothing but its scrollbar.
     * This acts as the single visible scrollbar on the right of the ResourceView.
     * We cannot have the last DayView `overflowY : true` because then its flexed width
     * being equal to the others, its content area would be <scrollBarWidth> narrower
     * than the others.
     * @private
     */
    get dayViewScroller() {
        const me = this;
        if (!me._dayViewScroller) {
            const viewConfig = ObjectHelper.copyProperties({}, me.view, me.timeAxisConfigs);
            me._dayViewScroller = ResourceDayViewTimeAxis.create({
                ...viewConfig,
                // Must always be at the end
                weight : 999,
                // This view must update its shape on data change but it won't contain any events.
                // All it has to do is keep its allDayEventsHeight in sync with all its siblings
                // which happens on refresh.
                project    : me.project,
                type       : 'resourcedayviewtimeaxis',
                flex       : `0 0 ${DomHelper.scrollBarWidth}px`,
                minWidth   : DomHelper.scrollBarWidth,
                cls        : 'b-resource-dayview-scroller',
                startDate  : day0,
                endDate    : day1,
                scrollable : {
                    overflowX : false,
                    overflowY : 'scroll'
                }
            }, me);
            me._dayViewScroller.scrollable.addPartner(me.dayViewTimeAxis.scrollable, 'y');
            // This may have to show/hide as we need/don't need a scrollbar
            me.monitorResize = true;
        }
        return me._dayViewScroller;
    }
    onElementResize() {
        super.onElementResize();
        this.syncDayViewScrollerVisibility();
    }
    syncDayViewScrollerVisibility() {
        // Show/hide our fake scroller if we have one
        if (this.dayViewScroller) {
            const needsScroller = this.dayViewTimeAxis.scrollable.hasOverflow('y');
            if (this.dayViewScroller.isVisible !== needsScroller) {
                this.dayViewScroller[needsScroller ? 'show' : 'hide']();
            }
        }
    }
    async scrollTo(target, options) {
        const { views } = this;
        if (target.isEventModel) {
            const owningView = views.find(v => v.eventStore.includes(target));
            owningView?.scrollTo(...arguments);
            target = owningView.getEventElement(target);
            // If the owning view was able to got to the passed event, ensure we
            // scroll it into view.
            if (target) {
                return this.scrollable.scrollIntoView(target, options);
            }
        }
        return views[0].scrollTo(...arguments);
    }
    createViewId(resource) {
        return `${this.id}-resource${this.config.view.type}-${resource.id}`;
    }
    updateDate(date) {
        // Inhibit any refreshes during multiple subview updates
        this.suspendVisibility();
        // Keep *all* views in sync, not just visible ones.
        // Hidden ones will not refresh immediately. If a view is not currently visible,
        // CalendarMixin's refresh schedules a refresh for the next time the view is painted.
        this.viewCache.forEach(v => {
            // We must skip the time axis and scroller that are added to handle Day/Week view scrolling
            if (!v.isResourceDayViewTimeAxis) {
                v.date = date;
            }
        });
        // Trigger one round of refreshes.
        this.resumeVisibility();
        if (this.viewType.isDayView) {
            // The allDayEvents refreshes might have caused one of them to be taller
            // than any others. We have to resync them after their refresh.
            this.items.forEach(v => {
                v.allDayEvents?.setEventContentHeight(v.allDayEvents?.cellContentHeight);
            });
        }
    }
    updateResourceWidth(value) {
        this.contentElement.style.setProperty('--resource-width', DomHelper.setLength(value));
    }
    updateWeekStartDay(weekStartDay, oldWeekStartDay) {
        super.updateWeekStartDay?.(weekStartDay);
        if (!this.isConfiguring) {
            this.syncViewConfig('weekStartDay', weekStartDay);
        }
    }
    updateHideNonWorkingDays(value) {
        if (!this.isConfiguring) {
            this.syncViewConfig('hideNonWorkingDays', value);
        }
    }
    updateNonWorkingDays(value) {
        if (!this.isConfiguring) {
            this.syncViewConfig('nonWorkingDays', value);
        }
    }
    updateIncludeTimeRanges(value) {
        if (!this.isConfiguring) {
            this.syncViewConfig('includeTimeRanges', value);
        }
    }
    syncViewConfig(configName, value) {
        const update = view => {
            view[configName] = value;
        };
        // Cache this during the run so that onChildViewConfigChange doesn't bounce
        this.syncingViewConfig = true;
        // Some configs must affect the DayView scroller axes.
        if (scrollAxesConfigs[configName]) {
            this.items.forEach(update);
        }
        // Most only go to the real child views.
        else {
            this.eachView(update);
        }
        this.syncingViewConfig = false;
    }
    descriptionRenderer() {
        return [...this.viewCache.items][0]?.description || 'No resources';
    }
    get startDate() {
        return new Date(Math.min(...this.items.reduce((result, v) => {
            if (!v.isResourceDayViewTimeAxis) {
                result.push(v.startDate);
            }
            return result;
        }, [])));
    }
    get endDate() {
        return new Date(Math.max(...this.items.reduce((result, v) => {
            if (!v.isResourceDayViewTimeAxis) {
                result.push(v.endDate);
            }
            return result;
        }, [])));
    }
    get stepUnit() {
        const firstItem = [...this.viewCache][0];
        return firstItem?.stepUnit;
    }
    get firstChild() {
        return this.items.filter(w => !w.isResourceDayViewTimeAxis)[0];
    }
    previous() {
        const firstItem = [...this.viewCache][0];
        // We may never have created a child view.
        if (firstItem) {
            this.date = DateHelper.add(this.date, -firstItem.duration, 'day');
        }
    }
    next() {
        const firstItem = [...this.viewCache][0];
        // We may never have created a child view.
        if (firstItem) {
            this.date = DateHelper.add(this.date, firstItem.duration, 'day');
        }
    }
}
// Special non-data reading classes to create the scrollers
// either side of a series of DayViews.
class ResourceDayViewAllDayEvents extends CalendarRow {
    static get $name() {
        return 'ResourceDayViewAllDayEvents';
    }
    // Factoryable type name
    static get type() {
        return 'resourcedayviewalldayevents';
    }
    // The timeAxis's allDayEvents has overflow if any of the
    // real sibling DayView allDayEvents has overflow
    get hasOverflow() {
        return this.up('resourceview').views.some(v => v.allDayEvents?.hasOverflow);
    }
    createCellMap() {
        return emptyMap;
    }
}
class ResourceDayViewTimeAxis extends DayView {
    static get $name() {
        return 'ResourceDayViewTimeAxis';
    }
    // Factoryable type name
    static get type() {
        return 'resourcedayviewtimeaxis';
    }
    static get configurable() {
        return {
            allDayEvents : {
                type : 'resourcedayviewalldayevents'
            }
        };
    }
    compose() {
        const result          = super.compose();
        result['aria-hidden'] = true;
        return result;
    }
    // These axes have no say in this
    scrollToVisibleStartTime() {}
    createCellMap() {
        return emptyMap;
    }
    calculateCellContentHeight() {
        return 0;
    }
    getDateFromPosition(clientX, clientY, local = false) {
        const
            me     = this,
            date   = me.startDate,
            top    = local ? 0 : me.dayContentElement.getBoundingClientRect().y,
            height = me.timeAxisElement.offsetHeight,
            dy     = clientY - top;
        return DateHelper.round(
            DateHelper.add(DateHelper.clearTime(date), me.dayStartMs + Math.floor(dy / height * me.getDayLength()), 'ms'),
            me.increment);
    }
}
ResourceDayViewAllDayEvents.initClass();
ResourceDayViewTimeAxis.initClass();
ResourceView.initClass();
ResourceView._$name = 'ResourceView';