import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import Factoryable from '../../Core/mixin/Factoryable.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
/**
 * @module Calendar/feature/CalendarFeature
 */
/**
 * The base abstract class for Calendar features.
 *
 * @extends Core/mixin/InstancePlugin
 * @abstract
 */
export default class CalendarFeature extends InstancePlugin.mixin(Factoryable) {
    static get factoryable() {
        return {
            // establish this class as the Factoryable base
        };
    }
    static get configurable() {
        return {
            clientListeners : {
                paint : 'onCalendarPaint'
            },
            disableOnReadOnly : null,
            zoneTypes : null
        };
    }
    getViewZoneType(view) {
        const { zoneTypes } = this;
        let ret;
        if (zoneTypes && (ret = zoneTypes[view.type]) === undefined) {
            if (view.isWeekView && zoneTypes.week !== undefined) {
                ret = zoneTypes.week;
            }
            else if (view.isDayView) {  // also covers WeekView
                ret = zoneTypes.day;
            }
            else if (view.isMonthView) {
                ret = zoneTypes.month;
            }
            else if (view.isYearView) {
                ret = zoneTypes.year;
            }
            else if (view.isResourceView) {
                ret = zoneTypes.resource;
            }
            else if (view.isAgendaView) {
                ret = zoneTypes.agenda;
            }
        }
        return ret;
    }
    updateDisableOnReadOnly(disable) {
        const
            me = this,
            { client } = me,
            name = 'readonlyToggle',
            handler = 'onClientReadOnlyToggle';
        if (disable) {
            me[handler]({ readOnly : client.readOnly });
            client.ion({
                name,
                readOnly : handler,
                thisObj  : me
            });
        }
        else {
            me.detachListeners(name);
        }
    }
    doDestroy() {
        this.syncModes();
        super.doDestroy();
    }
    createZone(type, config) {
        return new type({
            owner : this,
            ...config
        });
    }
    onClientReadOnlyToggle({ readOnly }) {
        this.disabled = readOnly;
    }
    updateDisabled(disabled, was) {
        super.updateDisabled(disabled, was);
        if (this.client.isPainted) {
            this.syncModes();
        }
    }
    onCalendarPaint({ firstPaint }) {
        if (firstPaint) {
            this.syncModes();
        }
    }
    syncModes() {
        const
            me = this,
            { client, _modeDetacher, zones } = me;
        let newZones = null,
            modes, name, view, type, zone;
        // Tear down the modes when the feature is disabled... that's not worth optimizing for nor writing special
        // code in the zone helpers and since they need to be destroyable, just destroy them.
        if (me.disabled || me.isDestroying || !me.zoneTypes) {
            _modeDetacher?.();
        }
        else {
            if (client.isCalendar) {
                if (!_modeDetacher) {
                    me._modeDetacher = client.ion({
                        addMode : () => me.syncModes()
                    });
                }
                modes = client.modes;
            }
            else {
                modes = {
                    view : client
                };
            }
            for (name in modes) {
                zone = zones?.[name];
                view = modes[name];
                type = me.getViewZoneType(view);
                if (type) {
                    if ((ObjectHelper.isObject(zone) ? zone.type : zone?.constructor) === type) {
                        delete zones[name];  // keep this zone around...
                    }
                    else if (view.isPainted) {
                        zone = me.createZone(type, {
                            view
                        });
                    }
                    else {
                        // Unpainted calendar views are not fully initialized, so we wait for them to paint before
                        // creating the zone helper instance.
                        zone = me.whenPainted(name, view, type);
                    }
                    (newZones || (newZones = {}))[name] = zone;
                }
            }
        }
        me.zones = newZones;
        if (zones) {
            for (name in zones) {
                zones[name].destroy();
            }
        }
    }
    whenPainted(name, view, type) {
        const
            me = this,
            proxy = {
                type,
                destroy : view.ion({
                    once : true,
                    // Must create zone first
                    prio : 10000,
                    paint() {
                        if (me.zones[name] === proxy) {
                            me.zones[name] = me.createZone(type, {
                                view
                            });
                        }
                    }
                })
            };
        return proxy;
    }
}
CalendarFeature._$name = 'CalendarFeature';