import VersionHelper from '../../Core/helper/VersionHelper.js';
import Factoryable from '../../Core/mixin/Factoryable.js';
import Featureable from '../../Core/mixin/Featureable.js';
import State from '../../Core/mixin/State.js';
import Base from '../../Core/Base.js';
import Panel from '../../Core/widget/Panel.js';
import Widget from '../../Core/widget/Widget.js';
import Responsive from '../../Core/widget/mixin/Responsive.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import TimeZoneHelper from '../../Core/helper/TimeZoneHelper.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
import DynamicObject from '../../Core/util/DynamicObject.js';
import ScrollManager from '../../Core/util/ScrollManager.js';
import CalendarFeature from '../feature/CalendarFeature.js';
import CrudManagerView from '../../Scheduler/crud/mixin/CrudManagerView.js';
import CurrentConfig from '../../Scheduler/view/mixin/CurrentConfig.js';
import Sidebar from '../widget/Sidebar.js';
import AgendaView from '../widget/AgendaView.js';
import DayView from '../widget/DayView.js';
import MonthView from '../widget/MonthView.js';
import WeekView from '../widget/WeekView.js';
import YearView from '../widget/YearView.js';
import EventList from '../widget/EventList.js';
import ResourceView from '../widget/ResourceView.js';
import DayResourceView from '../widget/DayResourceView.js';
import CalendarStores from '../mixin/CalendarStores.js';
import SchedulerInterface from '../mixin/SchedulerInterface.js';
import EventNavigation from './mixin/EventNavigation.js';
import EventSelection from './mixin/EventSelection.js';
import RecurringEvents from '../../Scheduler/view/mixin/RecurringEvents.js';
import '../localization/En.js';
import '../widget/ModeSelector.js';
import '../../Core/widget/layout/Card.js';
import '../../Core/widget/ButtonGroup.js';
import '../../Core/widget/panel/PanelCollapserOverlay.js';
import '../feature/CalendarDrag.js';
import '../feature/EventEdit.js';
import '../feature/EventMenu.js';
import '../feature/EventTooltip.js';
import '../feature/ScheduleMenu.js';
import StringHelper from '../../Core/helper/StringHelper.js';
/**
 * @module Calendar/view/Calendar
 */
const
    byWeight               = (l, r) => (l.weight || 0) - (r.weight || 0),
    relayedEvents          = /^.*event.*|.*resource.*|.*cell.*|.*row.*|.*schedule.*|daynumber.*|weeknumber.*|monthname.*|weekname.*$|beforedatechange|tickchange|.*showoverflowpopup|beforeautocreate|rangechange|weekflex/,
    isFocusedCalendarMixin = w => w.isCalendarMixin && w.containsFocus,
    // These are the configs which, when applied to the calendar are propagated down to child views.
    // modeDefaults *could* handle this task, but some configs make sense as Calendar-wide settings.
    propagatedConfigs      = ['date', 'coreHours', 'resourceImagePath', 'hideNonWorkingDays', 'nonWorkingDays', 'dateFormat', 'visibleStartTime', 'filterEventResources'],
    emptyArray             = Object.freeze([]),
    hasWeight              = m => m.weight > 0,
    noDateChangeEvents     = {
        weeknumberclick : 1,
        monthnameclick  : 1
    },
    isMonthView            = v => v.isMonthView || v.view?.type === 'monthview',
    isWeekView             = v => v.isWeekView || v.view?.type === 'weekview';
/**
 * @typedef {Object} CalendarHit
 * @property {'event'|'dayNumber'|'weekNumber'|'cellOverflow'|'schedule'} type The type of the target:
 * `'event'`, `'dayNumber'`, `'weekNumber'`, `'cellOverflow'`, or `'schedule'`.
 * @property {Date} date The date of the target.
 * @property {Scheduler.model.EventModel} eventRecord The target event record.
 */
/**
 * A configurable, aggregated view of a {@link Scheduler.data.EventStore} which may comprise several
 * view types to display the events in different ways.
 *
 * This is the primary way of creating a calendar UI.
 *
 * {@inlineexample Calendar/view/Calendar.js}
 *
 * ## Calendar modes
 * A Calendar may be configured with {@link #config-modes} which describe the types of views of the
 * calendar data which should be shown.
 *
 * These are:
 *  - `'agenda'`
 *  - `'year'`
 *  - `'month'`
 *  - `'week'`
 *  - `'day'`
 *  - `'list'`
 *  - `'resource'`
 *  - `'dayresource'`
 *
 * All except `'list'` and `'resource'` and `'dayresource'` are included by default.
 *
 * The modes are instances of the following classes:
 *
 * - {@link Calendar.widget.AgendaView}
 * - {@link Calendar.widget.YearView}
 * - {@link Calendar.widget.MonthView}
 * - {@link Calendar.widget.WeekView}
 * - {@link Calendar.widget.DayView}
 * - {@link Calendar.widget.EventList}
 * - {@link Calendar.widget.ResourceView}
 * - {@link Calendar.widget.DayResourceView}
 *
 * Each may be reconfigured from its default configuration by specifying its mode value as an object:
 *
 * ```javascript
 * new Calendar({
 *     appendTo : domElement,
 *     modes : {
 *         month : {
 *             // Week number in a separate, clickable column
 *             showWeekColumn : true
 *         },
 *         week : {
 *             // Day columns start at 8am. No events shown before this
 *             dayStartTime : 8
 *         }
 *     }
 * });
 * ```
 *
 * Modes may be omitted from the view by specifying its mode value as `null`.
 * To specify the initial active mode, {@link #config-mode} config should be used, to manage mode at runtime, {@link #property-mode} property is available.
 *
 * ```javascript
 * new Calendar({
 *     appendTo : domElement,
 *     mode  : 'week',
 *     modes : {
 *         agenda : null,
 *         year   : null
 *     }
 * });
 * ```
 *
 * Other provided UI elements may be omitted from the view by specifying their configuration values as `null`:
 *
 * ```javascript
 * new Calendar({
 *     appendTo : domElement,
 *
 *     // Do not show the top toolbar
 *     tbar : null,
 *
 *     // Do not show the sidebar
 *     sidebar : null
 * });
 * ```
 *
 * Or, for more granularity:
 *
 * ```javascript
 * new Calendar({
 *     appendTo : domElement,
 *
 *     tbar : {
 *         items : {
 *             // Do not show the "Today" button in the top toolbar
 *             todayButton : null
 *         }
 *     },
 *
 *     sidebar : {
 *         items : {
 *             // Do not show the mini calendar in the sidebar
 *             datePicker : null
 *         }
 *     }
 * });
 * ```
 *
 * ## Toolbar, sidebar and viewContainer
 * A Calendar is basically a {@link Core.widget.Panel} which contains nested widgets which implement
 * the complex UI. By default it has three immediate child widgets which may be accessed through the
 * following properties:
 *
 * - {@link #property-tbar} references the top toolbar, which is an instance of {@link Core.widget.Toolbar}.
 * - {@link #property-sidebar} references the sidebar, which is an instance of {@link Calendar.widget.Sidebar}.
 * <br>This item has a {@link Core.widget.Widget#config-weight} of `-10` by default, placing it at the
 * `inline-start` side of the Calendar.<br>If the Sidebar is configured with
 * {@link Calendar.widget.Sidebar#config-side} as `'end'`, then it uses `200` to move it to the
 * `inline-end` side.
 * - {@link #property-viewContainer} references the subordinate {@link Core.widget.Container} which manages the modes
 * and changes which mode is active through its {@link Core.widget.Container#property-layout}.
 *  <br>This item has a {@link Core.widget.Widget#config-weight} of `100` by default, placing it after
 * the sidebar.
 *
 * The Calendar *may* be configured with extra {@link #config-items} which will be integrated into the
 * horizontal flexbox display, sharing space with the `sidebar` and `viewContainer`.
 * The order may be controlled using the {@link Core.widget.Widget#config-weight} property.
 *
 * These three child widgets may be reconfigured at instantiation time using their config options
 * {@link #config-tbar}, {@link #config-sidebar} and {@link #config-viewContainer}.
 *
 * ## Loading data
 *
 * Fundamentally, all data stores mentioned below are subordinate to the Calendar's
 * {@link #property-project Project} which is the managing object for the {@link #property-eventStore},
 * {@link #property-resourceStore}, {@link #property-assignmentStore}, {@link #property-timeRangeStore} and
 * {@link #property-resourceTimeRangeStore}
 *
 * A Calendar loads its events into an {@link Scheduler.data.EventStore}.
 *
 * The recommended way to load and sync data is to use a {@link Scheduler.data.CrudManager} configured with
 * the URL of web services:
 *
 * ```javascript
 * new Calendar({
 *     appendTo : domElement,
 *     crudManager : {
 *         transport : {
 *             load : {
 *                 url : '/data-providing-url'
 *             }
 *             sync : {
 *                 url : '/data-update-url'
 *             }
 *         },
 *         autoLoad : true,
 *         autoSync : true
 *     }
 * });
 * ```
 *
 * JSON data should use this format:
 *
 * ```json
 * {
 *     "success" : true,
 *     "resources" : {
 *         "rows" : [
 *             {
 *                 "id" : 1,
 *                 "name" : 'Default Calendar',
 *                 "eventColor" : 'green'
 *             }
 *         ]
 *     },
 *     "events" : {
 *         "rows" : [
 *             {
 *                 "id" : 1,
 *                 "name" : 'Meeting',
 *                 "startDate" : '2020-10-01T10:00:00',
 *                 "endDate" : '2020-10-01T11:00:00',
 *                 "resourceId" : 1
 *             }
 *         ]
 *     }
 * }
 * ```
 *
 * ### Multiple assignment
 *
 * When multiple resource assignment is required, data should look as below. Assignments
 * are seperate records which link events to resources by connecting the ids of each.
 *
 * In this example, event id `1` is assigned to resources `1` and `2`
 *
 * Note that there is __no__ `resourceId` field in the event data:
 *
 * ```json
 * {
 *     "success" : true,
 *     "resources" : {
 *         "rows" : [
 *             {
 *                 "id" : 1,
 *                 "name" : 'Default Calendar',
 *                 "eventColor" : 'green'
 *             }, {
 *                 "id" : 2,
 *                 "name" : 'Alternative Calendar',
 *                 "eventColor" : 'red'
 *             }
 *         ]
 *     },
 *     "events" : {
 *         "rows" : [
 *             {
 *                 "id" : 1,
 *                 "name" : 'Meeting',
 *                 "startDate" : '2020-10-01T10:00:00',
 *                 "endDate" : '2020-10-01T11:00:00'
 *             }
 *         ]
 *     },
 *     "assignments" : {
 *         "rows" : [
 *             {
 *                 id         : 1,
 *                 eventId    : 1,
 *                 resourceId : 1
 *             }, {
 *                 id         : 2,
 *                 eventId    : 1,
 *                 resourceId : 2
 *             }
 *         ]
 *     }
 * }
 * ```
 *
 * ### Loading time ranges
 *
 * When using the {@link Calendar.feature.TimeRanges} feature, the time ranges are included in
 * named data blocks:
 *
 * ```json
 * {
 *     success : true,
 *     events : {...},
 *     resources : {...},
 *     timeRanges : {
 *         rows : [
 *             {
 *                 "id"        : 1,
 *                 "name"      : "Post-conference",
 *                 "alignment" : "end",
 *                 "startDate" : "2020-10-15 15:00",
 *                 "endDate"   : "2020-10-15 20:00",
 *                 "color"     : "red"
 *             }
 *         ]
 *     }
 * }
 * ```
 *
 * When using a {@link Calendar.widget.ResourceView}, resource-specific time ranges may
 * also be included in the data. These are only rendered in subviews of the resource view
 * with matching resource ids:
 *
 * ```json
 * {
 *     success : true,
 *     events : {...},
 *     resources : {...},
 *     resourceTimeRanges : {
 *         rows : [
 *             {
 *                 "id"         : 1,
 *                 "resourceId" : 1
 *                 "name"       : "Post-conference",
 *                 "alignment"  : "end",
 *                 "startDate"  : "2020-10-15 15:00",
 *                 "endDate"    : "2020-10-15 20:00",
 *                 "color"      : "red"
 *             }
 *         ]
 *     }
 * }
 * ```
 *
 * For more information on loading events and resources, see the {@link Scheduler.view.Scheduler}
 * documentation. Calendar uses the same data classes.
 *
 * ## Listening to events
 * The events emitted by the Calendar are documented {@link #event-eventClick here}. Note that in
 * addition to `click`, all other mouse events are also covered and all significant UI elements
 * are active, and follow the same naming convention.
 *
 * So there is a `dayNumberClick` event and a `weekNumberClick` event and a
 * `monthNameClick` event and so on.
 *
 * ## Specifying a default calendar
 *
 * To set the default calendar for your events, please use the {@link #config-defaultCalendar} config.
 *
 * ```javascript
 * new Calendar({
 *     defaultCalendar : 123 // the id of your default calendar
 * });
 * ```
 *
 * You may specify {@link #config-defaultCalendar} as `null` to specify that when an event is
 * {@link #config-autoCreate auto created}, it is not automatically assigned to a calendar.
 *
 * ## State saving
 *
 * By default the {@link #property-mode} and {@link #property-hideNonWorkingDays} properties are saved
 * when a {@link #config-stateProvider} is used.
 *
 * You can configure other properties to be part of the persistent state:
 *
 * ```javascript
 * new Calendar({
 *     stateful : [ 'date' ]
 * })
 * ```
 *
 * @extends Core/widget/Panel
 *
 * @mixes Core/widget/mixin/Responsive
 * @mixes Calendar/mixin/CalendarStores
 * @mixes Scheduler/crud/mixin/CrudManagerView
 * @mixes Scheduler/view/mixin/RecurringEvents
 * @mixes Calendar/view/mixin/EventNavigation
 * @mixes Calendar/view/mixin/EventSelection
 *
 * @features Calendar/feature/CalendarDrag
 * @features Calendar/feature/EventEdit
 * @features Calendar/feature/EventMenu
 * @features Calendar/feature/EventTooltip
 * @features Calendar/feature/ExternalEventSource
 * @features Calendar/feature/LoadOnDemand
 * @features Calendar/feature/ScheduleMenu
 * @features Calendar/feature/TimeRanges
 * @features Calendar/feature/WeekExpander
 *
 * @features Calendar/feature/print/Print
 *
 * @classtype calendar
 *
 * @widget
 */
export default class Calendar extends Panel.mixin(
    SchedulerInterface,
    CalendarStores,
    Featureable,
    CrudManagerView,
    EventNavigation,
    EventSelection,
    CurrentConfig,
    Responsive,
    State,
    RecurringEvents
) {
    //region Config
    static get $name() {
        return 'Calendar';
    }
    // Factoryable type name
    static get type() {
        return 'calendar';
    }
    static get configurable() {
        return {
            // region Hidden configs
            /**
             * @event eventSelectionChange
             * @hide
             */
            /**
             * @hideconfigs htmlCls, autoUpdateRecord, record, textContent, content, html
             */
            /**
             * @hideproperties content, html
             */
            // endRegion
            // Insert features config here because it is inherited from Featurable, which is @internal
            // and therefore does not show up in the API docs in its default state.
            /**
             * Specifies the features to create and associate with the Calendar.
             *
             * The keys of this object are the names of features. The values are config objects for those features.
             *
             * ```javascript
             *     features : {
             *         // Exclude the CalendarDrag feature from the Calendar
             *         drag : false,
             *
             *         // Change the default configuration of a feature
             *         eventTooltip : {
             *             showOn : 'hover'
             *         }
             *     }
             * ```
             * @config {Object} features
             */
            /**
             *
             * After construction, this property can be used to access the Calendar's features and
             * reconfigure them.
             *
             * For example:
             *
             * ```javascript
             *  myCalendar.features.eventTooltip.disabled = true;
             * ```
             * @member {Object} features
             */
            layout : 'hbox',
            /**
             * Enables events being recurring and also adds extra recurrence UI fields in the built-in event editor.
             * @config {Boolean}
             * @default true
             * @category Scheduled events
             */
            enableRecurringEvents : true,
            localizableProperties : [
                'autoCreate.newName'
            ],
            crudManager : null,
            features : {
                drag         : {},
                eventEdit    : {},
                eventTooltip : {},
                scheduleMenu : {},
                eventMenu    : {}
            },
            /**
             * Indicates where the Next/Previous/Today buttons should be placed.
             * @config {'toolbar'|'sidebar'}
             * @default 'toolbar'
             */
            navigatorPlacement : null,
            responsive : {
                small : {
                    when : 600,
                    once : {
                        mode : 'day'
                    }
                },
                medium : {
                    when : 800
                },
                large : {
                    overlaySidebar : false
                },
                '*' : {
                    overlaySidebar : true,
                    once : {
                        mode : 'week'
                    }
                }
            },
            responsiveRoot : true,
            stateful : [
                'hideNonWorkingDays',
                'mode'
            ],
            /**
             * A {@link Core.widget.Toolbar} which displays and manages the calendar title
             * and the buttons which manipulate the Calendar's temporal navigation and
             * active mode.
             *
             * Provided widgets include:
             *
             * - `toggleSidebar` A button to collapse and expand the {@link #config-sidebar}
             * - `todayButton` A button which moves the active view to include today's date.
             * - `prevButton` A button which moves the active view to its previous time span.
             * <br>__Note:__ The active view must yield a {@link Calendar.widget.mixin.CalendarMixin#property-stepUnit}
             * to use as the tooltip hint for this button, otherwise it will be disabled.
             * - `nextButton` A button which moves the active view to its next time span.
             * <br>__Note:__ The active view must yield a {@link Calendar.widget.mixin.CalendarMixin#property-stepUnit}
             * to use as the tooltip hint for this button, otherwise it will be disabled.
             * - `viewDescription` A widget which is used to display the `description` property of the active view.
             * - `spacer` A spacer widget which pushes following items to be aligned to the right.
             * - `modeSelector` A {@link Calendar.widget.ModeSelector} which contains buttons for selecting which
             *   view is active. This is hidden if only one {@link #config-modes mode} is enabled in the Calendar.
             *
             * These have `weight` values 100 to 700. New items can be inserted at any position by
             * configuring them with an appropriate `weight`.
             *
             * @member {Core.widget.Toolbar} tbar
             * @readonly
             * @category Content
             */
            /**
             * A config object containing definitions of the toolbar of the Calendar.
             *
             * Its `items` property defines the buttons and informational widgets
             * provided by default.
             *
             * There are several provided widgets, each of which may be reconfigured using
             * an object, or disabled by configuring them as `null`.
             *
             * ```javascript
             * tbar : {
             *     items : {
             *         prevButton    : null,
             *         toggleSidebar : null
             *     }
             * }
             * ```
             *
             * Provided widgets include:
             *
             * - `toggleSidebar` A button to collapse and expand the {@link #config-sidebar}
             * - `todayButton` A button which moves the active view to include today's date.
             * - `prevButton` A button which moves the active view to its previous time span.
             * <br>__Note:__ The active view must yield a {@link Calendar.widget.mixin.CalendarMixin#property-stepUnit}
             * to use as the tooltip hint for this button, otherwise it will be disabled.
             * - `nextButton` A button which moves the active view to its next time span.
             * <br>__Note:__ The active view must yield a {@link Calendar.widget.mixin.CalendarMixin#property-stepUnit}
             * to use as the tooltip hint for this button, otherwise it will be disabled.
             * - `viewDescription` A widget which is used to display the `description` property of the active view.
             * - `spacer` A spacer widget which pushes following items to be aligned to the right.
             * - `modeSelector` A {@link Calendar.widget.ModeSelector} which contains buttons for selecting which
             *   view is active. This is hidden if only one {@link #config-modes mode} is enabled in the Calendar.
             *
             * These have `weight` values 100 to 800. New items can be inserted at any position by
             * configuring them with an appropriate `weight`.
             *
             * Configure this as `null` to remove the toolbar entirely.
             * @config {ToolbarConfig}
             */
            tbar : {
                cls   : 'b-calendar-toolbar',
                items : {
                    toggleSidebar : {
                        icon    : 'b-icon-menu-vertical',
                        cls     : 'b-sidebar-toggle b-borderless b-transparent',
                        onClick : 'up.onToggleSidebarClick',
                        weight  : 100
                    },
                    todayButton : {
                        text    : 'L{Calendar.Today}',
                        cls     : 'b-cal-nav-item b-calendar-today-button',
                        icon    : 'b-icon-calendar-day',
                        onClick : 'up.shiftToNow',
                        weight  : 200
                    },
                    prevButton : {
                        onClick : 'up.shiftPrevious',
                        cls     : 'b-cal-nav-item b-borderless b-transparent',
                        icon    : 'b-icon-previous',
                        weight  : 300
                    },
                    nextButton : {
                        onClick : 'up.shiftNext',
                        cls     : 'b-cal-nav-item b-borderless b-transparent b-cal-nav-next',
                        icon    : 'b-icon-next',
                        weight  : 400
                    },
                    viewDescription : {
                        cls          : 'b-calendar-view-desc',
                        type         : 'widget',
                        flex         : '',
                        html         : '\xa0',
                        overflowable : 'none',
                        weight       : 500
                    },
                    spacer : {
                        type   : 'widget',
                        cls    : 'b-toolbar-fill',
                        weight : 600
                    },
                    modeSelector : {
                        type   : 'calendarModeSelector',
                        weight : 700
                    }
                }
            },
            /**
             * The child items of the Calendar view.
             *
             * The Calendar contains two child items:
             *
             * - `sidebar` The sidebar which by default contains a clickable date picker to show and/or select
             * the active date, and a Calendar filter UI to filter events belonging to the calendars
             * defined in the {@link Calendar.mixin.CalendarStores#config-resourceStore}.
             * - `viewContainer` A {@link Core.widget.layout.Card card layout}
             * {@link Core.widget.Container container} which contains the enabled {@link #config-modes}.
             *
             * These items may be reconfigured by specifying them as an object, for example:
             *
             * ```javascript
             * new Calendar({
             *     appendTo : document.body,
             *
             *     crudManager : {
             *         transport : {
             *             load : {
             *                 url : '/data-providing-url'
             *             }
             *             sync : {
             *                 url : '/data-update-url'
             *             }
             *         },
             *         autoLoad : true,
             *         autoSync : true
             *     },
             *
             *     // Reconfigure our viewContainer to change which "mode" is active upon startup
             *     items : {
             *         viewContainer : {
             *             // Start looking at the week - its the fourth "mode" by default.
             *             layout : {
             *                 activeIndex : 3
             *             }
             *         }
             *     }
             * });
             * ```
             * @config {Object}
             * @private
             */
            items : {},
            /**
             * Configuration options to change how the subordinate {@link Core.widget.Container} which contains
             * the calendar's child views is created.
             *
             * After instantiation, this Container may be referenced using the {@link #property-viewContainer} property
             *
             * For example:
             * ```javascript
             * new Calendar({
             *     appendTo : document.body,
             *
             *     crudManager : {
             *         loadUrl  : '/data-providing-url',
             *         syncUrl  : '/data-update-url',
             *         autoLoad : true,
             *         autoSync : true
             *     },
             *
             *     // Reconfigure our viewContainer to change which "mode" is active upon startup
             *     items : {
             *         viewContainer : {
             *         // No sliding in or out of child views
             *             layout : {
             *                 animateCardChange : false
             *             }
             *         }
             *     }
             * });
             * ```
             * @config {ContainerConfig} viewContainer
             */
            viewContainer : {
                $config : 'lazy',
                value   : {
                    type   : 'container',
                    weight : 100,
                    cls    : {
                        'b-calendar-viewcontainer' : 1
                    },
                    flex   : '1 1 100%',
                    layout : {
                        type : 'card'
                    },
                    scrollable : {
                        overflowY : true,
                        overflowX : true
                    },
                    suppressChildHeaders : true,
                    layoutStyle          : {
                        padding : 0
                    },
                    internalListeners : {
                        beforeActiveItemChange : 'up.onBeforeModeChange',
                        activeItemChange       : 'up.onModeChange'
                    }
                }
            },
            /**
             * Set to `true` to show the "Weekends" toggle button of the mode selector. Alternatively, this config
             * can be a {@link Core.widget.Button} config object to configure the button.
             * @config {Boolean|ButtonConfig}
             * @default
             */
            includeWeekendsButton : false,
            layoutStyle : {
                padding  : 0,
                flexFlow : 'row nowrap'
            },
            /**
             * The {@link #config-modes} as widget instances.
             * @member {Object<String,Core.widget.Widget>} modes
             */
            /**
             * Configures the view modes which are to be available in this Calendar.
             *
             * Normally, the property names in this object are the `type` names of the eight standard
             * Calendar views, and so the corresponding configuration object should be an object which
             * configures that type:
             *
             * - agenda - Configuration for a {@link Calendar.widget.AgendaView}
             * - year - Configuration for a {@link Calendar.widget.YearView}
             * - month - Configuration for a {@link Calendar.widget.MonthView}
             * - week - Configuration for a {@link Calendar.widget.WeekView}
             * - day - Configuration for a {@link Calendar.widget.DayView}
             * - list - Configuration for a {@link Calendar.widget.EventList}
             * - resource - Configuration for a {@link Calendar.widget.ResourceView}
             * - dayresource - Configuration for a {@link Calendar.widget.DayResourceView}
             *
             * By default, the following modes are included:
             *
             * - day
             * - week
             * - month
             * - year
             * - agenda
             *
             * Any of these may be reconfigured from its default, or omitted from the UI, by configuring the
             * property as `null`:
             *
             * ```javascript
             * modes : {
             *     agenda : {
             *         title : 'Conference events'
             *     },
             *     // Remove year view completely
             *     year : null,
             *     // Add an event list. This type of view is not included by default
             *     list : {
             *         range : 'month'
             *     }
             * }
             * ```
             *
             * Note that the default order of the modes as listed above can be changed by configuring the
             * modes with a `weight` value which causes them to be sorted into ascending weight order.
             *
             * Non-Calendar widgets, outside of those eight types may be specified by including a `type` property
             * in the configuration block. This is the type string of any widget.
             *
             * If using a non-Calendar widget the widget must contain the following properties:
             *
             * - `title` A string used as the button text in the mode activation button.
             * - `description` A string used as the Calendar title when the mode is activated.
             *
             * For example, you might want to use a scheduler as a calendar mode. This can be done like this:
             *
             * ```javascript
             *     modes : {
             *         myScheduler : {
             *             // If the mode name is not a standard Calender view
             *             // we need to specify the type of Widget we are configuring here.
             *             type        : 'scheduler',
             *
             *             // This text goes into the activation button
             *             title : 'Timeline',
             *             columns : [
             *                 { type : 'resourceInfo', field : 'name', text : 'Staff/Resource', width : 175 }
             *             ],
             *
             *             features : {
             *                 nonWorkingTime     : true,
             *                 timeRanges         : true,
             *                 resourceTimeRanges : true
             *             },
             *
             *             workingTime : {
             *                 fromHour : 7,
             *                 toHour   : 22
             *             },
             *         }
             *     }
             * ```
             *
             * @property {ContainerItemConfig} [modes.custom] Settings specific to custom mode. `custom` may be any `String` property within this configuration object.
             * @typings modes.custom -> {[mode: string]}:{Partial<ContainerItemConfig>|boolean|null|undefined}
             *
             * @property {AgendaViewConfig|Boolean|null} [modes.agenda] Settings specific to `agenda` mode. Specify `null` or `false` to disable this mode.
             * @property {YearViewConfig|Boolean|null} [modes.year] Settings specific to `year` mode. Specify `null` or `false` to disable this mode.
             * @property {MonthViewConfig|Boolean|null} [modes.month] Settings specific to `month` mode. Specify `null` or `false` to disable this mode.
             * @property {WeekViewConfig|Boolean|null} [modes.week] Settings specific to `week` mode. Specify `null` or `false` to disable this mode.
             * @property {DayViewConfig|Boolean|null} [modes.day] Settings specific to `day` mode. Specify `null` or `false` to disable this mode.
             * @property {EventListConfig|Boolean|null} [modes.list] Settings specific to `list` mode. This mode is disabled by default.
             * @property {ResourceViewConfig|Boolean|null} [modes.resource] Settings specific to `resource` mode. This mode is disabled by default.
             * @property {DayResourceViewConfig|Boolean|null} [modes.dayresourceview] Settings specific to `dayresourceview` mode. This mode is disabled by default.
             * @config {Object} [modes]
             * @default
             */
            modes : {
                day : {
                    // This view is locked to one day duration
                    fixedDuration : true
                },
                week   : {},
                month  : {},
                year   : {},
                agenda : {}
            },
            /**
             * Which of the built-in {@link #config-modes} should be the initially active view.
             * @config {'agenda'|'year'|'month'|'week'|'day'|'list'|'resource'|'dayresourceview'|String}
             * @default week
             */
            mode : {
                $config : 'lazy',
                value   : 'week'
            },
            /**
             * The default settings applied to all child views.
             *
             * This is a dynamic object, and mutations of it will reapply the new value to all
             * child views.
             * @member {Object} modeDefaults
             */
            /**
             * An object to use to configure common properties for all {@link #config-modes} prior to their construction.
             * @config {Object}
             */
            modeDefaults : {
                value : null  // use the value config so that the changer always runs so that Proxy is created
            },
            /**
             * The {@link #config-sidebar} as an instance of {@link Calendar.widget.Sidebar}.
             * @member {Core.widget.Container} sidebar
             * @readonly
             */
            /**
             * An optional config object to configure the {@link Calendar.widget.Sidebar} which is
             * shown next to the calendar views specified by the {@link #config-modes}.
             *
             * To disable the sidebar, configure it as `null`.
             *
             * To reconfigure it, specify this config value as an object. The following parameters include
             * several commonly reconfigured properties.
             * @config {Object|Boolean}
             * @param {'left'|'right'} side Which {@link Calendar.widget.Sidebar#config-side} to dock to.
             * @param {Boolean} collapsed May be initially {@link Calendar.widget.Sidebar#config-collapsed}.
             * @param {Object<String,ContainerItemConfig>} items Reconfigure or add to the sidebar UI.
             * @param {DatePickerConfig} items.datePicker The {@link Core.widget.DatePicker} may be reconfigured using
             * an object, or configured away using `null`. Its default {@link Core.widget.Widget#config-weight} is 100.
             * @param {TextFieldConfig} items.eventFilter The eventFilter is a {@link Core.widget.TextField} which may
             * be used to filter the events by matched name. It may be reconfigured using an object, or configured away
             * using `null`. Its default {@link Core.widget.Widget#config-weight} is 150.
             * @param {ResourceFilterConfig} items.resourceFilter The {@link Scheduler.widget.ResourceFilter} may be
             * reconfigured using an object, or configured away using `null`. Its default
             * {@link Core.widget.Widget#config-weight} is 200.
             * @param {Object} items.resourceFilter.scrollable By default it scrolls in the Y axis.
             * @param {String|Number} items.resourceFilter.flex By default it uses flex `1 1 auto`.
             * @param {Function} items.resourceFilter.masterFilter The filter function to apply when loading resources
             * from the project's `resourceStore`. Defaults to only including resources which are filtered into the Project's
             * `resourceStore`. Configure this as `() => true` to always include all resources.
             */
            sidebar : {
                $config : {
                    merge : 'objects'
                },
                value : {
                    type       : 'sidebar',
                    side       : 'left',
                    scrollable : {
                        overflowY : true
                    },
                    internalListeners : {
                        dateChange : 'up.onCalendarDateChange',
                        collapse   : 'up.onCalendarSidebarToggle',
                        expand     : 'up.onCalendarSidebarToggle'
                    }
                }
            },
            /**
             * An optional config object to configure the {@link Calendar.widget.CalendarDatePicker} which is
             * shown in the {@link #property-sidebar} next to the calendar views.
             *
             * To disable the datePicker, configure it as `null`.
             *
             * To reconfigure it, specify this config value as an object.
             *
             * Note that when `showEvents` is set in the `datePicker`, the cells are slightly larger,
             * therefore the `datePicker` takes up more space and so will make the
             * {@link #property-sidebar} a little wider than normal.
             * @config {Object|Boolean}
             * @param {Boolean|'count'|'dots'} [datePicker.showEvents] Displays the presence of events in each cell.
             * values may be:
             *
             * * `false` - Do not show events in cells.
             * * `true` - Show a themeable bullet to indicate the presence of events for a date.
             * * `'count'` - Show a themeable badge containing the event count for a date.
             * * '`dots'` - Show small event-coloured bullets (to a maximum of three) below the date.
             * @param {Boolean} [datePicker.collapsible] `true` to enable collapse into a header.
             * @param {String} [datePicker.title] The text to place in a header above the picker.
             * @param {Boolean} [datePicker.editMonth] `true` to enable setting the date using the controls
             * in the DatePicker header.
             */
            datePicker : {
                $config : {
                    merge : 'objects'
                },
                value : {
                    weight        : 100,
                    dayNameFormat : 'd1',
                    editMonth     : null,
                    focusable     : false,
                    trapFocus     : false
                }
            },
            /**
             * By default, the {@link #property-sidebar} expands and collapses taking width from the
             * calendar UI. Configure `overlaySidebar` as `true` to have it start collapsed, and then
             * **overlay** the calendar UI when the expand/collapse button is toggled.
             * @config {Boolean}
             * @default false
             */
            overlaySidebar : null,
            /**
             * The date which this Calendar encapsulates as its active date.
             *
             * This is usually selected by clicking in the {@link #property-sidebar}'s date picker
             * or by navigating the view forwards or backwards in time.
             *
             * This is also changed by clicking in day cells of the active view.
             *
             * This may be set programatically which will cause the currently active view to navigate to
             * encapsulate that date.
             * @member {Date} date
             */
            /**
             * The date which the Calendar, its {@link #property-sidebar}'s date picker, and
             * its active view should encapsulate upon creation.
             *
             * This defaults to current date unless {@link #state-saving state saving} has been
             * configured to save the date, in which case the calendar will start up showing the date
             * that was saved in state.
             * @config {Date}
             */
            date : {
                $config : {
                    equal : 'date'
                },
                value : new Date()
            },
            /**
             * A {@link Core.helper.DateHelper} format string to use to create date output for
             * view descriptions.
             * @prp {String}
             */
            dateFormat : 'MMMM DD, YYYY',
            scrollManager : {
                $config : ['lazy', 'nullify'],
                value   : {
                    direction : 'vertical'
                }
            },
            viewUpdateDelay : 2000,
            /**
             * Get/set the calendar's read-only state. When set to `true`, any UIs for modifying data are disabled.
             * @member {Boolean} readOnly
             */
            /**
             * Configure as `true` to make the calendar read-only, by disabling any UIs for modifying data.
             *
             * __Note that checks MUST always also be applied at the server side.__
             * @config {Boolean}
             * @default false
             */
            readOnly : null,
            /**
             * Set to false if you don't want to allow events overlapping times for any one resource (defaults to true).
             * @config {Boolean}
             * @default
             */
            allowOverlap : true,
            testConfig : {
                viewUpdateDelay : 5,
                // Test environment must be prevented from clicking the UI very fast while network IO
                // is in flight. LoadOnDemand testing was susceptible to this.
                loadMask : {
                    text      : 'L{GridBase.loadMask}',
                    showDelay : 0
                }
            },
            /**
             * Configure this as `true` to make picking a date in the {@link #config-sidebar}'s
             * date picker highlight the date cell in the active view.
             *
             * May also be specified as a function which will be passed the date cell element to enable
             * performing customized highlighting.
             *
             * @config {Boolean|Function}
             * @param {Date} date Cell date
             * @returns {Boolean} Returns `true` to make picking a date in a date picker
             */
            highlightDate : null,
            /**
             * If this config is set, then the `gesture` configured (which defaults to `dblclick`) creates a
             * new event at the mouse or touch event's time point.
             *
             * The exact time is rounded to the closest specified `step` value. This value is also used
             * for the {@link Core.widget.TimeField#property-step} value in the {@link Calendar.feature.EventEdit}'s
             * time input field.
             *
             * The duration of the created event is the specified `duration` value.
             *
             * If this is specified as `true`, the `gesture` becomes `dblclick`, and the other properties
             * are the default values listed below.
             *
             * If this is specified as a string, the string becomes the `gesture`, and the other properties
             * are the default values listed below.
             *
             * @property {String} [autoCreate.gesture='dblclick'] The event name which should trigger event creation at the event's position.
             * @property {String} [autoCreate.newName='New Event'] The name of an event created using `autoCreate` or a function to call which yields the name.
             * @property {String} [autoCreate.step='15 minutes'] The time unit by which to round the start click point of auto created events.
             * _Only for views which have a granularity of less than one day such as `WeekView` and `DayView'_.
             *
             * For views which show whole days, the start defaults to 8am.
             *
             * This is a string in the format required by {@link Core.helper.DateHelper#function-parseDuration-static}.
             *
             * This value is also used for the {@link Core.widget.TimeField#property-step} value in the
             * {@link Calendar.feature.EventEdit}'s time input field.
             *
             * @property {String} [autoCreate.duration='1 hour'] The default duration for auto created events.
             * @property {Number | String} [autoCreate.startHour=8] The default start hour for auto created events
             * in views where the time granularity is one day. In a DayView or WeekView where a mouse event position
             * will translate to a time of day, this is not used.
             *
             * This is the hour of the day to start the event at. It can be fractional or an HH:MM:SS time string.
             * @config {Object|String|Boolean}
             * @default
             */
            autoCreate : {
                gesture   : 'dblclick',
                newName   : 'L{Object.newEvent}',
                step      : '15 minutes',
                duration  : '1 hour',
                startHour : 8
            },
            focusable : false,
            // The common Navigator needs to know this
            eventCls : 'b-cal-event',
            /**
             * The week start day to be used throughout this Calendar, 0 meaning Sunday, 6 meaning Saturday.
             *
             * This propagates into all owned {@link #config-modes}.
             *
             * Defaults to {@link Core.helper.DateHelper#property-weekStartDay-static}. When the default value is used, changing locale
             * will dynamically change the week start day for the calendar and all {@link #config-modes}.
             *
             * __If__ this is configured in from the start, then locale values for the week start day
             * will __not__ apply.
             *
             * For example:
             * ```javascript
             * new Calendar({
             *     // Apply to this Calendar only
             *     weekStartDay : 1 // Week starts on Monday
             *     //....
             * });
             * ```
             *
             * @config {Number} [weekStartDay]
             */
            weekStartDay : false,
            /**
             * Configure as `true` to hide {@link #config-nonWorkingDays} for each calendar view
             * @prp {Boolean}
             */
            hideNonWorkingDays : null,
            /**
             * Non-working days as an object where keys are day indices, 0-6 (Sunday-Saturday), and the value is `true`.
             *
             * This propagates into all owned {@link #config-modes}.
             *
             * Defaults to {@link Core.helper.DateHelper#property-nonWorkingDays-static}. When the default value is used, changing locale
             * will dynamically change the week start day for the calendar and all {@link #config-modes}.
             *
             * __If__ this is configured in from the start, then locale values for the week start day
             * will __not__ apply.
             *
             * Both header and event cells for non working days get the CSS class `b-nonworking-day` added.
             *
             * Note that this is independent of and in addition to weekend days. Cells for Saturday and
             * Sunday always get the CSS class `b-weekend` added.
             *
             * For example:
             * ```javascript
             * new Calendar({
             *     // Apply to this Calendar only
             *     nonWorkingDays : {
             *         0 : true // Only Sunday is non-working day
             *     },
             *     //....
             * });
             * ```
             *
             * @config {Object<Number,Boolean>} [nonWorkingDays]
             */
            nonWorkingDays : false,
            coreHours : null,
            /**
             * Event which is used to show context menus via the context menu features
             * {@link Calendar.feature.EventMenu} and {@link Calendar.feature.ScheduleMenu}.
             * Available options are: 'contextmenu', 'click', 'dblclick'.
             * @config {'contextmenu'|'click'|'dblclick'}
             * @default
             * @category Misc
             */
            contextMenuTriggerEvent : 'contextmenu',
            /**
             * Set to true to listen for CTRL-Z (CMD-Z on Mac OS) keyboard event and trigger undo (redo when SHIFT is pressed).
             * Only applicable when using a {@link Core.data.stm.StateTrackingManager}.
             * @config {Boolean}
             * @default
             * @category Misc
             */
            enableUndoRedoKeys : true,
            /**
             * Path to load resource images from. Used by the resource headers in ResourceView and by
             * {@link Calendar.widget.mixin.CalendarMixin#config-showResourceAvatars} in event-displaying
             * modes.
             *
             * This is used to show resource avatars using the resource's
             * {@link Scheduler/model/ResourceModel#field-image} or
             * {@link Scheduler/model/ResourceModel#field-imageUrl} fields:
             *
             * * `image` represents image name inside the specified `resourceImagePath`,
             * * `imageUrl` represents fully qualified image URL.
             *
             * If an image is not specified for a resource, or the image is not found, the resource's
             * initials will be displayed.
             * @config {String}
             * @category Misc
             */
            resourceImagePath : null,
            /**
             * The minimum date to which the `startDate` of any child view may be navigated.
             * @member {Date} minDate
             */
            /**
             * The minimum date to which the `startDate` of any child view may be navigated.
             * @config {Date|String}
             */
            minDate : null,
            /**
             * The maximum date to which the `endDate` of any child view may be navigated.
             * @member {Date} maxDate
             */
            /**
             * The maximum date to which the `endDate` of any child view may be navigated.
             * @config {Date|String}
             */
            maxDate : null,
            /**
             * If this is set to `true`, then when determining which assigned resource of a multi assigned event
             * to use to create the event UI, the first resource which is still selected in the
             * {@link Calendar.widget.Sidebar#property-resourceFilter} is used.
             *
             * The default is to use the first resource in the assigned list.
             *
             * The resource's {@link Scheduler.model.ResourceModel#field-eventColor} is used as the colour for the
             * event unless the event has its own {@link Scheduler.model.EventModel#field-eventColor}.
             *
             * The resource's {@link Scheduler.model.ResourceModel#field-eventStyle} is used as the base of the style
             * for the event, and the event's {@link Scheduler.model.EventModel#field-style} is applied to it.
             * @config {Boolean}
             * @default false
             */
            filterEventResources : null,
            /**
             * An optional CSS class name to add to calendar date cells which encapsulate the calendar's
             * active {@link #property-date}.
             *
             * There is no theme rule connected to this date, so no built-in rendition of the active date.
             * The application may add view-specific CSS rules to provide visual indications.
             * @prp {String}
             */
            activeDateCls : null
        };
    }
    static get delayable() {
        return {
            refresh : {
                type              : 'raf',
                cancelOutstanding : true
            },
            syncUIWithActiveView : {
                type              : 'buffer',
                delay             : 1,
                cancelOutstanding : true
            }
        };
    }
    //endregion
    compose() {
        const { includeWeekendsButton, navigatorPlacement } = this;
        return {
            class : {
                [`b-calendar-nav-${navigatorPlacement || 'toolbar'}`] : 1,
                'b-calendar-include-weekends-button'                  : includeWeekendsButton
            }
        };
    }
    //region Overrides
    applyState() {
        // We want to inhibit multiple refreshes during the config changes that this will apply.
        // If we set the _hidden flag, views will queue any refreshes until we trigger the paint event.
        this._hidden = true;
        super.applyState(...arguments);
        this._hidden = false;
        this.triggerPaint();
    }
    onPaintOverride() {
        // Internal procedure used for paint method overrides
        // Not used in onInternalPaint() because it may be chained on instance and Override won't be applied
    }
    onInternalPaint() {
        if (this.onPaintOverride()) {
            return;
        }
        super.onInternalPaint();
    }
    onShowOverflowPopup({ overflowPopup }) {
        this.element.classList.add('b-overflow-popup-visible');
        overflowPopup.ion({
            hide : () => this.element.classList.remove('b-overflow-popup-visible'),
            once : true
        });
    }
    //endregion
    //region events
    /**
     * Fires before an event is removed. Can be triggered by user pressing [DELETE] or [BACKSPACE] or by the
     * event editor. Can for example be used to display a custom dialog to confirm deletion, in which case
     * records should be "manually" removed after confirmation:
     *
     * ```javascript
     * calendar.on({
     *    beforeEventDelete({ eventRecords, context }) {
     *        // Show custom confirmation dialog (pseudo code)
     *        confirm.show({
     *            listeners : {
     *                onOk() {
     *                    // Remove the events on confirmation
     *                    context.finalize(true);
     *                },
     *                onCancel() {
     *                    // do not remove the events if "Cancel" clicked
     *                    context.finalize(false);
     *                }
     *            }
     *        });
     *
     *        // Prevent default behaviour
     *        return false;
     *    }
     * });
     * ```
     *
     * @event beforeEventDelete
     * @param {Calendar.view.Calendar} source  The Calendar instance
     * @param {Scheduler.model.EventModel[]} eventRecords  The records about to be deleted
     * @param {Object} context  Additional removal context:
     * @param {Function} context.finalize  Function to call to finalize the removal.
     *      Used to asynchronously decide to remove the records or not. Provide `false` to the function to
     *      prevent the removal.
     * @param {Boolean} [context.finalize.removeRecords = true]  Provide `false` to the function to prevent
     *      the removal.
     * @preventable
     */
    /**
     * Fires when a day spanning event is found, and the date to which its encapsulating event bar
     * extends has been calculated.
     *
     * The default result in the event's `propagateEndDate` property may be mutated by a listener.
     *
     * Note that this is an ending point in time, it does *not* refer to a 24 hour block. So setting
     * the `propagateEndDate` to `new Date(2022, 1, 10)` means that the event bar will occupy cells
     * up to and including February 9 2022 and no further.
     *
     * This is relayed from all modes, so a single listener may be used, for example:
     *
     * ```javascript
     * new Calendar({
     *     listeners : {
     *         eventPropagate(eventData) {
     *             // If the event spills into the next day but not further
     *             // then we do not want an extended event bar.
     *             // An arrow will indicate that it continues rightwards.
     *             if (eventData.eventEndDate < DateHelper.add(eventData.date, 2, 'd')) {
     *                 eventData.propagateEndDate = DateHelper.add(DateHelper.clearTime(eventData.eventRecord.startDate), 1, 'd');
     *             }
     *         }
     *     }
     * });
     * ```
     *
     * The `eventEndDate` in the data block may also be changed to override the event's real end date.
     * This will mean that there will be no arrow indicating that the event continues:
     *
     * ```javascript
     * new Calendar({
     *     listeners : {
     *         eventPropagate(eventData) {
     *             // If the event spills into the next day but not further
     *             // then we do not want an extended event bar.
     *             // Because we override the eventEndDate, no arrow will be present
     *             // to indicate any continuation.
     *             if (eventData.eventEndDate < DateHelper.add(eventData.date, 2, 'd')) {
     *                 eventData.propagateEndDate = eventData.eventEndDate = DateHelper.add(DateHelper.clearTime(eventData.eventRecord.startDate), 1, 'd');
     *             }
     *         }
     *     }
     * });
     * ```
     *
     * @event eventPropagate
     * @param {Date} eventEndDate The end date for which to calculate the propagate end date.
     * @param {Date} propagateEndDate The system-calculated end point of the event bar.
     * @param {Boolean} isAllDay `true` if the event is an all day event, or spans multiple days.
     * @param {Boolean} isOverflow `true` if this is being called as part of further propagation.
     * @param {Boolean} overflows `true` if the event extends into future cells.
     * @param {Scheduler.model.EventModel} eventRecord The event record being propagated.
     * @param {Date} date The date from which the event is being propagated.
     */
    /**
     * Fires when an event bar is mouseovered in any view.
     * @event eventMouseOver
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date of the UI element which contains the event.
     * @param {HTMLElement} eventElement The UI element which represents the event.
     * @param {Scheduler.model.EventModel} eventRecord The event mouseovered.
     * @param {Scheduler.model.ResourceModel} resourceRecord The resource mouseovered if the UI includes a resource.
     * @param {Boolean} fromOverflowPopup `true` if the interaction was through the source view's
     * {@link Calendar.widget.mixin.DayCellRenderer#property-overflowPopup}.
     */
    /**
     * Fires when an event bar is mousedowned in any view.
     * @event eventMouseDown
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date of the UI element which contains the event.
     * @param {HTMLElement} eventElement The UI element which represents the event.
     * @param {Scheduler.model.EventModel} eventRecord The event mousedowned on.
     * @param {Scheduler.model.ResourceModel} resourceRecord The resource mousedowned on if the UI includes a resource.
     * @param {Boolean} fromOverflowPopup `true` if the interaction was through the source view's
     * {@link Calendar.widget.mixin.DayCellRenderer#property-overflowPopup}.
     */
    /**
     * Fires when an event bar is mouseupped in any view.
     * @event eventMouseUp
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date of the UI element which contains the event.
     * @param {HTMLElement} eventElement The UI element which represents the event.
     * @param {Scheduler.model.EventModel} eventRecord The event mouseupped.
     * @param {Scheduler.model.ResourceModel} resourceRecord The resource mouseupped if the UI includes a resource.
     * @param {Boolean} fromOverflowPopup `true` if the interaction was through the source view's
     * {@link Calendar.widget.mixin.DayCellRenderer#property-overflowPopup}.
     */
    /**
     * Fires when an event bar is clicked in any view.
     * @event eventClick
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date of the UI element which contains the event.
     * @param {HTMLElement} eventElement The UI element which represents the event.
     * @param {Scheduler.model.EventModel} eventRecord The event clicked on.
     * @param {Scheduler.model.ResourceModel} resourceRecord The resource clicked on if the UI includes a resource.
     * @param {Boolean} fromOverflowPopup `true` if the interaction was through the source view's
     * {@link Calendar.widget.mixin.DayCellRenderer#property-overflowPopup}.
     */
    /**
     * Fires when an event bar is double clicked in any view.
     * @event eventDblClick
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date of the UI element which contains the event.
     * @param {HTMLElement} eventElement The UI element which represents the event.
     * @param {Scheduler.model.EventModel} eventRecord The event double clicked on.
     * @param {Scheduler.model.ResourceModel} resourceRecord The resource double clicked on if the UI includes a resource.
     * @param {Boolean} fromOverflowPopup `true` if the interaction was through the source view's
     * {@link Calendar.widget.mixin.DayCellRenderer#property-overflowPopup}.
     */
    /**
     * Fires when an event bar is mouseouted in any view.
     * @event eventMouseOut
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date of the UI element which contains the event.
     * @param {HTMLElement} eventElement The UI element which represents the event.
     * @param {Scheduler.model.EventModel} eventRecord The event mouseouted.
     * @param {Scheduler.model.ResourceModel} resourceRecord The resource mouseouted if the UI includes a resource.
     * @param {Boolean} fromOverflowPopup `true` if the interaction was through the source view's
     * {@link Calendar.widget.mixin.DayCellRenderer#property-overflowPopup}.
     */
    /**
     * Fires when an event bar is right clicked in any view.
     * @event eventContextMenu
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date of the UI element which contains the event.
     * @param {HTMLElement} eventElement The UI element which represents the event.
     * @param {Scheduler.model.EventModel} eventRecord The event right clicked on.
     * @param {Scheduler.model.ResourceModel} resourceRecord The resource right clicked on if the UI includes a resource.
     * @param {Boolean} fromOverflowPopup `true` if the interaction was through the source view's
     * {@link Calendar.widget.mixin.DayCellRenderer#property-overflowPopup}.
     */
    /**
     * Fires when a day number is clicked in a view which shows day numbers. This will include
     * the cells of a {@link Calendar.widget.YearView YearView}, the cell header of cells
     * in a {@link Calendar.widget.MonthView MonthView}, and the day header in a
     * {@link Calendar.widget.DayView}.
     * @event dayNumberClick
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date clicked on.
     * @param {DayCell} cellData An object that contains data about the calendar cell for the date.
     * @param {Boolean} fromOverflowPopup `true` if the interaction was through the source view's
     * {@link Calendar.widget.mixin.DayCellRenderer#property-overflowPopup}.
     */
    /**
     * Fires when a week number is clicked. This will include the week number cells of a
     * {@link Calendar.widget.YearView YearView}, the week number in the first cell
     * in each row of a {@link Calendar.widget.MonthView MonthView}
     * @event weekNumberClick
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Number[]} week The `[year, week]` clicked on.
     * @param {Date} date The date clicked on.
     */
    /**
     * Fires when a month name header in a {@link Calendar.widget.YearView YearView} is clicked on.
     * @event monthNameClick
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Number} month The month index _(zero based)_ clicked on.
     * @param {Date} date The date of the first of the month clicked on.
     */
    /**
     * Fires when a cell overflow indicator is clicked in any view. This will include
     * the cells of a {@link Calendar.widget.YearView YearView} and the `+ n more` overflow
     * indicator of cells in a {@link Calendar.widget.MonthView MonthView}, and "all day" events section
     * of a {@link Calendar.widget.DayView}.
     * @event cellOverflowClick
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date clicked on.
     */
    /**
     * Fires when a mouseover made its way through to an empty part of any view.
     * @event scheduleMouseOver
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date mouseovered. _Note that in a {@link Calendar.widget.DayView},
     * this will include granular time information. For other views, this is the *start* of the date.
     */
    /**
     * Fires when a mousedown made its way through to an empty part of any view.
     * @event scheduleMouseDown
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date mousedowned on. _Note that in a {@link Calendar.widget.DayView},
     * this will include granular time information. For other views, this is the *start* of the date.
     */
    /**
     * Fires when a mouseup made its way through to an empty part of any view.
     * @event scheduleMouseUp
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date mouseupped on. _Note that in a {@link Calendar.widget.DayView},
     * this will include granular time information. For other views, this is the *start* of the date.
     */
    /**
     * Fires when a click made its way through to an empty part of any view.
     * @event scheduleClick
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date clicked on. _Note that in a {@link Calendar.widget.DayView},
     * this will include granular time information. For other views, this is the *start* of the date.
     */
    /**
     * Fires when a dblclick made its way through to an empty part of any view.
     * @event scheduleDblClick
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date double clicked on. _Note that in a {@link Calendar.widget.DayView},
     * this will include granular time information. For other views, this is the *start* of the date.
     */
    /**
     * Fires when a context menu made its way through to an empty part of any view.
     * @event scheduleContextMenu
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date clicked on. _Note that in a {@link Calendar.widget.DayView},
     * this will include granular time information. For other views, this is the *start* of the date.
     */
    /**
     * Fires when a mouseout made it's way through to an empty part of any view.
     * @event scheduleMouseOut
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which triggered the event.
     * @typings source -> {typeof CalendarMixin}
     * @param {Event} domEvent The initiating DOM event.
     * @param {Date} date The date mouseouted. _Note that in a {@link Calendar.widget.DayView},
     * this will include granular time information. For other views, this is the *start* of the date.
     */
    /**
     * Fires when one of the views in this Calendar refreshes.
     * @event refresh
     * @param {Calendar.widget.mixin.CalendarMixin} source The view which refreshed.
     * @typings source -> {typeof CalendarMixin}
     */
    /**
     * Fired when an {@link Calendar.widget.mixin.CalendarMixin#config-autoCreate} gesture has
     * created a new event and added it to the event store.
     *
     * If the {@link Calendar.feature.EventEdit} feature is present, it listens for
     * this event and initiates an edit operation. Adding a high `prio` listener which
     * returns `false` can prevent this event from reaching the `eventEdit` processing.
     * @event eventAutoCreated
     * @param {Calendar.widget.mixin.CalendarMixin} source The child view on which the event was initiated.
     * @typings source -> {typeof CalendarMixin}
     * @param {Scheduler.model.EventModel} eventRecord The new event record.
     */
    /**
     * Fired when an empty cell content area is clicked on in a {@link Calendar.widget.MonthView} or a
     * {@link Calendar.widget.CalendarRow}.
     * @event emptyCellClick
     * @param {Event} domEvent The triggering DOM event.
     * @param {Date} date The date which has no visible events
     */
    /**
     * This event fires whenever a child view's {@link Calendar.widget.mixin.CalendarMixin#config-autoCreate
     * autoCreate gesture} is detected and also when a {@link Calendar.feature.CalendarDrag
     * drag-create} gesture is detected.
     *
     * This event is preventable and may be used to validate UI-initiated event creation.
     * @event beforeAutoCreate
     * @preventable
     * @param {Event} domEvent The DOM event which initiated the creation.
     * @param {Date} date The starting time of the event to be created.
     * @param {Scheduler.model.ResourceModel} resourceRecord The resource if the UI includes a resource.
     */
    //endregion
    static get featureable() {
        return {
            factory : CalendarFeature
        };
    }
    // Gets current date time, time zone converted if time zone is set
    get dateTimeNow() {
        if (this.timeZone != null) {
            return TimeZoneHelper.toTimeZone(new Date(), this.timeZone);
        }
        return new Date();
    }
    // Gets current date with day info only. Time zone converted if time zone is set
    get today() {
        const today = this.dateTimeNow;
        today.setHours(0, 0, 0, 0);
        return today;
    }
    finalizeInit() {
        super.finalizeInit();
        const
            me                 = this,
            hideNonWorkingDays = me.activeView?.hideNonWorkingDays;
        if (hideNonWorkingDays != null && me.hideNonWorkingDays == null) {
            me.hideNonWorkingDays = hideNonWorkingDays;
        }
    }
    toggleEmptyText() {
        const
            { crudManager } = this,
            { eventStore }  = crudManager || this;
        DomHelper.toggleClasses(this.contentElement, 'b-calendar-empty', !(eventStore.count || (crudManager?.isLoading)));
    }
    /**
     * Schedules a refresh of the UI for the next animation frame. This is a useful method to call when
     * making multiple data changes, so that each change merely *schedules* a refresh for the next AF and
     * DOM churn is kept to a minimum.
     */
    refresh() {
        this.eachView(v => v.refresh?.());
    }
    /**
     * The {@link #property-modes} as an Array.
     * @property {Core.widget.Widget[]}
     */
    get views() {
        // Do not invoke the DynamicObject twice
        const { modes } = this;
        return modes ? Object.values(modes) : [];
    }
    /**
     * Returns the currently active mode as a {@link Core.widget.Widget} instance.
     *
     * Similar to the {@link #property-mode} property which is the active mode's name as a string.
     * @property {Core.widget.Widget}
     */
    get activeView() {
        return this.viewContainer.layout.activeItem;
    }
    /**
     * Returns the lowest level of active view in the mode container.
     *
     * For example, if a {@link Calendar.widget.ResourceView} is the active view, this would
     * return the sub view of that which is currently focused. Or the resourceView if none
     * of the sub views were focused.
     * @property {Core.widget.Widget}
     */
    get activeSubView() {
        const
            { activeView } = this,
            { items }      = activeView,
            // If its a multi-CalendarWidget view (Such as a ResourceView), narrow down activeView
            // to the active subView which contains focus.
            activeSubView  = items.filter(isFocusedCalendarMixin)?.[0];
        return activeSubView || activeView;
    }
    captureFocus() {
        return this.activeView.captureFocus();
    }
    /**
     * Returns the event record for a DOM element or DOM event.
     * @param {HTMLElement|Event} elementOrEvent The DOM node to lookup, or a DOM event whose target to lookup.
     * @returns {Scheduler.model.EventModel} The event record
     */
    getEventRecord(elementOrEvent) {
        return this.activeView.getEventRecord(elementOrEvent);
    }
    /**
     * Returns the event record for a DOM element or DOM event.
     * @param {HTMLElement|Event} elementOrEvent The DOM node to lookup, or a DOM event whose target to lookup.
     * @returns {Scheduler.model.EventModel} The event record
     */
    resolveEventRecord(elementOrEvent) {
        // this method is added for symmetry w/SchedulerInterface
        return this.getEventRecord(elementOrEvent);
    }
    onBeforeModeChange({
        prevActiveItem,
        activeItem
    }) {
        /**
         * Fired before a change of view is initiated.
         * @event beforeActiveItemChange
         * @param {Calendar.view.Calendar} source This Calendar instance.
         * @param {Core.widget.Widget} prevActiveItem The previously active view.
         * @param {Core.widget.Widget} activeItem The new active view.
         * @preventable
         */
        if (this.trigger('beforeActiveItemChange', arguments[0]) === false) {
            return false;
        }
        // Access the property name directly because this is a lazy config.
        // We do not want to cause ingestion and update here.
        // It might be still the initial config form, so might not have hide()
        prevActiveItem._overflowPopup?.hide?.();
        // In case some of the modes are not standard Calendar widgets which
        // all implement a date setter. Allow them to opt-out of syncing date.
        if (('date' in activeItem) && activeItem.syncViewDate !== false) {
            const date = this.date || prevActiveItem.date;
            // activeItem's config system will protect it from non-changes.
            if (date) {
                // Calendar views know to only update their UI when visible
                if (activeItem.isCalendarMixin) {
                    activeItem.date = date;
                }
                // Other view types have to be protected
                else {
                    activeItem.whenVisible(() => activeItem.date = date);
                }
            }
        }
    }
    onModeChange(event) {
        /**
         * Fired when a change of view has completed. By default, view changes are animated and this
         * event fires when the view is fully visible.
         * @event activeItemChange
         * @param {Calendar.view.Calendar} source This Calendar instance.
         * @param {Core.widget.Widget} prevActiveItem The previously active view.
         * @param {Core.widget.Widget} activeItem The new active view.
         */
        this.trigger('activeItemChange', event);
    }
    onToggleSidebarClick() {
        const
            { sidebar } = this,
            { collapsible } = sidebar;
        // If we are overlaying the sidebar, the button toggles between collapsed and revealed
        // state rather than expanded and collapsed.
        if (collapsible?.isPanelCollapserOverlay) {
            if (!collapsible.revealing) {
                collapsible.toggleReveal(!sidebar.revealed);
            }
        }
        else {
            sidebar.toggleCollapsed();
        }
    }
    /**
     * Navigates Calendar's {@link #property-activeView active view} to the current local date
     */
    shiftToNow() {
        const
            me          = this,
            datePicker  = me.sidebar?.widgetMap.datePicker,
            { dayTime } = me.activeView,
            now         = new Date(),
            today       = dayTime ? dayTime.startOfDay(now) : DateHelper.clearTime(now);
        // Reaction to this to change the activeView's date happens in onCalendarDateChange.
        // updateDate changes the sidebar date if there is a sidebar, else it calls
        // onCalendarDateChange directly.
        // Make sure we use the current view's conception of where a day begins
        me.date = today;
        // Our updateDate will only have done this if our current date is *not* today
        // The sidebar's date picker can be navigated around, but clicking the "today"
        // button must bring all views into line.
        datePicker && (datePicker.date = today);
        me.updateViewDescription();
    }
    /**
     * Navigates back in time in a step unit specific to the Calendar's {@link #property-activeView active view}
     */
    shiftPrevious() {
        const { activeItem } = this.viewContainer.layout;
        if (activeItem?.previous) {
            activeItem.previous();
            this.updateViewDescription(activeItem);
            if (activeItem.animateTimeShift) {
                DomHelper.slideIn(activeItem.contentElement, -1);
            }
        }
    }
    /**
     * Navigates forward in time in a step unit specific to the Calendar's {@link #property-activeView active view}
     */
    shiftNext() {
        const { activeItem } = this.viewContainer.layout;
        if (activeItem?.next) {
            activeItem.next();
            this.updateViewDescription(activeItem);
            if (activeItem.animateTimeShift) {
                DomHelper.slideIn(activeItem.contentElement, 1);
            }
        }
    }
    onCalendarSidebarToggle(event) {
        event.source = this;
        /**
         * Fired when the sidebar is collapsed either through the UI or programatically.
         * @event sidebarCollapse
         * @param {Calendar.view.Calendar} source This Calendar instance
         */
        /**
         * Fired when the sidebar is expanded either through the UI or programatically.
         * @event sidebarExpand
         * @param {Calendar.view.Calendar} source This Calendar instance
         */
        this.trigger(`sidebar${StringHelper.capitalize(event.type)}`, event);
    }
    async onCalendarDateChange({ date, oldDate, userAction }) {
        const
            me             = this,
            { layout }     = me.viewContainer,
            // If the card layout is changing, use the item it is changing *to*
            { activeItem } = layout.isChangingCard ? layout.animateDetacher.event : layout,
            {
                highlightDate
            }              = me;
        // Allow calendar views to opt out of being in sync with Calendar's date
        if (activeItem && activeItem.syncViewDate !== false && ('date' in activeItem)) {
            const { getDayElement } = activeItem;
            // In case triggered from header click the overflow popup for an "other month" cell.
            // Use "_overflowPopup" so as not to call it into existence if it's not created.
            // It might be still the initial config form, so might not have hide()
            if (!DateHelper.betweenLesser(date, activeItem.startDate, activeItem.endDate)) {
                activeItem._overflowPopup?.hide?.();
            }
            activeItem.date = date;
            // We must match the active item's date.
            // If the date change caused a refresh, that will have happened, and this will be a no-op
            // If the active item already contained the date, this will not have happened.
            me.date = activeItem.date;
            // Highlight the date element if the date change was triggered by user interaction
            // with the date picker. If the date picker was updated from one of the modes
            // then highlighting will not be expected.
            if (userAction && highlightDate && getDayElement) {
                // Some views need to scroll to a date.
                // Agenda, Month (if constrained size), and CalendarRow
                if (activeItem.scrollTo) {
                    await activeItem.scrollTo(date);
                    // EventList might not contain the exact date. It will scroll to the
                    // closest date. If it did that, the scrolledToDate property will be set.
                    if (activeItem.scrolledToDate) {
                        date = activeItem.scrolledToDate;
                    }
                }
                // The date's element might not exist until after the scroll.
                const cell = activeItem.getDayElement?.(date);
                // AgendaView might not have a representation of the date
                if (cell) {
                    // Not coercible to a number means its a function or name of a function
                    if (isNaN(highlightDate)) {
                        me.callback(highlightDate, me, [cell, me]);
                    }
                    // Otherwise, it's truthy or falsy
                    else {
                        DomHelper.highlight(Rectangle.from(cell));
                    }
                }
            }
        }
        me.syncViewActiveDates(oldDate, date);
        /**
         * Fires when the calendar changes the date that it orientates its views around.
         *
         * The Calendar tracks which date the user interacts with so that on mode change,
         * the user is shown the view for the date being interacted with.
         *
         * This is updated on any interaction with the UI. Clicking in the
         * {@link #property-sidebar}'s date picker, clicking on an event, or clicking on a
         * day cell in any view updates the date which the Calendar is using as its orientating date.
         * @event dateChange
         * @param {Calendar.view.Calendar} source This Calendar.
         * @param {Date} oldDate The previous Calendar date.
         * @param {Date} date The new Calendar date.
         */
        me.trigger('dateChange', { oldDate, date });
    }
    updateActiveDateCls(activeDateCls, oldActiveDateCls) {
        this.syncViewActiveDates(this.date, this.date, oldActiveDateCls);
    }
    syncViewActiveDates(oldDate, date = this.date, oldActiveDateCls = this.activeDateCls) {
        const
            me                 = this,
            { activeDateCls }  = me,
            { contentElement } = me.viewContainer;
        // Keep all view's active date synced
        if (oldActiveDateCls) {
            const oldDateCells = oldDate ? contentElement.querySelectorAll(`[data-date="${DateHelper.makeKey(oldDate)}"],[data-header-date="${DateHelper.makeKey(oldDate)}"]`) : emptyArray;
            for (let i = 0, { length } = oldDateCells; i < length; i++) {
                oldDateCells[i].classList.remove(oldActiveDateCls);
            }
        }
        if (activeDateCls) {
            const newDateCells = contentElement.querySelectorAll(`[data-date="${DateHelper.makeKey(date)}"],[data-header-date="${DateHelper.makeKey(date)}"]`);
            for (let i = 0, { length } = newDateCells; i < length; i++) {
                newDateCells[i].classList.add(activeDateCls);
            }
        }
    }
    updateViewDescription(activeView = this.activeView) {
        const
            me = this,
            { tbar, widgetMap } = me,
            { viewDescription } = widgetMap,
            description = `<span class="b-calendar-view-desc-text">${activeView?.description || ''}</span>`;
        // Allow the Calendar's active mode to be targeted by CSS selectors.
        // Use the activeView property because the passed view might not be a mode
        // it might be a child view of a resource view.
        me.element.dataset.mode = me.activeView.modeName;
        // Keep the sidebar's concept of date of interest in sync with current activeView
        if (activeView) {
            if (tbar && viewDescription) {
                // Even if tbar is present, our predefined tbar widgets may have been configured away.
                // Need the change check to avoid adding the changed class upon initialization.
                // Note that viewDescription?. cannot be used here because the result of
                // viewDescription?.description, if viewDescription is null will not be ===
                // description, so it will then attempt to set the content property. And
                // `viewDescription?.content = description` results in "invalid assignment left-hand side" error.
                if (viewDescription.content !== description) {
                    viewDescription.content = description;
                }
            }
            const viewDate = activeView.date || activeView.startDate;
            if (viewDate) {
                // Reaction to this to change the activeView's date happens in onCalendarDateChange.
                // updateDate changes the sidebar date if there is a sidebar, else it calls
                // onCalendarDateChange directly.
                this.date = viewDate;
            }
        }
    }
    getStepUnitText(activeView = this.activeView) {
        // stepUnit is localized by views.
        // Views must normalize the returned property value for use in
        // "Next ${stepUnit}" button text, eg "1 day" to "day"
        return activeView.stepUnit;
    }
    syncUIWithActiveView(activeView = this.activeView) {
        const
            me                           = this,
            { tbar, sidebar, widgetMap } = me,
            { toggleSidebar }            = widgetMap;
        // tbar may have been configured away
        if (tbar && activeView?.isVisible) {
            const
                {
                    prevButton,
                    nextButton,
                    modeSelector
                }             = widgetMap,
                stepUnitText  = me.getStepUnitText(activeView);
            modeSelector?.syncActiveMode(activeView);
            if (sidebar) {
                sidebar.stepUnitText = stepUnitText;
            }
            me.updateViewDescription(activeView);
            // Each view localizes its own stepUnit
            // Also, any widget may be configured away, so must be truthy.
            if (prevButton) {
                prevButton.tooltip = stepUnitText ? me.L('L{previous}', stepUnitText) : '';
                prevButton.disabled = !stepUnitText;
            }
            if (nextButton) {
                nextButton.tooltip = stepUnitText ? me.L('L{next}', stepUnitText) : '';
                nextButton.disabled = !stepUnitText;
            }
        }
        if (!sidebar) {
            toggleSidebar?.hide();
        }
    }
    updateEventStore(eventStore) {
        this.detachListeners('eventStoreDateRange');
        // The loadOnDemand feature needs to be initialized as soon as we get our
        // event store so that it can hook *all* loadDateRange events.
        this.features.loadOnDemand;
        // Ensure the listeners are present
        this.processConfiguredListeners();
        eventStore.ion({
            loadDateRange : 'onEventStoreDateRangeRequested',
            name          : 'eventStoreDateRange',
            thisObj       : this
        });
        // This is all we have to do.
        // The ProjectConsumer mixin implements a changeEventStore which updates the Project
        // and our project which we configure into all our views will update any subscribers.
        if (this.sidebar) {
            this.sidebar.eventStore = eventStore;
        }
    }
    /**
     * Executes the passed function for each child calendar view in {@link #config-modes}
     * @param {Function} fn The function to call.
     * @param {Object[]} [args] The arguments to pass. Defaults to the view being called.
     * @param {Object} [thisObj] The `this` reference for the function. Defaults to the view being called.
     */
    eachView(fn, args, thisObj = null) {
        const passView = args == null;
        for (const view of this.views) {
            if (passView) {
                args = [view];
            }
            if (view.callback(fn, thisObj || view, args) === false) {
                return;
            }
        }
    }
    onEventStoreDateRangeRequested(event) {
        /**
         * Fired when the eventStore is queried for events by date range, and the date range
         * requested is different from the last time the store was queried.
         *
         * This may not be triggered when moving to a different view. If a `MonthView` has been
         * displayed, and the `WeekView` is then shown, the date range is already available.
         *
         * When moving a view through time, and into a time range which has not previously been
         * loaded, this will be triggered.
         * @event dateRangeChange
         * @param {Calendar.view.Calendar} source This Calendar instance.
         * @param {Object} old The old date range
         * @param {Date} old.startDate the old start date.
         * @param {Date} old.endDate the old end date.
         * @param {Object} new The new date range
         * @param {Date} new.startDate the new start date.
         * @param {Date} new.endDate the new end date.
         * @deprecated 6.0 Use {@link #event-dateRangeRequested} instead and interrogate its `changed` property.
         */
        if (event.changed) {
            this.trigger('dateRangeChange', event);
        }
        /**
         * Fired every time the eventStore is queried for events by date range.
         *
         * This will always be triggered when changing the active view or when navigating
         * the calendar backwards or forwards in time.
         * @event dateRangeRequested
         * @param {Calendar.view.Calendar} source This Calendar instance.
         * @param {Object} old The old date range
         * @param {Date} old.startDate the old start date.
         * @param {Date} old.endDate the old end date.
         * @param {Object} new The new date range
         * @param {Date} new.startDate the new start date.
         * @param {Date} new.endDate the new end date.
         * @param {Boolean} changed `true` if the date range is different from the last time a request was made.
         */
        this.trigger('dateRangeRequested', event);
    }
    async onModeSelectorToggle({ source : button, pressed }) {
        if (pressed) {
            const
                me                = this,
                { layout    }     = me.viewContainer,
                { view }          = button;
            // First await any ongoing mode switching
            await me.modeSelectionPromise;
            me.modeSelectionPromise = layout.setActiveItem(view).promise;
        }
    }
    /**
     * Sets and gets which of the configured {@link #property-modes} is the current active view.
     *
     * When read, this yields the string name of the currently active mode.
     *
     * When setting, this accepts either the name, or the actual widget instance:
     *
     * ```javascript
     * calendar.mode = 'week';
     * ```
     *
     * or
     *
     * ```javascript
     * calendar.mode = calendar.modes.agenda;
     * ```
     * @member {String} mode
     */
    updateMode(mode) {
        const
            me      = this,
            configs = me.initialConfig || {};
        // If the user configured activeIndex or mode, ignore the (one-time) responsiveUpdate of mode
        if (!(me.isConfiguring || (me._responsiveUpdating && ('mode' in configs || 'activeIndex' in configs)))) {
            mode = typeof mode === 'string' ? me.modes[mode] : mode;
            if (mode && mode !== me.activeView) {
                // Do not animate the mode if we are updating responsive state
                me.viewContainer.layout.setActiveItem(mode, undefined, {
                    animation : !me.isResponsiveUpdating
                });
            }
        }
    }
    // Handle eventStore load or full datachange.
    // It will have refreshed all our views, so keep the Calendar UI synced.
    onEventStoreRefresh() {
        if (!this.isConfiguring) {
            this.syncUIWithActiveView(this.viewContainer.layout.activeItem);
        }
    }
    handleViewPaint({ source : view }) {
        this.syncUIWithActiveView(view);
        // Keep the property in line with reality.
        this._mode = view.modeName;
        /**
         * Fires when one of the child views is painted. That is when it becomes visible.
         * Note that due to the slide-in animation, while the view is visible, it will not
         * yet be in its final position.
         * @event viewPaint
         * @param {Core.widget.Widget} source The widget being painted.
         * @param {Boolean} firstPaint `true` if this is the first paint.
         */
        this.trigger('viewPaint', { ...arguments[0] });
    }
    onViewRefresh({ source : view }) {
        super.onViewRefresh?.(...arguments);
        if (view.isVisible) {
            // The activeView refreshed
            if (view === this.activeView) {
                this.updateViewDescription(view);
            }
            // A child view of the current active view refreshed.
            else if (this.activeView.owns(view)) {
                this.syncUIWithActiveView(this.activeView);
            }
        }
        this.syncViewActiveDates();
    }
    updateEventCls(eventCls) {
        if (!this.initialConfig.eventInnerSelector) {
            this.eventInnerSelector = `.${eventCls}`;
        }
        if (!this.initialConfig.eventSelector) {
            this.eventSelector = `${this.eventInnerSelector}-wrap`;
        }
    }
    changeModes(modes, was) {
        const
            me        = this,
            modeArray = [];
        // This will bring in any configured CrudManager which will give us our stores.
        me.getConfig('crudManager');
        me.getConfig('defaultCalendar');
        // Sort the modes into weight order if there are weights configured
        let modeConfig;
        for (const modeType in modes) {
            modeConfig = modes[modeType];
            if (modeConfig) {
                modeArray.push(ObjectHelper.assign({
                    modeType,
                    weight : modeConfig.weight ?? 0
                }, modeConfig));
            }
        }
        // Weights found, so sort and rebuild of modes object in requested order needed
        if (modeArray.some(hasWeight)) {
            modes = {};
            modeArray.sort(byWeight);
            for (let i = 0, { length } = modeArray; i < length; i++) {
                modeConfig = modeArray[i];
                modes[modeConfig.modeType] = modeConfig;
            }
        }
        const
            { tbar, element } = me,
            listeners = {
                thisObj : me,
                // When a mode has an internal change that requires the description shown in the tbar to be refreshed
                descriptionChange : 'onViewDescriptionChange',
                // These are for implementing drilling from higher to lower level views
                weekNumberClick : 'onViewWeekNumberClick',
                monthNameClick  : 'onViewMonthNameClick',
                dayNumberClick  : 'onViewDayNumberClick',
                // Handle a double click gesture inside a scheduler child mode
                beforeEventAdd : 'onViewBeforeEventAdd',
                // This is how the Calendar relays events from modes to the outside world
                // The Calendar listeners get first look at the events so that app code gets
                // a chance to intervene early.
                catchAll : {
                    fn   : 'onViewCatchAll',
                    prio : 10000
                },
                paint   : 'handleViewPaint',
                refresh : 'onViewRefresh'
            },
            manager = me.$modes || (me.$modes = new DynamicObject({
                factory   : Modes,
                inferType : true,  // the name of a mode is its default type (allow its config object to override it)
                owner     : me,
                // All instances have this.calendar pointing to this
                ownerName : 'calendar',
                created(mode, name) {
                    // Each one must know what mode it represents
                    mode.modeName = name;
                    // Attach our eventEdit feature to Schedulers
                    if (mode.isScheduler) {
                        const { eventEdit } = me.features;
                        if (eventEdit) {
                            eventEdit.applyPluginConfig(mode);
                            mode.editEvent = me.editEvent.bind(me);
                        }
                    }
                    // Allows selection by what views are present
                    element.classList.add(`b-cal-${name}`);
                    // We need to sync our UI when the view changes.
                    mode.ion(listeners);
                    // Give app code a shot at affecting the propagate end date for DayCellCollecters
                    me.relayEvents(mode, ['eventPropagate']);
                    if (!me.isConfiguring) {
                        me.trigger('addMode', { mode });
                    }
                    // Allow tbar to be configured away.
                    // Note that tbar may be configured away using tbar : null, so tbar?.widgetMap.modeSelector
                    // won't work since it will interrogate the widgetMap of null in that case,
                    const modeSelector = tbar?.widgetMap?.modeSelector;
                    if (modeSelector) {
                        modeSelector.calendar = me;  // in case the up() traversal is needed too early
                        modeSelector.addMode(name, mode);
                    }
                },
                setup(config) {
                    const
                        { defaultCalendar, modeDefaults } = me,
                        viewType = Modes.resolveType(config.type);
                    if (modeDefaults) {
                        config = ObjectHelper.merge({}, modeDefaults, config);
                    }
                    // Inhibit Scheduler features which our features override.
                    if (viewType.isScheduler) {
                        const features = config.features || (config.features = {});
                        features.eventTooltip =
                            features.eventMenu =
                            features.eventEdit =
                            features.scheduleMenu = false;
                    }
                    else {
                        config.eventSelector = me.eventSelector;
                        config.eventInnerSelector = me.eventInnerSelector;
                    }
                    if (!('date' in config)) {
                        config.date = me.date;
                    }
                    config.rtl = me.rtl;
                    config.project = me.project;
                    config.readOnly = me.readOnly;
                    config.allowOverlap = me.allowOverlap;
                    config.hidden = true;
                    config.weekStartDay = me.weekStartDay;
                    config.nonWorkingDays = me.nonWorkingDays;
                    config.timeZone = me.timeZone;
                    // Only inject into the child view if our config overrode the default
                    if ('autoCreate' in me.initialConfig) {
                        config.autoCreate = me.autoCreate;
                    }
                    if (ObjectHelper.hasOwn(me, '_defaultCalendar')) {
                        config.defaultCalendar = me._defaultCalendar;
                    }
                    else if (defaultCalendar != null) {
                        config.defaultCalendar = defaultCalendar;
                    }
                    // Copy in configs which are propagated from the calendar into child views.
                    // This does *not* override any existing configs.
                    ObjectHelper.copyPropertiesIf(config, me, propagatedConfigs);
                    return config;
                }
            }));
        manager.update(modes);
        if (!was) {
            // Only return the target once. Further calls are processed above so we need to return undefined to ensure
            // onConfigChange is called. By returning the same target on 2nd+ call, it passes the === test and won't
            // trigger onConfigChange.
            return manager.target;
        }
    }
    changeModeDefaults(modeDefaults) {
        const
            me     = this,
            result = new Proxy(ObjectHelper.assign({}, modeDefaults), {
                set(target, prop, value) {
                    const result = Reflect.set(...arguments);
                    // Pass new property setting in to child views
                    me.updateModeDefaults({
                        [prop] : value
                    });
                    return result;
                },
                deleteProperty(target, prop) {
                    const result = Reflect.deleteProperty(...arguments);
                    // Pass new property setting in to child views
                    me.updateModeDefaults({
                        [prop] : null
                    });
                    return result;
                }
            });
        return result;
    }
    updateModeDefaults(modeDefaults) {
        if (!this.isConfiguring) {
            this.eachView(v => v.setConfig(modeDefaults));
        }
    }
    get collapsibleConfig() {
        const
            overlaySidebar = this.peekConfig('overlaySidebar'),
            config = {
                type : overlaySidebar ? 'overlay' : 'inline',
                tool : null
            };
        if (overlaySidebar) {
            config.recollapseTool = null;
        }
        return config;
    }
    updateOverlaySidebar(overlaySidebar) {
        const
            { sidebar, collapsibleConfig } = this,
            collapsible = sidebar?.collapsible;
        if (collapsible) {
            if (collapsible.type !== collapsibleConfig.type) {
                sidebar.collapsible = collapsibleConfig;
            }
            if (overlaySidebar) {
                if (!sidebar.collapsed) {
                    sidebar.collapsed = true;
                    sidebar.collapsedDueToOverlay = true;
                }
            }
            // If moving back to inline sidebar, then if it was
            // collapsed only due to being made overlayed, or it's been overlayed into visibility at this time
            // then expand it again.
            else if (sidebar.collapsedDueToOverlay || (sidebar.isVisible && collapsible.isPanelCollapserOverlay)) {
                sidebar.collapsed = false;
                sidebar.collapsedDueToOverlay = false;
            }
        }
    }
    changeSidebar(config, sidebar) {
        const
            me                                   = this,
            { datePicker, statefulId : stateId } = me;
        if (config) {
            const
                sidebarItems      = config.items || (config.items = {}),
                sidebarDatePicker = sidebarItems.datePicker;
            // If the developer nulled out the datePicker in her sidebar config
            // we cannot impose our default datePicker.
            if (datePicker) {
                if (sidebarDatePicker !== null && sidebarDatePicker !== false) {
                    sidebarItems.datePicker = Calendar.mergeConfigs(sidebarItems.datePicker, datePicker);
                }
            }
            else {
                sidebarItems.datePicker = null;
            }
        }
        return Sidebar.reconfigure(sidebar, config, {
            owner : me,
            setup : (cfg, type) => me.setupWidgetConfig(cfg, type),
            // These are combined with "config" prior to calling our setup() above (so they are part of the "cfg"
            // parameter passed to setup():
            defaults : {
                stateId     : stateId && `${stateId}:sidebar`,
                eventStore  : me.eventStore,
                date        : me.date,
                weight      : (config?.side === 'right' || config?.side === 'end') ? 200 : -10,
                collapsible : me.collapsibleConfig,
                items       : {
                    datePicker : {
                        weekStartDay : me.weekStartDay
                    }
                }
            }
        });
    }
    changeTbar(tbar) {
        // Change legacy name to new, correctly cased name
        if (tbar?.toggleSideBar && !tbar.isWidget) {
            VersionHelper.deprecate('calendar', '7.0.0', 'toggleSideBar ref is deprecated in favor of toggleSidebar');
            tbar.toggleSidebar = tbar.toggleSideBar;
            delete tbar.toggleSideBar;
        }
        return super.changeTbar(...arguments);
    }
    changeDatePicker(config) {
        if (config) {
            return Object.assign(config, {
                eventStore : this.eventStore,
                date       : this.date
            });
        }
    }
    /**
     * The {@link #config-datePicker} as an instance of {@link Calendar.widget.CalendarDatePicker}.
     * @member {Calendar.widget.CalendarDatePicker} datePicker
     * @readonly
     */
    get datePicker() {
        return this.isConfiguring ? this._datePicker : this.sidebar?.widgetMap?.datePicker;
    }
    changeItems(items) {
        const { modes, sidebar } = this;
        // Add our sidebar if it was not configured away.
        if (sidebar) {
            items.sidebar = sidebar;
        }
        // Add the configured viewContainer to our items
        items.viewContainer = this.viewContainer;
        // Add our modes.
        items.viewContainer.items = modes;
        items.viewContainer.activeIndex = Math.max(Object.keys(modes).indexOf(this.mode), 0);
        // Set the viewContainer layout's activeIndex to the requested mode's index
        if (this.activeModeIndex) {
            items.viewContainer.layout.activeIndex = this.activeModeIndex;
        }
        const result = super.changeItems(items);
        /**
         * @member {Core.widget.Container} viewContainer
         * A {@link Core.widget.Container} which contains the configured {@link #config-modes} and
         * manages the currently active mode through its {@link Core.widget.Container#property-layout}.
         * @readonly
         */
        this._viewContainer = this.widgetMap.viewContainer;
        return result;
    }
    onViewCatchAll(event) {
        const
            me = this,
            {
                fromOverflowPopup,
                type,
                source : view
            }  = event;
        // Reject events which have bubbled up from other things than our views (MenuItem events bubble).
        // We are only forwarding events from our owned Calendar widgets.
        if (event.bubbles) {
            return;
        }
        let result, date, { domEvent } = event;
        // If the event was from another type of view, such as a Scheduler, they pass
        // DOM events as "event", so use that.
        if (!domEvent && DomHelper.isDOMEvent(event.event)) {
            domEvent = event.event;
        }
        /**
         * When a child calendar view is being interacted with, this property
         * yields a reference to the child being interacted with.
         * @member {Calendar.widget.mixin.CalendarMixin} eventSource
         * @typings {typeof CalendarMixin}
         * @readonly
         */
        me.eventSource = view;
        // Implement the interface which the inherited Feature structure requires.
        // Features hook into client element event handling methods
        if (!domEvent || me.handleEvent(domEvent) !== false) {
            const
                isEmptyCell = type.startsWith('emptycell'),
                // For EventLists, when we are using a startDate->endDate range as opposed to a fixed
                // range, the dates are *inclusive*, so pick the correct date containment function.
                dateContainmentFn = view.isEventList && !view.range ? 'betweenLesserEqual' : 'betweenLesser';
            // Only change date when click originates from a calendar view (not an embedded scheduler for example)
            if (type.endsWith('click') && !noDateChangeEvents[type] && me.activeView.isCalendarMixin && (date = (event.date || view.getDateFromDomEvent?.(domEvent)))) {
                // If it's a DayView, it could be a shifted day, so move it back to the day start.
                // If the day starts at 18:00, and the click is on 2020-06-17T06:00, the date must
                // be 2020-06-16T18:00 so that the Calendar's date is stable.
                if (view.isDayView && view.dayTime) {
                    date = view.dayTime.startOfDay(date);
                }
                const
                    isOverflowClick = fromOverflowPopup || type === 'celloverflowclick' || domEvent.target.closest('.b-cal-cell-overflow'),
                    isOtherMonth = (view.getDayElement && !view.getDayElement(date, true)) || (view.isDateRangeOwner && !DateHelper[dateContainmentFn](date, view.startDate, view.endDate)),
                    // Only move onto the clicked date if it's explicitly a dayNumber Click.
                    // Any other kind of click must not change date. Mats, 07/07/2022
                    changeDate   = view.isCalendarRow || !(fromOverflowPopup && isOtherMonth) && !isEmptyCell && !(view.isDateRangeOwner && isOtherMonth) && (type === 'daynumberclick' || !(isOtherMonth && (isOverflowClick || type.startsWith('event') || type.startsWith('schedule'))));
                // Set our date to the event date if we contain it.
                // If the above fell back to startDate, and it starts before the
                // view start, this must not happen.
                // Note that custom model may not implement getDayElement.
                if (changeDate) {
                    me.date = date;
                }
            }
            if (isEmptyCell || relayedEvents.test(type)) {
                event.view = view;
                result = me.trigger(event.eventName, event);
            }
        }
        me.eventSource = null;
        return result;
    }
    // A mode had an internal change that requires updating the description shown in the tbar
    onViewDescriptionChange() {
        this.updateViewDescription();
    }
    // Implement drilling down from a MonthView week number click to a WeekView
    // or a child view which encapsulates WeekViews via its view.type config.
    onViewWeekNumberClick({ date, week }) {
        const weekView = this.modes.week || this.viewContainer.query(isWeekView);
        if (weekView) {
            // Only change date if the date is not encapsulated in the destination WeekView
            if (!DateHelper.betweenLesser(date, weekView.startDate, weekView.endDate)) {
                this.date = date;
            }
            weekView.week = week;
            this.viewContainer.layout.activeItem = weekView;
        }
    }
    // Implement drilling down from a YearView month name click to the MonthView
    // or a child view which encapsulates MonthViews via its view.type config.
    onViewMonthNameClick({ date }) {
        // Activate the month mode, or
        const monthView = this.modes.month || this.viewContainer.query(isMonthView);
        if (monthView) {
            // Only change date if the date is not encapsulated in the destination MonthView
            if (!monthView.getDayElement(date, true)) {
                this.date = date;
            }
            this.viewContainer.layout.activeItem = monthView;
        }
        // monthNameClick is excluded from date tracking in onViewCatchAll, so if
        // we could not activate a MonthView, set the date.
        else {
            this.date = date;
        }
    }
    // Implement drilling down from a DayView's header day number click to the DayView
    // or a child view which encapsulates DayViews via its view.type config.
    onViewDayNumberClick({ source, date, cellData, fromOverflowPopup, domEvent }) {
        // For a year view, we are clear to go ahead and navigate to the day on click if the overflow popup
        // trigger is not click, or if the overflow popup has been configured away or if there are no
        // events for the date and there's no empty cell renderer which would show some UI about there
        // being no events. Or if the click was on an overflow popup header.
        const validYearViewNavigate = source.isYearView &&
            (source.overflowPopupTrigger !== 'click' || fromOverflowPopup || !source.overflowPopup || cellData.isOtherMonth || (!cellData.events?.length && !source.emptyCellRenderer));
        if (source.isCalendarRow || source.isMonthView || validYearViewNavigate || source.isAgendaView) {
            const
                { viewContainer } = this,
                dayView           = this.modes.day || viewContainer.query(v => v.view?.type.toLowerCase() === 'dayview');
            if (dayView && source !== dayView.allDayEvents) {
                const
                    { layout }                     = viewContainer,
                    { activeItem : oldActiveItem } = layout,
                    { syncViewDate }               = oldActiveItem;
                // Don't sync the outgoing item with our date.
                // It will be synced on its way back in next time.
                oldActiveItem.syncViewDate = false;
                // Must update our date before we attempt the card change because
                // *before* changing card, the incoming card gets its date set.
                // See onBeforeModeChange. New card must be synced with us from the start.
                this.date = date;
                viewContainer.layout.activeItem = dayView;
                // Restore the outgoing item so that when it comes back in
                // onBeforeModeChange will sync it.
                oldActiveItem.syncViewDate = syncViewDate;
                return false;
            }
        }
    }
    onViewBeforeEventAdd({ eventRecord, assignmentRecords }) {
        const
            { eventEdit } = this.features,
            isCreating    = eventEdit && !eventEdit.disabled;
        // If double-clicking in a Scheduler child view, it has its eventEdit feature off, so help it know that
        // the created records are being edited
        eventRecord.isCreating = isCreating;
        assignmentRecords?.forEach(assignmentRecord => assignmentRecord.isCreating = isCreating);
    }
    /**
     * Uses the current active mode to create an event on the specified date which conforms to that mode's
     * {@link Calendar.widget.mixin.CalendarMixin#config-autoCreate} setting.
     *
     * This method may be called programmatically by application code if the `autoCreate` setting
     * is `false`, in which case the default values for `autoCreate` will be used.
     *
     * If the {@link Calendar.feature.EventEdit EventEdit} feature is active, the new event
     * will be displayed in the event editor.
     *
     * This is a utility method to use as a shortcut to {@link Calendar.widget.mixin.CalendarMixin#function-createEvent}
     * @param {Date} [date] Optionally, the date to add the event at. Uses the Calendar's currently
     * active date, as set in the {@link #config-sidebar}'s date picker.
     *
     * If there's no time component, and the current active mode is a calendar view, the
     * {@link Calendar.widget.mixin.CalendarMixin#config-autoCreate}'s `startHour` will be used.
     */
    createEvent(date = this.date, resourceRecord) {
        this.doCreateEvent(date, resourceRecord);
    }
    // This is separate because its signature is fixed. Second parameter is an optional view to use
    // to do the creating. The createEvent method may be called by Features which pass extra parameters.
    doCreateEvent(date, resourceRecord, source = this.activeSubView) {
        const
            { modes }   = this,
            // Yearview defers to other views present in its owning calendar to handle auto creating
            // events. This method finds a suitable host view to postprocess the creation.
            editingView = source.isYearView ? modes.week || modes.month || modes.day || source : source;
        // Ensure the view which is going to postprocess the new event (such as editing it) is active
        if (!this.activeView.owns(editingView)) {
            const
                { viewContainer } = this.widgetMap,
                { layout }        = viewContainer;
            if (editingView) {
                // activeItemChange fires after the animation, so it will be fully ready.
                viewContainer.ion({
                    activeItemChange : 'doCreateEvent',
                    thisObj          : source,
                    once             : true,
                    args             : [date, resourceRecord, editingView]
                });
                this.date = date;
                layout.activeItem = editingView;
                return false;
            }
        }
        else {
            // Calendar vs Scheduler
            source.doCreateEvent ? source.doCreateEvent(date, resourceRecord) : source.createEvent(date, resourceRecord);
        }
    }
    changeDate(date, oldDate) {
        date = typeof date === 'string' ? DateHelper.parse(date) : new Date(date);
        if (isNaN(date)) {
            throw new Error('Calendar widget date ingestion must be passed a Date, or a YYYY-MM-DD date string');
        }
        date = DateHelper.clearTime(date);
        // Protect the setter from processing a no-change.
        if (!oldDate || (date - oldDate)) {
            return date;
        }
    }
    updateAutoCreate() {
        if (!this.isConfiguring) {
            this.syncCommonConfig('autoCreate');
        }
    }
    updateDate(date, oldDate) {
        if (!this.isConfiguring) {
            const { sidebar } = this;
            if (sidebar) {
                // The sidebar's datechange event is listened by onCalendarDateChange
                sidebar.date = date;
            }
            else {
                this.onCalendarDateChange({ date, oldDate });
            }
        }
    }
    updateDateFormat() {
        if (!this.isConfiguring) {
            this.syncCommonConfig('dateFormat');
        }
    }
    changeIncludeWeekendsButton(value) {
        return (value === true) ? {} : value;
    }
    updateIncludeWeekendsButton(value) {
        // we don't pass null/false down because we only want to configure the button not destroy it
        if (!this.isConstructing && value && ObjectHelper.isObject(value)) {
            this.widgetMap.modeSelector.includeWeekendsButton = value;
        }
    }
    changeScrollManager(scrollManager, oldScrollManager) {
        oldScrollManager?.destroy();
        if (this.isDestroying) {
            return;
        }
        return new ScrollManager(Object.assign({
            element : this.element
        }, scrollManager));
    }
    updateDefaultCalendar() {
        if (!this.isConfiguring) {
            this.syncCommonConfig('defaultCalendar');
        }
    }
    changeWeekStartDay(weekStartDay) {
        // Apply the default from DateHelper which draws its value from the locale.
        if (weekStartDay === false) {
            weekStartDay = DateHelper.weekStartDay;
        }
        return weekStartDay;
    }
    updateReadOnly() {
        super.updateReadOnly(...arguments);
        if (!this.isConfiguring) {
            this.syncCommonConfig('readOnly');
        }
    }
    updateWeekStartDay(weekStartDay) {
        if (!this.isConfiguring) {
            this.syncCommonConfig('weekStartDay');
            if (this.sidebar?.widgetMap.datePicker) {
                this.sidebar.widgetMap.datePicker.weekStartDay = weekStartDay;
            }
        }
    }
    changeHideNonWorkingDays(hideNonWorkingDays) {
        // Falsy must be coerced to false so that non-changes do not propagate
        return Boolean(hideNonWorkingDays);
    }
    updateHideNonWorkingDays(hideNonWorkingDays, was) {
        if (!this.isConfiguring) {
            this.syncCommonConfig('hideNonWorkingDays');
            this.trigger('changeHideNonWorkingDays', {
                value : hideNonWorkingDays,
                was
            });
        }
    }
    changeNonWorkingDays(nonWorkingDays) {
        // Apply the default from DateHelper which draws its value from the locale.
        if (nonWorkingDays === false) {
            nonWorkingDays = ObjectHelper.assign({}, DateHelper.nonWorkingDays);
        }
        return nonWorkingDays;
    }
    updateNonWorkingDays() {
        if (!this.isConfiguring) {
            this.syncCommonConfig('nonWorkingDays');
        }
    }
    updateCoreHours() {
        if (!this.isConfiguring) {
            this.syncCommonConfig('coreHours');
        }
    }
    /**
     * Syncs configs which are passed down into child views when changed here.
     * @param {String} name The name of teh config to pass from this Calendar into every child view
     * @private
     */
    syncCommonConfig(name) {
        this.eachView(v => {
            v[name] = this[name];
        });
    }
    onCalendarStoreChange() {
        // Keep mixins informed.
        super.onCalendarStoreChange?.(...arguments);
    }
    updateSelected() {
        const eventEls = this.viewContainer.contentElement.querySelectorAll('.b-cal-event-wrap');
        for (let i = 0, { length } = eventEls; i < length; i++) {
            eventEls[i].classList.toggle(this.eventSelectedCls, this.isEventSelected(eventEls[i].dataset.eventId));
        }
    }
    getElementFromEventRecord(eventRecord, resourceRecord) {
        return this.activeView.getEventElement(eventRecord, undefined, resourceRecord);
    }
    getElementsFromEventRecord(eventRecord, resourceRecord) {
        return [this.getElementFromEventRecord(eventRecord, resourceRecord)];
    }
    updateLocalization() {
        const me = this;
        if (me.isPainted) {
            let refreshed = false;
            // Only go with locale's weekStartDay if we were not initially configured with a weekStartDay
            if (!('weekStartDay' in me.initialConfig) && me.weekStartDay !== DateHelper.weekStartDay) {
                me.weekStartDay = DateHelper.weekStartDay;
                // weekStartDay change will cause a refresh
                refreshed = true;
            }
            // Only go with locale's nonWorkingDays if we were not initially configured with nonWorkingDays
            if (!('nonWorkingDays' in me.initialConfig) && !ObjectHelper.isEqual(me.nonWorkingDays, DateHelper.nonWorkingDays)) {
                me.nonWorkingDays = DateHelper.nonWorkingDays;
                // nonWorkingDays change will cause a refresh
                refreshed = true;
            }
            if (!refreshed) {
                me.refresh();
            }
            me.syncUIWithActiveView();
        }
        super.updateLocalization();
    }
}
Calendar.initClass();
// Views which we import may need to access Calendar at runtime. They do it through the bryntum global.
if (globalThis.bryntum) {
    globalThis.bryntum.Calendar = Calendar;
}
class Modes extends Base.mixin(Factoryable) {
    static get factoryable() {
        return {
            // Allow any widget type:
            extends : Widget
        };
    }
}
Modes.register('agenda', AgendaView);
Modes.register('year', YearView);
Modes.register('month', MonthView);
Modes.register('week', WeekView);
Modes.register('day', DayView);
Modes.register('list', EventList);
Modes.register('resource', ResourceView);
Modes.register('dayresource', DayResourceView);
Calendar.Modes = Modes;
VersionHelper.setVersion('calendar', '5.6.6');
Calendar._$name = 'Calendar';