import Base from '../../Core/Base.js';
import ProjectConsumer from '../../Scheduler/data/mixin/ProjectConsumer.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import CrudManager from '../data/CrudManager.js';
import ProjectModel from '../model/ProjectModel.js';
import TimeRangeModel from '../model/TimeRangeModel.js';
import TimeZoneHelper from '../../Core/helper/TimeZoneHelper.js';
import ResourceTimeRangeModel from '../../Scheduler/model/ResourceTimeRangeModel.js';
/**
 * @module Calendar/mixin/CalendarStores
 */
/**
 * Functions for store assignment and store event listeners.
 *
 * @mixin
 * @extends Scheduler/data/mixin/ProjectConsumer
 */
export default Target => class CalendarStores extends ProjectConsumer(Target || Base) {
    static $name = 'CalendarStores';
    static configurable = {
        /**
         * The calendar ({@link Scheduler.model.ResourceModel Resource}), (or its `id`) to use as the
         * default calendar for new events created using {@link Calendar.view.Calendar#config-autoCreate dblclick}
         * or {@link Calendar.feature.CalendarDrag drag-create}, or {@link Calendar.feature.ExternalEventSource dragging in}.
         *
         * May be specified as the `id` of an existing resource, or a resource record that is present in the project.
         *
         * If not specified, the first record in the {@link #property-resourceStore} will be used.
         *
         * If specified as `null`, then new events created using {@link Calendar.view.Calendar#config-autoCreate dblclick}
         * or {@link Calendar.feature.CalendarDrag drag-create}, or {@link Calendar.feature.ExternalEventSource dragging in}
         * will not be automatically assigned a calendar.
         * @config {String|Scheduler.model.ResourceModel}
         */
        defaultCalendar : null,
        /**
         * Class that should be used to instantiate a CrudManager in case it's provided as a simple object to
         * {@link #config-crudManager} config.
         * @config {Scheduler.data.CrudManager}
         * @category Data
         */
        crudManagerClass : CrudManager,
        /**
         * Get/set the CrudManager instance
         * @member {Scheduler.data.CrudManager} crudManager
         * @category Data
         */
        /**
         * Supply a {@link Scheduler.data.CrudManager} instance or a config object if you want to use
         * CrudManager for handling data.
         * @config {CrudManagerConfig|Scheduler.data.CrudManager}
         * @category Data
         */
        crudManager : null,
        /**
         * Set to a time zone or a UTC offset. This will set the projects
         * {@link Scheduler.model.ProjectModel#config-timeZone} config accordingly. As this config is only a referer,
         * please se project's config {@link Scheduler.model.ProjectModel#config-timeZone documentation} for more
         * information.
         *
         * ```javascript
         * new Calendar(){
         *   timeZone : 'America/Chicago'
         * }
         * ```
         * @config {String|Number} timeZone
         * @category Misc
         */
        timeZone : {
            // Don't ingest the config eagerly because it relies on project being present.
            // Lazy means it waits for ingestion until timeZone property is referenced.
            $config : 'lazy',
            value   : null
        }
    };
    static defaultConfig = {
        projectModelClass : ProjectModel
    };
    //region Default config
    // This is the static definition of the Stores we consume from the project, and
    // which we must provide *TO* the project if we or our CrudManager is configured
    // with them.
    // The property name is the store name, and within that there is the dataName which
    // is the property which provides static data definition. And there is a listeners
    // definition which specifies the listeners *on this object* for each store.
    //
    // To process incoming stores, implement an updateXxxxxStore method such
    // as `updateEventStore(eventStore)`.
    //
    // To process an incoming Project implement `updateProject`. __Note that
    // `super.updateProject(...arguments)` must be called first.__
    static get projectStores() {
        return {
            resourceStore : {
                dataName  : 'resources',
                // eslint-disable-next-line bryntum/no-listeners-in-lib
                listeners : {
                    changePreCommit : 'onCalendarStoreChange'
                }
            },
            eventStore : {
                dataName  : 'events',
                // eslint-disable-next-line bryntum/no-listeners-in-lib
                listeners : {
                    changePreCommit : 'onCalendarStoreChange'
                }
            },
            assignmentStore : {
                dataName : 'assignments'
            },
            timeRangeStore : {
                dataName   : 'timeRanges',
                modelClass : TimeRangeModel
            },
            resourceTimeRangeStore : {
                dataName   : 'resourceTimeRanges',
                modelClass : ResourceTimeRangeModel
            }
        };
    }
    /**
     * Inline events, will be loaded into the {@link #property-eventStore}.
     * @prp {Scheduler.model.EventModel[]|EventModelConfig[]} events
     * @category Data
     */
    /**
     * The {@link Scheduler.data.EventStore} or a reconfiguring object for a store which will hold
     * the events to be rendered into the Calendar.
     * @config {Scheduler.data.EventStore|EventStoreConfig} eventStore
     * @category Data
     */
    /**
     * The {@link Scheduler.data.EventStore} holding the events to be rendered into the Calendar.
     * @member {Scheduler.data.EventStore} eventStore
     * @readonly
     * @category Data
     */
    /**
     * Inline resources, will be loaded into the {@link #property-resourceStore}.
     * @prp {Scheduler.model.ResourceModel[]|ResourceModelConfig[]} resources
     * @category Data
     */
    /**
     * The {@link Scheduler.data.ResourceStore} or a reconfiguring object for a store which will hold the resources to be rendered into the Calendar.
     * @config {Scheduler.data.ResourceStore|ResourceStoreConfig} resourceStore
     * @category Data
     */
    /**
     * The {@link Scheduler.data.ResourceStore} holding the resources to be rendered into the Calendar.
     * @member {Scheduler.data.ResourceStore} resourceStore
     * @readonly
     * @category Data
     */
    /**
     * Inline assignments, will be loaded into the {@link #property-assignmentStore}.
     * @prp {Scheduler.model.AssignmentModel[]|AssignmentModelConfig[]} assignments
     * @category Data
     */
    /**
     * The {@link Scheduler.data.AssignmentStore} or a reconfiguring object for a store which will hold assignments linking resources to events.
     * @config {Scheduler.data.AssignmentStore|AssignmentStoreConfig} assignmentStore
     * @category Data
     */
    /**
     * The {@link Scheduler.data.AssignmentStore} holding assignments linking resources to events.
     * @member {Scheduler.data.AssignmentStore} assignmentStore
     * @readonly
     * @category Data
     */
    /**
     * Inline time ranges, will be loaded into the {@link #property-timeRangeStore}.
     *
     * Time ranges are rendered in Calendar {@link Calendar.widget.DayView} or
     * {@link Calendar.widget.WeekView} when the Calendar has the {@link Calendar.feature.TimeRanges}
     * feature enabled.
     *
     * Time ranges are rendered into a {@link Scheduler.view.Scheduler} subview when that subview
     * has the {@link Scheduler.feature.TimeRanges} feature enabled.
     *
     * @prp {Calendar.model.TimeRangeModel[]|TimeRangeModelConfig[]} timeRanges
     * @accepts {Calendar.model.TimeRangeModel[]|TimeRangeModelConfig[]}
     * @category Data
     */
    /**
     * The {@link Calendar.data.TimeRangeStore} or a reconfiguring object for a store which will hold time ranges.
     * These may be used by the {@link Calendar.feature.TimeRanges} feature.
     * @config {Calendar.data.TimeRangeStore|TimeRangeStoreConfig} timeRangeStore
     * @category Data
     */
    /**
     * The {@link Calendar.data.TimeRangeStore} holding time ranges.
     * These may be used by the {@link Calendar.feature.TimeRanges} feature.
     * @member {Calendar.data.TimeRangeStore} timeRangeStore
     * @readonly
     * @category Data
     */
    /**
     * Inline resource time ranges, will be loaded into {@link #property-resourceTimeRangeStore}.
     *
     * Resource time ranges are rendered in a Calendar {@link Calendar.widget.ResourceView} when the
     * Calendar has the {@link Calendar.feature.TimeRanges} feature enabled.
     *
     * Resource time ranges are rendered into a {@link Scheduler.view.Scheduler} subview when that subview
     * has the {@link Scheduler.feature.ResourceTimeRanges} feature enabled.
     *
     * @prp {Scheduler.model.ResourceTimeRangeModel[]|ResourceTimeRangeModelConfig[]} resourceTimeRanges
     * @accepts {Scheduler.model.ResourceTimeRangeModel[]|ResourceTimeRangeModelConfig[]}
     * @category Data
     */
    /**
     * The {@link Scheduler.data.ResourceTimeRangeStore} or a reconfiguring object for a store which will hold resource time ranges.
     * These may be used by the {@link Calendar.feature.TimeRanges} feature.
     * @config {Scheduler.data.ResourceTimeRangeStore|ResourceTimeRangeStoreConfig} resourceTimeRangeStore
     * @category Data
     */
    /**
     * The {@link Scheduler.data.ResourceTimeRangeStore} holding resource time ranges.
     * These may be used by the {@link Calendar.feature.TimeRanges} feature.
     * @member {Scheduler.data.ResourceTimeRangeStore} resourceTimeRangeStore
     * @readonly
     * @category Data
     */
    //endregion
    //region CrudManager
    changeCrudManager(crudManager) {
        const me = this;
        if (crudManager && !crudManager.isCrudManager) {
            const type = crudManager.type || me.crudManagerClass;
            // CrudManager injects itself into is Scheduler's _crudManager property
            // because code it triggers needs to access it through its getter.
            crudManager = type.new({
                scheduler : me
            }, crudManager);
        }
        // config setter will veto because of above described behaviour
        // of setting the property early on creation
        me._crudManager = crudManager;
        me.bindCrudManager?.(crudManager);
    }
    //endregion
    updateTimeZone(timeZone) {
        this.project.timeZone = timeZone;
    }
    get timeZone() {
        return this.project.timeZone;
    }
    // When project changes time zone, change Calendar date
    onBeforeTimeZoneChange({ timeZone, oldTimeZone }) {
        if (this.isConfiguring) {
            // Too early
            return;
        }
        const
            me = this,
            // Only one receiver of this event should change Calendar date.
            // The Calendar's activeView if we are inside a Calendar, or this view if not
            target = me.isCalendar ? me.activeView : (!me.calendar && me);
        // Only change view date if dayView
        if (target?.duration === 1) {
            // Get events in current dayView
            const currentEvents =  target.events.filter(e => e.startDate >= target.startDate && e.endDate && e.endDate <= target.endDate);
            if (currentEvents.length) {
                // If there is events, calculate an average date of those events
                const
                    eventsCenterMs  = currentEvents.map(e => (e.startDate.getTime() + e.endDate.getTime()) / 2),
                    avgCenterDate   = new Date(Math.floor(eventsCenterMs.reduce((sum, e) => sum + e, 0) / eventsCenterMs.length)),
                    // If already converted, revert that date to local time zone
                    centerDateLocal = oldTimeZone != null ? TimeZoneHelper.fromTimeZone(avgCenterDate, oldTimeZone) : avgCenterDate,
                    // And then convert it to the new time zone (or not)
                    centerDateTZ    = timeZone != null ? TimeZoneHelper.toTimeZone(centerDateLocal, timeZone) : centerDateLocal;
                // If different date from current, change
                if (centerDateTZ < target.startDate || centerDateTZ > target.endDate) {
                    (target.calendar || target).date = centerDateTZ;
                }
            }
        }
    }
    // Need this to not run conversion in ProjectConsumer
    onTimeZoneChange() {}
    /**
     * The default Calendar to be used when creating new events which do not initially have a resource.
     *
     * This includes {@link Calendar.view.Calendar#config-autoCreate dblclick-created} events and
     * {@link Calendar.feature.CalendarDrag drag-created} events and
     * {@link Calendar.feature.ExternalEventSource dragged in} events.
     * @property {Scheduler.model.ResourceModel}
     */
    get defaultCalendar() {
        const
            { eventStore }  = this,
            defaultCalendar = ObjectHelper.hasOwn(this, '_defaultCalendar') ? this._defaultCalendar : eventStore.defaultCalendarId;
        if (defaultCalendar !== null) {
            return defaultCalendar != undefined ? (defaultCalendar.isResourceModel ? defaultCalendar : eventStore.resourceStore.getById(defaultCalendar)) : eventStore.resourceStore.first;
        }
    }
    get defaultCalendarId() {
        return this.eventStore.modelClass.asId(this.defaultCalendar);
    }
    //region Project
    updateProject(project, oldProject) {
        super.updateProject(project, oldProject);
        const me = this;
        // Now is the time to force timeZone ingestion. When we have all our stores.
        me.getConfig('timeZone');
        me.detachListeners('CalendarStores');
        project.ion({
            name      : 'CalendarStores',
            dataReady : 'onCalendarStoreChange',
            refresh   : 'onProjectRefresh',
            thisObj   : me
        });
        // Assigned a project at runtime
        if (!me.isConfiguring) {
            me.eachView(view => {
                view.project = project;
            });
        }
    }
    onProjectRefresh({ source : project, isInitialCommit }) {
        // Force reevaluation of date indices next time requested because the
        // initial project commit may have rescheduled the events.
        if (isInitialCommit) {
            project.eventStore.invalidateDayIndices();
        }
    }
    updateEventStore(eventStore) {
        // Decorate the eventStore's createRecord method to inject our defaultCalendar into new
        // events as the default resource.
        if (eventStore && !eventStore.isCalendarEventStore) {
            const storeOwner = this;
            Object.defineProperty(eventStore, 'defaultCalendar', {
                get : () => storeOwner.defaultCalendar
            });
            eventStore.isCalendarEventStore = true;
            // Events should stay available when they are deassigned unless requested otherwise
            if (!('removeUnassignedEvent' in eventStore.initialConfig)) {
                eventStore.removeUnassignedEvent = false;
            }
        }
    }
    //endregion
    //region WidgetClass
    // This does not need a className on Widgets.
    // Each *Class* which doesn't need 'b-' + constructor.name.toLowerCase() automatically adding
    // to the Widget it's mixed in to should implement thus.
    get widgetClass() {}
    //endregion
};
