import SchedulerTimeRangeStore from '../../Scheduler/data/TimeRangeStore.js';
import TimeRangeModel from '../model/TimeRangeModel.js';
/**
 * @module Calendar/data/TimeRangeStore
 */
/**
 * A store representing a collection of time ranges.
 *
 * @extends Scheduler/data/TimeRangeStore
 * @typings Scheduler.data.TimeRangeStore -> Scheduler.data.SchedulerTimeRangeStore
 */
export default class TimeRangeStore extends SchedulerTimeRangeStore {
    static defaultConfig = {
        modelClass : TimeRangeModel
    };
}
TimeRangeStore.initClass();
TimeRangeStore._$name = 'TimeRangeStore';