/**
 * @module Calendar/util/EventSlots
 */
/**
 * An Array which allows prefilling of some slots and automatic filling
 * of vacant slots.
 *
 * Used by the Calendar to fill event slots in day cells in the MonthView
 * @internal
 */
export default class EventSlots extends Array {
    firstFreeSlot = 0;
    set(index, event) {
        const me = this;
        if (index == null) {
            return me.add(event);
        }
        else {
            me[index] = event;
        }
        // Stepped on the first free slot pointer, move it on.
        if (me.firstFreeSlot === index) {
            // Move onwards while the pointer references an occupied slot
            while (me[me.firstFreeSlot]) {
                me.firstFreeSlot++;
            }
        }
    }
    add(event) {
        const result = this.firstFreeSlot;
        this[result] = event;
        // Move onwards while the pointer references an occupied slot
        while (this[this.firstFreeSlot]) {
            this.firstFreeSlot++;
        }
        return result;
    }
    setEvents(events) {
        const length = this.length = this.firstFreeSlot = events.length;
        for (let i = 0; i < length; i++) {
            this[i] = events[i];
        }
    }
}
EventSlots._$name = 'EventSlots';