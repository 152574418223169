import DateHelper from '../../Core/helper/DateHelper.js';
import CalendarRow from './CalendarRow.js';
/**
 * @module Calendar/widget/DayResourceCalendarRow
 */
/**
 * This is normally used as the "All day events" section of a {@link Calendar.widget.DayResourceView}.
 * It is not designed to be used standalone as a regular Widget.
 *
 * A Widget which displays date and resource headers, and all day events at the top of a
 * {@link Calendar.widget.DayResourceView}.
 *
 * Cell rendering can be customized using the {@link #config-dayCellRenderer} method.
 *
 * Event rendering can be customized using the {@link #config-eventRenderer} method.
 *
 * {@inlineexample Calendar/widget/DayResourceView.js}
 *
 * @demo Calendar/date-resource
 *
 * @extends Calendar/widget/CalendarRow
 * @classtype dayresourcecalendarrow
 */
export default class DayResourceCalendarRow extends CalendarRow {
    static $name = 'DayResourceCalendarRow';
    // Factoryable type name
    static type = 'dayresourcecalendarrow';
    static configurable = {
        /**
         * Configure as `true` to show avatars of the resources (calendars) alongside
         * resource names in resource column headers.
         *
         * Configure as `'last'` to show avatars of the assigned resources (calendars) after the name.
         *
         * Note that you must set {@link #config-resourceImagePath} in order that the system
         * knows where to access the resource's image file from.
         *
         * If no image is set, or the image is not found, the resource's initials are shown instead.
         *
         * By default it is inherited from the owning Calendar:
         * ```javascript
         * new Calendar({
         *     resourceImagePath   : 'images/resources/'
         *     modes : {
         *         dayresource : {
         *             showHeaderAvatars : true,
         *         }
         *     }
         * });
         * ```
         * @config {Boolean|'last'}
         * @default
         */
        showHeaderAvatars : true,
        responsive : {
            // We can use "large" formats here because the cell will be wide
            // to accommodate multiple resources.
            small : {
                dayNameFormat     : 'ddd',
                dayNumberCompress : false
            }
        },
        autoRefresh : [
            'hideEmptyResources'
        ],
        /**
         * Configure this as `true` to hide resource columns which contain no events.
         *
         * <div class="note">Use with care. This may result in no resource columns being rendered
         * for completely empty dates.</div>
         * @prp {Boolean}
         * @default false
         */
        hideEmptyResources : null
    };
    get eventContentElement() {
        return this.bodyElement;
    }
    // Dragging can drag to the resource header cells. This view does not need to open
    // up temporarily to accept a drop gesture
    expandGutter() {}
    get viewResources() {
        const
            me                = this,
            { resourceStore } = me,
            resourceFilter    = me.calendar?.widgetMap.resourceFilter;
        return resourceFilter ? resourceStore.records.filter(c => resourceFilter.value.includes(c)) : resourceStore.records;
    }
    onCellOverflowGesture({ resourceRecord, date }) {
        const
            cellData           = this.cellMap.get(DateHelper.makeKey(date)),
            { renderedEvents } = cellData;
        cellData.renderedEvents = renderedEvents.filter(e => e.eventRecord.resources?.includes(resourceRecord));
        super.onCellOverflowGesture(...arguments);
        cellData.renderedEvents = renderedEvents;
    }
    getCellFromEvent(domEvent) {
        return domEvent.target.closest('.b-calendar-cell[data-date] [data-resource-id]');
    }
    createCellMap() {
        const
            cellMap           = super.createCellMap(...arguments),
            { viewResources } = this;
        // We need to recalculate maxEventCount due to them being split and stacked by resource
        this.maxEventCount = 0;
        cellMap.forEach(cellData => {
            for (let i = 0, { length } = viewResources; i < length; i++) {
                // Filter down day's events to only those for this resource to calculate max stacking height
                this.maxEventCount = Math.max(this.maxEventCount, cellData.renderedEvents.filter(e => e.eventRecord.resources?.includes(viewResources[i])).length);
            }
        });
        return cellMap;
    }
    getCellDomConfig(cellData) {
        const
            me               = this,
            {
                viewResources,
                eventsPerCell,
                showHeaderAvatars,
                hideEmptyResources
            }                = me,
            renderedEvents   = cellData.renderedEvents,
            bodyChildren     = [],
            today            = me.dayTime.startOfDay(me.calendar?.dateTimeNow || new Date()),
            isToday          = cellData.date.getTime() === today.getTime(),
            {
                maxRow,
                day
            }                = cellData,
            nonWorkingDays   = me.nonWorkingDays || me.month.nonWorkingDays,
            dayEvents        = hideEmptyResources && (me.owner?.getEventsForDay(cellData.key, me.startDate) || renderedEvents),
            // If we are hiding empty resource columns, filter down visibleResources to only those resources
            // which have events for this day
            visibleResources = hideEmptyResources ? viewResources.reduce((result, resource) => {
                const resourceEvents = dayEvents.filter(e => e.resources?.includes(resource));
                if (resourceEvents.length) {
                    result.push(resource);
                }
                return result;
            }, []) : viewResources;
        me.weekLength = 1;
        // One sub-column for each resource
        for (let i = 0, { length } = visibleResources; i < length; i++) {
            // Filter down day's events to only those for this resource column
            cellData.renderedEvents = renderedEvents.filter(e => e.eventRecord.resources?.includes(visibleResources[i]));
            // There is only one column in this view. It is a single day column.
            cellData.visibleColumnIndex = cellData.columnIndex = 0;
            cellData.maxRow = cellData.renderedEvents.length > eventsPerCell ? maxRow : eventsPerCell;
            const dayCell = super.getCellDomConfig(cellData);
            dayCell.children[1].dataset = {
                resourceId : visibleResources[i].id
            };
            // We only need the cell content
            bodyChildren.push({
                className : 'b-resourcecalendarrow-column-resource-cell',
                dataset   : {
                    resourceId : visibleResources[i].id
                },
                children : [
                    dayCell.children[1],
                    // Add the overflow button
                    cellData.hasOverflow && cellData.renderedEvents.length > eventsPerCell ? dayCell.children[2] : null
                ]
            });
        }
        // Restore day's full event load
        cellData && (cellData.renderedEvents = renderedEvents);
        if (visibleResources.length || !hideEmptyResources) {
            // A single day's column encapsulates all resources.
            // The outer element becomes the content cell.
            // children[0] is extracted to use as the header cell.
            const result = {
                className : {
                    'b-dayresourcecalendarrow-column' : 1
                },
                style : {
                    '--visible-resource-count' : visibleResources.length
                },
                children : [{
                    style : {
                        // This element is extracted into the event content element
                        // so it does not inherit --visible-resource-count in the final DOM structure
                        '--visible-resource-count' : visibleResources.length
                    },
                    className : {
                        'b-dayresource-allday'   : 1,
                        'b-cal-cell-header'      : 1,
                        [me.todayCls]            : isToday,
                        [me.nonWorkingDayCls]    : nonWorkingDays[day] || false,
                        [me.weekendCls]          : DateHelper.weekends[day],
                        [`b-day-of-week-${day}`] : 1
                    },
                    children : [{
                        className : {
                            'b-dayname-date' : 1
                        }
                    }, ...visibleResources.map(r => {
                        return {
                            className : {
                                'b-dayresourcecalendarrow-resource-header' : 1,
                                'b-avatar-after'                           : showHeaderAvatars === 'last'
                            },
                            children : [
                                showHeaderAvatars ? me.getResourceAvatar(r) : null,
                                {
                                    className : 'b-dayresourcecalendarrow-resource-name',
                                    text      : me.resourceHeaderRenderer(r)
                                }
                            ],
                            dataset : {
                                resourceId : r.id
                            }
                        };
                    })]
                },
                ...bodyChildren
                ],
                dataset : {}
            };
            me.addCellHeaderContent(result.children[0].children[0], cellData);
            return result;
        }
    }
    updateMinResourceWidth(minResourceWidth) {
        const
            {
                scrollable,
                headerScroller
            } = this;
        this.element.style.setProperty('--cell-container-overflow', minResourceWidth ? 'visible' : 'hidden');
        if (minResourceWidth) {
            // Overflow 'hidden-scroll' is a special Scroller setting.
            // With overlayed scrollbars it just means 'auto'
            // With space-consuming scrollbars, it hides scrollbars using CSS while using auto.
            // But if the two axes scroll, then both scrollbars should not be hidden so it will
            // fall back to using overflow:hidden and a `wheel` listener.
            scrollable.overflowX = headerScroller.overflowX = 'hidden-scroll';
        }
        else {
            scrollable.overflowX = headerScroller.overflowX = false;
        }
    }
    resourceHeaderRenderer(resource) {
        return resource.name;
    }
    updateShowHeaderAvatars(showHeaderAvatars) {
        if (showHeaderAvatars) {
            // We need the AvatarRendering utility if we are showing avatars.
            this.avatarRendering || (this.avatarRendering = true);
        }
        this.refresh();
    }
    // This is never fullWeek
    changeFullWeek() {}
}
DayResourceCalendarRow.initClass();
DayResourceCalendarRow._$name = 'DayResourceCalendarRow';