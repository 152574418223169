import Printer from './Printer.js';
import { PaperFormat } from '../../../../Grid/feature/export/Utils.js';
/**
 * @module Calendar/feature/print/printer/CalendarPrinter
 */
const bigNumberProperty = {
    configurable : true,
    get          : () => Number.MAX_SAFE_INTEGER
};
/**
 * Helper class for the Calendar print feature.
 * @private
 */
export default class CalendarPrinter extends Printer {
    static get $name() {
        return 'CalendarPrinter';
    }
    static get type() {
        return 'calendar';
    }
    getTotalHeight(client) {
        const { activeView } = client;
        switch (client.mode) {
            case 'agenda':
                return activeView.virtualScrollHeight;
            case 'day' :
                return activeView.dayContentElement.scrollHeight + activeView.alldayRowElement.offsetHeight;
            case 'week':
                return activeView.dayContentElement.scrollHeight + activeView.alldayRowElement.offsetHeight;
            case 'month':
                // Duplicating screen week row heights exactly.
                if (this.owner.wysiwyg) {
                    return activeView.weeksElement.scrollHeight + activeView.weekdaysHeader.offsetHeight + 10;
                }
                // If we're not doing wysiwyg, total height is set during content cloning.
                break;
            case 'year' :
                return this.inchToPx(PaperFormat.A4.width);
        }
    }
    getTotalWidth(client) {
        switch (client.mode) {
            case 'year' :
                return this.inchToPx(PaperFormat.A4.height);
            default:
                return this.inchToPx(PaperFormat.A4.width);
        }
    }
    async prepareComponent(config) {
        const
            me                   = this,
            { owner }            = me,
            { wysiwyg }          = owner,
            shrinkwrapRowHeights = !wysiwyg,
            { client }           = config,
            { activeView }       = client;
        me.exportMeta = {
            title       : owner.callback(owner.titleRenderer, owner, [activeView]),
            totalWidth  : me.getTotalWidth(client),
            totalHeight : me.getTotalHeight(client),
            subGrids    : {}
        };
        if (client.mode === 'month' && shrinkwrapRowHeights) {
            Object.defineProperty(activeView, 'eventsPerCell', bigNumberProperty);
            // Refresh with eventsPerCell at a Very Large Number so that all are rendered
            // since we are shrinkwrapping the week rows.
            activeView.doRefresh();
        }
        me.cloneElement(activeView.currentElement);
        if (client.mode === 'month') {
            let rowHeights;
            // Measure the row heights based upon the number of rendered events (which will be all for that day)
            // and the event height.
            if (shrinkwrapRowHeights) {
                rowHeights = activeView.shrinkwrapRowHeights;
                delete activeView.eventsPerCell;
                activeView.doRefresh();
                // Total exported height must be all calculated heights
                me.exportMeta.totalHeight = activeView.weekdaysHeader.offsetHeight + rowHeights.reduce((sum, r) => {
                    return sum + r;
                }, 5);
            }
            activeView.weekElements.forEach((el, i) => {
                const clonedElement = me.element.querySelector(`[data-week="${el.dataset.week}"]`);
                clonedElement.style.minHeight = '';
                if (shrinkwrapRowHeights) {
                    clonedElement.style.flex = `0 0 ${rowHeights[i]}px`;
                }
                else if (wysiwyg) {
                    clonedElement.style.flex = `1 0 ${el.offsetHeight}px`;
                }
                else {
                    clonedElement.style.flex = `1 0 ${250 / 6}mm`;
                }
            });
        }
    }
    pxToInch(value) {
        return (value / 96).toFixed(2);
    }
}
// HACK: terser/obfuscator doesn't yet support async generators, when processing code it converts async generator to regular async
// function.
CalendarPrinter.prototype.pagesExtractor = async function * pagesExtractor(config) {
    const
        me = this,
        {
            totalHeight,
            totalWidth,
            title
        }  = me.exportMeta;
    yield {
        html : me.pageTpl({
            config,
            title,
            html        : me.prepareExportElement(),
            styles      : me.stylesheets,
            paperWidth  : me.pxToInch(totalWidth),
            paperHeight : me.pxToInch(totalHeight)
        })
    };
};
CalendarPrinter._$name = 'CalendarPrinter';