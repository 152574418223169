import LocaleHelper from '../../Core/localization/LocaleHelper.js';
import '../../Scheduler/localization/En.js';
const locale = {
    localeName : 'En',
    localeDesc : 'English (US)',
    localeCode : 'en-US',
    EventEdit : {
        Calendar  : 'Calendar',
        'All day' : 'All day',
        day       : 'Day',
        week      : 'Week',
        month     : 'Month',
        year      : 'Year',
        decade    : 'Decade'
    },
    EventMenu : {
        duplicateEvent : 'Duplicate event',
        copy           : 'copy'
    },
    Calendar : {
        Today      : 'Today',
        next       : range => `Next ${range}`,
        previous   : range => `Previous ${range}`,
        plusMore   : value => `+${value} more`,
        allDay     : 'All day',
        endsOn     : d => `Ends ${d}`,
        weekOfYear : ([y, w]) => `Week ${w}, ${y}`,
        loadFail   : 'Calendar data load failed. Please contact your system administrator'
    },
    CalendarDrag : {
        holdCtrlForRecurrence : 'Hold CTRL for a recurring event'
    },
    CalendarMixin : {
        eventCount : count => `${count || 'No'} event${count === 1 ? '' : 's'}`
    },
    EventTip : {
        'Edit event' : 'Edit event',
        timeFormat   : 'LST'
    },
    ModeSelector : {
        includeWeekends : 'Include weekends',
        weekends        : 'Weekends'
    },
    AgendaView : {
        Agenda : 'Agenda'
    },
    MonthView : {
        Month     : 'Month',
        monthUnit : 'month'
    },
    WeekView : {
        weekUnit : 'week'
    },
    YearView : {
        Year     : 'Year',
        yearUnit : 'year',
        noEvents : 'No events'
    },
    EventList : {
        List   : 'List',
        Start  : 'Start',
        Finish : 'End',
        days   : count => `${count > 1 ? `${count} ` : ''}day${count === 1 ? '' : 's'}`
    },
    DayView : {
        Day               : 'Day',
        dayUnit           : 'day',
        daysUnit          : 'days',
        expandAllDayRow   : 'Expand all-day section',
        collapseAllDayRow : 'Collapse all-day section',
        timeFormat        : 'LST'
    },
    DayResourceView : {
        dayResourceView : 'Day resources'
    },
    Sidebar : {
        'Filter events' : 'Filter events'
    },
    WeekExpander : {
        expandTip   : 'Click to expand row',
        collapseTip : 'Click to collapse row'
    }
};
export default LocaleHelper.publishLocale(locale);
