import CalendarFeature from './CalendarFeature.js';
import SchedulerEventEdit from '../../Scheduler/feature/EventEdit.js';
import '../widget/EventEditor.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import '../../Core/widget/SlideToggle.js';
import Scroller from '../../Core/helper/util/Scroller.js';
/**
 * @module Calendar/feature/EventEdit
 */
const isCalendar = w => w.isCalendar;
/**
 * Feature that displays a popup containing fields for editing event data.
 *
 * To customize its contents you can:
 *
 * * Reconfigure built-in widgets by providing override configs in the {@link Scheduler.feature.base.EditBase#config-items} config.
 * * Change the date format of the date & time fields: {@link Scheduler.feature.base.EditBase#config-dateFormat} and {@link Scheduler.feature.base.EditBase#config-timeFormat}
 * * Configure provided fields in the editor and add your own in the {@link Scheduler.feature.base.EditBase#config-items} config.
 * * Remove fields related to recurring events configuration (such as `recurrenceCombo`) by setting {@link Scheduler.feature.mixin.RecurringEventEdit#config-showRecurringUI} config to `false`.
 * * Advanced: Reconfigure the whole editor widget using {@link Scheduler.feature.EventEdit#config-editorConfig}
 *
 * For more info on customizing the event editor, please see [Customize event editor](#Calendar/guides/customization/eventedit.md) guide.
 *
 * {@inlineexample Calendar/feature/EventEdit.js}
 *
 * This feature is **enabled** by default.
 *
 * @demo Calendar/eventedit
 *
 * @extends Scheduler/feature/EventEdit
 * @classtype eventEdit
 * @feature
 *
 * @typings Scheduler.feature.EventEdit -> Scheduler.feature.SchedulerEventEdit
 */
export default class EventEdit extends SchedulerEventEdit {
    static get $name() {
        return 'EventEdit';
    }
    static get type() {
        return 'eventEdit';
    }
    static get configurable() {
        return {
            editorConfig : {
                type  : 'calendareventeditor',
                items : {
                    resourceField : {
                        label          : 'L{Calendar}',
                        showEventColor : true
                    },
                    allDay : {
                        type              : 'checkbox',
                        cls               : 'b-match-label',
                        name              : 'allDay',
                        weight            : 250,
                        label             : 'L{All day}',
                        internalListeners : {
                            change : 'up.onAllDayChange'
                        }
                    }
                }
            }
        };
    }
    construct(config) {
        // Legacy constructor signature
        super.construct(config.client, config);
    }
    async onDragCreateEnd({ eventRecord, resourceRecord }) {
        eventRecord._dragCreated = true;
        await this.editNewlyCreatedEvent(eventRecord, resourceRecord);
        eventRecord._dragCreated = null;
    }
    onEventAutoCreated({ eventRecord }) {
        this.editNewlyCreatedEvent(eventRecord);
    }
    editNewlyCreatedEvent(eventRecord, resourceRecord) {
        if (!this.disabled) {
            // The Drag feature adds the record so we must set the flag here.
            eventRecord.isCreating = true;
            // Implementations may be async, so the return value must always be propagated.
            // also, set the `stmCapture` argument to `false` do disable the STM capturing mechanism
            // calendar is performing its own handling
            return this.editEvent(eventRecord, resourceRecord, null, false);
        }
    }
    /**
     * Opens an editor for the passed event. This function is exposed on Calendar and can be called as
     * `calendar.editEvent()`.
     *
     * If the event is not present in the `eventStore`, the event will be added so that it becomes
     * visible in the UI. The editor will then shows a "Cancel" button which removes the event when
     * clicked to abort a "New Event" operation.
     * @method editEvent
     * @param {Scheduler.model.EventModel} eventRecord Event to edit
     * @param {Scheduler.model.ResourceModel} [resourceRecord] Not used. Inherited from Scheduler.
     * @param {HTMLElement} [element] Element to anchor editor to (defaults to events element)
     * @async
     * @on-owner
     */
    // editEvent is the single entry point in the base class.
    // Subclass implementations of the action may differ, so are implemented in doEditEvent
    async doEditEvent(eventRecord, resourceRecord, element) {
        const
            me             = this,
            { client }     = me,
            { eventStore } = client,
            { startDate, endDate } = eventRecord;
        // eventSource is the descendant view that is being interacted with.
        // See Calendar#onViewCatchAll
        // activeSubView is the lowest level active view in the currently visible view in the
        // card layout of the viewContainer.
        // eventSource might be a descendant of the activeView.
        let view       = client.eventSource || client.activeSubView;
        // If we get this event from a Scheduler view, use superclass's edit process.
        if (view.isScheduler) {
            me.client = view;
            await super.doEditEvent(...arguments);
            me.client = client;
            return;
        }
        // If we get this event from a non-Calendar view, or the view is cell editing, ignore it.
        if (!view.isCalendarMixin || view.features?.cellEdit?.isEditing) {
            return;
        }
        if (eventRecord.startDate && eventRecord.endDate &&
            !DateHelper.intersectSpans(
                startDate,
                endDate,
                view.startDate,
                view.endDate)) {
            client.date = eventRecord.startDate;
        }
        // If we've created in a DayView, and we are editing an inter day event, the responsible
        // view is the DayView's alldayEvents row **if there is one, and it's being used**.
        if (view.allDayEvents && view.isAllDayEvent(eventRecord) && view.showAllDayHeader) {
            view = view.allDayEvents;
        }
        // Occurrences are *never* added to the eventStore, but any other event being edited,
        // make sure it's in and set the `isCreating` flag to show the Cancel UI.
        if (!eventRecord.isOccurrence) {
            let added;
            if (!eventStore.includes(eventRecord)) {
                eventStore.add(eventRecord);
                added = true;
            }
            if (added || eventRecord._dragCreated) {
                // We need the view to contain the event's element, not the drag-create proxy.
                // So wait for refresh for a max of 100ms, if the refresh has not triggered, force the issue.
                await view.afterRefresh({ forceRefresh : true });
            }
        }
        // Special handling if editing from an overflow popup
        const
            { _overflowPopup } = view,
            activeDate         = view.getDateFromElement(element);
        // Get the element for the date into which the element was rendered.
        // AgendaView can render multiple elements for day-spanning events
        // so we must attach to the correct one.
        let fromOverflow = _overflowPopup?.isVisible,
            eventElement = fromOverflow ? _overflowPopup.getEventElement(eventRecord) : view.getEventElement(eventRecord, activeDate);
        if (fromOverflow) {
            if (eventElement) {
                fromOverflow = false;
            }
            else {
                eventElement = _overflowPopup.getEventElement(eventRecord);
            }
        }
        // Call Calendar template method
        if (eventRecord.isCreating) {
            client.onEventCreated?.(eventRecord);
        }
        // If called from eventlist
        if (!eventElement && view.isEventList) {
            eventElement = view?.rowManager?.getRowFor(eventRecord)?.element;
        }
        // This flag is meant to prevent scrolling the element into the view if we dragcreate event and scroll the
        // view in the process. If we're creating event, then check if event was already added. If event was not
        // created allow scrolling - we haven't dragcreated event and should try to scroll to the element
        const scrollingAllowed = (eventElement && !DomHelper.isInView(eventElement)) || !eventRecord.isCreating || !eventRecord._dragCreated;
        // If editing triggered from overflow popup, ensure that the eventElement is focused.
        if (fromOverflow) {
            eventElement.focus();
        }
        else if (eventElement) {
            if (scrollingAllowed) {
                // If we found a rendered element, ensure the top 100 px and full width is visible in all
                // scroll viewports.
                // Sometimes there are two encapsulating scroll elements, one for horizontal
                // and one for vertical. For example ResourceView with multiple dayViews.
                // Static scrollIntoView searches all ancestors.
                await Scroller.scrollIntoView(eventElement, {
                    animate   : true,
                    focus     : true,
                    maxHeight : 100
                });
            }
            else {
                eventElement.focus({ preventScroll : true });
            }
        }
        // If no element found, then we have to ask the view to navigate in time to encompass that event
        else {
            // If it's a single day event on a hidden, non-working day, show it centered.
            // align : { target : true } means centered.
            if (!view.isAllDayEvent(eventRecord) && (!startDate || view.hiddenNonWorkingDays[startDate.getDay()])) {
                me.internalShowEditor(eventRecord, null, {
                    target : true
                });
                return;
            }
            if (startDate && scrollingAllowed) {
                // Ensure we have an in-view element to align to - data change response is async.
                // scrollTo refreshes if the element is not present
                await view.scrollTo(eventRecord);
            }
            // scrollTo might have to refresh to create the target.
            eventElement = view.getEventElement(eventRecord);
        }
        // If event element is missing and we render days (month, year), try to align editor to the date
        if (!eventElement && !element && view.isDayCellRenderer) {
            eventElement = view.getCell(eventRecord.startDate);
        }
        me.internalShowEditor(eventRecord, null, {
            target         : eventElement || element,
            allowTargetOut : view.isAnimating
        });
        // Only on first show do we ignore clipping.
        // If target is scrolled out of view, editor must hide.
        if (me.editor?.lastAlignSpec) {
            me.editor.lastAlignSpec.allowTargetOut = false;
        }
    }
    async onSaveClick() {
        const
            me              = this,
            { eventRecord } = me,
            { isCreating }  = eventRecord.meta;
        if (me.isValid) {
            eventRecord.isCreating = false;
        }
        // So that resetting editing context doesn't remove the record
        const saved = await super.onSaveClick(...arguments);
        if (isCreating && saved) {
            me.eventStore.added.add(eventRecord);
            if (!me.eventStore.usesSingleAssignment) {
                me.assignmentStore.added.add(eventRecord.assignments);
            }
            if (me.client.crudManager?.autoSync) {
                me.client.crudManager.scheduleAutoSync();
            }
        }
    }
    internalShowEditor() {
        const
            {
                client,
                editor,
                startTimeField,
                endTimeField
            }                 = this,
            calendar          = client.closest(isCalendar) || client,
            autoCreate        = calendar.activeView?.autoCreate ?? (calendar.autoCreate || client.constructor.configurable.autoCreate),
            step              = autoCreate?.step,
            { activeSubView } = calendar,
            _overflowPopup    = activeSubView?._overflowPopup;
        // In case the tooltip is visible, we must hide it.
        // This reverts focus so that we pick up focusIn from the event.
        (client.isSchedulerBase ? client.calendar : client).features.eventTooltip?._tooltip?.hide();
        // Always hook the closest owning widget as our owner because there may be intervening
        // focused widgets between the Calendar (our default), and us.
        // For example, the all day row of a DayView may show an OverflowPopup. We have
        // to be owned by the OverflowPopup so that our owner chain is correct.
        editor.owner = _overflowPopup?.isVisible ? _overflowPopup : activeSubView;
        if (super.internalShowEditor(...arguments) === false) {
            // Editing was vetoed
            editor.owner = editor.initialConfig.owner;
            return;
        }
        if (step) {
            startTimeField && (startTimeField.step = step);
            endTimeField && (endTimeField.step = step);
        }
    }
    // calendar drag/auto create is different enough from scheduler's, so we just override this method
    // to empty function to opt-out of the STM capture mechanism
    async finalizeStmCapture(saved) {
    }
}
// Register this feature type with its Factory
CalendarFeature.register(EventEdit.type, EventEdit);
EventEdit._$name = 'EventEdit';