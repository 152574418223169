import Fencible from '../../Core/mixin/Fencible.js';
import Button from '../../Core/widget/Button.js';
import ButtonGroup from '../../Core/widget/ButtonGroup.js';
import MenuItem from '../../Core/widget/MenuItem.js';
import Container from '../../Core/widget/Container.js';
import Minifiable from '../../Core/widget/mixin/Minifiable.js';
import Responsive from '../../Core/widget/mixin/Responsive.js';
import '../../Core/widget/layout/Box.js';
/**
 * @module Calendar/widget/ModeSelector
 */
/**
 * Displays the set of calendar modes. The modes can be displayed as a {@link Core.widget.ButtonGroup} or a single
 * {@link Core.widget.Button} with a {@link Core.widget.Button#config-menu}. Which presentation is used depends on
 * the {@link #config-minified} config property. By default, this is set
 * {@link Core.widget.mixin.Responsive#config-responsive responsively} in order to present the best UI for different
 * screen sizes.
 *
 * @extends Core/widget/Container
 * @classtype calendarModeSelector
 * @widget
 */
export default class ModeSelector extends Container.mixin(Fencible, Minifiable, Responsive) {
    //region Config
    static $name = 'ModeSelector';
    static type = 'calendarmodeselector';
    static configurable = {
        // Since Minifiable is @internal for now
        /**
         * Set to `false` to prevent this widget from assuming is {@link #config-minified} form automatically (for
         * example, due to {@link Core.widget.Toolbar#config-overflow} handling.
         *
         * When this value is `true` (the default), the minifiable widget's {@link #config-minified} config may be
         * set to `true` to reduce toolbar overflow.
         *
         * @config {Boolean}
         * @default
         */
        minifiable : true,
        /**
         * The config property that will be used as the readable title for each mode.
         * @config {String}
         * @default
         */
        titleProperty : 'title',
        /**
         * Set to `true` to present this widget in its minimal form.
         * @config {Boolean}
         * @default false
         */
        minified : null,
        /**
         * The button or button config object that will display a popup menu to select the calendar mode.
         * @config {Core.widget.Button}
         */
        button : {
            type              : 'button',
            icon              : 'b-icon-calendar-days',  // avoid b-icon-menu since toolbar overflow also uses that icon
            cls               : 'b-calendar-mode-button',
            menuIcon          : null,
            menu              : [],
            internalListeners : {
                beforeShowMenu : 'up.onBeforeFirstShowModeMenu',
                once           : true
            }
        },
        /**
         * The button group or button group config object that will display the calendar modes, one per button.
         * @config {Core.widget.ButtonGroup}
         */
        buttonBar : {
            type                 : 'buttongroup',
            ignoreParentReadOnly : true
        },
        calendar : {
            value   : 'calendar',
            $config : 'lazy'
        },
        includeWeekendsButton : {
            $config : 'lazy',
            value : {
                type        : 'button',
                cls         : 'b-calendar-fullweek-button',
                toggleGroup : `no`,  // so ButtonGroup does not take over
                text        : 'L{ModeSelector.weekends}',
                tooltip     : 'L{ModeSelector.includeWeekends}',
                onToggle    : 'up.onToggleWorkingDaysButton',
                weight      : -1
            }
        },
        includeWeekendsMenuItem : {
            $config : 'lazy',
            value : {
                separator : true,
                text      : 'L{ModeSelector.includeWeekends}',
                checked   : false,
                onToggle  : 'up.onToggleWorkingDaysMenu',
                weight    : 10
            }
        },
        layout : {
            type : 'box',
            wrap : false
        },
        overflowable : 'none',
        responsive : {
            large : {
                // Allow toolbar overflow to kick us into minified state
                minifiable : true,
                minified   : null
            },
            '*' : {
                // Don't allow toolbar overflow to kick us out of minified state
                minifiable : false,
                minified   : true
            }
        },
        responsiveTarget : '@calendar'
    };
    static fenced = {
        onToggleButton   : true,
        onToggleMenuItem : true,
        syncFullWeek     : true,
        syncActiveMode   : {
            all  : 'syncActiveMode',
            lock : ['onToggleButton', 'onToggleMenuItem', 'syncActiveMode']
        }
    };
    get hideNonWorkingDays() {
        return this.calendar?.hideNonWorkingDays;
    }
    set hideNonWorkingDays(value) {
        const { calendar } = this;
        // If the current value of calendar.hideNonWorkingDays is null, it is acting as false, so don't bother to
        // set it if value is also false. If we do, that would push the value down to each mode which would mask a
        // value that may be set directly on the child view.
        if (calendar && !(!value && calendar.hideNonWorkingDays == null)) {
            calendar.hideNonWorkingDays = value;
        }
    }
    changeIncludeWeekendsButton(config, existing) {
        const
            me = this,
            includeWeekendsButton = config && me.calendar?.includeWeekendsButton;
        if (includeWeekendsButton) {
            // Calendar may have false/null for its config but we ignore such values. In that case, the button will be
            // hidden by CSS rules (not destroyed)
            config = Button.mergeConfigs(config, includeWeekendsButton);
        }
        if (config && !existing) {
            return me.add(config);
        }
        return Button.reconfigure(existing, config, /* owner = */me);
    }
    updateIncludeWeekendsButton(includeWeekendsButton) {
        if (includeWeekendsButton) {
            includeWeekendsButton.toggleGroup = null;
        }
        this.syncFullWeek();
    }
    changeIncludeWeekendsMenuItem(config, existing) {
        const menu = this?.button?.menu;
        if (config && !existing) {
            return menu?.add(config);
        }
        return MenuItem.reconfigure(existing, config, /* owner = */menu);
    }
    updateIncludeWeekendsMenuItem() {
        this.syncFullWeek();
    }
    onBeforeFirstShowModeMenu({ menu }) {
        const
            me             = this,
            { calendar }   = me,
            { activeView } = calendar,
            modes          = Object.values(calendar.modes);
        let menuItem, mode;
        for (mode of modes) {
            menuItem = mode.$modeMenuItem;
            menu.add(menuItem);
            // We cannot set the check state until after adding to the menu or else we will fail to find the correct
            // rootElement and toggle group
            menuItem.checked = mode === activeView;
            // Wire up handler after setting "checked" (we don't want to be called as we set things up)
            menuItem.onToggle = 'up.onToggleMenuItem';
        }
        me.getConfig('includeWeekendsMenuItem');
        me.syncFullWeek();
        me.menuReady = true;
    }
    addMode(name, view, options) {
        const
            me = this,
            { buttonBar } = me,
            text = view[me.titleProperty] || view.displayName || view.type,
            // We cannot add items to the menu at this time because the menu will not be assigned to a rootElement
            // as yet which is problematic for floated widgets. So, instead we create the MenuItem widgets and attach
            // them to their view and add them to the button's menu just before it is shown.
            //  NOPE - menuItem = me.button.menu.add(MenuItem.mergeConfigs({
            menuItem = view.$modeMenuItem = MenuItem.reconfigure(view.$modeMenuItem, MenuItem.mergeConfigs({
                type        : MenuItem,
                view, // Button carries a reference to the view it shows
                ref         : `${name}ShowMenuItem`,
                checked     : false,
                closeParent : true,
                toggleGroup : `${me.id}-modeSelectorMenuItem`,
                localizable : false,
                text,
                weight      : 0
            }, options)),
            button = buttonBar.add(Button.mergeConfigs({
                view, // Button carries a reference to the view it shows
                ref            : `${name}ShowButton`,
                toggleGroup    : `${me.id}-modeSelectorButton`,
                text,
                localizable    : false,
                onBeforeToggle : 'up.onBeforeToggleButton',
                onToggle       : 'up.onToggleButton',
                weight         : 0
            }, options));
        me.getConfig('includeWeekendsButton');
        view.ion({
            thisObj : me,
            localized() {
                button.text = view.displayName;
                menuItem.text = view.displayName;
            }
        });
    }
    onBeforeToggleButton({ pressed }) {
        // Effectively disable button toggling during animated card change
        if (pressed && this.calendar.viewContainer.layout.isChangingCard) {
            return false;
        }
    }
    onToggleButton({ source, pressed }) {
        if (pressed) {
            this.calendar.mode = source.view;
        }
    }
    updateDisabled(disabled) {
        // When disabling, disable all buttons except the one that just got pressed - the focused one.
        this.buttonBar.items.forEach(button => {
            if (!disabled || !button.containsFocus) {
                button.disabled = disabled;
            }
        });
    }
    onToggleMenuItem({ source, checked }) {
        if (checked) {
            this.calendar.mode = source.view;
        }
    }
    onToggleWorkingDaysButton({ pressed }) {
        this.hideNonWorkingDays = !pressed;
    }
    onToggleWorkingDaysMenu({ checked }) {
        this.hideNonWorkingDays = !checked;
    }
    syncActiveMode(activeView) {
        const
            me = this,
            buttons = me.buttonBar.items;
        let item;
        me.button.text = activeView.displayName;
        me.includeWeekendsButton.hidden = !activeView.hasNonWorkingDays;
        me.syncFullWeek();
        // These loops would call onToggleButton/MenuItem if it weren't for Fencible
        for (item of buttons) {
            if (item.view) {
                item.pressed = item.view === activeView;
            }
        }
        if (me.menuReady) {
            for (item of me.button.menu.items) {
                if (item.view?.$modeMenuItem === item) { // if (a calendar mode item)
                    item.checked = item.view === activeView;
                }
            }
        }
        // Don't show the mode selector if there's only one view
        me[buttons.length > 1 ? 'show' : 'hide']();
    }
    syncFullWeek() {
        const
            {
                calendar,
                hideNonWorkingDays,
                _includeWeekendsButton   : includeWeekendsButton,
                _includeWeekendsMenuItem : includeWeekendsMenuItem
            } = this,
            fullWeek = !hideNonWorkingDays;
        if (!calendar.isConfiguring) {
            if (includeWeekendsButton) {
                includeWeekendsButton.pressed = fullWeek;
            }
            if (includeWeekendsMenuItem) {
                includeWeekendsMenuItem.checked = fullWeek;
            }
        }
    }
    changeButton(config, existing) {
        return Button.reconfigure(existing, config);
    }
    updateButton(button) {
        button && this.add(button);
    }
    changeButtonBar(config, existing) {
        return ButtonGroup.reconfigure(existing, config);
    }
    updateButtonBar(buttonBar) {
        buttonBar && this.add(buttonBar);
    }
    changeCalendar(calendar) {
        if (calendar && !calendar.isWidget) {
            calendar = this.up(calendar);
        }
        return calendar;
    }
    updateCalendar(calendar) {
        this.detachListeners('cal');
        this.syncFullWeek();
        calendar?.ion({
            name                     : 'cal',
            changeHideNonWorkingDays : 'syncFullWeek',
            thisObj                  : this
        });
    }
}
// Register this feature type with its Factory
ModeSelector.initClass();
ModeSelector._$name = 'ModeSelector';