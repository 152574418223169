import DomHelper from '../../Core/helper/DomHelper.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import CalendarFeature from './CalendarFeature.js';
import CalendarZone from './CalendarZone.js';
import LayoutDim from '../layout/LayoutDim.js';
import AttachToProjectMixin from '../../Scheduler/data/mixin/AttachToProjectMixin.js';
/**
 * @module Calendar/feature/TimeRanges
 */
/**
 * A mutable object used to render an element of the time range.
 *
 * @typedef {Object} TimeRangeRenderData
 * @property {Calendar.model.TimeRangeModel} record The record being rendered
 * @property {String} color The color to be applied to the element
 * @property {Object} cls An object whose truthy property names will be added to the element's CSS `classList`
 * @property {Object} style An object containing style properties for the element
 * @property {TimeRangeRenderData} [outer] The render data for the outermost element. This property is present when
 * rendering any of the inner elements. The outer element is rendered after all inner elements, meaning this object
 * can be modified by an inner element renderer function.
 * @property {TimeRangeRenderData} [header] The render data for the header element. This property is present when
 * rendering the outermost element. The corresponding element has already been rendered, meaning that this object
 * should be considered read only.
 * @property {TimeRangeRenderData} [body] The render data for the body element. This property is present when
 * rendering the outermost element. The corresponding element has already been rendered, meaning that this object
 * should be considered read only.
 * @property {TimeRangeRenderData} [footer] The render data for the footer element. This property is present when
 * rendering the outermost element. The corresponding element has already been rendered, meaning that this object
 * should be considered read only.
 */
/**
 * The object passed to a {@link Calendar.feature.TimeRanges#config-renderer} function.
 * @typedef {Object} TimeRangeRenderInfo
 * @property {TimeRangeRenderData} renderData The render data object to modify
 * @property {Calendar.model.TimeRangeModel} timeRange The record being rendered
 * @property {DomConfig} [domConfig] The default DOM config. This is only passed to the `outer` renderer and represents
 * the DOM config that will be used for the element. The `className` and `style` properties are applied after the
 * renderer returns.
 */
/**
 * An object containing rendering methods for the various elements of a time range. All functions are optional. The
 * `footer` function is special in that there is no footer element by default. If a footer is desired, a `footer`
 * renderer function must be provided.
 *
 * @typedef {Object} TimeRangeRenderer
 * @property {Function} [outer] An optional function to be called to render the outermost element. This function is
 * passed a {@link Calendar.feature.TimeRanges#typedef-TimeRangeRenderInfo} object.
 * @property {Function} [body] An optional function to be called to render the body element. This function is passed
 * a {@link Calendar.feature.TimeRanges#typedef-TimeRangeRenderInfo} object.
 * @property {Function} [header] An optional function to be called to render the header element. This function is passed
 * a {@link Calendar.feature.TimeRanges#typedef-TimeRangeRenderInfo} object.
 * @property {Function} [footer] An optional function to be called to render the footer element. This function is passed
 * a {@link Calendar.feature.TimeRanges#typedef-TimeRangeRenderInfo} object.
 */
const
    genericRenderer = renderData => ({
        className : renderData.cls,
        style     : renderData.style
    }),
    defaultRotation = {
        end   : 'pos',
        start : 'neg'
    },
    oppositeAlign = {
        end   : 'start',
        start : 'end'
    },
    valueFields = Object.entries({
        alignment : 'alignment',
        color     : 'color',
        footer    : 'footer',
        header    : 'name',
        rotation  : 'rotation'
    });
/*
    This class manages rendering time ranges in a Day/WeekView. Instances of this class are created by the
    CalendarFeature via zoneTypes map.
 */
class DayZone extends CalendarZone {
    static $name = 'DayZone';
    static configurable = {
        viewListeners : {
            beforeLayoutEvents : 'onBeforeLayoutEvents'
            // renderEvents       : 'onRenderEvents'
        }
    };
    onBeforeLayoutEvents({ source, context }) {
        // This event is fired by DayView.renderEvents
        // We hook into that event which fires for each day even if there are no events so we can decorate the day
        // with appropriate time ranges
        const ranges = source.getTimeRanges(context.cellData.date, context.cellData.tomorrow);
        if (ranges.length) {
            const
                { layout } = context,
                footers = {},
                headers = {},
                clusters = [],
                items    = [];
            let children, item;
            context.timeRangeHeaders = headers;
            context.timeRanges = ranges;
            for (const timeRange of ranges) {
                item = layout.createLayoutItem(timeRange, context);
                item.values = Object.fromEntries(valueFields.map(([name, fieldName]) => [name, timeRange[fieldName]]));
                item.values.rotation = item.values.rotation || defaultRotation[item.values.alignment];
                layout.clusterize(clusters, items, item, context);
                children = context.dayDomConfig.children.inset;
                children = children.children || (children.children = []);
                children.push(this.renderTimeSpan(context, item, headers, footers));
            }
            if (headers.end || footers.end) {
                context.dayDomConfig.className['b-dayview-inset-after'] = 1;
            }
            if (headers.start || footers.start) {
                context.dayDomConfig.className['b-dayview-inset-before'] = 1;
            }
        }
    }
    renderPart(defaultRenderer, renderer, renderData, part, headerFooter, align, extraData) {
        const
            isFooter = part === 'footer',
            { record } = renderData,
            renderInfo = {
                timeRange : record
            };
        let data = renderData,
            domConfig, extraDom, value;
        if (typeof part === 'string') {
            renderData[part] = data = ObjectHelper.merge({
                part,
                record,
                outer : renderData,
                cls   : {
                    [`b-cal-timerange-${part}`] : 1
                }
            }, extraData);
            value = renderData.values[part];
            if (value != null) {
                renderInfo.value = data.value = value;
            }
        }
        else {
            extraDom = part;
            part = 'outer';
        }
        defaultRenderer = defaultRenderer?.[part];
        renderer = renderer?.[part];
        renderInfo.renderData = data;
        renderInfo.domConfig  = extraDom;
        if (renderer) {
            data.style = {};
            const ret = renderer(renderInfo);
            if (typeof ret === 'string') {
                extraDom = Object.assign({ html : ret }, extraDom);
            }
            else if (ObjectHelper.isObject(ret)) {
                domConfig = ret;
            }
        }
        domConfig = domConfig || defaultRenderer?.(renderInfo);
        if (extraDom) {
            domConfig = ObjectHelper.merge(domConfig || {}, extraDom);
        }
        if (headerFooter && domConfig) {
            align = (isFooter && oppositeAlign[align]) || align;
            headerFooter[align] = (headerFooter[align] || 0) + 1;
        }
        data.domConfig = domConfig;
        return domConfig;
    }
    renderTimeSpan(context, item, headers, footers) {
        const
            me = this,
            { owner } = me,  // our feature
            { defaultRenderer, renderer } = owner,
            timeRange = item.eventRecord,
            sizeSeconds = isNaN(item.end) ? 0 : Math.abs(item.end - item.start), // seconds
            isRange = sizeSeconds > 1,
            isLine = !isRange,
            { rtl } = context.layout.owner,
            { values } = item,
            { alignment : align, color, rotation } = values,
            renderData = {
                color,
                values,
                record        : timeRange,
                layoutContext : context,
                cls           : {
                    'b-readonly'                           : timeRange.readOnly,
                    'b-rtl'                                : rtl,
                    'b-cal-timerange'                      : 1,
                    'b-cal-timerange-line'                 : isLine,
                    'b-cal-timerange-narrow'               : isRange && sizeSeconds <= owner.narrowThreshold * 60,
                    [`b-cal-timerange-align-${align}`]     : align,
                    [`b-cal-timerange-rotate-${rotation}`] : isRange && rotation
                }
            };
        // This minor fudge allows the background grid lines to show through (better aesthetically)
        isRange && item.height.adjust(0, -1);
        const styles = item.getStyles(rtl);
        return DomHelper.normalizeChildren(me.renderPart(defaultRenderer, renderer, renderData, {
            dataset : {
                'timerange-id' : timeRange.id,
                btip           : isLine && values.header || null
            },
            className : {
                [timeRange.cls] : timeRange.cls
            },
            elementData : {
                timeRange
            },
            style : {
                top    : styles.top,
                height : styles.height
            },
            children : isRange && {
                header : me.renderPart(defaultRenderer, renderer, renderData, 'header', headers, align, {
                    cls : {
                        [timeRange.iconCls] : timeRange.iconCls
                    }
                }),
                body   : me.renderPart(defaultRenderer, renderer, renderData, 'body'),
                footer : me.renderPart(defaultRenderer, renderer, renderData, 'footer', footers, align)
            }
        }));
    }
}
/**
 * This feature provides an easy way to highlight ranges of time in a calendar's day and week views. Each time range is
 * represented using the {@link Calendar.model.TimeRangeModel}.
 *
 * {@inlineexample Calendar/feature/TimeRanges.js}
 *
 * Time ranges can take a few different forms:
 *
 * - A line at the {@link Calendar.model.TimeRangeModel#field-startDate} with optional tooltip based on the
 *  {@link Calendar.model.TimeRangeModel#field-name}.
 * - A styled region between the {@link Calendar.model.TimeRangeModel#field-startDate} and
 *   {@link Calendar.model.TimeRangeModel#field-endDate}. The {@link Calendar.model.TimeRangeModel#field-cls} field is
 *   used to apply the desired style to the time range element.
 * - A titled region based on the {@link Calendar.model.TimeRangeModel#field-name} field, between the
 *   {@link Calendar.model.TimeRangeModel#field-startDate} and {@link Calendar.model.TimeRangeModel#field-endDate}. The
 *   {@link Calendar.model.TimeRangeModel#field-cls} field can be used to apply the styling to the time range element.
 *   The {@link Calendar.model.TimeRangeModel#field-color} and {@link Calendar.model.TimeRangeModel#field-iconCls}
 *   fields can be used to apply a background color and icon to the header element. An optional
 *   {@link Calendar.model.TimeRangeModel#field-footer} can also be added.
 *
 * ## ResourceTimeRanges
 *
 * If `resourceTimeRanges` are included in the loaded data, the results are only applied to views
 * which display that resource. This means a {@link Calendar.widget.DayResourceView} or
 * subviews of a {@link Calendar.widget.ResourceView}.
 *
 * Be sure to see additional examples on Mar 4 (Wed) in the Live Demo.
 *
 * This feature is **disabled** by default.
 *
 * @demo Calendar/timeranges
 * @extends Scheduler/feature/TimeRanges
 * @classtype timeRanges
 * @feature
 *
 * @typings Scheduler.feature.TimeRanges -> Scheduler.feature.SchedulerTimeRanges
 */
export default class TimeRanges extends CalendarFeature.mixin(AttachToProjectMixin) {
    static $name = 'TimeRanges';
    static type = 'timeRanges';
    static configurable = {
        defaultRenderer : {
            outer({ renderData }) {
                const
                    ret = genericRenderer(renderData),
                    { color } = renderData;
                if (color) {
                    if (DomHelper.isNamedColor(color)) {
                        ret.className[`b-cal-color-${color}`] = 1;
                    }
                    else {
                        // Background color is in a pseudo element whose styles
                        // come from CSS vars, so set the var locally to the element.
                        (ret.style || (ret.style = {}))['--timerange-color'] = color;
                    }
                }
                ret.className['b-cal-timerange-has-header'] = renderData.header?.domConfig;
                return ret;
            },
            body : ({ renderData }) => genericRenderer(renderData),
            header : ({ renderData, value }) => value ? {
                ...genericRenderer(renderData),
                children : [{
                    className : {
                        'b-cal-timerange-header-text' : 1
                    },
                    text : value
                }]
            } : null,
            footer : ({ renderData, value }) => value ? {
                ...genericRenderer(renderData),
                children : [{
                    className : {
                        'b-cal-timerange-footer-text' : 1
                    },
                    text : value
                }]
            } : null
        },
        /**
         * The number of pixels or proportion of the overall width to allocate for time range headers.
         *
         * Values less than 1 are the fractional proportion of the width (for example, 0.04 is 4% of the width),
         * while values greater than or equal to 1 are a number of pixels.
         * @config {Number}
         * @default
         */
        headerWidth : 40,
        narrowThreshold : 60,
        /**
         * An empty function by default, but provided so that you can override it.
         *
         * This function is called each time a time range is rendered to allow developers to mutate the element metadata,
         * or the CSS classes to be applied to the rendered element.
         *
         * It's called with a {@link #typedef-TimeRangeRenderInfo} object containing the time span record, and a
         * {@link #typedef-TimeRangeRenderData renderData} object which allows you to mutate event metadata such as
         * `cls` and `style`.
         *
         * A non-null return value from the renderer is used as the element body content. A nullish return value results
         * in the default renderer for the element.
         *
         * ```javascript
         *  timeRanges : {
         *      renderer ({ timeRange, renderData }) {
         *          if (timeRange.name === 'Doctors appointment') {
         *              renderData.style.fontWeight = 'bold';
         *              renderData.cls['custom-cls'] = 1;
         *
         *              return 'Special doctors appointment';
         *          }
         *      }
         *  }
         * ```
         * <div class="note">When returning content, be sure to consider how that content should be encoded to avoid XSS
         * (Cross-Site Scripting) attacks. This is especially important when including user-controlled data such as
         * the event's `name`. The function {@link Core.helper.StringHelper#function-encodeHtml-static} as well as
         * {@link Core.helper.StringHelper#function-xss-static} can be helpful in these cases.</div>
         *
         * For example:
         * ```javascript
         *  timeRanges : {
         *      renderer ({ timeRange, renderData }) {
         *          return StringHelper.xss`Special ${timeRange.name}`;
         *      }
         *  }
         * ```
         *
         * For advanced rendering, this config can be a {@link #typedef-TimeRangeRenderer} object with rendering
         * functions for individual elements: `header`, `body`, `footer`, and `outer`. When a function is provided,
         * that is equivalent to passing the `header` renderer. In other words, the above example is equivalent to
         * the following:
         *
         * ```javascript
         *  timeRanges : {
         *      renderer : {
         *          header({ timeRange, renderData }) {
         *              return StringHelper.xss`Special ${timeRange.name}`;
         *          }
         *      }
         *  }
         * ```
         *
         * @config {Function|TimeRangeRenderer} renderer
         * @param {TimeRangeRenderInfo} info An object that contains data about the time span being rendered.
         * @returns {String}
         * @default
         */
        renderer : null,
        zoneTypes : {
            day      : DayZone,  // also covers WeekView
            resource : DayZone
        }
    };
    attachToProject(project) {
        super.attachToProject(project);
        this.detachListeners('project');
        project.timeRangeStore?.ion({
            name    : 'project',
            change  : 'refresh',
            thisObj : this
        });
        project.resourceTimeRangeStore?.ion({
            name    : 'project',
            change  : 'refresh',
            thisObj : this
        });
    }
    changeRenderer(renderer) {
        if (typeof renderer === 'function') {
            renderer = {
                header : renderer
            };
        }
        return renderer;
    }
    updateHeaderWidth(width) {
        const el = this.owner?.element;
        if (el) {
            el.style.setProperty('--timerange-header-width', LayoutDim.from(width)?.toString());
        }
    }
    refresh() {
        this.client.refresh();
    }
}
// Register this feature type with its Factory
CalendarFeature.register(TimeRanges.type, TimeRanges);
TimeRanges._$name = 'TimeRanges';