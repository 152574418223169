import Base from '../../../Core/Base.js';
import Config from '../../../Core/Config.js';
import Featureable from '../../../Core/mixin/Featureable.js';
import StringHelper from '../../../Core/helper/StringHelper.js';
import DomHelper from '../../../Core/helper/DomHelper.js';
import EventHelper from '../../../Core/helper/EventHelper.js';
import ObjectHelper from '../../../Core/helper/ObjectHelper.js';
import Responsive from '../../../Core/widget/mixin/Responsive.js';
import Rectangle from '../../../Core/helper/util/Rectangle.js';
import DH from '../../../Core/helper/DateHelper.js';
import CalendarFeature from '../../feature/CalendarFeature.js';
import CalendarStores from '../../mixin/CalendarStores.js';
import SchedulerInterface from '../../mixin/SchedulerInterface.js';
import EventRenderer from './EventRenderer.js';
import AvatarRendering from '../../../Core/widget/util/AvatarRendering.js';
import Describable from '../../../Scheduler/view/mixin/Describable.js';
// This Mixin is the basis of the Calendar package, so it must load the default locale
import '../../localization/En.js';
import EventSorter from '../../util/EventSorter.js';
/**
 * @module Calendar/widget/mixin/CalendarMixin
 */
const
    immediatePromise       = Promise.resolve(),
    emptyObject            = Object.freeze({}),
    { eventNameMap }       = EventHelper,
    isFocusedCalendarMixin = w => w.isCalendarMixin && w.containsFocus;
/**
 * Mixin that provides common handling methods and configs for Calendar widgets.
 *
 * This mixin also brings in the {@link Core.mixin.Featureable} mixin.
 *
 * @mixin
 * @mixes Scheduler/view/mixin/Describable
 * @mixes Calendar/widget/mixin/EventRenderer
 */
export default Target => class CalendarMixin extends (Target || Base).mixin(
    Describable,
    SchedulerInterface,
    Featureable,
    CalendarStores,
    EventRenderer,
    Responsive
) {
    static $name = 'CalendarMixin';
    static get configurable() {
        return {
            //region Hidden configs
            /**
             * @event eventSelectionChange
             * @hide
             */
            /**
             * @hideconfigs htmlCls, autoUpdateRecord, record, textContent, content, html
             */
            /**
             * @hideproperties  content, html
             */
            /**
             * A String which describes how much the {@link #function-next} and {@link #function-previous}
             * methods will move this view forwards or backwards in time.
             *
             * This is used to create the tooltip hints for the `nextButton` and `prevButton` in the
             * {@link Calendar.view.Calendar#property-tbar Calendar's toolbar}. If this property
             * is not defined, the `nextButton` and `prevButton` will be disabled when this view is active.
             *
             * Note that {@link Calendar.widget.WeekView} and {@link Calendar.widget.YearView} use a localized
             * string property to yield this value. Other view types implement a `get stepUnit` getter because
             * their step increments are variable.
             * @member {String} stepUnit
             * @readonly
             */
            //endregion
            localizableProperties : [
                'autoCreate.newName', 'timeFormat', 'shortDateFormat', 'shortDateTimeFormat'
            ],
            eventStore : null,
            resourceStore : null,
            /**
             * A function which compares events which some views use to decide upon rendering order.
             *
             * Default sorter function are provided from the {@link Calendar.util.EventSorter} class.
             *
             * A custom sort function may be configured.
             *
             * Note that the two objects to compare may either be {@link Scheduler.model.EventModel}s
             * or {@link EventBar}s which contain an `eventRecord` property which is the {@link Scheduler.model.EventModel}.
             * @config {Function}
             * @param {Scheduler.model.EventModel|EventBar} lhs The left side value to conpare
             * @param {Scheduler.model.EventModel|EventBar} rhs The right side value to conpare
             * @returns {Number}
             */
            eventSorter : EventSorter.defaultSorterFn,
            responsive : {},  // brand as responsive so b-responsive-xxx CSS classes get added
            /**
             * Configure as `true` to hide {@link #config-nonWorkingDays}
             * @config {Boolean}
             */
            hideNonWorkingDays : null,
            hideNonWorkingDaysCls : 'b-hide-nonworking-days',
            /**
             * The week start day, 0 meaning Sunday, 6 meaning Saturday.
             * Defaults to {@link Core.helper.DateHelper#property-weekStartDay-static}.
             * @config {Number}
             */
            weekStartDay : DH.weekStartDay,
            /**
             * Non-working days as an object where keys are day indices, 0-6 (Sunday-Saturday), and the value is `true`.
             * Defaults to {@link Core.helper.DateHelper#property-nonWorkingDays-static}.
             * @config {Object<Number,Boolean>}
             */
            nonWorkingDays : {
                value : DH.nonWorkingDays,
                $config : {
                    merge : 'replace'
                }
            },
            /**
             * The class name to add to calendar cells which are non working days.
             * @config {String}
             * @private
             */
            nonWorkingDayCls : 'b-nonworking-day',
            /**
             * The class name to add to calendar cells.
             * @member {String} dayCellCls
             */
            /**
             * The class name to add to calendar cells.
             * @config {String}
             * @private
             */
            dayCellCls : 'b-calendar-cell',
            /**
             * The class name to add to calendar cells which are weekend days.
             * @config {String}
             * @private
             */
            weekendCls : 'b-weekend',
            todayCls : 'b-today',
            pastEventCls : 'b-past-event',
            /**
             * The class name to add to events which have duration less than or equal to
             * {@link #config-shortEventDuration}.
             * @config {String}
             */
            shortEventCls : 'b-short-event',
            /**
             * The duration at which below and equal to this value, an event's encapsulating element gets
             * the {@link #config-shortEventCls} added to it so that small event bars can have style rearrangements.
             *
             * In {@link Calendar.widget.DayView}s, short events have compressed layout so that the event name is
             * visible on the top line next to the start time.
             *
             * This may be a string in the format required by {@link Core.helper.DateHelper#function-parseDuration-static}.
             *
             * It may also be configured as a millisecond value.
             * @config {String|Number}
             * @default
             */
            shortEventDuration : '30 minutes',
            /**
             * The height of event bars if this view creates event bars.
             *
             * {@link Calendar.widget.MonthView MonthView}, {@link Calendar.widget.MonthView CalendarRow}
             * (the {@link Calendar.widget.DayView#config-allDayEvents all day row} in a
             * {@link Calendar.widget.WeekView WeekView}) and {@link Calendar.widget.AgendaView AgendaView}
             * use this config.
             *
             * In {@link Calendar.widget.DayView DayView} and {@link Calendar.widget.WeekView WeekView},
             * the event element's height is part of the widget's layout and signifies the event's duration,
             * so these use a default value of `'auto'`.
             * @config {Number|String}
             * @default
             */
            eventHeight : 25,
            eventSpacing : 2,
            intradayCls : 'b-intraday',
            alldayCls : 'b-allday',
            solidBarCls : 'b-solid-bar',
            dayNameSelector : '.b-day-name',    // MonthView widens this to be the whole cell header
            // if it's showing a separate week number column.
            // CalendarRow always widens this to the cell header.
            showTime : false,
            /**
             * A {@link Core.helper.DateHelper} format string used to format the time displayed in events
             *
             * @config {String}
             * @default 'LT'
             */
            timeFormat : {
                value   : 'LT',
                $config : {
                    localeKey : 'L{timeFormat}'
                }
            },
            /**
             * __Not applicable in a `DayView`__
             *
             * This specifies whether to show a circular "bullet" icon if there is no
             * {@link Scheduler.model.EventModel#field-iconCls} defined for an event.
             *
             * By default, events which are rendered as solid blocks of colour (such as all day events) do __not__
             * show the bullet icon.
             *
             * By default, events which do not show as a colour block show the bullet icon as a means of showing
             * the event's defined `eventColor`.
             *
             * This property may contain two properties which define whether to show the bullet icon for both
             * event types.
             *
             * If configured as `true`, all event bars will show a bullet icon if they do not have an
             * {@link Scheduler.model.EventModel#field-iconCls}
             * @config {Boolean|Object}
             * @param {Boolean} [bar] This is `false` by default. Set this to `true` in modes where a solid event
             * bar should show a bullet icon
             * @param {Boolean} [noBar] This is `true` by default. Events with no background colour, use this to
             * show the event's defined `eventColor`
             * @default
             */
            showBullet : {
                bar   : false,
                noBar : true
            },
            eventColourStyleProperty : 'color', // By default the event color becomes the colour
            // DayView uses backgroundColor
            handlePointerInteraction : true,
            /**
             * A {@link Core.helper.DateHelper} format string to use to create date output for
             * abbreviated view descriptions.
             * @prp {String}
             * @default 'll'
             */
            shortDateFormat : {
                value   : 'll',
                $config : {
                    localeKey : 'L{shortDateFormat}'
                }
            },
            /**
             * A title text used by the Calendar's mode selector button
             * @config {String}
             */
            title : null,
            /**
             * A {@link Core.helper.DateHelper} format string to use to create date and time output for
             * abbreviated view descriptions.
             * @prp {String}
             * @default 'll LT'
             */
            shortDateTimeFormat : {
                value   : 'll LT',
                $config : {
                    localeKey : 'L{shortDateTimeFormat}'
                }
            },
            /**
             * Configure as `true` to make the view read-only, by disabling any UIs for modifying data.
             *
             * __Note that checks MUST always also be applied at the server side.__
             * @config {Boolean}
             */
            readOnly : null,
            /**
             * If this config is set, then the `gesture` configured (which defaults to `dblclick`) creates a
             * new event at the mouse or touch event's time point.
             *
             * The exact time is rounded to the closest specified `step` value. This value is also used
             * for the {@link Core.widget.TimeField#property-step} value in the {@link Calendar.feature.EventEdit}'s
             * time input field.
             *
             * The duration of the created event is the specified `duration` value.
             *
             * If this is specified as `true`, the `gesture` becomes `dblclick`, and the other properties
             * are the default values listed below.
             *
             * If this is specified as a string, the string becomes the `gesture`, and the other properties
             * are the default values listed below.
             *
             * @prp {Object}
             * @property {String} [gesture='dblclick'] The DOM event name which initiates event creation at the event's position.
             * @property {String|Function} [newName='New Event'] The name of an event created using `autoCreate` or a function to call which yields the name.
             * @property {String} [step='15 minutes'] The time unit by which to snap the start click point of auto created events.
             * __Only for views which have a granularity of less than one day such as `WeekView` and `DayView`__.
             *
             * For views which show whole days, the start defaults to 8am.
             *
             * This is a string in the format required by {@link Core.helper.DateHelper#function-parseDuration-static}.
             *
             * This value is also used for the {@link Core.widget.TimeField#property-step} value in the
             * {@link Calendar.feature.EventEdit}'s time input field.
             *
             * @property {'round'|'ceil'|'floor'} [snapType='round'] How to snap a precise gesture time to a boundary specified by the `step` property.
             * __Only for views which have a granularity of less than one day such as `WeekView` and `DayView`__.
             * @property {String} [duration='1 hour'] The default start hour for auto created events in the form accepted by {@link Core.helper.DateHelper#function-parseDuration-static}
             * @property {Number} [startHour=8] The default start hour for auto created events
             * in views where the time granularity is one day. In a `DayView` or `WeekView` where a mouse event position
             * will translate to a time of day, this is not used.
             *
             * This is the hour of the day to start the event at. It may be fractional.
             * @accepts {Object|String|Boolean}
             * @default
             */
            autoCreate : {
                gesture   : 'dblclick',
                newName   : 'L{Object.newEvent}',
                step      : '15 minutes',
                snapType  : 'round',
                duration  : '1 hour',
                startHour : 8
            },
            /**
             * The {@link Scheduler.model.EventModel#field-durationUnit} to use when drag-creating events
             * in this view.
             *
             * For {@link Calendar.widget.DayView}s, this is normally `'hour'`, for views with a granularity
             * level of one day, the default is `'day'`.
             * @config {String}
             */
            dragUnit : 'hour',
            autoRefresh : {
                $config : {
                    merge : 'classList'
                },
                value : null
            },
            /**
             * Set to false if you don't want to allow events overlapping times for any one resource (defaults to true).
             * @config {Boolean}
             * @default
             * @private
             */
            allowOverlap : true,
            /**
             * When used as a {@link Calendar.view.Calendar#config-modes mode} of a Calendar, the
             * date will automatically be kept synced with the Calendar's
             * {@link Calendar.view.Calendar#property-date}.
             *
             * Configure this as `false` to opt out of this.
             *
             * __Note that this places the onus on the application developer to control the
             * viewed date range in this widget.__
             * @config {Boolean} syncViewDate
             * @default
             */
            syncViewDate : true,
            // For views which are Panels, make them not include a tabIndex
            focusable : false,
            // Allow an AvatarRendering instance to be specified
            avatarRendering : {
                $config : 'lazy',
                value   : null
            },
            /**
             * Configure as `true` to show avatars of the assigned resources (calendars) at the
             * start of the event bar.
             *
             * Configure as `'last'` to show avatars of the assigned resources (calendars) at the
             * end of the event bar.
             *
             * Note that the avatars are `2.22em` diameter circles, and this may not be suitable
             * for rendering in short events inside a DayView.
             *
             * In a view which renders event bars, the {@link #config-eventHeight} should be
             * increased from the default to accommodate the extra information.
             *
             * Note that you must set {@link #config-resourceImagePath} in order that the system
             * knows where to access the resource's image file from.
             *
             * If no image is set, or the image is not found, the resource's initials are shown instead.
             *
             * By default it is inherited from the owning Calendar:
             * ```javascript
             * new Calendar({
             *     resourceImagePath   : 'images/resources/'
             *     modes : {
             *         month : {
             *             showResourceAvatars : true,
             *         },
             *         week : {
             *             // Images go at the end of the body with name first
             *             showResourceAvatars : 'last,
             *         }
             *     }
             * });
             * ```
             * @config {Boolean|String}
             * @default false
             */
            showResourceAvatars : null,
            /**
             * Path to load resource images from. Used by the {@link #config-showResourceAvatars} config
             * to create URLs using the resource's
             * {@link Scheduler/model/ResourceModel#field-image} or
             * {@link Scheduler/model/ResourceModel#field-imageUrl} fields:
             *
             * * `image` represents image name inside the specified `resourceImagePath`,
             * * `imageUrl` represents fully qualified image URL.
             *
             * **NOTE**: The path should end with a `/`:
             *
             * ```javascript
             * new Calendar({
             *     modeDefaults : {
             *         showResourceAvatars : true,
             *         resourceImagePath   : 'images/resources/'
             *     }
             * });
             * ```
             * @config {String}
             */
            resourceImagePath : null,
            /**
             * The minimum date to which the `startDate` of this view may be navigated.
             * @member {Date} minDate
             */
            /**
             * The minimum date to which the `startDate` of this view may be navigated.
             * @config {Date|String}
             */
            minDate : null,
            /**
             * The maximum date to which the `endDate` of this view may be navigated.
             * @member {Date} maxDate
             */
            /**
             * The maximum date to which the `endDate` of this view may be navigated.
             * @config {Date|String}
             */
            maxDate : null,
            /**
             * By default, when navigating through time, the next time
             * block will be animated in from the appropriate direction.
             *
             * Configure this as `false` to disable this.
             * @prp {Boolean} animateTimeShift
             * @default
             */
            animateTimeShift : true,
            // Private at this level, it's only processed for a ResourceView
            includeTimeRanges : null,
            testConfig : {
                animateTimeShift : false
            }
        };
    }
    static get delayable() {
        return {
            refreshSoon : {
                type              : 'raf',
                cancelOutstanding : true
            }
        };
    }
    static get featureable() {
        return {
            factory : CalendarFeature
        };
    }
    construct(config) {
        const me = this;
        super.construct(config);
        // Not tabbable, but conducts focus.
        // We have not implemented Calendar Cell navigation which is external
        // to event-to-event navigation, so YearView does not receive focus yet.
        // Only add tabIndex if we don't already have it
        if (!me.isYearView && me.element.tabIndex !== -1 && me.contentElement?.tabIndex !== -1) {
            (me.contentElement || me.element).tabIndex = -1;
        }
        // Pull any the AvatarRendering instance through
        me.getConfig('avatarRendering');
        EventHelper.on({
            element : me.element,
            keydown : 'onCalendarKeyDown',
            thisObj : me
        });
        if (me.handlePointerInteraction) {
            EventHelper.on({
                element   : me.element,
                mouseover : 'onEventMouseOverOut',
                mouseout  : 'onEventMouseOverOut',
                mousedown : 'onCalendarPointerInteraction',
                mouseup   : 'onCalendarPointerInteraction',
                // Block subsequent clicks before 300ms has elapsed
                click : {
                    handler : 'onCalendarPointerInteraction',
                    block   : 300
                },
                dblclick    : 'onCalendarPointerInteraction',
                contextmenu : 'onCalendarPointerInteraction',
                thisObj     : me
            });
        }
    }
    /**
     * For use by the {@link Calendar.feature.TimeRanges} feature. This yields the set of
     * {@link Calendar.model.TimeRangeModel}s and {@link Scheduler.model.ResourceTimeRangeModel}s
     * to be rendered in the passed date range.
     * @param {Date} startDate The start date of the range to be returned
     * @param {Date} endDate The end date of the range to be returned.
     * @returns {Calendar.model.TimeRangeModel[]}
     * @private
     */
    getTimeRanges(startDate, endDate) {
        const
            {
                resourceId,
                project
            }                 = this,
            includeTimeRanges = resourceId ? this.owner.includeTimeRanges : true,
            ranges            = (resourceId == null || includeTimeRanges) ? project?.getTimeRanges(startDate, endDate) : [];
        // Add in resourceTimeRanges for this view if this view is for a certain resource
        if (resourceId != null) {
            const resourceRanges = project?.getResourceTimeRanges(startDate, endDate).filter(r => r.resourceId == resourceId);
            // Default color to event color for resource
            resourceRanges.forEach(r => {
                if (!r.color) {
                    r.color = this.resource.eventColor;
                }
            });
            ranges.push(...resourceRanges);
        }
        return ranges;
    }
    updateIncludeTimeRanges() {
        if (!this.isConfiguring) {
            this.refresh();
        }
    }
    onConfigChange(info) {
        if (this.autoRefresh?.[info?.name]) {
            this.refreshSoon();
        }
        super.onConfigChange(info);
    }
    changeAvatarRendering(avatarRendering) {
        return AvatarRendering.new({
            element : this.element
        }, avatarRendering);
    }
    updateShowResourceAvatars(showResourceAvatars) {
        if (showResourceAvatars) {
            // We need the AvatarRendering utility if we are showing avatars.
            this.avatarRendering || (this.avatarRendering = true);
        }
        this.refresh();
    }
    getResourceAvatar(resourceRecord) {
        return this.avatarRendering.getResourceAvatar({
            resourceRecord,
            imageUrl : resourceRecord.image === false ? null : (resourceRecord.imageUrl || resourceRecord.image && (this.resourceImagePath + resourceRecord.image)),
            color    : resourceRecord.eventColor,
            initials : resourceRecord.initials,
            dataset  : {
                btip       : StringHelper.encodeHtml(resourceRecord.name),
                resourceId : resourceRecord.id
            }
        });
    }
    updateEventHeight(eventHeight) {
        const { style } = this.element;
        // Force a recalculate on next access
        this._eventHeightInPixels = null;
        style.setProperty('--event-height', DomHelper.setLength(eventHeight));
        style.setProperty('--arrow-width', 'calc(var(--event-height) / 3)');
        style.setProperty('--arrow-margin', 'calc(var(--event-height) / -3)');
        // Schedule a refresh
        if (!this.isConfiguring) {
            this.refreshSoon();
        }
    }
    /**
     * Returns the pixel value of the {@link #config-eventHeight} in case it was configured as a
     * CSS measurement in other units.
     * @private
     */
    get eventHeightInPixels() {
        const
            me              = this,
            { eventHeight } = me;
        let eventHeightInPixels = me._eventHeightInPixels;
        // Some views, like DayView don't have a defined event height.
        if (eventHeight !== 'auto') {
            if (!eventHeightInPixels) {
                eventHeightInPixels = me._eventHeight;
                // Measure the height if it's a string value.
                // Value is cached until eventHeight is changed again.
                if (typeof eventHeightInPixels === 'string') {
                    eventHeightInPixels = DomHelper.measureSize(eventHeightInPixels, me.contentElement.querySelector(`.${me.eventBarContainerCls}`), false);
                }
                me._eventHeightInPixels = eventHeightInPixels;
            }
        }
        return eventHeightInPixels;
    }
    /**
     * This property yields the base selector to use to find visible cell elements in this view.
     *
     * It's based upon the {@link #property-dayCellCls}, but also takes into account the
     * {@link #config-hideNonWorkingDays} setting.
     *
     * If this is a MonthView, it also takes into account the
     * {@link Calendar.widget.MonthView#config-hideOtherMonthCells} setting.
     * @property {String}
     * @readonly
     */
    get visibleCellSelector() {
        const excludes = [];
        if (this.hideOtherMonthCells) {
            excludes.push(`.${this.otherMonthCls}`);
        }
        if (this.hideNonWorkingDays) {
            excludes.push(`.${this.nonWorkingDayCls}`);
        }
        return `.${this.dayCellCls}${excludes.length ? `:not(${excludes.join(',')})` : ''}`;
    }
    /**
     * This property yields this widget. This is to enable Calendar Features to be able to attach
     * to standalone Calendar widgets as their owning client, and to access a currently active view
     * in a standard way.
     * @property {Calendar.widget.mixin.CalendarMixin}
     * @typings {typeof CalendarMixin}
     * @readonly
     * @internal
     */
    get activeView() {
        return this;
    }
    /**
     * This property yields this widget. This is to enable Calendar Features to be able to attach
     * to standalone Calendar widgets as their owning client, and to access a currently active view
     * in a standard way.
     * @property {Calendar.widget.mixin.CalendarMixin}
     * @typings {typeof CalendarMixin}
     * @readonly
     * @internal
     */
    get activeSubView() {
        const
            { items } = this,
            // If we're a multi-CalendarWidget view (Such as a ResourceView), narrow down activeView
            // to the active subView which contains focus.
            activeSubView  = items.filter(isFocusedCalendarMixin)?.[0];
        return activeSubView || this;
    }
    /**
     * Calendar mode that this view represents (eg. "day", "month" etc). Only accessible when used within a Calendar.
     * @member {String} modeName
     * @readonly
     */
    /**
     * This function allows a Calendar widget to act as a Feature host by exposing the same interface
     * as a {@link Calendar.view.Calendar}. It executes the passed function on this widget.
     * @internal
     * @param {Function} fn The function to call.
     * @param {Object[]} [args] The arguments to pass. Defaults to this view.
     * @param {Object} [thisObj] The `this` reference for the function. Defaults to this view.
     */
    eachView(fn, args, thisObj = null) {
        this.callback(fn, thisObj || this, args || [this]);
    }
    get focusElement() {
        const { calendar } = this;
        if (calendar) {
            const { navigator } = calendar;
            return navigator.activeItem || navigator.previousActiveItem || this.element.querySelector(navigator.itemSelector) || super.focusElement;
        }
    }
    captureFocusItem(activeElement) {
        const
            activeEvent = this.getEventRecord(activeElement),
            base = super.captureFocusItem(activeElement);
        return (scrollIntoView = true) => {
            const newEl = activeEvent && this.getEventElement(activeEvent);
            if (newEl) {
                scrollIntoView ? newEl.focus() : newEl.focus({ preventScroll : true });
            }
            else {
                base?.(scrollIntoView);
            }
        };
    }
    /**
     * Refreshes the UI after a change to the EventStore, or to a configuration that requires
     * the UI to change.
     *
     * Only updates the UI if this widget is visible. If it is not visible, the refresh is
     * deferred until it next becomes visible.
     */
    refresh() {
        // If we're being called programmatically, cancel upcoming delayed refreshes.
        this.refreshSoon.cancel();
        this.month && this.element.style.setProperty('--week-length', this.month.weekLength);
        // Only refresh immediately if we are visible.
        this.whenVisible('refreshNow');
    }
    refreshNow() {
        const refocus = this.captureFocus();
        this.doRefresh();
        refocus();
    }
    /**
     * Executes the passed callback after the next refresh, but waits only for a maximum number of
     * milliseconds before optionally performing a refresh and executing the callback.
     *
     * When awaited, the function resolves after the refresh or timeout and after any callback have been executed
     * and the Promise yields `true` if a refresh has been performed.
     *
     * @param {String|Function} [callback] A function or the name of a function in the ownership hierarchy
     * to run after the next refresh operation. May be omitted, and `options` passed as the sole parameter.
     * @param {Object} [options] How long to wait and what to do on timeout.
     * @param {Number} [options.delay=100] The number of milliseconds to wait for a refresh.
     * @param {Boolean} [options.forceRefresh=false] If the refresh does not happen within the timer, call refresh.
     * @async
     * @internal
     */
    afterRefresh(callback, options = { delay : 100, forceRefresh : false }) {
        if (typeof callback === 'object') {
            options = callback;
            callback = null;
        }
        else if (callback) {
            callback = this.resolveCallback(callback, this);
            callback = callback.handler.bind(callback.thisObj);
        }
        if (typeof options === 'number') {
            options = { delay : options };
        }
        return new Promise(resolve => {
            this.ion({
                refresh : () => {
                    callback?.();
                    resolve(true);
                },
                once    : true,
                expires : {
                    delay : options.delay || 100,
                    alt   : () => {
                        options.forceRefresh && this.refresh();
                        callback?.();
                        resolve(options.forceRefresh);
                    }
                }
            });
        });
    }
    get displayName() {
        return StringHelper.capitalize(this._displayName || this.title || this.type);
    }
    get hiddenNonWorkingDays() {
        return this.hideNonWorkingDays ? (this.nonWorkingDays || this.month.nonWorkingDays) : emptyObject;
    }
    changeAutoCreate(autoCreate) {
        const defaults = CalendarMixin.$meta.config.autoCreate;
        if (autoCreate === true) {
            return defaults;
        }
        if (typeof autoCreate === 'string') {
            autoCreate = {
                gesture : autoCreate
            };
        }
        return Config.merge(autoCreate, defaults);
    }
    updateDateSeparator() {
        this.refreshCalendarDescription();
    }
    updateDescriptionFormat() {
        this.refreshCalendarDescription();
    }
    refreshCalendarDescription() {
        const { calendar } = this;
        if (calendar?.isPainted && calendar.activeView === this) {
            calendar.updateViewDescription();
        }
    }
    changeShortEventDuration(shortEventDuration) {
        return isNaN(shortEventDuration) ? DH.as('ms', shortEventDuration) : Number(shortEventDuration);
    }
    updateShortEventDuration() {
        if (!this.isConfiguring) {
            this.refresh();
        }
    }
    updateLocalization() {
        // If user configured calendar with specific config, then prefer it to the localized value
        if (!('weekStartDay' in this.initialConfig)) {
            this.weekStartDay = DH.weekStartDay;
        }
        if (!('nonWorkingDays' in this.initialConfig)) {
            this.nonWorkingDays = DH.nonWorkingDays;
        }
        super.updateLocalization();
        this.refreshCalendarDescription();
    }
    updateAutoCreate(autoCreate) {
        // The autocreate.newEvent property must be processed
        this.updateLocalization();
    }
    updateWeekStartDay(weekStartDay) {
        const { refreshCount, month } = this;
        super.updateWeekStartDay?.(weekStartDay);
        // This can be called from changeMonth during initialization of the Month object
        // and at that time, obviously the property will not be present.
        if (month) {
            month.weekStartDay = weekStartDay;
        }
        if (this.isPainted && this.refreshCount === refreshCount) {
            this.refresh();
        }
    }
    changeNonWorkingDays(nonWorkingDays) {
        const
            me          = this,
            result      = new Proxy(ObjectHelper.assign({}, nonWorkingDays), {
                set(target) {
                    const result = Reflect.set(...arguments);
                    me.updateNonWorkingDays(target);
                    return result;
                },
                deleteProperty(target) {
                    const result = Reflect.deleteProperty(...arguments);
                    me.updateNonWorkingDays(target);
                    return result;
                }
            });
        return result;
    }
    updateNonWorkingDays(nonWorkingDays) {
        const { refreshCount, month } = this;
        super.updateNonWorkingDays?.(nonWorkingDays);
        // This can be called from changeMonth during initialization of the Month object
        // and at that time, obviously the property will not be present.
        if (month) {
            month.nonWorkingDays = nonWorkingDays;
        }
        if (this.isPainted && this.refreshCount === refreshCount) {
            this.refresh();
        }
    }
    dayOfDate(date) {
        return DH.clearTime(date);
    }
    ingestDate(date) {
        date = typeof date === 'string' ? DH.parse(date) : new Date(date);
        if (isNaN(date)) {
            throw new Error('Calendar widget date ingestion must be passed a Date, or a YYYY-MM-DD date string');
        }
        return this.dayOfDate(date);
    }
    changeDate(date, oldDate) {
        date = this.ingestDate(date);
        // Honour minDate and maxDate
        // isValidTargetDate always needs the answer.
        if (!this.isInIsValidTargetDate && !this.isValidTargetDate(date)) {
            return;
        }
        // Don't fire the beforeDateChange event for a no-change.
        if (!oldDate || (date - oldDate)) {
            /**
             * Triggered before a view's orientating date changes.
             *
             * return `false` from an event handler to veto the temporal navigation.
             * @preventable
             * @event beforeChangeDate
             * @param {Date} oldDate The current orientating date of this view.
             * @param {Date} date The new date to which this view is to be orientated.
             */
            if (this.trigger('beforeDateChange', { date, oldDate }) !== false) {
                return date;
            }
        }
    }
    isValidTargetDate(date, end) {
        const
            me      = this,
            minDate = me.minDate || me.calendar?.minDate,
            maxDate = me.maxDate || me.calendar?.maxDate;
        if (!isNaN(minDate) || !isNaN(maxDate)) {
            // flag so that changers don't ask for validation.
            this.isInIsValidTargetDate = true;
            // We need to call changer here so that subclasses can snap to their range start.
            // But the base changer above must not ask for validation.
            const newDate = end ? me[`change${StringHelper.capitalize(end)}Date`](date, null) : date;
            me.isInIsValidTargetDate = false;
            // Veto navigation to before minDate.
            if (!isNaN(minDate) && newDate < minDate) {
                return false;
            }
            // Veto navigation to after maxDate.
            if (!isNaN(maxDate)) {
                return !(end === 'end' ? newDate > maxDate : newDate >= maxDate);
            }
        }
        return true;
    }
    changeStartDate(startDate) {
        // Subclass may already have vetoed the change
        if (startDate) {
            startDate = this.ingestDate(startDate);
            // Honour minDate and maxDate
            // isValidTargetDate always needs the answer.
            if (this.isInIsValidTargetDate || this.isValidTargetDate(startDate, 'start')) {
                return startDate;
            }
        }
    }
    changeEndDate(endDate) {
        // Subclass may already have vetoed the change
        if (endDate) {
            endDate = this.ingestDate(endDate);
            // Honour minDate and maxDate
            // isValidTargetDate always needs the answer.
            if (this.isInIsValidTargetDate || this.isValidTargetDate(endDate, 'end')) {
                return endDate;
            }
        }
    }
    /**
     * Brings an event or a time into view. Optionally visually highlights the target.
     *
     * __This may change the date range encompassed by this view to bring the date or event into its
     * ownership__.
     *
     * Scrolling may or may not be required, depending on the type and size constraints of the view.
     * @param {Scheduler.model.EventModel|Date} target The event or Date to scroll to.
     * @param {Object} [options] How to scroll.
     * @param {'start'|'end'|'center'|'nearest'} [options.block] How far to scroll the target.
     * @param {Number} [options.edgeOffset] edgeOffset A margin around the target to bring into view.
     * @param {Object|Boolean|Number} [options.animate] Set to `true` to animate the scroll by 300ms,
     * or the number of milliseconds to animate over, or an animation config object.
     * @param {Number} [options.animate.duration] The number of milliseconds to animate over.
     * @param {String} [options.animate.easing] The name of an easing function.
     * @param {Boolean|Function} [options.highlight] Set to `true` to highlight the resulting element
     * when it is in view. May be a function which is called passing the resulting element
     * to provide customized highlighting.
     * @param {Boolean} [options.focus] Set to `true` to focus the element when it is in view.
     * @param {Boolean} [options.x] Pass as `false` to disable scrolling in the `X` axis.
     * @param {Boolean} [options.y] Pass as `false` to disable scrolling in the `Y` axis.
     * @returns {Promise} A promise which is resolved when the target has been scrolled into view.
     */
    async scrollTo(target, options = { animate : true }) {
        const
            me             = this,
            { scrollable } = me;
        let promise = immediatePromise;
        if (me.scrollPromise) {
            await me.scrollPromise;
        }
        // Scrolling to an event. Make sure it's in our date range first
        if (target.isEvent) {
            const eventRecord = target;
            // If we do not encompass the event, move to the event's startDate.
            if (!DH.intersectSpans(me.startDate, me.endDate, target.startDate, target.endDate)) {
                me.date = target.startDate;
            }
            target = me.getEventElement(target);
            if (!target) {
                me.refresh();
                target = me.getEventElement(eventRecord);
            }
        }
        // The only other option is scrolling to a Date
        else {
            target = me.changeDate(target);
            // If we do not own the date, move to that date.
            if (!DH.betweenLesser(target, me.startDate, me.endDate) || !me.getDayElement(target, true)) {
                me.date = target;
            }
            target = me.getDayElement(target);
        }
        // If this view does scrolling, scroll the target into view
        if (scrollable) {
            promise = scrollable.scrollIntoView(target, options);
        }
        // Otherwise, we are responsible for any highlight
        else if (options.highlight) {
            if (typeof options.highlight === 'boolean') {
                DomHelper.highlight(Rectangle.from(target));
            }
            else {
                me.callback(options.highlight, me, [target, me]);
            }
        }
        return me.scrollPromise = promise;
    }
    async checkAutoCreateGesture(domEvent, date, resourceRecord) {
        const
            me             = this,
            { autoCreate } = me;
        // If the gesture is on a known date, and we are visible, and not readOnly and it's an autoCreate.gesture...
        if (date && me.isVisible && !me.readOnly && domEvent.type === autoCreate?.gesture?.toLowerCase()) {
            const
                dateStart      = DH.startOf(date, undefined, undefined, me.weekStartDay),
                startHourMS    = isNaN(autoCreate.startHour) ? DH.getTimeOfDay(DH.parse(autoCreate.startHour, 'HH:mm:ss')) : autoCreate.startHour * 1000 * 60 * 60;
            /**
             * This event fires whenever the {@link #config-autoCreate autoCreate gesture} is detected
             * and also when a {@link Calendar.feature.CalendarDrag drag-create} gesture is detected.
             *
             * This event is preventable and may be used to validate UI-initiated event creation.
             * @event beforeAutoCreate
             * @preventable
             * @param {Event} domEvent The DOM event which initiated the creation.
             * @param {Date} date The starting time of the event to be created. If this is in a
             * `DayView, this will be snapped according to the specification in {@link #property-autoCreate}
             */
            if (me.trigger('beforeAutoCreate', { domEvent, date : me.isDayView ? DH[autoCreate.snapType](date, autoCreate.step) : DH.add(dateStart, startHourMS) }) !== false) {
                return me.createEvent(date, resourceRecord);
            }
        }
    }
    /**
     * Creates an event on the specified date which conforms to this view's {@link #config-autoCreate}
     * setting.
     *
     * This method may be called programmatically by application code if the `autoCreate` setting
     * is `false`, in which case the default values for `autoCreate` will be used.
     *
     * If the {@link Calendar.feature.EventEdit EventEdit} feature is active, the new event
     * will be displayed in the event editor.
     * @param {Date} date The date to add the event at. If there's no time component, the
     * {@link #config-autoCreate}'s `startHour` will be used.
     */
    createEvent(date, resourceRecord) {
        const handler = this.calendar || this.owner?.calendar || this;
        // If contained by a Calendar the Calendar may have opinions about which view
        // to pass to doCreateEvent as the editing view.
        handler.doCreateEvent(date, resourceRecord, this);
    }
    async doCreateEvent(date, resourceRecord, editingView = this) {
        resourceRecord = resourceRecord ?? this.defaultCalendar;
        const
            me             = this,
            { isDayView }  = (me.viewType || me),
            // We are either a mode or a CalendarRow
            calendar       = me.calendar || me.owner?.calendar,
            // Some views may be created with a chained EventStore.
            // We must use the Calendar's EventStore.
            eventStore     = calendar?.eventStore || me.eventStore,
            autoCreate     = editingView.autoCreate || editingView.changeAutoCreate(true),
            { modelClass } = eventStore,
            { newName }    = autoCreate,
            dateStart      = new Date(date.getTime() + (me.dayStartShift || 0)),
            startHourMS    = isNaN(autoCreate.startHour) ? DH.getTimeOfDay(DH.parse(autoCreate.startHour, 'HH:mm:ss')) : autoCreate.startHour * 1000 * 60 * 60,
            // If this view has high definition time granularity (isa DayView), then round the precise date
            // passed to this view's autoCreate.step and snapType.
            // Otherwise default to an event starting at autoCreate.startHour.
            startDate   = isDayView ? DH[autoCreate.snapType](date, autoCreate.step) : DH.add(dateStart, startHourMS),
            duration    = DH.parseDuration(autoCreate.duration),
            endDate     = DH.add(startDate, duration.magnitude, duration.unit),
            name        = me.resolveCallback(newName, me, false) ? me.callback(newName, me, [me, startDate]) : newName,
            recordData  = {
                [modelClass.getFieldDataSource('name')]         : name,
                [modelClass.getFieldDataSource('startDate')]    : startDate,
                [modelClass.getFieldDataSource('endDate')]      : endDate,
                [modelClass.getFieldDataSource('duration')]     : duration.magnitude,
                [modelClass.getFieldDataSource('durationUnit')] : duration.unit,
                // If the view's settings resulted in a midnight to midnight event, flag it as allDay
                allDay : DH.diff(startDate, endDate, 'day') === 1
            },
            // For EventLists, when we are using a startDate->endDate range as opposed to a fixed
            // range, the dates are *inclusive*, so pick the correct date containment function.
            dateContainmentFn = editingView.isEventList && !editingView.range ? 'betweenLesserEqual' : 'betweenLesser';
        const newRecord = eventStore.createRecord(recordData);
        // If an editor is available, mark the event as non-persistable while it is being edited
        if (calendar?.features.eventEdit && !calendar.features.eventEdit.disabled) {
            newRecord.isCreating = true;
        }
        if (resourceRecord) {
            eventStore.assignmentStore.assignEventToResource(newRecord, resourceRecord);
        }
        await eventStore.addAsync(newRecord);
        // If the date we are being asked to create at is in view, edit the event when
        // the view has rendered it.
        if (DH[dateContainmentFn](startDate, editingView.startDate, editingView.endDate)) {
            if (editingView.getEventElement(newRecord, startDate)) {
                editingView.editAutoCreatedEvent(newRecord);
            }
            else {
                editingView.ion({
                    refresh({ source }) {
                        // Conditionally call. May have been destroyed
                        source.editAutoCreatedEvent?.(newRecord);
                    },
                    once    : true,
                    prio    : -10000,
                    buffer  : 100,
                    expires : 500
                });
            }
        }
    }
    editAutoCreatedEvent(eventRecord) {
        /**
         * Fired when an {@link #config-autoCreate} gesture has created a new event
         * and added it to the event store.
         *
         * If the {@link Calendar.feature.EventEdit} feature is present, it listens for
         * this event and initiates an edit operation. Adding a high `prio` listener which
         * returns `false` can prevent this event from reaching the `eventEdit` processing.
         * @event eventAutoCreated
         * @param {Calendar.widget.mixin.CalendarMixin} source This Calendar view instance.
         * @typings source -> {typeof CalendarMixin}
         * @param {Scheduler.model.EventModel} eventRecord The new event record.
         */
        this.trigger('eventAutoCreated', {
            eventRecord
        });
    }
    get duration() {
        // All views have a startDate and endDate property, so duration can be calculated.
        // The endDate is "exclusive" because it means 00:00:00 of that day.
        return this.endDate ? this.calculateDuration(this.startDate, this.endDate) : 1;
    }
    calculateDuration(startDate, endDate) {
        // This is overridden in WeekView to enforce the correct duration.
        // This enables updaters to "calculate" the duration and therefore share code.
        return DH.diff(startDate, endDate, 'day');
    }
    /**
     * Moves this view forwards in time by its configured (or intrinsic if it's a
     * {@link Calendar.widget.WeekView} or a {@link Calendar.widget.YearView}) duration.
     */
    next() {
        this.date = DH.add(this.date, this.duration, 'day');
    }
    /**
     * Moves this view backwards in time by its configured (or intrinsic if it's a
     * {@link Calendar.widget.WeekView} or a {@link Calendar.widget.YearView}) duration.
     */
    previous() {
        this.date = DH.add(this.date, -this.duration, 'day');
    }
    get eventContentElement() {
        return this.contentElement;
    }
    /**
     * The first *visible* event-bearing element in this view. So if the first day defined in the
     * range is a Sunday, and {@link #config-hideNonWorkingDays} is set, then the first visible
     * cell will be for the Monday.
     * @property {HTMLElement}
     */
    get firstVisibleCell() {
        return this.eventContentElement.querySelector(this.visibleCellSelector);
    }
    /**
     * The last *visible* event-bearing element in this view. So if the last day defined in the
     * range is a Sunday, and {@link #config-hideNonWorkingDays} is set, then the last visible
     * cell will be for the Friday.
     * @property {HTMLElement}
     */
    get lastVisibleCell() {
        const visibleCells = this.contentElement.querySelectorAll(this.visibleCellSelector);
        return visibleCells[visibleCells.length - 1];
    }
    /**
     * The date of the first *visible* event-bearing element in this view. So if the first day defined
     * in the range is a Sunday, and {@link #config-hideNonWorkingDays} is set, then the first visible
     * date will be the date of the Monday.
     * @property {Date}
     */
    get firstVisibleDate() {
        const
            me        = this,
            date      = new Date(me.startDate),
            // Extracting the month index from our instance of the Month helper class
            { month } = me.month;
        while ((me.hideOtherMonthCells && date.getMonth() !== month) || me.hiddenNonWorkingDays[date.getDay()]) {
            date.setDate(date.getDate() + 1);
        }
        return date;
    }
    /**
     * The date of the last *visible* event-bearing element in this view. So if the last day defined
     * in the range is a Sunday, and {@link #config-hideNonWorkingDays} is set, then the last visible
     * date will be the date of the Friday.
     * @property {Date}
     */
    get lastVisibleDate() {
        const
            me        = this,
            date      = DH.add(me.endDate, -1, 'd'),
            // Extracting the month index from our instance of the Month helper class
            { month } = me.month;
        while ((me.hideOtherMonthCells && date.getMonth() !== month) || me.hiddenNonWorkingDays[date.getDay()]) {
            date.setDate(date.getDate() - 1);
        }
        return date;
    }
    updateHideNonWorkingDays(hideNonWorkingDays) {
        const
            me = this,
            {
                month,
                calendar
            }  = me;
        me.contentElement?.classList[hideNonWorkingDays ? 'add' : 'remove'](me.hideNonWorkingDaysCls);
        // Bail out for view types not supporting this (ResourceView)
        if (month == null) {
            return;
        }
        let activeColumnIndex, date, activeDay;
        // Our active date is going to be hidden
        if (!me.isConfiguring && hideNonWorkingDays) {
            date = me.date;
            activeDay = date?.getDay();
            if (date && me.nonWorkingDays[activeDay] && me.getDayElement(date)) {
                activeColumnIndex = month.visibleDayColumnIndex[activeDay];
            }
        }
        month.hideNonWorkingDays = hideNonWorkingDays;
        super.updateHideNonWorkingDays?.(hideNonWorkingDays);
        // Active date has been hidden by hiding nonworking days, we must move it.
        if (typeof activeColumnIndex === 'number') {
            const
                weekStart            = month.getWeekStart(month.getWeekNumber(date)),
                newActiveColumnIndex = Math.min(activeColumnIndex, month.visibleColumnCount - 1);
            // Find the date for the new visible column index
            for (let i = -1; ; weekStart.setDate(weekStart.getDate() + 1)) {
                // Entry may be zero, cannot use truthiness test
                if (typeof month.visibleDayColumnIndex[weekStart.getDay()] === 'number') {
                    if (++i === newActiveColumnIndex) {
                        break;
                    }
                }
            }
            me.date = weekStart;
            // Owning active date should change
            calendar && (calendar.date = date);
        }
    }
    onCalendarStoreChange({ source, action }) {
        const me = this;
        // Draw on project refresh instead of on dataset.
        // Unless it's a chained store; they change dataset upon master store change
        if (action === 'dataset' && !source.isChained) {
            return;
        }
        // Only refresh once initial commit is performed and change is not cause by project writing back data
        if (me.project.isInitialCommitPerformed && !me.project.isWritingData && me.project.isEngineReady()) {
            // CellMap must be rebuilt when data changes
            me._cellMap?.clear();
            me.refreshSoon();
        }
    }
    /**
     * Schedules a refresh of the UI for the next animation frame. This is a useful method to call when
     * making multiple data changes, so that each change merely *schedules* a refresh for the next AF and
     * DOM churn is kept to a minimum.
     *
     * Calling {@link #function-refresh} directly cancels any scheduled refresh operation and updates
     * the UI immediately
     */
    refreshSoon() {
        this.refresh();
    }
    /**
     * Called when new event is created.
     * Сan be overridden to supply default record values etc.
     * @param {Scheduler.model.EventModel} eventRecord Newly created event
     */
    onEventCreated(eventRecord) {
        // template method
    }
    /**
     * Returns the event record for a DOM element or DOM event.
     * @param {HTMLElement|Event} elementOrEvent The DOM node to lookup, or a DOM event whose target to lookup.
     * @returns {Scheduler.model.EventModel} The event record
     */
    getEventRecord(elementOrEvent) {
        let element = (elementOrEvent instanceof Event) ? elementOrEvent.target : elementOrEvent;
        element = element?.closest?.('[data-event-id]');
        return element && this.eventStore.getById(element.dataset.eventId);
    }
    /**
     * Returns the resource record for a DOM element or DOM event if the element is inside a view
     * which displays events for one resource such as a {@link Calendar.widget.ResourceView}
     * or a {@link Calendar.widget.DayResourceView}.
     * @param {HTMLElement|Event} elementOrEvent The DOM node to lookup, or a DOM event whose target to lookup.
     * @returns {Scheduler.model.ResourceModel} The resource record
     */
    getResourceRecord(elementOrEvent) {
        let element = (elementOrEvent instanceof Event) ? elementOrEvent.target : elementOrEvent;
        element = element?.closest('[data-resource-id]') || null;
        return element && this.resourceStore.getById(element.dataset.resourceId);
    }
    /**
     * Returns the event record for a DOM element or DOM event.
     * @param {HTMLElement|Event} elementOrEvent The DOM node to lookup, or a DOM event whose target to lookup.
     * @returns {Scheduler.model.EventModel} The event record
     */
    resolveEventRecord(elementOrEvent) {
        // this method is added for symmetry w/SchedulerInterface
        return this.getEventRecord(elementOrEvent);
    }
    getDateFromElement(element, keyParser = null, raw = false) {
        // Month headers also yield the start date for that month
        let dateElement = element?.closest('[data-date],[data-header-date],[data-month-date]');
        // Clicked on an element with a data-date or data-header-date, or data-month-date value.
        // Callers who need a more granular date WRT time shifting, such as CalendarDrag should pass
        // a DayTime instance with the correct startShift.
        // As a default, most views use DateHelper, a DayView uses its own DayTime.
        if (dateElement) {
            const rawDate = dateElement.dataset.date || dateElement.dataset.headerDate || dateElement.dataset.monthDate;
            return raw ? rawDate : (keyParser || (this.isDayView ? this.dayTime : DH)).parseKey(rawDate);
        }
        dateElement = element?.closest('[data-week]');
        // Clicked on an element with a data-week value, that should yield the week start date.
        if (dateElement) {
            return this.month?.getWeekStart(dateElement.dataset.week.split(',').map(Number));
        }
    }
    getDateFromDomEvent(domEvent) {
        return this.getDateFromElement(DomHelper.getEventElement(domEvent));
    }
    getDateFromPosition() {
        return null;
    }
    dateKey(date) {
        return DH.makeKey(date);
    }
    /**
     * Returns the cell associated with the passed date.
     *
     * In certain views, the strict definition if whether the view owns the date may be optionally enforced.
     *
     * For example, in a YearView or MonthView, dates outside the configured year or month may be displayed.
     *
     * To exclude these, pass the `strict` parameter as `true`
     * @param {Date|String} date The date to find the element for or a key in the format `YYYY-MM-DD`
     * @param {Boolean} strict Only return the element if this view *owns* the date. (MonthView and YearView)
     */
    getDayElement(date, strict) {
        if (typeof date !== 'string') {
            date = this.dateKey(date);
        }
        return this.eventContentElement.querySelector(`[data-date="${date}"]`);
    }
    // Used by DayView and CalendarRow to see which day cell the X position relates to.
    getDayElementFromX(x) {
        const dayCells = this.eventContentElement.querySelectorAll('[data-date]');
        for (let rect, el, i = 0, { length } = dayCells; i < length; i++) {
            rect = (el = dayCells[i]).getBoundingClientRect();
            if (x >= rect.x && x <= rect.x + rect.width) {
                return el;
            }
        }
        return dayCells[0];
    }
    /**
     * Returns the outermost element which represents the first block of the passed event in the view. *If the
     * event is represented within the view*.
     *
     * *Note* if the event covers multiple weeks, this will only return the first element.
     *
     * To return all elements use {@link #function-getEventElements}.
     *
     * To return an event element at a particular date, pass the date as the second parameter.
     * @param {Scheduler.model.EventModel|String|Number} eventRecord The event, or event ID to find the element for.
     * @param {Date} [date] Optionally, the event element at the specified date.
     * @returns {HTMLElement} The first element which corresponds to the event. Note that *some* views,
     * such as {@link Calendar.widget.MonthView MonthView} and {@link Calendar.widget.CalendarRow CalendarRow}
     * may render multiple elements for long events.
     */
    getEventElement(eventRecord, date = Math.max(eventRecord.startDate, this.firstVisibleDate || this.startDate)) {
        const
            me                 = this,
            activeEventElement = me.calendar?.navigator.activeItem,
            activeDate         = me.getDateFromElement(activeEventElement),
            eventId            = me.eventStore.modelClass.asId(eventRecord);
        // If the navigated to event is still in the document and is the event being asked for,
        // and on the active date, then use that element.
        // Some views have multiple elements representing one event.
        if (document.contains(activeEventElement) && activeEventElement?.dataset.eventId === String(eventId) && (activeDate && !(date - activeDate))) {
            return activeEventElement;
        }
        if (date) {
            const dayCell = me.getDayElement(date);
            if (dayCell) {
                // In EventList, the day cell is the event el.
                // In all other views the day cell *contains* the event el.
                return DomHelper.down(dayCell, `[data-event-id="${eventId}"]`);
            }
        }
        return me.getEventElements(eventRecord)[0];
    }
    /**
     * Returns all outermost elements which represents the passed event in the view. *If the
     * event is represented within the view*
     * @param {Scheduler.model.EventModel|String|Number} eventRecord The event, or event ID to find the elements for.
     * @returns {HTMLElement[]} The elements which corresponds to the event. Note that *some* views,
     * such as {@link Calendar.widget.MonthView MonthView} and {@link Calendar.widget.CalendarRow CalendarRow}
     * may render multiple elements for long events.
     */
    getEventElements(eventRecord) {
        const eventId = this.eventStore.modelClass.asId(eventRecord);
        return this.eventContentElement.querySelectorAll(`[data-event-id="${eventId}"]`);
    }
    onEventMouseOverOut(domEvent) {
        const
            me        = this,
            {
                currentOverEventEl
            }         = me,
            isOut     = domEvent.type === 'mouseout',
            toElement = domEvent[isOut ? 'relatedTarget' : 'target'],
            toEventEl = toElement?.closest('.b-cal-event-wrap') || null,
            isChange  = toEventEl !== (currentOverEventEl || null);
        if (isChange) {
            if (isOut) {
                me.currentOverEventEl = null;
                if (currentOverEventEl) {
                    Object.defineProperty(domEvent, 'target', {
                        configurable : true,
                        get          : () => currentOverEventEl
                    });
                }
                return me.onCalendarPointerInteraction(domEvent);
            }
            else {
                me.currentOverEventEl = toEventEl;
                return me.onCalendarPointerInteraction(domEvent);
            }
        }
    }
    /**
     * Determines what is under the cursor of the specified event or what is described by the given element.
     * @param {Event|Element} domEvent The event or element
     * @returns {CalendarHit}
     */
    calendarHitTest(domEvent) {
        const
            me                = this,
            { monthSelector } = me,
            date              = me.getDateFromDomEvent(domEvent),
            target            = DomHelper.getEventElement(domEvent);
        let ret = null,
            closest,
            eventRecord;
        // Only a hit on an event if the event could be found in the EventStore.
        // May be a transient event added solely to the UI and not backed by the store.
        if ((closest /* assignment */ = target.closest('.b-cal-event-wrap')) && (eventRecord /* assignment */ = me.eventStore.getById(closest.dataset.eventId))) {
            ret = {
                type         : 'event',
                eventElement : closest,
                eventRecord
            };
        }
        // If we are showing week number in its own column, then the whole cell header represents the day.
        // Otherwise just the .b-day-name represents it.
        else if ((closest /* assignment */ = target.closest(me.dayNameSelector))) {
            ret = {
                type             : 'dayNumber',
                dayNumberElement : closest
            };
        }
        else if ((closest /* assignment */ = target.closest('.b-week-num,.b-week-number-cell'))) {
            const weekElement = target.closest('.b-calendar-week');
            // Week number cell in the day name row has no week.
            if (weekElement && weekElement.dataset.week) {
                ret = {
                    type              : 'weekNumber',
                    week              : weekElement.dataset.week.split(',').map(Number),
                    weekNumberElement : closest,
                    weekElement
                };
            }
        }
        else if (monthSelector && target.closest(monthSelector)) {
            ret = {
                type  : 'monthName',
                month : date.getMonth(),
                date
            };
        }
        if (!ret) {
            if ((closest /* assignment */ = target.closest('.b-cal-cell-overflow'))) {
                ret = {
                    type                : 'cellOverflow',
                    cellOverflowElement : closest
                };
            }
            else if (date) {
                ret = {
                    type : 'schedule'
                };
            }
        }
        if (ret) {
            ret.resource = me.getResourceRecord(domEvent);
            ret.cell = target.closest('.b-calendar-cell');
            ret.date = date;
            ret.view = me;
        }
        return ret;
    }
    onCalendarPointerInteraction(domEvent) {
        const
            me                = this,
            { monthSelector } = me,
            { target }        = domEvent,
            fromOverflowPopup = Boolean(target.closest('.b-overflowpopup')),
            domEventName      = eventNameMap[domEvent.type],
            eventWrap         = target.closest('.b-cal-event-wrap'),
            eventRecord       = eventWrap ? me.eventStore.getById(eventWrap.dataset.eventId) : me.getEventRecord(target),
            date              = domEvent.key ? eventRecord?.startDate : me.getDateFromDomEvent(domEvent),
            eventElement      = eventWrap || (eventRecord && me.getEventElement(eventRecord, date)),
            resourceElement   = target.closest('[data-resource-id]'),
            resourceRecord    = resourceElement && me.resourceStore.getById(resourceElement.dataset.resourceId);
        let result;
        // Mouse interaction was on a resource.
        // These can be outside of the eventContentElement.
        // Resource is a property of an event, so it triggers first.
        if (resourceRecord) {
            result = me.trigger(`resource${domEventName}`, {
                domEvent,
                date,
                eventElement,
                eventRecord,
                resourceRecord,
                fromOverflowPopup
            });
        }
        // If we are showing week number in its own column, then the whole cell header represents the day.
        // Otherwise just the .b-day-name represents it.
        if (target.closest(me.dayNameSelector)) {
            result = me.trigger(`dayNumber${domEventName}`, {
                domEvent,
                date,
                cellData : me.cellMap.get(date) || me.createCellData(date),
                resourceRecord,
                fromOverflowPopup
            });
            if (result === false) {
                return result;
            }
        }
        // All other interaction must be in content element or our overflow popup.
        if (!fromOverflowPopup && !me.eventContentElement.contains(target)) {
            return;
        }
        // Mouse interaction was on an event
        if (result !== false && eventRecord) {
            const eventResult = me.trigger(`event${domEventName}`, {
                domEvent,
                date,
                eventElement,
                eventRecord,
                resourceRecord,
                fromOverflowPopup
            });
            if (eventResult) {
                result = eventResult;
            }
        }
        // Interacted with an event. No further interaction.
        if (eventRecord) {
            return result;
        }
        // Interaction was with a week number
        if (target.closest('.b-week-num,.b-week-number-cell')) {
            const weekElement = domEvent.target.closest('[data-week]');
            // If we find an element we can ask the week.
            if (weekElement) {
                return me.trigger(`weekNumber${domEventName}`, {
                    domEvent,
                    week : weekElement.dataset.week.split(',').map(Number),
                    date : me.getDateFromElement(weekElement.querySelector('.b-calendar-cell')),
                    fromOverflowPopup
                });
            }
        }
        // Interaction was with a month in the YearView.
        if (monthSelector && target.closest(monthSelector)) {
            return me.trigger(`monthName${domEventName}`, {
                domEvent,
                month : date.getMonth(),
                date,
                fromOverflowPopup
            });
        }
        // Interacting with a cell overflow indicator
        if (target.closest('.b-cal-cell-overflow')) {
            if (me.trigger(`cellOverflow${domEventName}`, {
                domEvent,
                date,
                fromOverflowPopup,
                resourceRecord
            }) !== false) {
                return;
            }
        }
        // It's only a schedule{event} if the event is in a day cell.
        // Pure Grid views like ListView don't have a schedule area - it's all events.
        if (date && me.dayCellCls && domEvent.target.closest(`.${me.dayCellCls}`)) {
            result = me.trigger(`schedule${domEventName}`, {
                domEvent,
                date,
                fromOverflowPopup,
                resourceRecord
            });
            if (result === false) {
                return result;
            }
        }
        // Finally check if the gesture matches the autoCreate gesture.
        // A precise time is passed to autoCreate if possible (Only DayView offers a precise time)
        // This is so that the autoCreate's configure snapType may be applied
        me.checkAutoCreateGesture(domEvent, me.getDateFromDomEvent(domEvent, true), resourceRecord || undefined);
        return result;
    }
    onCalendarKeyDown(keyEvent) {
        if (keyEvent.ctrlKey && keyEvent.key.toLowerCase() === 'z' && this.calendar?.enableUndoRedoKeys) {
            this.project?.stm?.onUndoKeyPress(keyEvent);
        }
        else {
            this.onCalendarPointerInteraction(keyEvent);
        }
    }
    isAllDayEvent(eventRecord) {
        return eventRecord.allDay || (eventRecord.isScheduled && this.dayTime ? this.dayTime.isInterDay(eventRecord) : eventRecord.isInterDay);
    }
    /**
     * Sort the given array of `events` in the desired order for this view.
     * @param {Scheduler.model.EventModel[]} events
     * @internal
     */
    sortEvents(events) {
        events.sort(this.eventSorter);
    }
    //region Extract configs
    // These functions are not meant to be called by any code other than Base#getCurrentConfig().
    // This excludes project and calendar from being serialized,
    // they are always assigned on creation not actually configurable
    preProcessCurrentConfigs(configs) {
        super.preProcessCurrentConfigs(configs);
        delete configs.calendar;
        delete configs.project;
    }
    // Extracts the current configs for the calendar view, with special handling to exclude project
    getCurrentConfig(options) {
        const result = super.getCurrentConfig(options);
        delete result.project;
        return result;
    }
    //endregion
};
