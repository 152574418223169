import CalendarRow from './CalendarRow.js';
import FunctionHelper from '../../Core/helper/FunctionHelper.js';
import DateHelper from '../../Core/helper/DateHelper.js';
/**
 * @module Calendar/widget/DaySelector
 */
/**
 * This is used as the day selector header in an {@link Calendar.widget.EventList}
 * or {@link Calendar.widget.DayView}. It should not be used standalone as a regular Widget.
 *
 * @internal
 * @extends Calendar/widget/CalendarRow
 * @classtype dayselector
 */
export default class DaySelector extends CalendarRow {
    static $name = 'DaySelector';
    // Factoryable type name
    static type = 'dayselector';
    static configurable = {
        readOnly   : true,
        autoCreate : false,
        flex       : '0 0 auto',
        dock       : 'top'
    };
    afterConfigure() {
        const
            me        = this,
            { owner } = me;
        super.afterConfigure();
        owner.scrollable?.ion({
            overflowChange : 'onOwnerOverflowChange',
            thisObj        : me
        });
        // We take over our owner's descriptionRenderer
        owner.descriptionRenderer = me.descriptionRenderer.bind(me);
        // We take over control over our owner's next and previous methods.
        // "this" within these functions will refer to the owner widget
        owner.previous = function() {
            this.date = DateHelper.add(owner.date, -owner.duration, 'd');
        };
        owner.next = function() {
            this.date = DateHelper.add(owner.date, owner.duration, 'd');
        };
        // We need to update ourselves in sync with our owner's date
        FunctionHelper.before(owner, 'updateDate', this.beforeOwnerDateUpdate, this);
    }
    beforeOwnerDateUpdate(date) {
        this.date = date;
    }
    onOwnerOverflowChange({ y }) {
        this.headerElement.classList.toggle('b-show-yscroll-padding', Boolean(y));
    }
    onDayNumberClick({ date }) {
        if (this.owner) {
            this.owner.date = date;
        }
        // No further navigation must take place. Note that this is not a scheduleClick - this
        // widget is merely a slave of its owning Calendar widget. This is an internal gesture
        // which sets the owning Calendar widget's date.
        return false;
    }
    descriptionRenderer() {
        const
            {
                startDate,
                endDate,
                owner
            }          = this,
            startMonth = startDate.getMonth(),
            endMonth   = endDate.getMonth(),
            monthDesc  = startMonth === endMonth ? DateHelper.format(startDate, 'MMMM') : `${DateHelper.format(startDate, 'MMM')} - ${DateHelper.format(endDate, 'MMM')}`,
            week       = owner.month.getWeekNumber(startDate);
        return `${this.L('L{Object.Week}')} ${week[1]}, ${monthDesc} ${week[0]}`;
    }
}
DaySelector.initClass();
DaySelector._$name = 'DaySelector';