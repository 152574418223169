import DayView from './DayView.js';
import './DayResourceCalendarRow.js';
import DomHelper from '../../Core/helper/DomHelper.js';
/**
 * @module Calendar/widget/DayResourceView
 */
/**
 * A calendar view showing resources grouped by date, it is normally used as a {@link Calendar.view.Calendar#config-modes mode}
 * of a Calendar but may be used standalone as a regular Widget.
 *
 * By default, it shows a one week range encapsulating the Calendar's current {@link Calendar.view.Calendar#property-date}.
 * This can be configured using the {@link #config-range} config property.
 *
 * {@inlineexample Calendar/widget/DayResourceView.js}
 *
 * @demo Calendar/date-resource
 *
 * @extends Calendar/widget/DayView
 * @classtype dayresource
 * @typingswidget
 */
export default class DayResourceView extends DayView {
    static $name = 'DayResourceView';
    static type = 'dayresource';
    static configurable = {
        /**
         * The minimum width of a resource column. This must be a non-zero value to ensure that space
         * is allocated even when there are many resources and a narrow viewport.
         *
         * The default value is `4em`
         *
         * If the day columns columns overflow the horizontal space available, the columns
         * will be scrollable horizontally in the normal way.
         *
         * There is a {@link #property-horizontalScroller} property which handles scrolling in this
         * dimension.
         * @prp {Number|String}
         */
        minResourceWidth : '4em',
        /**
         * By default, resource avatars are shown in the resource header if there is horizontal space.
         * Configure this as `false` to inhibit this.
         * @prp {Boolean}
         */
        showAvatars : true,
        title : 'L{dayResourceView}',
        allDayEvents : {
            type : 'dayresourcecalendarrow'
        },
        range : '1w',
        /**
         * Configure as `true` to show avatars of the resources (calendars) alongside
         * resource names in resource column headers.
         *
         * Configure as `'last'` to show avatars of the assigned resources (calendars) after the name.
         *
         * Note that you must set {@link #config-resourceImagePath} in order that the system
         * knows where to access the resource's image file from.
         *
         * If no image is set, or the image is not found, the resource's initials are shown instead.
         *
         * By default it is inherited from the owning Calendar:
         * ```javascript
         * new Calendar({
         *     resourceImagePath   : 'images/resources/'
         *     modes : {
         *         dayresource : {
         *             showHeaderAvatars : true,
         *         }
         *     }
         * });
         * ```
         * @config {Boolean|'last'}
         */
        showHeaderAvatars : true,
        autoRefresh : [
            'hideEmptyResources'
        ],
        /**
         * Configure this as `true` to hide resource columns which contain no events.
         *
         * <div class="note">Use with care. This may result in no resource columns being rendered
         * for completely empty dates.</div>
         * @prp {Boolean}
         * @default false
         */
        hideEmptyResources : null
    };
    changeAllDayEvents() {
        const result = super.changeAllDayEvents(...arguments);
        if (result) {
            result.hideEmptyResources = this.hideEmptyResources;
        }
        return result;
    }
    checkAutoCreateGesture(domEvent, date, resourceRecord) {
        if (resourceRecord) {
            return super.checkAutoCreateGesture(...arguments);
        }
    }
    // Called as an onFunction by updateElementLayout triggering 'layoutUpdate'.
    onLayoutUpdate() {
        this.syncNarrowResourceWidth();
        super.onLayoutUpdate?.(...arguments);
    }
    onDayContainerResize() {
        this.syncNarrowResourceWidth();
        return super.onDayContainerResize?.(...arguments);
    }
    syncNarrowResourceWidth() {
        const resourceHeader = this.element.querySelector('.b-dayresourcecalendarrow-resource-header');
        if (resourceHeader) {
            this.allDayEvents.element.classList.toggle('b-narrow-resource-header', resourceHeader.offsetWidth < 100);
        }
    }
    updateHideEmptyResources(hideEmptyResources) {
        if (!this.isConfiguring) {
            this._allDayEvents && (this._allDayEvents.hideEmptyResources = hideEmptyResources);
        }
    }
    changeMinResourceWidth(minResourceWidth) {
        // Falsy/zero means use class default
        return minResourceWidth || this.constructor.configurable.minResourceWidth;
    }
    updateMinResourceWidth(minResourceWidth) {
        this.element.style.setProperty('--min-resource-width', DomHelper.setLength(minResourceWidth));
        this.updateElementLayout();
        this.allDayEvents?.updateMinResourceWidth(minResourceWidth);
    }
    updateShowHeaderAvatars(showHeaderAvatars) {
        const { allDayEvents : a } = this;
        if (a) {
            a.resourceImagePath   = this.resourceImagePath;
            a.showHeaderAvatars = showHeaderAvatars;
        }
    }
    updateWeekStartDay(weekStartDay, oldWeekStartDay) {
        super.updateWeekStartDay?.(weekStartDay);
        if (!this.isConfiguring) {
            const startDate = new Date(this.startDate);
            startDate.setDate(startDate.getDate() + weekStartDay - oldWeekStartDay);
            this.startDate = startDate;
        }
    }
    // Override from DayView.
    // This returns a DayView day column config for each resource
    getDayDomConfig(date) {
        const
            me            = this,
            {
                hideEmptyDays,
                hideEmptyResources
            }             = me,
            key           = me.dayTime.dateKey(date),
            day           = date.getDay(),
            eventsForDate = (hideEmptyDays || hideEmptyResources) && me.getEventsForDay(key),
            // Skip hidden, nonworking days (unless we're a single day view).
            // Skip empty days if configured to do so.
            skipDay       = (me.hiddenNonWorkingDays[day] && me.duration !== 1) || (hideEmptyDays && !eventsForDate.length);
        // Return nullish for skipped days.
        if (!skipDay) {
            const
                key            = me.dayTime.dateKey(date),
                cellData       = me.cellMap.get(key) || me.createCellData(date),
                events         = cellData.events,
                children       = [],
                {
                    calendar,
                    resourceStore
                }              = me,
                resourceFilter = calendar?.widgetMap.resourceFilter,
                resources      = resourceFilter ? resourceStore.records.filter(c => resourceFilter.value.includes(c)) : resourceStore.records;
            // One sub-column for each resource
            for (let i = 0, { length } = resources, dayColumn; i < length; i++) {
                const
                    resource        = me.resource = resources[i],
                    filterResources = e => e.resources?.includes(resource);
                // Various renderers detect whether the view owns a Resource.
                // While processing a resource column for this day, we must own that resource.
                me.resourceId = resource.id;
                // Filter down day's events to only those for this resource column
                cellData.events = events.filter(filterResources);
                // Skip the resource if it's empty and we are configured to skip empty resources
                if (!hideEmptyResources || eventsForDate.filter(filterResources).length) {
                    // The super call must not process hideEmptyDays. We might have just filtered
                    // cellData.events to empty if current resource has no events for the day.
                    me._hideEmptyDays = false;
                    dayColumn = super.getDayDomConfig(date);
                    me._hideEmptyDays = hideEmptyDays;
                    delete dayColumn.dataset.date;
                    dayColumn.dataset.resourceId = resource.id;
                    children.push(dayColumn);
                }
                // We only own a resource when creating a resource column for a day
                me.resource = me.resourceId = null;
            }
            // Restore day's full event load
            cellData.events = events;
            // A single day's column encapsulates all resources.
            // Do not return a DomSync object if all resources have been skipped as empty.
            if (children.length || !hideEmptyResources) {
                return {
                    className : {
                        'b-dayresourceview-column'        : 1,
                        [`b-day-of-week-${cellData.day}`] : 1
                    },
                    dataset : {
                        date : key
                    },
                    children
                };
            }
        }
    }
}
DayResourceView.initClass();
DayResourceView._$name = 'DayResourceView';