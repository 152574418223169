import DayView from './DayView.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
/**
 * @module Calendar/widget/WeekView
 */
/**
 * This is normally used as a {@link Calendar.view.Calendar#config-modes mode} of a Calendar (as seen in the live
 * demo below) but may be used standalone as a regular Widget.
 *
 * {@inlineexample Calendar/widget/CalendarWeekView.js}
 *
 * As a standalone widget, it will lack the capabilities of the {@link Calendar.view.Calendar Calendar}
 * class, such as keyboard-based event to event navigation and drag/drop features. As seen in this demo:
 *
 * {@inlineexample Calendar/widget/WeekView.js}
 *
 * A Panel which displays up to 7 columns of days with events for each day arranged in ascending
 * time order in each column.
 *
 * All day events, and multi day events which intersect that day are displayed in a row at the top.
 *
 * Intra day events are arranged in ascending time order down the column from the {@link Calendar.widget.DayView#config-dayStartTime} to
 * the {@link Calendar.widget.DayView#config-dayEndTime}
 *
 * Event rendering can be customized using the {@link #config-eventRenderer} method.
 *
 * @extends Calendar/widget/DayView
 *
 * @classtype weekview
 * @classtypealias week
 * @typingswidget
 */
export default class WeekView extends DayView {
    static $name = 'WeekView';
    static type = 'weekview';
    static get configurable() {
        return {
            localizableProperties : ['title', 'stepUnit'],
            title : 'L{Week}',
            stepUnit : 'L{weekUnit}',
            range : {
                magnitude : 1,
                unit      : 'week'
            },
            responsive : {
                small : {
                    descriptionFormat : 'Wp YYYY'
                },
                large : {
                    descriptionFormat : ['MMMM YYYY (Wp)', 'S{MMM} - E{MMM YYYY} (S{Wp})']
                },
                '*' : {
                    descriptionFormat : ['MMMM YYYY', 'S{MMM} - E{MMM YYYY}']
                }
            },
            week : null,
            // Override from DayView. This means that in addition to endDate being
            // locked to startDate, and being changed when startDate changes, startDate
            // is also locked to endDate and is changed when the endDate changes.
            fixedDuration : true
        };
    }
    /**
     * Returns the resource associated with this week view when used inside a {@link Calendar.widget.ResourceView}
     * @readonly
     * @member {Scheduler.model.ResourceModel} resource
     */
    calculateDuration() {
        // Override from CalendarMixin where the value is calculated from two passed dates.
        return 7;
    }
    updateWeekStartDay(weekStartDay, oldWeekStartDay) {
        super.updateWeekStartDay?.(weekStartDay);
        if (!this.isConfiguring) {
            const startDate = new Date(this.startDate);
            startDate.setDate(startDate.getDate() + weekStartDay - oldWeekStartDay);
            this.startDate = startDate;
        }
    }
    changeMonth() {
        const result = super.changeMonth(...arguments);
        result.ion({
            weekChange : 'onMonthWeekChange',
            thisObj    : this
        });
        return result;
    }
    onMonthWeekChange({ source, newDate }) {
        // We have to keep the week number in sync
        this.week = source.getWeekNumber(newDate);
    }
    changeWeek(week, oldWeek) {
        const me = this;
        // If not an array, assume it's the week number
        week = Array.isArray(week) ? week : [me.month.year, week];
        // Only return a value if a change has been requested
        if (!ObjectHelper.isEqual(week, oldWeek)) {
            const
                minDate = me.minDate || me.calendar?.minDate,
                maxDate = me.maxDate || me.calendar?.maxDate;
            // Only do date arithmetic if we need to validate.
            if (!isNaN(minDate) || !isNaN(maxDate)) {
                const
                    weekStart = me.month.getWeekStart(week),
                    weekEnd   = DateHelper.add(weekStart, me.duration, 'd');
                if (!isNaN(minDate) && weekStart < minDate) {
                    return;
                }
                if (!isNaN(maxDate) && weekEnd > maxDate) {
                    return;
                }
            }
            return week;
        }
    }
    updateWeek(week) {
        const
            me                       = this,
            { month, date, dayTime } = me;
        // If our current date is outside of the requested week, move it
        // to the week start which will set the startDate
        if (!date || !ObjectHelper.isEqual(month.getWeekNumber(date), week)) {
            me.date = dayTime.shiftDate(month.getWeekStart(week));
        }
        // In case a beforeDateChange handler.
        // vetoed the date change - we have to sync the week to our date.
        me.week = month.getWeekNumber(me.date);
    }
}
WeekView.initClass();
WeekView._$name = 'WeekView';