import CalendarMixin from './mixin/CalendarMixin.js';
import DayCellCollecter from './mixin/DayCellCollecter.js';
import DayCellRenderer from './mixin/DayCellRenderer.js';
import Panel from '../../Core/widget/Panel.js';
import DH from '../../Core/helper/DateHelper.js';
import Month from '../../Core/util/Month.js';
import DomSync from '../../Core/helper/DomSync.js';
import SDP from '../../Scheduler/widget/SchedulerDatePicker.js';
import Tooltip from '../../Core/widget/Tooltip.js';
/**
 * @module Calendar/widget/YearView
 */
const isHoverGesture = {
    hover     : 1,
    mouseover : 1
};
/**
 * This is normally used as a {@link Calendar.view.Calendar#config-modes mode} of a Calendar (as seen in the live
 * demo below) but may be used standalone as a regular Widget.
 *
 * {@inlineexample Calendar/widget/CalendarYearView.js}
 *
 * As a standalone widget, it will lack the capabilities of the {@link Calendar.view.Calendar Calendar}
 * class, such as keyboard-based event to event navigation and drag/drop features. As seen in this demo:
 *
 * {@inlineexample Calendar/widget/YearView.js}
 *
 * A Panel which displays a year's worth of months with days which have events highlighted.
 *
 * @extends Core/widget/Panel
 * @mixes Core/widget/mixin/Responsive
 * @mixes Calendar/widget/mixin/CalendarMixin
 * @mixes Calendar/widget/mixin/DayCellCollecter
 * @mixes Calendar/widget/mixin/DayCellRenderer
 *
 * @classtype yearview
 * @classtypealias year
 * @typingswidget
 */
export default class YearView extends Panel.mixin(CalendarMixin, DayCellCollecter, DayCellRenderer) {
    static $name = 'YearView';
    static type = 'yearview';
    static get configurable() {
        return {
            textContent : false,
            localizableProperties : ['title', 'stepUnit'],
            title : 'L{Year}',
            scrollable : {
                overflowY : true
            },
            stepUnit : 'L{yearUnit}',
            dragUnit : 'day',
            month : true,
            /**
             * A date which specifies the year to display. All types of calendar view have a `date`
             * config which they use to set their visible date range according to their configuration
             * and type.
             * @config {Date}
             */
            date : {
                $config : {
                    equal : 'date'
                },
                value : null
            },
            descriptionFormat : 'YYYY',
            /**
             * The year to display
             * @config {Number}
             */
            year : null,
            monitorResize : false,
            showWeekColumn : true,
            hideWeekColumnCls : 'b-hide-week-numbers',
            /**
             * The class name to add to calendar cells which are in the previous or next month.
             * @config {String}
             * @private
             */
            otherMonthCls : 'b-other-month',
            /**
             * By default, all months show six week rows. Pass `false` to only render as many rows
             * as needed.
             * @config {Boolean}
             */
            sixWeeks : true,
            /**
             * An empty function by default, but provided so that you can override it.
             *
             * This function is called for each cell rendered to allow developers to mutate the cell metadata, or
             * the CSS classes to be applied to the cell.
             *
             * The cellConfig (a {@link Core.helper.DomHelper#typedef-DomConfig}) definition passed as part of the
             * single parameter may be mutated to add `style` and `className`.
             *
             * ```javascript
             * dayCellRenderer : function(cellData) {
             *     // I don't like Mondays!
             *     if (cellData.date === 1) {
             *         cellData.cls['dayoff'] = true;
             *     }
             * }
             *
             *```
             *
             * A non-null return value from the renderer is used as the content of the day cell element.
             *
             * @config {Function} dayCellRenderer
             * @param {Object} cellData An object that contains data about the cell.
             * @param {Date} cellData.date The Date of this cell
             * @param {Scheduler.model.EventModel[]} cellData.events The array of events in this cell
             * @param {DomConfig} cellData.cellConfig A {@link Core.helper.DomHelper#typedef-DomConfig}
             *   object that contains data about the cells element.
             * @param {String} cellData.cellConfig.style The style property is an object containing
             *   style properties for the cell header element.
             * @param {String} cellData.cellConfig.cls The cls property is an object whose property
             *   names will be added to the cell element if the property value is truthy.
             * @returns {DomConfig|String|null}
             *
             * @category Rendering
             */
            dayCellRenderer : null,
            monthSelector : '.b-yearview-month-name',
            focusable : false,
            /**
             * How to indicate the presence of events for a date. The default is `heatmap` which adds
             * classes depending on how many events intersect the date.Values may be:
             *
             * * `false` - Do not show events in cells.
             * * `true` - Show a heatmap of colours the intensity of which indicates event load.
             * * `'heatmap'` - Show a heatmap of colours the intensity of which indicates event load.
             * * `'count'` - Show a themeable badge containing the event count for a date.
             * * `'dots'` - Show small event-coloured bullets up to a maximum of {@link #config-maxDots}
             * to indicate event presence.
             * @prp {Boolean|'heatmap'|'dots'}
             * @default
             */
            showEvents : 'heatmap',
            /**
             * When {@link #config-showEvents} is `'dots'`, this is the maximum number of dots to show
             * below the date number in the cell.
             *
             * The default is four. Note that increasing this may lead to a cluttered UI in which the dots
             * obscure the date figure in the cell.
             *
             * @prp {Number}
             * @default
             */
            maxDots : 4,
            /**
             * When {@link #config-overflowPopupTrigger} is not a mouseover gesture, setting this to
             * a truthy value means that a tooltip containing the event count will be shown on hover of
             * a day cell.
             *
             * It may be specified as an Object which contains {@link Core.widget.Tooltip} config options,
             * and also the following option:
             *
             * - showNoEvents - When set to `true`, a tooltip containing "No events" is shown when
             * hovering empty day cells.
             *
             * For example:
             *
             * ```javascript
             * {
             *     modes : {
             *         year : {
             *             // We want it to show if there are no visible events for that date.
             *             // And we want the tip showing to the right of the cell
             *             eventCountTip : {
             *                 showNoEvents : true,
             *                 align        : 'l-r
             *             }
             *         }
             *     }
             * }
             * ```
             * @prp {Boolean|Object<String,Boolean|String>}
             * @default
             */
            eventCountTip : null
        };
    }
    /**
     * Returns the resource associated with this year view when used inside a {@link Calendar.widget.ResourceView}
     * @readonly
     * @member {Scheduler.model.ResourceModel} resource
     */
    // Override from DayCellCollecter
    // We don't propagate. We copy all events into renderedEvents.
    createCellMap(options) {
        // We use the dateIndex, so gather all events which intersect. No need for propagation.
        const result = super.createCellMap(options = {
            skipPropagate : true,
            ...options
        });
        // YearView always places all its events as rendered events because we use skipPropagate
        // when creating the cell map, and just copy them all in so that the overflowPopup uses
        // them all. Any number of events is overflow for a YearView
        for (const cellData of result.values()) {
            cellData.renderedEvents.setEvents(cellData.events);
        }
        return result;
    }
    get date() {
        // If we have been injected with a specific date (Such from the Calendar Sidebar)
        // then use that as our anchor point. Fall back to the first visible event. If we are
        // without orientation use 1st January
        return this._date || (!this.isConfiguring && this.firstEventDate) || new Date(this.year, 0, 1);
    }
    next() {
        this.date = new Date(this.year + 1, this.date.getMonth(), this.date.getDate());
    }
    previous() {
        this.date = new Date(this.year - 1, this.date.getMonth(), this.date.getDate());
    }
    get firstEventDate() {
        const entries = [...this.cellMap.values()];
        if (entries.length) {
            return entries[0].date;
        }
    }
    get lastEventDate() {
        const entries = [...this.cellMap.values()];
        if (entries.length) {
            return entries[entries.length - 1].date;
        }
    }
    onCalendarPointerInteraction(domEvent) {
        const
            me        = this,
            superCall = super.onCalendarPointerInteraction,
            {
                target,
                type
            }         = domEvent;
        if (type === 'mousedown' || type.endsWith('click')) {
            // Any clicking after kicking off the overflow timer cancels it
            me.clearTimeout(me.cellOverflowTimer);
        }
        // Veto focusing out of the overflowPopup when mousedowning overflow button
        // (which is a cell which contains elements) so that the overflow popup doesn't flicker.
        if (type === 'mousedown' && me._overflowPopup?.isVisible && target.closest('.b-cal-cell-overflow')) {
            domEvent.preventDefault();
        }
        // If we're showing the overflow popup on click, and they clicked on an empty
        // cell, then they *might* be intending a dblclick, so we need to pause the
        // propagation of the click event into the processing
        if (me.overflowPopupTrigger === 'click' && me.autoCreate?.gesture === 'dblclick' && type === 'click' && target.closest(`.${me.emptyCellCls}`) && !me.emptyCellRenderer) {
            me.cellOverflowTimer = me.setTimeout({
                fn    : superCall,
                delay : 300,
                args  : [domEvent]
            });
        }
        else {
            return superCall.apply(me, arguments);
        }
    }
    changeMonth(month, oldMonth) {
        const
            me = this,
            listeners = {
                name       : 'yearChangeListener',
                yearChange : 'onMonthYearChange',
                thisObj    : me
            };
        me.detachListeners('yearChangeListener');
        if (month instanceof Month) {
            month.ion(listeners);
            me._year = month.year;
        }
        else {
            month = new Month({
                date               : new Date(me.year || new Date().getFullYear(), 0, 1),
                weekStartDay       : me.weekStartDay,
                hideNonWorkingDays : me.hideNonWorkingDays,
                nonWorkingDays     : me.nonWorkingDays,
                sixWeeks           : me.sixWeeks,
                internalListeners  : listeners
            });
        }
        if (me.nonWorkingDays == null) {
            me.nonWorkingDays = me._month.nonWorkingDays;
        }
        // Keep our property in line with reality
        me._year = month.year;
        return month;
    }
    onMonthYearChange({ source : { year, date } }) {
        const me = this;
        // Keep our property in line with reality
        me._year = year;
        if (!me.isConfiguring) {
            me._cellMap?.clear();
            me.date = date;
            me.refresh();
        }
    }
    // Override for the YearView. We have to scroll *months* into view
    scrollTo(date, options = { animate : true }) {
        const me = this;
        // We do not display events. If passed an event, scroll to its start date.
        date = me.ingestDate(date.isEventModel ? date.startDate : date);
        // If we do not own the date, move to that date.
        if (!DH.betweenLesser(date, me.startDate, me.endDate) || !me.getDayElement(date, true)) {
            me.date = date;
        }
        else {
            const monthEl = me.eventContentElement.querySelector(`[data-month-index="${date.getMonth()}"]`);
            monthEl && me.scrollable.scrollIntoView(monthEl, options);
        }
    }
    get eventsPerCell() {
        return 0;
    }
    get startDate() {
        return this.month.getOtherMonth(new Date(this.year, 0, 1)).startDate;
    }
    get endDate() {
        // It's exclusive of the day itself - this is a timepoint; 00:00 on the day after
        return DH.add(this.month.getOtherMonth(new Date(this.year, 11, 1)).endDate, 1, 'day');
    }
    /**
     * For a consistent API, allow startDate to set the year
     * @internal
     */
    set startDate(startDate) {
        this.date = startDate;
    }
    isValidTargetDate(date) {
        const newYear = date.getFullYear();
        if (newYear !== this.year) {
            const
                minDate = this.minDate || this.calendar?.minDate,
                maxDate = this.maxDate || this.calendar?.maxDate;
            // Only do date arithmetic if we need to.
            if (!isNaN(minDate) || !isNaN(maxDate)) {
                const { cellMonth } = this;
                if (!isNaN(minDate)) {
                    // Work out what our new startDate would be.
                    // eg startDate of Jan 2021 as a Calendar view is 27th Dec 20221.
                    const newStartDate = cellMonth.getWeekStart(cellMonth.getWeekNumber(new Date(newYear, 0, 1)));
                    // Veto navigation to before minDate.
                    if (newStartDate < minDate) {
                        return false;
                    }
                }
                if (!isNaN(maxDate)) {
                    // Move month to December to see what our new end date would be.
                    // eg endDate of Dec 2021 as a Calendar view is 8th Jan 2022.
                    cellMonth.date = new Date(newYear, 11, 31);
                    // Veto navigation to after maxDate.
                    // Month class's concept of date is inclusive. Its dates
                    // refer to a 24 hour block unlike scheduling UIs so increment it.
                    if (DH.add(cellMonth.endDate, 1, 'd') > maxDate) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    updateDate(date) {
        this.month.date = date;
        // Only scroll to the date if we are visible.
        // onInternalPaint will scroll the active date into view when we are shown
        if (this.isVisible && !this.isConfiguring) {
            this.scrollTo(date);
        }
    }
    changeYear(year) {
        if (this.isConfiguring) {
            return year;
        }
        this.month.year = year;
        return this.month.year;
    }
    changeShowEvents(showEvents, oldShowEvents) {
        return showEvents === true ? 'heatmap' : showEvents;
    }
    updateShowEvents(showEvents, oldShowEvents) {
        const { classList } = this.contentElement;
        showEvents && classList.add(`b-show-events-${showEvents}`);
        classList.remove(`b-show-events-${oldShowEvents}`);
        if (!this.isConfiguring) {
            this.doRefresh();
        }
    }
    updateShowWeekNumber() {
        this.bodyElement.classList[this.showWeekColumn ? 'remove' : 'add'](this.hideWeekColumnCls);
    }
    createCellData(date) {
        // So that createCellData can evaluate if the cell is "other month"
        // For YearView, the month needs to be moved along as the cells are created.
        this.month.month = date.getMonth();
        return super.createCellData(date);
    }
    // We must implement the CalendarMixin interface.
    // All views must expose a doRefresh method.
    doRefresh() {
        const
            me = this,
            {
                bodyElement : targetElement,
                month,
                dayCellCls,
                otherMonthCls,
                weekendCls,
                todayCls,
                nonWorkingDayCls,
                emptyCellCls,
                cellMap,
                showEvents,
                eventCountTip
            } = me,
            children = [],
            today = me.calendar?.today || new Date(),
            todayKey = DH.makeKey(today);
        for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
            month.month = monthIndex;
            const
                monthHeader = {
                    tag       : 'button',
                    className : 'b-yearview-month-name',
                    html      : DH.getMonthName(monthIndex),
                    dataset   : {
                        // Month names must not look like day cells which have data-date="YYYY-MM-DD"
                        monthDate : DH.makeKey(new Date(me.year, monthIndex, 1))
                    }
                },
                weeknameCells = [{
                    className : 'b-week-number-cell'
                }],
                weekNameRow = {
                    className : 'b-calendar-week b-calendar-weekdays',
                    children  : weeknameCells
                },
                monthElement = {
                    className : 'b-yearview-month',
                    children  : [monthHeader, weekNameRow],
                    dataset   : {
                        monthIndex
                    }
                },
                weeks = monthElement.children;
            children.push(monthElement);
            let cellIndex = 0;
            month.eachWeek((week, dates) => {
                // Populate week day cells when processing first week of month.
                if (weeknameCells.length === 1) {
                    for (let columnIndex = 0; columnIndex < 7; columnIndex++) {
                        weeknameCells.push({
                            className : {
                                'b-yearview-weekday-cell' : 1,
                                [nonWorkingDayCls]        : me.nonWorkingDays[month.canonicalDayNumbers[columnIndex]]
                            },
                            text : DH.format(dates[columnIndex], 'd1')
                        });
                    }
                }
                const
                    weekElement = {
                        className : 'b-calendar-week',
                        dataset   : {
                            week : `${week[0]},${week[1]}`
                        }
                    },
                    dayCells = weekElement.children = [{
                        tag       : 'button',
                        ariaLabel : me.L('L{Calendar.weekOfYear}', week),
                        className : 'b-week-number-cell',
                        html      : week[1]
                    }];
                weeks.push(weekElement);
                for (let columnIndex = 0; columnIndex < 7; columnIndex++) {
                    const
                        date          = dates[columnIndex],
                        isOtherMonth  = date.getMonth() !== month.month,
                        key           = DH.makeKey(date),
                        day           = date.getDay(),
                        cellData      = !me.isConfiguring && cellMap.get(key),
                        events        = cellData?.events,
                        eventCount    = isOtherMonth ? 0 : events?.length || 0,
                        showOverflow  = eventCount,
                        cell          = {
                            // tabIndex  : -1, When we implement Calendar Cell navigation
                            style     : {},
                            className : {
                                'b-day-name'   : 1,
                                [dayCellCls]   : 1,
                                [emptyCellCls] : !eventCount
                            },
                            dataset : {
                                date : key,
                                cellIndex,
                                columnIndex
                            }
                        };
                    let content = String(date.getDate());
                    Object.assign(cell.className, {
                        [weekendCls]                         : DH.weekends[day],
                        [todayCls]                           : key === todayKey && !isOtherMonth,
                        [nonWorkingDayCls]                   : me.nonWorkingDays[day],
                        [otherMonthCls]                      : isOtherMonth,
                        'b-cal-cell-overflow'                : showOverflow,
                        [SDP.getEventCountClass(eventCount)] : showOverflow,
                        [`b-day-of-week-${day}`]             : 1
                    });
                    if (me.dayCellRenderer) {
                        const result = me.callback(me.dayCellRenderer, me, [{ cellConfig : cell, date, events : cellData?.events?.map(data => data.eventRecord) || [] }]);
                        if (result != null) {
                            content = result;
                        }
                    }
                    // Event count tooltip to show only if the overflow popup is not shown on hover.
                    if (eventCountTip && !(isHoverGesture[me.overflowPopupTrigger])) {
                        const btip = eventCount ? me.L('L{ResourceInfoColumn.eventCountText}', eventCount) : eventCountTip.showNoEvents ? me.L('L{noEvents}') : null;
                        if (btip) {
                            const tipData = Tooltip.encodeConfig(eventCountTip);
                            cell.dataset = {
                                ...cell.dataset,
                                ...tipData,
                                btip
                            };
                        }
                    }
                    if (showEvents === 'dots') {
                        const children = [];
                        for (let i = 0; i < Math.min(eventCount || 0, me.maxDots); i++) {
                            const eventDomConfig = me.createEventDomConfig({
                                eventRecord : events[i].eventRecord,
                                // renders without content with just background colour
                                minimal : true
                            });
                            children.push(eventDomConfig);
                        }
                        content = [typeof content === 'object' ? content : {
                            tag  : 'span',
                            html : content
                        }, {
                            className : 'b-cal-minimal-event-container',
                            children
                        }];
                    }
                    else if (showEvents === 'count') {
                        const count = eventCount;
                        content = [typeof content === 'object' ? content : {
                            tag  : 'span',
                            html : content
                        }, count ? {
                            dataset : {
                                count
                            },
                            class : {
                                'b-cell-events-badge' : 1
                            },
                            text : count
                        } : null];
                    }
                    // Cell content
                    cell.children = [{
                        className                                           : 'b-calendar-cell-inner',
                        [typeof content === 'string' ? 'html' : 'children'] : content
                    }];
                    dayCells.push(cell);
                    cellIndex++;
                }
            });
        }
        DomSync.sync({
            targetElement,
            domConfig : {
                children
            }
        });
        me.refreshCount = (me.refreshCount || 0) + 1;
        /**
         * Fires when this YearView refreshes.
         * @param {Calendar.widget.YearView} source The triggering instance.
         * @event refresh
         */
        me.trigger('refresh');
    }
    getDayElement(date, strict) {
        if (typeof date !== 'string') {
            date = DH.makeKey(date);
        }
        // Enforce strict meaning this view must own that date
        if (strict && parseInt(date.substr(0, 4)) !== this.year) {
            return;
        }
        // In a multi month view, we must filter out dates which are in the "other month" category.
        return this.contentElement.querySelector(`.b-calendar-cell[data-date="${date}"]:not(.b-other-month)`);
    }
    // Override in this class to eliminate "other" month cells.
    getCell() {
        return this.getDayElement(...arguments);
    }
    onInternalPaint({ firstPaint }) {
        if (firstPaint) {
            this.refresh();
        }
        // If we are animating into view, we have to jump over that.
        this.setTimeout(() => this.scrollTo(this.date, { animate : false }), 10);
    }
}
YearView.initClass();
YearView._$name = 'YearView';