import EventRenderer from './mixin/EventRenderer.js';
import SchedulerDatePicker from '../../Scheduler/widget/SchedulerDatePicker.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import DomHelper from '../../Core/helper/DomHelper.js';
/**
 * @module Calendar/widget/CalendarDatePicker
 */
/**
 * A subclass of {@link Core.widget.DatePicker} which is able to show the presence of
 * events in its cells if contained in a Calendar, and {@link #config-showEvents} is set
 * to a truthy value.
 *
 * The `datepicker` Widget type is implemented by this class when this class is imported, or built
 * into a bundle, and so any {@link Core.widget.DateField} may have its
 * {@link Core.widget.PickerField#config-picker} configured to use its capabilities of showing
 * the presence of events in its date cells.
 *
 * @extends Scheduler/widget/SchedulerDatePicker
 * @mixes Calendar/widget/mixin/EventRenderer
 * @classtype datepicker
 * @widget
 */
export default class CalendarDatePicker extends SchedulerDatePicker.mixin(EventRenderer) {
    static $name = 'CalendarDatePicker';
    // Factoryable type name
    static type = 'datepicker';
    static configurable = {
        /**
         * How to show presence of events in the configured {@link #config-eventStore} in the
         * day cells. Values may be:
         *
         * * `false` - Do not show events in cells.
         * * `true` - Show a themeable bullet to indicate the presence of events for a date.
         * * `'count'` - Show a themeable badge containing the event count for a date.
         * * `'dots'` - Show small event-coloured bullets up to a maximum of {@link #config-maxDots} to indicate event presence.
         * @prp {Boolean|'count'|'dots'}
         * @default false
         */
        showEvents : null,
        /**
         * When {@link #config-showEvents} is `'dots'`, this is the maximum number of dots to show
         * below the date number in the cell.
         *
         * The default is four. Note that increasing this may lead to a cluttered UI in which the dots
         * obscure the date figure in the cell.
         *
         * @prp {Number}
         * @default
         */
        maxDots : 4,
        /**
         * When {@link #config-showEvents} is __not__ `'count'`, setting this to
         * `true` means that a tooltip containing the event count will be shown on hover of
         * a day cell.
         *
         * @prp {Boolean}
         * @default
         */
        eventCountTip : null,
        alwaysRefreshOnMonthChange : true
    };
    construct(config) {
        const calendar = (config.owner || config.parent)?.up('calendar');
        if (calendar && !config.eventStore) {
            config.eventStore = calendar.eventStore;
        }
        super.construct(...arguments);
    }
    updateMaxDots() {
        this.refresh();
    }
    refreshEventsMap() {
        const me = this;
        if (me.showEvents) {
            me.eventsMap = me.eventStore[me.showEvents === 'dots' ? 'getEvents' : 'getEventCounts']({
                startDate : me.startDate,
                endDate   : me.endDate,
                dateMap   : me.eventsMap || (me.eventsMap = new Map()),
                filter    : me.eventFilter
            });
        }
    }
    cellRenderer({ cell, date }) {
        const
            { showEvents } = this,
            isCount        = showEvents === 'count',
            isDots         = showEvents === 'dots',
            eventInfo      = this.eventsMap?.get?.(DateHelper.makeKey(date)),
            count          = isDots ? eventInfo?.length : eventInfo,
            countCls       = isCount ? 'b-cell-events-badge' : isDots ? 'b-cal-minimal-event-container' : 'b-icon b-icon-circle';
        if (isDots) {
            // We replace the text-only date with an element so it can carry the background
            // and the background will not obscure the dots.
            cell.innerHTML = '';
            DomHelper.createElement({
                class  : 'b-date-cell-inner',
                text   : date.getDate(),
                parent : cell
            });
        }
        delete cell.parentNode.dataset.btip;
        if (count) {
            if (!isCount && this.eventCountTip) {
                cell.parentNode.dataset.btip = this.L('L{ResourceInfoColumn.eventCountText}', count);
            }
            const cellContent = {
                dataset : {
                    count
                },
                class : {
                    [countCls]                                      : 1,
                    [SchedulerDatePicker.getEventCountClass(count)] : 1
                },
                parent                  : cell,
                [isCount ? 'text' : ''] : count
            };
            if (isDots) {
                const children = cellContent.children = [];
                for (let i = 0; i < Math.min(eventInfo?.length || 0, this.maxDots); i++) {
                    const eventDomConfig = this.createEventDomConfig({
                        eventRecord : eventInfo[i],
                        // renders without content with just background colour
                        minimal : true
                    });
                    children.push(eventDomConfig);
                }
            }
            DomHelper.createElement(cellContent);
        }
    }
    static setupClass(meta) {
        // We take over the type name 'datepicker' when we are in the app
        meta.replaceType = true;
        super.setupClass(meta);
    }
}
CalendarDatePicker.initClass();
CalendarDatePicker._$name = 'CalendarDatePicker';