import AbstractTimeRanges from './AbstractTimeRanges.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import DateHelper from '../../Core/helper/DateHelper.js';
import VersionHelper from '../../Core/helper/VersionHelper.js';
import AttachToProjectMixin from '../data/mixin/AttachToProjectMixin.js';
import NonWorkingTimeMixin from './mixin/NonWorkingTimeMixin.js';
/**
 * @module Scheduler/feature/NonWorkingTime
 */
/**
 * Feature that allows styling of weekends (and other non-working time) by adding timeRanges for those days.
 *
 * {@inlineexample Scheduler/feature/NonWorkingTime.js}
 *
 * By default, the basic Scheduler's calendar is empty. When enabling this feature in the basic Scheduler, it injects
 * Saturday and Sunday weekend intervals if no intervals are encountered. For Scheduler Pro, it visualizes the project
 * calendar and does not automatically inject anything. You have to define a Calendar in the application and assign it
 * to the project, for more information on how to do that, please see Scheduler Pro's Scheduling/Calendars guide.
 *
 * Please note that to not clutter the view (and have a large negative effect on performance) the feature does not
 * render ranges shorter than the base unit used by the time axis. The behavior can be disabled with
 * {@link #config-hideRangesOnZooming} config.
 *
 * The feature also bails out of rendering ranges for very zoomed out views completely for the same reasons (see
 * {@link #config-maxTimeAxisUnit} for details).
 *
 * Also note that the feature uses virtualized rendering, only the currently visible non-working-time ranges are
 * available in the DOM.
 *
 * This feature is **disabled** by default for Scheduler, but **enabled** by default for Scheduler Pro.
 * For info on enabling it, see {@link Grid/view/mixin/GridFeatures}.
 *
 * @extends Scheduler/feature/AbstractTimeRanges
 * @demo Scheduler/nonworkingdays
 * @classtype nonWorkingTime
 * @mixes Scheduler/feature/mixin/NonWorkingTimeMixin
 * @feature
 */
export default class NonWorkingTime extends AbstractTimeRanges.mixin(AttachToProjectMixin, NonWorkingTimeMixin) {
    //region Default config
    static $name = 'NonWorkingTime';
    /** @hideconfigs enableResizing, store*/
    static get defaultConfig() {
        return {
            /**
             * Set to `true` to highlight non-working periods of time
             * @config {Boolean}
             * @deprecated Since 5.2.0, will be removed since the feature is pointless if set to false
             */
            highlightWeekends : null,
            /**
             * The feature by default does not render ranges smaller than the base unit used by the time axis.
             * Set this config to `false` to disable this behavior.
             *
             * <div class="note">The {@link #config-maxTimeAxisUnit} config defines a zoom level at which to bail out of
             * rendering ranges completely.</div>
             * @config {Boolean}
             * @default
             */
            hideRangesOnZooming : true,
            showHeaderElements : true,
            showLabelInBody    : true,
            autoGeneratedWeekends : false
        };
    }
    static pluginConfig = {
        chain : [
            'onInternalPaint',
            'attachToProject',
            'updateLocalization',
            'onConfigChange',
            'onSchedulerHorizontalScroll'
        ]
    };
    //endregion
    //region Init & destroy
    doDestroy() {
        this.attachToCalendar(null);
        super.doDestroy();
    }
    set highlightWeekends(highlight) {
        VersionHelper.deprecate('Scheduler', '6.0.0', 'Deprecated in favour of disabling the feature');
        this.disabled = !highlight;
    }
    get highlightWeekends() {
        return !this.disabled;
    }
    onConfigChange({ name }) {
        if (!this.isConfiguring && name === 'fillTicks') {
            this.refresh();
        }
    }
    //endregion
    //region Project
    attachToProject(project) {
        super.attachToProject(project);
        this.attachToCalendar(project.effectiveCalendar);
        // if there's no graph yet - need to delay this call until it appears, but not for scheduler
        if (!project.graph && !this.client.isScheduler) {
            project.ion({
                name      : 'project',
                dataReady : { fn : () => this.attachToCalendar(project.effectiveCalendar), once : true },
                thisObj   : this
            });
        }
        project.ion({
            name           : 'project',
            calendarChange : () => this.attachToCalendar(project.effectiveCalendar),
            thisObj        : this
        });
    }
    //endregion
    //region TimeAxisViewModel
    onTimeAxisViewModelUpdate(...args) {
        this._timeAxisUnitDurationMs = null;
        return super.onTimeAxisViewModelUpdate(...args);
    }
    //endregion
    //region Calendar
    attachToCalendar(calendar) {
        const
            me                  = this,
            { project, client } = me;
        me.detachListeners('calendar');
        me.autoGeneratedWeekends = false;
        if (calendar) {
            // Sets up a default weekend calendar for basic Scheduler, when no calendar is set
            me.setupDefaultCalendar();
            calendar.intervalStore.ion({
                name   : 'calendar',
                change : () => me.setTimeout(() => me.refresh(), 1)
            });
        }
        // On changing calendar we react to a data level event which is triggered after project refresh.
        // Redraw right away
        if (client.isEngineReady && !client.project.isDelayingCalculation && !client.isDestroying) {
            me.refresh();
        }
        // Initially there is no guarantee we are ready to draw, wait for refresh
        else if (!project.isDestroyed) {
            me.detachListeners('initialProjectListener');
            project.ion({
                name : 'initialProjectListener',
                refresh({ isCalculated }) {
                    // Cant render early, have to wait for calculations
                    if (isCalculated !== false) {
                        me.refresh();
                        me.detachListeners('initialProjectListener');
                    }
                },
                thisObj : me
            });
        }
    }
    get calendar() {
        return this.project?.effectiveCalendar;
    }
    //endregion
    //region Draw
    get timeAxisUnitDurationMs() {
        // calculate and cache duration of the timeAxis unit in milliseconds
        if (!this._timeAxisUnitDurationMs) {
            this._timeAxisUnitDurationMs = DateHelper.as('ms', 1, this.client.timeAxis.unit);
        }
        return this._timeAxisUnitDurationMs;
    }
    /**
     * Based on this method result the feature decides whether the provided non-working period should
     * be rendered or not.
     * The method checks that the range has non-zero {@link Scheduler.model.TimeSpan#field-duration},
     * lays in the visible timespan and its duration is longer or equal the base timeaxis unit
     * (if {@link #config-hideRangesOnZooming} is `true`).
     *
     * Override the method to implement your custom range rendering vetoing logic.
     * @param {Scheduler.model.TimeSpan} range Range to render.
     * @returns {Boolean} `true` if the range should be rendered and `false` otherwise.
     */
    shouldRenderRange(range, start, end) {
        // if the range is longer or equal than one timeAxis unit then render it
        return super.shouldRenderRange(range, start, end) && (!this.hideRangesOnZooming || range.durationMS >= this.timeAxisUnitDurationMs);
    }
    // Calendar intervals as TimeSpans, with adjacent intervals merged to create fewer
    get timeRanges() {
        const me = this;
        if (!me._timeRanges) {
            me._timeRanges = me.getCalendarTimeRanges(me.calendar);
        }
        return me._timeRanges;
    }
    //endregion
}
NonWorkingTime._$name = 'NonWorkingTime'; GridFeatureManager.registerFeature(NonWorkingTime, false, 'Scheduler');
GridFeatureManager.registerFeature(NonWorkingTime, true, ['SchedulerPro', 'Gantt', 'ResourceHistogram']);
